import StaffByCompanyComponent from "../components/superCOO/StaffByCompany";

const StaffByCompanyPage = () => {
  return (
    <>
      <StaffByCompanyComponent />
    </>
  );
};
export default StaffByCompanyPage;
