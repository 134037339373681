import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { useParams } from "react-router-dom";
import ModalForNewOLTCreation from "../Model/CreateNewOLTModal";
import { formatDate } from "../../helpers/date";
import ChangeStaffPasswordModal from "../Model/ChangeStaffPasswordModel";
type ActiveData = {
  intPkUserID: number | string;
  userFullname: string;
};
const StaffByCompanyComponent: React.FC<GP> = ({}) => {
  const [
    getApiData,
    apiResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<OLTsByCompany>("superCOO/getStaffByCompany");
  const { intPkCompanyID, companyName } = useParams();
  const [allStaff, setAllStaff] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [activeData, setActiveData] = useState<ActiveData>({
    intPkUserID: "",
    userFullname: "",
  });
  useEffect(() => {
    getApiData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponseData !== null) {
      const { responseData } = apiResponseData;
      setAllStaff(responseData || []);
    }
  }, [apiResponseData]);
  const handleClose = (isCall: boolean) => {
    setShow(false);
    if (isCall === true) {
      getApiData({ intPkCompanyID: Number(intPkCompanyID) });
    }
  };
  return (
    <>
      <ToastContainer />
      {show === true ? (
        <ChangeStaffPasswordModal
          show={show}
          handleClose={handleClose}
          intPkUserID={activeData.intPkUserID}
          userFullname={activeData.userFullname}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Staff Of {companyName}
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">User Full Name</th>
                      <th className="table-header-standard">UserName</th>
                      <th className="table-header-standard">Password</th>
                      <th className="table-header-standard">Contact Number</th>
                      <th className="table-header-standard">User Type</th>
                      <th className="table-header-standard">Created Date</th>
                      <th className="table-header-standard">Update Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allStaff.map((item: any) => {
                      return (
                        <tr key={item.intPkUserID}>
                          <td>{item.userFullname}</td>
                          <td>{item.username}</td>
                          <td>{item.password}</td>
                          <td>{item.contactNumber}</td>
                          <td>{item.userType}</td>
                          <td>{formatDate(item.dateCreated)}</td>
                          <td>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveData({
                                  intPkUserID: item.intPkUserID,
                                  userFullname: item.userFullname,
                                });
                                setShow(true);
                              }}
                              className="button-blue link-class"
                            >
                              Update Password
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default StaffByCompanyComponent;
