import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
type Props = {
  isLoggedIn?: boolean;
};

const CreateAreaComponent: React.FC<Props> = ({}) => {
  const [
    postCreateAreaData,
    createAreaResponseData,
    areaError,
    areaLoading,
    areaMessage,
    areaErrorCount,
  ] = useAxiosPost<CreateAreaProp>("areas/createArea");

  const CreateOltValidationSchema = yup.object().shape({
    areaName: yup.string().required(),
    areaTypeEnum: yup.string().required(),
  });

  useEffect(() => {
    if (areaError !== null && areaErrorCount !== 0) {
      notifyError(areaError);
    }
  }, [areaError, areaErrorCount]);
  useEffect(() => {
    if (createAreaResponseData !== null) {
      const { message } = createAreaResponseData;
      if (message) {
        notifySuccess(message);
      }
      setTimeout(() => {
        window.location.href = "/all-areas";
      }, 5000);
    }
  }, [createAreaResponseData]);

  const [areaData, setAreaData] = useState<CreateAreaProp>({
    areaName: "",
    areaTypeEnum: "MAIN_AREA",
  });
  const handleCreateArea = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateAreaProp = areaData;
    try {
      const isValid = await CreateOltValidationSchema.validate(dataToSend);
      postCreateAreaData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAreaData({
      ...areaData,
      [name]: value,
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={areaData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="6" md="6" className="bg-dark text-white p-5">
            <h3 className="mb-5 text-center">Create New Area</h3>
            <Form>
              {renderInput(`areaName`, "Enter Area Name", `Enter Area Name`)}
              <Button
                onClick={handleCreateArea}
                variant="primary"
                type="submit"
                disabled={areaLoading}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default CreateAreaComponent;
