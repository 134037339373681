import React from "react";
import { PieChart } from "react-minimal-pie-chart";

const defaultLabelStyle = {
  fontSize: "8px",
  fontFamily: "sans-serif",
  fill: "white",
};
const PieChartComponent: React.FC<PieChartData> = ({ pieChartData }) => {
  return (
    <>
      <PieChart
        data={pieChartData}
        label={({ dataEntry }) => dataEntry.value + "%"}
        labelStyle={{
          ...defaultLabelStyle,
        }}
      />
    </>
  );
};
export default PieChartComponent;
