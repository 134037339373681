import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";

const UpdateEmailModalAtSignupComponent: React.FC<
  ShowModalForUpdateEmailAtSignup
> = ({ show, handleClose, intPkUserID }) => {
  const [
    postCrateNewPricePolicy,
    pricePolicyResponse,
    pricePolicyError,
    pricePolicyLoading,
    endUserMessage,
    pricePolicyErrorCount,
  ] = useAxiosPost<UpdateEmailAtSignUpProp>(
    "users/auth/changeSignedUpUserEmail"
  );

  useEffect(() => {
    if (pricePolicyError !== null && pricePolicyErrorCount !== 0) {
      notifyError(pricePolicyError);
    }
  }, [pricePolicyError, pricePolicyErrorCount]);
  useEffect(() => {
    if (pricePolicyResponse !== null) {
      const response: any = pricePolicyResponse;
      const { message, responseData } = response;

      if (message) {
        notifySuccess(message);
        handleClose(true);
      }
    }
  }, [pricePolicyResponse]);

  const updateEmailValidationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Invalid Email"),
  });
  const [email, setEmail] = useState<string>("");

  const handleUpdateEmail = async (event: any) => {
    event.preventDefault();

    try {
      const dataToSend: any = {
        email,
        intPkUserID,
      };
      const isValid = await updateEmailValidationSchema.validate(dataToSend);
      dataToSend.newEmail = email;
      postCrateNewPricePolicy(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Update Email <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className=" p-5">
                <h3 className="mb-5 text-center">Update Email</h3>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label> Your New Email</Form.Label>
                    <Form.Control
                      placeholder={`Your Email`}
                      type={`text`}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Button
                    onClick={handleUpdateEmail}
                    variant="primary"
                    type="submit"
                    disabled={pricePolicyLoading}
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UpdateEmailModalAtSignupComponent;
