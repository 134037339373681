import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import AssignPonPortToManager from "../Model/AssignManagerToPonPort";

const AllPonPortsBySlotComponent: React.FC<GP> = ({}) => {
  const [
    getAllSlotsData,
    slotsResponseData,
    slotError,
    slotLoading,
    oltMessage,
    slotErrorCount,
  ] = useAxiosPost<PonPortResponseProp>("slots/ponports/getPonPortsSlotWise");

  const [
    getMyManagersData,
    ManagersResponse,
    managersError,
    ManagersLoading,
    managersMessage,
    managersErrorCount,
  ] = useAxiosPost<ResponseProp>("managers/getMyManagers");

  const [
    assignPonPortAPi,
    assignPonPortApiResponse,
    assignmentError,
    assignmentLoading,
    assignmentMessage,
    assignmentErrorCount,
  ] = useAxiosPost<AssignManagerToPonPortProp>(
    "slots/ponports/assignPonPortToManager"
  );

  useEffect(() => {
    if (assignmentError !== null && assignmentErrorCount !== 0) {
      notifyError(assignmentError);
    }
  }, [assignmentError, assignmentErrorCount]);
  useEffect(() => {
    if (assignPonPortApiResponse !== null) {
      const { message } = assignPonPortApiResponse;
      if (message) {
        notifySuccess(message);
      }
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [assignPonPortApiResponse]);
  const [allSlots, setAllSlots] = useState<any>([]);
  const [activePonPort, setActivePonPort] = useState<number>(-1);
  const [allManagers, setAllManagers] = useState<any>([]);
  const { intPkSlotID, intPkOltID } = useParams();
  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    getAllSlotsData({
      intPkSlotID: Number(intPkSlotID),
      intPkOltID: Number(intPkOltID),
    });
  }, [intPkSlotID, intPkOltID]);
  useEffect(() => {
    getMyManagersData({});
  }, []);
  useEffect(() => {
    if (slotError !== null && slotErrorCount !== 0) {
      notifyError(slotError);
    }
  }, [slotError, slotErrorCount]);
  useEffect(() => {
    if (slotsResponseData !== null) {
      const { responseData } = slotsResponseData;
      setAllSlots(responseData || []);
    }
  }, [slotsResponseData]);

  useEffect(() => {
    if (ManagersResponse !== null) {
      const { responseData } = ManagersResponse;
      setAllManagers(responseData || []);
    }
  }, [ManagersResponse]);
  const assignManagerHandler = (managerId: number, ponPortId: number) => {
    //lets hit the api
    assignPonPortAPi({
      intPkUserID: managerId,
      intPkPonPortID: ponPortId,
      intPkOltID: Number(intPkOltID),
    });
  };

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Pon Ports By Slot
            </Card.Title>
            <Row className="mt-5">
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">PonPort Name</th>
                      <th className="table-header-standard">Olt Frame</th>
                      <th className="table-header-standard">Olt Slot</th>
                      <th className="table-header-standard">Olt PonPort</th>
                      <th className="table-header-standard">
                        Max Number Of Onts
                      </th>
                      <th className="table-header-standard">
                        Ponport Default VLAN
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSlots.map((ponPort: any) => {
                      return (
                        <tr key={ponPort.intPkPonPortID}>
                          <td>{ponPort.ponPortName}</td>
                          <td>{ponPort.oltFrame}</td>
                          <td>{ponPort.oltSlot}</td>
                          <td>{ponPort.oltPonPort}</td>
                          <td>{ponPort.maxNumberOfOnts}</td>
                          <td>{ponPort.defaultPonPortVLAN}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllPonPortsBySlotComponent;
