import React, { useEffect, useState, ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";

import { toast } from "react-toastify";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
import { removeSpacesFromString } from "../../helpers/strings";
const CreateNewBankModalComponent: React.FC<
  CreateNewBankModalComponentProp
> = ({ handleClose, show }) => {
  const [
    postBotData,
    linkedBotsResponse,
    linkingError,
    linkingLoading,
    vlanMessage,
    linkingErrorCount,
  ] = useAxiosPost<createNewBankProp>("superAdmin/createNewBank");
  const UpdatePasswordForStaffValidationSchema = yup.object().shape({
    bankName: yup.string().required(),
  });

  const [bankName, setBankName] = useState<string>("");
  const handleCreateVlan = async (event: any) => {
    event.preventDefault();
    const dataToSend: createNewBankProp = {
      bankName: bankName.trim(),
    };
    try {
      const isValid = await UpdatePasswordForStaffValidationSchema.validate(
        dataToSend
      );

      postBotData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (linkingError !== null && linkingErrorCount !== 0) {
      notifyError(linkingError);
    }
  }, [linkingError, linkingErrorCount]);
  useEffect(() => {
    if (linkedBotsResponse !== null) {
      const { message } = linkedBotsResponse;
      if (message) {
        notifySuccess(message || "Bank Created Successfully");
      }
      handleClose(true);
    }
  }, [linkedBotsResponse]);
  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Create New Bank <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label> Bank Name</Form.Label>
            <Form.Control
              placeholder={`Bank Name`}
              type="text"
              value={bankName}
              onChange={(e) => {
                setBankName(e.target.value.trimStart());
              }}
            />
          </Form.Group>

          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateVlan}
              disabled={linkingLoading}
              className="button-primary"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateNewBankModalComponent;
