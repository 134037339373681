import React, { useEffect } from "react";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import PieChartComponent from "../Charts/Pie";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OLTImage from "./../../assets/pngs/olt/olt.png";
import VsolEponOLTImage from "./../../assets/pngs/olt/vsol.png";
import VsolGponOLTImage from "./../../assets/pngs/olt/vsolGpon.png";
import "./../../assets/styles/olt.css";
import LinkButtonImage from "./../../assets/svgs/goto/Link.svg";
import ColdTemprature from "./../../assets/svgs/temprature/cold.svg";
import NormalTemprature from "./../../assets/svgs/temprature/normal.svg";
import UpsetTemprature from "./../../assets/svgs/temprature/upset.svg";
import TooHightTemprature from "./../../assets/svgs/temprature/tooHigh.svg";
import RebootedStatus from "./../../assets/svgs/statuses/rebootingWhite.svg";
import RebootingStatus from "./../../assets/svgs/statuses/rebooting.svg";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { confirmAlert } from "react-confirm-alert"; // Import
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";

const SingleOLTCard: React.FC<SingleOLTData> = ({
  healthStats,
  oltName,
  oltDefaultVlan,
  oltHealthStats,
  intPkOltID,
  index,
  isOltEponEnabled,
  isOltGponEnabled,
  brand,
  model,
  OltCpuUsagePercentage,
  OltFirmwareVersion,
  OltHardwareVersion,
  OltMemoryUsagePercentage,
  OltNameInOLT,
  OltOwnSystemMacAddress,
  OltUptime,
  host,
  oltTemperature,
  serialNumber,
  isOltRebooted,
  reloadTheApi,
  showGoto = true,
}) => {
  const [
    rebootOlt,
    rebootOltResponse,
    rebootOltError,
    rebootLoading,
    rebootMessage,
    rebootOltErrorCount,
  ] = useAxiosPost<RebootOltProp>("netflow/requests/rebootOLT", true);
  const navigate = useNavigate();
  const handleOpenOLT = (e: any) => {
    if (showGoto == false) {
      return false;
    }
    if (isOltRebooted === true) {
      return;
    }
    e.preventDefault();
    if (isOltGponEnabled == 1 && brand == "Huawei") {
      navigate(`/browse-slots-by-olt/${intPkOltID}/${oltName}`);
    } else if (isOltGponEnabled == 1 && brand == "VSOL") {
      navigate(`/browse-pon-ports-by-slot/-1/0/${oltName}/${intPkOltID}/1/3`);
    } else if (isOltEponEnabled == 1 && brand == "VSOL") {
      navigate(`/browse-pon-ports-by-slot/-1/0/${oltName}/${intPkOltID}/1/1`);
    } else {
      navigate(`/browse-slots-by-olt/${intPkOltID}/${oltName}`);
    }
  };

  useEffect(() => {
    if (rebootOltError !== null && rebootOltErrorCount !== 0) {
      notifyError(rebootOltError);
    }
  }, [rebootOltError, rebootOltErrorCount]);
  useEffect(() => {
    if (rebootOltResponse !== null) {
      const { responseData } = rebootOltResponse;
      notifySuccess(`Olt Reboot Request Logged`);
      reloadTheApi();
    }
  }, [rebootOltResponse]);
  const handleOltReboot = () => {
    confirmAlert({
      title: "OLT Reboot",
      message: "Do you Want to Reboot OLT ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Reboot");
            rebootOlt({ intPkOltID });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const getImage = () => {
    if (brand === "Huawei" && isOltGponEnabled === 1) {
      //it's Huawei
      return (
        <img
          onClick={(e: any) => handleOpenOLT(e)}
          src={OLTImage}
          style={{ maxWidth: "80%", cursor: "pointer" }}
          className="olt-image-primary"
        />
      );
    }
    if (brand === "VSOL" && isOltGponEnabled === 1) {
      return (
        <img
          onClick={(e: any) => handleOpenOLT(e)}
          src={VsolGponOLTImage}
          style={{ maxWidth: "80%", cursor: "pointer" }}
          className="olt-image-primary"
        />
      );
    }
    if (brand === "VSOL" && isOltEponEnabled === 1) {
      return (
        <img
          onClick={(e: any) => handleOpenOLT(e)}
          src={VsolEponOLTImage}
          style={{ maxWidth: "80%", cursor: "pointer" }}
          className="olt-image-primary"
        />
      );
    }
    return (
      <img
        src={VsolGponOLTImage}
        style={{ maxWidth: "80%" }}
        className="olt-image-primary"
      />
    );
  };
  const showTemperatureImage = () => {
    const temp = Number(oltTemperature);
    if (temp < 1) {
      return (
        <img
          src={ColdTemprature}
          style={{ maxHeight: "27px", height: "27px" }}
        />
      );
    }
    if (temp >= 1 && temp <= 30) {
      return (
        <img
          src={NormalTemprature}
          style={{ maxHeight: "27px", height: "27px" }}
        />
      );
    }
    if (temp >= 31 && temp < 50) {
      return (
        <img
          src={UpsetTemprature}
          style={{ maxHeight: "27px", height: "27px" }}
        />
      );
    }
    if (temp >= 50) {
      return (
        <img
          src={TooHightTemprature}
          style={{ maxHeight: "27px", height: "27px" }}
        />
      );
    }
  };
  const getClassName = () => {
    const temp = Number(oltTemperature);
    if (temp < 1) {
      return "cold-temprature";
    }
    if (temp >= 1 && temp <= 30) {
      return "normal-temprature";
    }
    if (temp >= 31 && temp <= 50) {
      return "upset-temprature";
    }
    if (temp > 50) {
      return "too-high-temprature";
    }
  };
  const getText = () => {
    if (isOltEponEnabled == 1) {
      return "EPON";
    }
    if (isOltGponEnabled == 1) {
      return "GPON";
    }
  };
  const getBlinkingDot = () => {
    const temp = Number(oltTemperature);

    if (temp >= 40 && temp < 50) {
      return (
        <>
          <span className="image-float-left blinking-image ">
            <span style={{ color: "RED" }}>{getText()}</span>
            <img
              style={{ maxHeight: "27px", height: "27px" }}
              className=""
              src={UpsetTemprature}
            />
          </span>
        </>
      );
    }
    if (temp >= 50) {
      return (
        <>
          <span className="image-float-left blinking-image ">
            <span style={{ color: "RED" }}>{getText()}</span>
            <img
              style={{ maxHeight: "27px", height: "27px" }}
              className=""
              src={TooHightTemprature}
            />
          </span>
        </>
      );
    }
    return (
      <>
        <span className="image-float-left blinking-image ">
          <span style={{ color: "RED" }}>{getText()}</span>
        </span>
      </>
    );
  };

  return (
    <>
      <Card style={{ marginBottom: "10px" }} className="text-center">
        <Card.Body>
          <Card.Title
            className="olt-heading"
            style={{ cursor: "pointer" }}
            onClick={(e: any) => handleOpenOLT(e)}
          >
            {brand === "VSOL" ? getBlinkingDot() : null}
            {oltName}

            <span className="olt-counter">
              {index < 10 ? "0" + index : index}
            </span>
            <br />
          </Card.Title>
          {brand === "VSOL" ? (
            <>
              {isOltRebooted === false ? (
                <>
                  <span
                    onClick={(e) => {
                      if (rebootLoading) {
                        return;
                      }
                      const role = getUserType();
                      if (
                        role === "" ||
                        role == "DEMO_DEALER" ||
                        role === "DEMO_ADMIN"
                      ) {
                        notifyDemoAccountError();
                        return;
                      }
                      handleOltReboot();
                    }}
                    style={{ float: "left", marginTop: "5px" }}
                  >
                    {" "}
                    <span className="reboot-olt-button">
                      <span>OLT Reboot</span>
                      <img
                        style={{ marginLeft: "5px", height: "30px" }}
                        src={RebootedStatus}
                      />
                    </span>
                  </span>
                </>
              ) : (
                <span style={{ float: "left", marginTop: "5px" }}>
                  {" "}
                  <span className="rebooting-olt-button">
                    <span>OLT Rebooting </span>
                    <img
                      style={{ marginLeft: "5px", height: "30px" }}
                      src={RebootingStatus}
                    />
                  </span>
                </span>
              )}
              {showGoto === true ? (
                <>
                  <button
                    className="create-vlan-button"
                    onClick={(e) => {
                      navigate(`/sync-vlans-with-olt/${intPkOltID}`);
                    }}
                  >
                    VLANs
                  </button>
                </>
              ) : null}
            </>
          ) : null}
        </Card.Body>
        {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12} xxl={12}>
            {getImage()}
            {/* <img src={OLTImage} className="olt-image-primary" /> */}
            {/* <PieChartComponent pieChartData={healthStats} /> */}
          </Col>
          <Col md={12} lg={12} sm={12} xs={12} xl={12} xxl={12}>
            {/* <Card.Title className="olt-heading-small">
              OLT Default VLAN : {oltDefaultVlan}
            </Card.Title> */}
          </Col>
          {brand === "VSOL" ? (
            <>
              <Col
                className="mb-5 mt-2 olt-stats-container"
                md={12}
                lg={12}
                sm={12}
                xs={12}
                xl={12}
                xxl={12}
              >
                <Row style={{ textAlign: "left", marginLeft: "10%" }}>
                  <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        Model
                      </span>{" "}
                      :
                      <span className="data-r-field olt-card-data-labels">
                        {model}
                      </span>
                    </span>
                  </Col>
                  <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        Serial No
                      </span>
                      :
                      <span className="data-r-field olt-card-data-labels">
                        {serialNumber}
                      </span>
                    </span>
                  </Col>
                  <Col md={5} lg={5} sm={12} xs={12} xl={5} xxl={5}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        Up Time
                      </span>{" "}
                      :
                      <span className="data-r-field olt-card-data-labels">
                        {OltUptime}
                      </span>
                    </span>
                  </Col>
                </Row>
                <Row
                  className="mt-3"
                  style={{ textAlign: "left", marginLeft: "10%" }}
                >
                  <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        Firmware
                      </span>
                      :{" "}
                      <span className="data-r-field olt-card-data-labels">
                        {OltFirmwareVersion}
                      </span>
                    </span>
                  </Col>
                  <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        Hardware
                      </span>
                      :
                      <span className="data-r-field olt-card-data-labels">
                        {OltHardwareVersion}
                      </span>
                    </span>
                  </Col>
                  <Col md={5} lg={5} sm={12} xs={12} xl={5} xxl={5}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        Temperature
                      </span>
                      :
                      <span
                        className={`data-r-field olt-card-data-labels ${getClassName()}`}
                      >
                        {showTemperatureImage()} {oltTemperature}&deg;C
                      </span>
                    </span>
                  </Col>
                </Row>
                <Row
                  className="mt-3"
                  style={{ textAlign: "left", marginLeft: "10%" }}
                >
                  <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        Memory
                      </span>
                      <span className="data-r-field olt-card-data-labels">
                        {OltMemoryUsagePercentage} %
                      </span>
                    </span>
                  </Col>
                  <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        CPU
                      </span>
                      :
                      <span className="data-r-field olt-card-data-labels">
                        {OltCpuUsagePercentage} %
                      </span>
                    </span>
                  </Col>
                  <Col md={5} lg={5} sm={12} xs={12} xl={5} xxl={5}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        Mac Address
                      </span>{" "}
                      :
                      <span className="data-r-field olt-card-data-labels">
                        {OltOwnSystemMacAddress}
                      </span>
                    </span>
                  </Col>
                </Row>
                <Row
                  className="mt-3"
                  style={{ textAlign: "left", marginLeft: "10%" }}
                >
                  <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}></Col>
                  <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}></Col>
                  <Col md={5} lg={5} sm={12} xs={12} xl={5} xxl={5}>
                    <span className="bullet-point-for-olt-view">
                      <span className="data-r-label olt-card-data-labels">
                        IP
                      </span>{" "}
                      :
                      <span className="data-r-field olt-card-data-labels">
                        {host}
                      </span>
                    </span>
                  </Col>
                </Row>
              </Col>
            </>
          ) : null}

          <Col
            className="mb-5 mt-2 olt-stats-container"
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            xxl={12}
          >
            <Container>
              <Row className="justify-content-center">
                <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                  <p>
                    <span className="online-circle"></span>{" "}
                    <span className="stats-counter">
                      {oltHealthStats.totalOnlineOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Online</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="offline-circle"></span>{" "}
                    <span className="stats-counter">
                      {oltHealthStats.totalOfflineOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Offline</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="upset-circle"></span>{" "}
                    <span className="stats-counter">
                      {oltHealthStats.totalUpsetOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Upset</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="inactive-circle"></span>{" "}
                    <span className="stats-counter">
                      {oltHealthStats.totalInActiveOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">InActive</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  {" "}
                  <p>
                    <span className="invisible-circle"></span>{" "}
                    <span className="stats-counter">
                      {oltHealthStats.totalInInvisibleOnts > 0
                        ? oltHealthStats.totalInInvisibleOnts
                        : 0}
                    </span>
                  </p>
                  <p className="stats-counter-label">InVisible</p>
                </Col>
              </Row>
            </Container>
          </Col>
          {showGoto == true ? (
            <>
              <Col
                className="mt-1 mb-3"
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                sm={12}
                xs={12}
              >
                <img
                  onClick={(e: any) => handleOpenOLT(e)}
                  className="image-float-right"
                  src={LinkButtonImage}
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </>
          ) : null}
        </Row>
      </Card>
    </>
  );
};
export default SingleOLTCard;
