import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import OneBar from "./../../assets/svgs/signals/oneBar.svg";
import TwoBars from "./../../assets/svgs/signals/twoBars.svg";
import ThreeBars from "./../../assets/svgs/signals/threeBars.svg";
import FourBars from "./../../assets/svgs/signals/fourBars.svg";
import FiveBars from "./../../assets/svgs/signals/fiveBars.svg";
import SixBars from "./../../assets/svgs/signals/sixBars.svg";
import MinusOneBar from "./../../assets/svgs/signals/minusOneBar.svg";
import LocationSvg from "./../../assets/svgs/maps/location.svg";
import RefreshSVG from "./../../assets/svgs/refresh/refresh.svg";
import RefreshDisabledSVG from "./../../assets/svgs/refresh/refreshDisabled.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

//status
import UpStatus from "./../../assets/svgs/statuses/up.svg";
import DownStatus from "./../../assets/svgs/statuses/down.svg";
import InActiveStatus from "./../../assets/svgs/statuses/inactive.svg";
import InProcessStatus from "./../../assets/svgs/statuses/inprocess.svg";
import UpSetStatus from "./../../assets/svgs/statuses/upset.svg";
import InVisibleStatus from "./../../assets/svgs/statuses/invisible.svg";
import RebootedStatus from "./../../assets/svgs/statuses/rebooting.svg";

// Statics
import StaticIspURL from "./../../assets/svgs/statuses/ispLogo.png";
// import UpStatus from "./../../assets/svgs/statuses/up.svg";
import StaticRouter from "./../../assets/svgs/statuses/router-static.png";
import CopyButton from "./../../assets/svgs/copy/Copy.svg";
import LinkButtonImage from "./../../assets/svgs/goto/Link.svg";

import BlockOntsActionIcon from "./../../assets/svgs/icons/blockOntActionIcon.svg";
import SelectMultipleOntsIcon from "./../../assets/svgs/icons/SelectMultipleIcon.svg";
import DeleteOntActionIcon from "./../../assets/svgs/icons/deleteOntActionIcon.svg";
import RebootOntActionIcon from "./../../assets/svgs/icons/rebootOntActionIcon.svg";
//block svgs

import BlockInProcessOntIcon from "./../../assets/svgs/router/blockInProcess.svg";
import UnBlockInProcessOntIcon from "./../../assets/svgs/router/unblockInProcess.svg";
import BlockedOntIcon from "./../../assets/svgs/router/blocked.svg";

// Statics End
import TimeAgo from "react-timeago";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { useParams, Link, useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import { Card, Dropdown } from "react-bootstrap";
import {
  faLocation,
  faLocationPin,
  faSearch,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatDate } from "../../helpers/date";
import Moment from "react-moment";
import moment from "moment";
import MacAddressesCard from "../Cards/MacAddressesCard";

import { ThreeDots } from "react-loader-spinner";
import EditEndUsersModal from "../Model/EditEndUsersModal";
import { showUserName } from "../../helpers/endUser";
import { confirmAlert } from "react-confirm-alert"; // Import
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
import {
  getBrowseOntsByPonPortRoute,
  getPonPortsBySlotInBrowseOntsRoute,
} from "../../helpers/apiRoutes";
const role = getUserType();
const BROWSE_ONTS_ROUTES = getBrowseOntsByPonPortRoute();
const GET_PON_PORTS_BY_SLOT_ON_BROWSE_ONTS_ROUTE =
  getPonPortsBySlotInBrowseOntsRoute();
const BrowseOntsByPonPortsComponent: React.FC<OntsByPonPortComponent> = ({
  switchTab,
}) => {
  const ponPortStatusRef = useRef<any>(null);

  const [
    deleteRegistration,
    deleteRegistrationResponse,
    deleteError,
    deleteLoading,
    deleteMessage,
    deleteErrorCount,
  ] = useAxiosPost<DeleteRegistrationFromSearchProp>("binding/deleteBinding");

  //  * Multiple onts start

  const [
    requestRebootOnts,
    rebootedOntsResponse,
    rebootedOntsError,
    rebootedOntsLoading,
    rebootedOntsMessage,
    rebootedOntsErrorCount,
  ] = useAxiosPost<RebootMultipleOntsOfAPonPortProp>(
    "netflow/requests/requestMultipleOntsOfAPonPortOrWholePonPortReboot",
    true
  );
  useEffect(() => {
    if (rebootedOntsResponse != null) {
      notifySuccess(`Reboot Onts Request Logged`);
      getOntsListByPonPort({ intPkPonPortID: Number(intPkPonPortID) });
    }
  }, [rebootedOntsResponse]);

  const [
    requestDeleteOnts,
    deletedOntsResponse,
    deletedOntsError,
    deletedOntsLoading,
    deletedOntsMessage,
    deletedOntsErrorCount,
  ] = useAxiosPost<DeleteMultipleOntsOfAPonPortProp>(
    "netflow/requests/requestMultipleOntsOfAPonPortOrWholePonPortDelete",
    true
  );
  useEffect(() => {
    if (deletedOntsResponse != null) {
      notifySuccess(`Delete Onts Request Logged`);
      getOntsListByPonPort({ intPkPonPortID: Number(intPkPonPortID) });
    }
  }, [deletedOntsResponse]);

  const [
    requestBlockOnts,
    blockedOntsResponse,
    blockedOntsError,
    blockedOntsLoading,
    blockedOntsMessage,
    blockedOntsErrorCount,
  ] = useAxiosPost<BlockMultipleOntsProp>(
    "netflow/requests/requestMultipleOntsOfAPonPortToBlock",
    true
  );
  useEffect(() => {
    if (blockedOntsResponse != null) {
      notifySuccess(`Block Onts Request Logged`);
      getOntsListByPonPort({ intPkPonPortID: Number(intPkPonPortID) });
    }
  }, [blockedOntsResponse]);

  // * Multiple onts END
  let iterations = 0;

  const {
    intPkSlotID,
    oltSlot,
    oltName,
    intPkPonPortID,
    oltPonPort,
    intPkOltID,
  } = useParams();
  const navigate = useNavigate();
  const [actualOnts, setActualOnts] = useState<any>([]);
  const [allPonPorts, setAllPonPorts] = useState<any>([]);
  const [selectedOntStatusFilter, setSelectedOntStatusFilter] = useState("");
  const [selectedOntSignalFilter, setSelectedOntSignalFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [activeEndUsers, setActiveEndUsers] = useState<any>([]);
  const [activeBindingId, setActiveBindingID] = useState<number>(-1);
  const [ontType, setOntType] = useState<number>(2);
  const [isMultipleSelection, setIsMultipleSelection] =
    useState<boolean>(false);
  const [selectedOntsList, setSelectedOntsList] = useState<any>([]);
  const [
    postPonPortsBySlot,
    ponPortsBySlotResponse,
    ponPortsBySlotError,
    ponPortsBySlotLoading,
    ponPortsBySlotMessage,
    ponPortsBySlotErrorCount,
  ] = useAxiosPost<PonPortResponseProp>(
    `${GET_PON_PORTS_BY_SLOT_ON_BROWSE_ONTS_ROUTE}`
  );
  const handleDeleteRegistration = (
    ponNumber: string,
    intPkOltID: number,
    intPkOntBindingID: number
  ) => {
    const bondObj = {
      ponNumber,
      intPkOltID,
      intPkOntBindingID,
    };
    deleteRegistration({
      bondObj,
    });
  };
  useEffect(() => {
    if (deleteError !== null && deleteErrorCount !== 0) {
      notifyError(deleteError);
    }
  }, [deleteError, deleteErrorCount]);
  useEffect(() => {
    if (deleteRegistrationResponse !== null) {
      console.log({ deleteRegistrationResponse });
      notifySuccess("Ont Deleted Successfully");
      getOntsListByPonPort({ intPkPonPortID: Number(intPkPonPortID) });
    }
  }, [deleteRegistrationResponse]);
  const [activeBinding, setActiveBinding] = useState<ActiveBindingForEndUser>({
    oltFrame: -1,
    oltSlot: -1,
    oltPonPort: -1,
    intPkOltID: -1,
    ponNumber: "",
  });
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isCall: boolean) => {
    if (isCall === true) {
      getOntsListByPonPort({ intPkPonPortID: Number(intPkPonPortID) });
    }
    setShow(false);
    setActiveEndUsers([]);
    setActiveBindingID(-1);
    setActiveBinding({
      oltFrame: -1,
      oltSlot: -1,
      oltPonPort: -1,
      intPkOltID: -1,
      ponNumber: "",
    });
  };
  const [packetsReceived, setPacketReceived] = useState({
    signal: false,
    status: false,
    distance: false,
  });
  const getSignalBars = (signal: any) => {
    if (signal > -8) {
      return 6;
    } else if (signal >= -24.5 && signal <= -8) {
      return 5;
    } else if (signal >= -26.5 && signal <= -24.5) {
      return 4;
    } else if (signal >= -27.5 && signal <= -26.5) {
      return 3;
    } else if (signal >= -28.0 && signal <= -27.5) {
      return 2;
    } else if (signal <= -28.0) {
      return 1;
    }
  };
  const [stats, setStats] = useState({
    up: 0,
    down: 0,
    upSet: 0,
    inActive: 0,
    total: 0,
  });

  const [signalStats, setSignalStats] = useState({
    tooHigh: 0,
    healthy: 0,
    upset: 0,
    bad: 0,
  });
  const [allOnts, setAllOnts] = useState<any>([]);

  const [
    getOntsListByPonPort,
    FoundOntsResponseData,
    foundOntsError,
    foundOntsLoading,
    foundOntsMessage,
    foundOntsErrorCount,
  ] = useAxiosPost<GetOntsListByPonPortsProp>(`${BROWSE_ONTS_ROUTES}`);

  const [
    requestPonPortData,
    ponPortRequestResponse,
    ponPortRequestError,
    ponPortRequestLoading,
    ponPortRequestMessage,
    ponPortRequestErrorCount,
  ] = useAxiosPost<RequestPonPortEnumsProp>(
    "netflow/requests/requestPonPortData",
    true
  );
  const [
    resultPonPortData,
    ponPortResultResponse,
    ponPortResultError,
    ponPortResultLoading,
    ponPortResultMessage,
    ponPortResultErrorCount,
  ] = useAxiosPost<RequestPonPortEnumsProp>(
    "netflow/results/getPonPortDataResult",
    true
  );
  useEffect(() => {
    if (ponPortRequestError !== null && ponPortRequestErrorCount !== 0) {
      setIsLoading(false);
    }
  }, [ponPortRequestError, ponPortRequestErrorCount]);

  const startIntervalHandler = () => {
    ponPortStatusRef.current = setInterval(() => {
      if (iterations > 12) {
      } else {
        console.log("new api call");
        //we'll call the result api here
        resultPonPortData({
          ponPortId: Number(intPkPonPortID),
          intPkOltID: Number(intPkOltID),
        });
      }
      iterations++;
    }, 2000);
    setTimeout(() => {
      clearInterval(ponPortStatusRef.current);
    }, 30000);
  };
  useEffect(() => {
    if (ponPortResultResponse !== null) {
      const data: any = ponPortResultResponse.responseData;
      const { signalData, statusData, distanceData } = data;
      if (ontType == 1 || ontType == 3) {
        if (packetsReceived.status == true) {
          iterations = 15;
          setIsLoading(false);
          console.log({ iterations });
          clearInterval(ponPortStatusRef.current);
          return;
        }
      }
      if (
        packetsReceived.distance === true &&
        packetsReceived.signal === true &&
        packetsReceived.status === true
      ) {
        console.warn("we have it");
        iterations = 15;
        setIsLoading(false);
        console.log(
          "We have received all of the packets , so no more further api calls ."
        );
        clearInterval(ponPortStatusRef.current);

        return;
      }
      if (signalData.length > 0 && packetsReceived.signal !== true) {
        const _actualOnts = actualOnts.map((item: any) => {
          const ponNumber = item.ponNumber;
          const foundItem = signalData.find(
            (item: any) =>
              item.ponNumber.toLowerCase() == ponNumber.toLowerCase()
          );
          if (foundItem) {
            item.ontRxSignalFromOLT = foundItem.ontRxSignalFromOLT;
            item.ontStatus = foundItem.ontUpDownUpsetStatus;
            item.updateResultOntRxSignalFromOLTDate =
              foundItem.updateResultOntRxSignalFromOLTDate;
            item.barsCount =
              foundItem.ontRxSignalFromOLT !== null
                ? getSignalBars(foundItem.ontRxSignalFromOLT)
                : -1;
            return item;
          } else {
            console.log("not found");

            return item;
          }
        });

        const _allOnts = allOnts.map((item: any) => {
          const ponNumber = item.ponNumber;
          const foundItem = signalData.find(
            (item: any) =>
              item.ponNumber.toLowerCase() == ponNumber.toLowerCase()
          );
          if (foundItem) {
            item.ontRxSignalFromOLT = foundItem.ontRxSignalFromOLT;
            item.updateResultOntRxSignalFromOLTDate =
              foundItem.updateResultOntRxSignalFromOLTDate;
            return item;
          } else {
            console.log("not found");

            return item;
          }
        });
        setActualOnts(_actualOnts);
        setAllOnts(_allOnts);
        setPacketReceived({
          ...packetsReceived,
          signal: true,
        });
        setIsLoading(false);
      }
      if (statusData.length > 0 && packetsReceived.status !== true) {
        const _actualOnts = actualOnts.map((item: any) => {
          const ponNumber = item.ponNumber;
          const foundItem = statusData.find(
            (item: any) =>
              item.ponNumber.toLowerCase() == ponNumber.toLowerCase()
          );
          if (foundItem) {
            item.ontStatus = foundItem.ontUpDownUpsetStatus;
            item.updateResultOntLastDownCauseCodeDate =
              foundItem.updateResultOntLastDownCauseCodeDate;
            item.updateResultOntStatusUpdateDate =
              foundItem.updateResultOntStatusUpdateDate;
            return item;
          } else {
            console.log("not found");

            return item;
          }
        });

        const _allOnts = allOnts.map((item: any) => {
          const ponNumber = item.ponNumber;
          const foundItem = statusData.find(
            (item: any) =>
              item.ponNumber.toLowerCase() == ponNumber.toLowerCase()
          );
          if (foundItem) {
            item.ontStatus = foundItem.ontUpDownUpsetStatus;
            item.updateResultOntLastDownCauseCodeDate =
              foundItem.updateResultOntLastDownCauseCodeDate;
            item.updateResultOntStatusUpdateDate =
              foundItem.updateResultOntStatusUpdateDate;
            return item;
          } else {
            console.log("not found");

            return item;
          }
        });
        setActualOnts(_actualOnts);

        setAllOnts(_allOnts);
        setPacketReceived({
          ...packetsReceived,
          status: true,
        });
      }
      if (distanceData.length > 0 && packetsReceived.distance !== true) {
        const _actualOnts = actualOnts.map((item: any) => {
          const ponNumber = item.ponNumber;
          const foundItem = distanceData.find(
            (item: any) =>
              item.ponNumber.toLowerCase() == ponNumber.toLowerCase()
          );
          if (foundItem) {
            item.ontDistanceFromOltInMeters =
              foundItem.ontDistanceFromOltInMeters;
            item.updateResultOntDistanceFromOltInMetersDate =
              foundItem.updateResultOntDistanceFromOltInMetersDate;
            return item;
          } else {
            console.log("not found");

            return item;
          }
        });

        const _allOnts = allOnts.map((item: any) => {
          const ponNumber = item.ponNumber;
          const foundItem = distanceData.find(
            (item: any) =>
              item.ponNumber.toLowerCase() == ponNumber.toLowerCase()
          );
          if (foundItem) {
            item.ontDistanceFromOltInMeters =
              foundItem.ontDistanceFromOltInMeters;
            item.updateResultOntDistanceFromOltInMetersDate =
              foundItem.updateResultOntDistanceFromOltInMetersDate;
            return item;
          } else {
            console.log("not found");

            return item;
          }
        });
        setActualOnts(_actualOnts);

        setAllOnts(_allOnts);
        setPacketReceived({
          ...packetsReceived,
          distance: true,
        });
      }
    }
  }, [ponPortResultResponse]);
  useEffect(() => {
    if (ponPortRequestResponse !== null) {
      const success: any = ponPortRequestResponse.success;
      if (success === true) {
        //let's call the interval baby
        setTimeout(() => {
          console.log("we are going to call interval from now on.");
          startIntervalHandler();
        }, 5000);
      }
    }
  }, [ponPortRequestResponse]);
  const getSignalsBar = (
    bars: number,
    isOntRebooted: any,
    ontStatus: any,
    isLoading: boolean
  ) => {
    if (isOntRebooted === true) {
      return (
        <img
          src={MinusOneBar}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    }
    if (ontStatus === "DOWN") {
      return (
        <img
          src={MinusOneBar}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    }
    if (bars === -1) {
      return (
        <img
          src={MinusOneBar}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 1) {
      return (
        <img
          src={OneBar}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 2) {
      return (
        <img
          src={TwoBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 3) {
      return (
        <img
          src={ThreeBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 4) {
      return (
        <img
          src={FourBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 5) {
      return (
        <img
          src={FiveBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 6) {
      return (
        <img
          src={SixBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    }
  };

  useEffect(() => {
    getOntsListByPonPort({ intPkPonPortID: Number(intPkPonPortID) });
    requestPonPortData({ ponPortId: Number(intPkPonPortID) });

    postPonPortsBySlot({
      intPkOltID: Number(intPkOltID),
      intPkSlotID: Number(intPkSlotID),
    });
  }, [intPkPonPortID]);
  useEffect(() => {
    if (ponPortsBySlotResponse !== null) {
      const data: any = ponPortsBySlotResponse.responseData;
      console.log({ data });
      setAllPonPorts(data || []);
    }
  }, [ponPortsBySlotResponse]);
  useEffect(() => {
    return () => {
      localStorage.removeItem("allOnts");
      localStorage.removeItem("actualOnts");
    };
  }, []);
  useEffect(() => {
    if (foundOntsError !== null && foundOntsErrorCount !== 0) {
      notifyError(foundOntsError);
    }
  }, [foundOntsError, foundOntsErrorCount]);
  useEffect(() => {
    if (actualOnts.length > 0) {
      let down = 0;
      let up = 0;
      let upSet = 0;
      let inActive = 0;
      let healthSignal = 0,
        upsetSignal = 0,
        badSignal = 0,
        tooHighSignal = 0;
      actualOnts.map((item: any) => {
        if (item.ontStatus === "UP") {
          up++;
        }
        if (item.ontStatus === "UPSET") {
          upSet++;
        }
        if (item.isOntInActive === true) {
          inActive++;
        }
        if (item.ontStatus === "DOWN" && item.isOntInActive !== true) {
          down++;
        }
        //signal stats
        if (item.barsCount == 6) {
          tooHighSignal++;
        }
        if (item.barsCount == 5 || item.barsCount == 4) {
          healthSignal++;
        }
        if (item.barsCount == 3) {
          upsetSignal++;
        }
        if (item.barsCount < 3 && item.barsCount >= 1) {
          badSignal++;
        }
      });
      setSignalStats({
        tooHigh: tooHighSignal,
        healthy: healthSignal,
        upset: upsetSignal,
        bad: badSignal,
      });
      setStats({
        up,
        down,
        upSet,
        inActive,
        total: actualOnts.length,
      });
    }
  }, [allOnts, actualOnts]);

  useEffect(() => {
    setIsMultipleSelection(false);
    setSelectedOntsList([]);
    if (FoundOntsResponseData !== null) {
      const responseData: any = FoundOntsResponseData.responseData;

      if (responseData.length > 0) {
        const data = responseData[0];
        const { isOntEponOrGpon } = data;
        setOntType(Number(isOntEponOrGpon));
        let down = 0;
        let up = 0;
        let upSet = 0;
        let inActive = 0;
        let healthSignal = 0,
          upsetSignal = 0,
          badSignal = 0,
          tooHighSignal = 0;
        responseData.map((item: any) => {
          if (item.ontStatus === "UP") {
            up++;
          }
          if (item.ontStatus === "UPSET") {
            upSet++;
          }
          if (item.isOntInActive === true) {
            inActive++;
          }
          if (item.ontStatus === "DOWN" && item.isOntInActive !== true) {
            down++;
          }
          //signal stats
          if (item.barsCount == 6) {
            tooHighSignal++;
          }
          if (item.barsCount == 5 || item.barsCount == 4) {
            healthSignal++;
          }
          if (item.barsCount == 3) {
            upsetSignal++;
          }
          if (item.barsCount < 3 && item.barsCount >= 1) {
            badSignal++;
          }
        });
        setSignalStats({
          tooHigh: tooHighSignal,
          healthy: healthSignal,
          upset: upsetSignal,
          bad: badSignal,
        });
        setStats({
          up,
          down,
          upSet,
          inActive,
          total: responseData.length,
        });
      }
      setAllOnts(responseData || []);
      setActualOnts(responseData || []);
    }
  }, [FoundOntsResponseData]);
  const convertMetersToKiloMeters = (meters: any) => {
    if (meters < 1000) {
      return `${meters} Meters`;
    } else {
      return `${meters / 1000} KM`;
    }
  };

  const getTimeAgo = (_date: any) => {
    const startTime = moment(_date);
    const currentTime = moment();
    // <TimeAgo date={new Date(_date)} />;
    return (
      <TimeAgo date={new Date(_date)} />
      // <Moment from={currentTime} ago>
      //   {startTime}
      // </Moment>
    );
  };
  const getBackGroundColorCodeByOntStatus = (
    status: string,
    isOntInActive: boolean
  ) => {
    if (isOntInActive) {
      return "grey";
    }
    let color = "green";
    switch (status) {
      case "UP":
        color = "green";
        break;
      case "DOWN":
        color = "red";
        break;
      case "UPSET":
        color = "orange";
        break;
    }
    return color;
  };
  const handleOntStatusFilter = (status: string, signal: string) => {
    if (status == "" && signal == "") {
      setAllOnts(actualOnts);
      return;
    }
    let _onts = actualOnts;
    if (status === "INACTIVE") {
      _onts = _onts.filter((item: any) => item.isOntInActive == true);
    }
    if (status === "DOWN") {
      _onts = _onts.filter((item: any) => item.ontStatus == "DOWN");
    }
    if (status == "UP") {
      _onts = _onts.filter((item: any) => item.ontStatus == "UP");
    }
    if (status == "UPSET") {
      _onts = _onts.filter((item: any) => item.ontStatus == "UPSET");
    }

    if (signal === "healthy") {
      _onts = _onts.filter(
        (item: any) => item.barsCount == 5 || item.barsCount == 4
      );
    }
    if (signal === "tooHigh") {
      _onts = _onts.filter((item: any) => item.barsCount == 6);
    }
    if (signal === "upset") {
      _onts = _onts.filter((item: any) => item.barsCount == 3);
    }
    if (signal === "bad") {
      _onts = _onts.filter(
        (item: any) => item.barsCount < 3 && item.barsCount >= 1
      );
    }
    setAllOnts(_onts);

    // return;
  };
  const handleOntSignalFilter = (status: string) => {
    if (status === "") {
      setAllOnts(actualOnts);
      return;
    }
  };
  const getSignalImage = (
    status: any,
    isOntInActive: any,
    isActivationInProcess: any,
    intPkOntRegisterationID: any,
    isOntRebooted: any,
    isLoading: boolean
  ) => {
    if (isOntRebooted === true) {
      return (
        <img
          src={RebootedStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }

    if (isActivationInProcess == true) {
      return (
        <img
          src={InProcessStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (isOntInActive == true) {
      return (
        <img
          src={InActiveStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (status === "UP") {
      return (
        <img
          src={UpStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (status === "DOWN") {
      return (
        <img
          src={DownStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (status === "UPSET") {
      return (
        <img
          src={UpSetStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (intPkOntRegisterationID == null) {
      return (
        <img
          src={InVisibleStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
  };
  const handlePonNumberCopy = (ponNumber: any) => {
    navigator?.clipboard
      ?.writeText(ponNumber)
      .then(() => {
        notifySuccess(`Pon Number Copy To Clip Board`);
      })
      .catch((error) => {
        notifyError(`Error While Copying Pon Number`);
      });
  };
  const handleRefresh = () => {
    setIsRefreshDisabled(true);
    setIsLoading(true);
    setPacketReceived({
      status: false,
      signal: false,
      distance: false,
    });
    iterations = 0;
    requestPonPortData({ ponPortId: Number(intPkPonPortID), isRefresh: true });

    setTimeout(() => {
      setIsRefreshDisabled(false);
    }, 45000);
  };
  const showPonOrMacAsciiPon = (
    ontType: number,
    mac: any,
    pon: any,
    asciiPon: any
  ) => {
    if (ontType === 1) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">MacAddress</span> :{" "}
          <span className="data-r-field">{mac}</span>{" "}
          <CopyToClipboard
            text={mac || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
    if (ontType === 2) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">Pon</span> :{" "}
          <span className="data-r-field">{pon}</span>{" "}
          <CopyToClipboard
            text={pon || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
    if (ontType === 3) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">Pon</span> :{" "}
          <span className="data-r-field">{asciiPon}</span>{" "}
          <CopyToClipboard
            text={asciiPon || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
  };
  const handleSelectOrUnSelect = (type: boolean, ont: any) => {
    const selectedOnts = selectedOntsList;
    if (type === true) {
      selectedOnts.push({
        ontID: ont.ontID,
        intPkOntRegisterationID: ont.intPkOntRegisterationID,
        intPkOntBindingID: ont.intPkOntBindingID,
        status: ont.ontStatus || "DOWN",
        macAddress: ont.ontEponMacAddressID,
      });
      console.log({ selectedOnts });

      setSelectedOntsList(selectedOnts);
    } else {
      //let's pull that out.
      const selectedAfterPull = selectedOnts.filter(
        (item: any) => item.intPkOntBindingID !== ont.intPkOntBindingID
      );
      setSelectedOntsList(selectedAfterPull);
    }
  };
  useEffect(() => {
    console.log({ selectedOntsList });
  }, [selectedOntsList]);
  const handleRebootMultipleOnts = () => {
    if (selectedOntsList.length < 1) {
      return;
    }
    const onts = selectedOntsList.filter(
      (item: any) => item.status == "UP" || item.status == "UPSET"
    );
    console.log({ onts });
    if (onts.length < 1) {
      notifyError(`Invalid Items Selected`);
      return;
    }
    confirmAlert({
      title: "Reboot",
      message: "Do you Want to Reboot Selected  ONTs of this PonPort ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Reboot");

            requestRebootOnts({
              ponPortId: Number(intPkPonPortID),
              rebootAllOntsOfThisPonPort: false,
              onts: onts,
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const handleBlockMultipleOnts = () => {
    if (selectedOntsList.length < 1) {
      return;
    }

    confirmAlert({
      title: "Block",
      message: "Do you Want to Block Selected  ONTs of this PonPort ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Block");

            requestBlockOnts({
              ponPortId: Number(intPkPonPortID),
              macAddressArray: selectedOntsList,
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const handleDeleteMultipleOnts = () => {
    if (selectedOntsList.length < 1) {
      return;
    }

    confirmAlert({
      title: "Delete",
      message: "Do you Want to Delete Selected  ONTs of this PonPort ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Delete");

            requestDeleteOnts({
              ponPortId: Number(intPkPonPortID),
              deleteUnAuthAllOntsOfThisPonPort: false,
              onts: selectedOntsList,
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const getBlockedImage = (blockState: number) => {
    if (blockState == 0) {
      return <img src={StaticRouter} className="image-float-right" />;
    }
    if (blockState == 1) {
      return <img src={BlockedOntIcon} className="image-float-right" />;
    }
    if (blockState == 2) {
      return <img src={BlockInProcessOntIcon} className="image-float-right" />;
    }
    if (blockState == 3) {
      return (
        <img src={UnBlockInProcessOntIcon} className="image-float-right" />
      );
    }
  };
  return (
    <>
      {foundOntsLoading === true ? (
        <ThreeDots
          height="100"
          width="100"
          radius="9"
          color="#5ac8fa"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        />
      ) : (
        <>
          <ToastContainer />
          {show === true ? (
            <EditEndUsersModal
              show={show}
              intPkOntBindingID={activeBindingId}
              handleClose={handleClose}
              endUsers={activeEndUsers}
              activeBinding={activeBinding}
            />
          ) : null}
          <Container fluid className="mt-5">
            <Row>
              <Col xs lg="12" md="12" xl={12} xxl={12}>
                <Card className="p-5">
                  <Card.Title className="text-center olt-heading pt-3 main-ont-heading">
                    {" "}
                    {oltName} - Slot {oltSlot} - PonPort {oltPonPort}
                    <span>
                      <img
                        style={{ cursor: "pointer" }}
                        src={
                          isRefreshDisabled === true
                            ? RefreshDisabledSVG
                            : RefreshSVG
                        }
                        onClick={(e) => {
                          console.log({ isRefreshDisabled });
                          if (isRefreshDisabled === true) {
                            return;
                          }
                          handleRefresh();
                        }}
                        className="image-float-right"
                      />
                    </span>
                  </Card.Title>
                  <Row className="mt-4">
                    <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}>
                      <Form.Select
                        value={intPkPonPortID}
                        onChange={(e) => {
                          //here we'll redirect it , we don't have to set it actually
                          const ponPort: any = allPonPorts.find(
                            (item: any) => item.intPkPonPortID == e.target.value
                          );
                          if (!ponPort) {
                            return;
                          }
                          const {
                            oltSlot,
                            oltFrame,
                            oltPonPort,
                            intPkPonPortID,
                          } = ponPort;
                          console.log({ ponPort });
                          navigate(
                            `/browse-onts-by-pon-ports/${intPkSlotID}/${oltSlot}/${oltName}/${intPkOltID}/${intPkPonPortID}/${oltPonPort}`
                          );
                        }}
                      >
                        {allPonPorts.map((item: any) => {
                          return (
                            <option
                              value={item.intPkPonPortID}
                            >{`${item.oltFrame}/${item.oltSlot}/${item.oltPonPort}`}</option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                    <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        />
                        <div className="input-group-append">
                          <button
                            className="button-primary-search"
                            type="button"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Container className="mt-5">
                      <Row>
                        <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}>
                          <img src={LocationSvg} /> <span>No Name</span>
                        </Col>
                        <Col md={10} lg={10} xs={12} sm={12} xl={10} xxl={10}>
                          <span className="ont-status-counter-container">
                            <span className="mr-20p color-online status-counter-wrapper">
                              Up:{stats.up}
                            </span>
                            <span className="mr-20p color-offline status-counter-wrapper">
                              Down:{stats.down}
                            </span>
                            <span className="mr-20p color-upset status-counter-wrapper">
                              Upset:{stats.upSet}
                            </span>
                            <span className="mr-20p color-inactive status-counter-wrapper">
                              InActive:{stats.inActive}
                            </span>
                            <span className="mr-20p status-counter-wrapper">
                              Total:{stats.total}
                            </span>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                    <Container className="mt-2">
                      <Row>
                        <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
                        <Col md={10} lg={10} xs={12} sm={12} xl={10} xxl={10}>
                          <span className="ont-status-counter-container">
                            <span className="mr-20p color-online status-counter-signal">
                              Healthy:{signalStats.healthy}
                            </span>
                            <span className="mr-20p color-offline status-counter-signal">
                              Bad:{signalStats.bad}
                            </span>
                            <span className="mr-20p color-upset status-counter-signal">
                              Upset:{signalStats.upset}
                            </span>
                            <span className="mr-20p color-too-high status-counter-signal">
                              Too High:{signalStats.tooHigh}
                            </span>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                    {ontType == 1 ? (
                      <>
                        <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                          <span style={{ float: "right" }}>
                            {" "}
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  style={{
                                    marginRight: "10px",
                                    marginTop: "5px",
                                  }}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsMultipleSelection(true);
                                  }}
                                >
                                  <img src={SelectMultipleOntsIcon} />
                                  Select Multiple
                                </Dropdown.Item>
                                {isMultipleSelection == true ? (
                                  <>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.preventDefault();
                                        console.log({ selectedOntsList });
                                        if (
                                          role === "" ||
                                          role == "DEMO_DEALER" ||
                                          role === "DEMO_ADMIN"
                                        ) {
                                          notifyDemoAccountError();
                                          return;
                                        }
                                        handleRebootMultipleOnts();
                                      }}
                                    >
                                      <img src={RebootOntActionIcon} />
                                      Reboot Selected
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          role === "" ||
                                          role == "DEMO_DEALER" ||
                                          role === "DEMO_ADMIN"
                                        ) {
                                          notifyDemoAccountError();
                                          return;
                                        }
                                        console.log({ selectedOntsList });
                                        handleDeleteMultipleOnts();
                                      }}
                                    >
                                      <img src={DeleteOntActionIcon} />
                                      Delete Selected
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          role === "" ||
                                          role == "DEMO_DEALER" ||
                                          role === "DEMO_ADMIN"
                                        ) {
                                          notifyDemoAccountError();
                                          return;
                                        }
                                        console.log({ selectedOntsList });
                                        handleBlockMultipleOnts();
                                      }}
                                    >
                                      <img src={BlockOntsActionIcon} />
                                      Block Selected
                                    </Dropdown.Item>
                                  </>
                                ) : null}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                        </Col>
                      </>
                    ) : null}
                    {ontType == 3 ? (
                      <>
                        <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                          <span style={{ float: "right" }}>
                            {" "}
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  style={{
                                    marginRight: "10px",
                                    marginTop: "5px",
                                  }}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setIsMultipleSelection(true);
                                  }}
                                >
                                  <img src={SelectMultipleOntsIcon} />
                                  Select Multiple
                                </Dropdown.Item>
                                {isMultipleSelection == true ? (
                                  <>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          role === "" ||
                                          role == "DEMO_DEALER" ||
                                          role === "DEMO_ADMIN"
                                        ) {
                                          notifyDemoAccountError();
                                          return;
                                        }
                                        console.log({ selectedOntsList });
                                        handleRebootMultipleOnts();
                                      }}
                                    >
                                      <img src={RebootOntActionIcon} />
                                      Reboot Selected
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          role === "" ||
                                          role == "DEMO_DEALER" ||
                                          role === "DEMO_ADMIN"
                                        ) {
                                          notifyDemoAccountError();
                                          return;
                                        }
                                        console.log({ selectedOntsList });
                                        handleDeleteMultipleOnts();
                                      }}
                                    >
                                      <img src={DeleteOntActionIcon} />
                                      Delete Selected
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item
                                  onClick={(e) => {
                                    e.preventDefault();
                                    console.log({ selectedOntsList });
                                    handleBlockMultipleOnts();
                                  }}
                                >
                                  <img src={BlockOntsActionIcon} />
                                  Block Selected
                                </Dropdown.Item> */}
                                  </>
                                ) : null}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                  <Row className="mt-3">
                    <Col md={6} lg={6} xxl={6} xl={6} sm={12} xs={12}>
                      <Form.Group>
                        <Form.Label>Choose Ont status</Form.Label>
                        <Form.Select
                          value={selectedOntStatusFilter}
                          onChange={(e) => {
                            setSelectedOntStatusFilter(e.target.value);
                            handleOntStatusFilter(
                              e.target.value,
                              selectedOntSignalFilter
                            );
                          }}
                        >
                          <option value={""} key={""}>
                            All
                          </option>{" "}
                          <option value={"UP"} key={"UP"}>
                            UP
                          </option>{" "}
                          <option value={"DOWN"} key={"DOWN"}>
                            DOWN
                          </option>{" "}
                          <option value={"UPSET"} key={"UPSET"}>
                            UPSET
                          </option>{" "}
                          <option value={"INACTIVE"} key={"INACTIVE"}>
                            INACTIVE
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {!(
                      selectedOntStatusFilter == "DOWN" ||
                      selectedOntStatusFilter === "INACTIVE"
                    ) ? (
                      <Col md={6} lg={6} xxl={6} xl={6} sm={12} xs={12}>
                        <Form.Group>
                          <Form.Label>Signal Strength</Form.Label>
                          <Form.Select
                            value={selectedOntSignalFilter}
                            onChange={(e) => {
                              setSelectedOntSignalFilter(e.target.value);
                              // handleOntSignalFilter(e.target.value);
                              handleOntStatusFilter(
                                selectedOntStatusFilter,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""}>
                              All
                            </option>{" "}
                            <option value={"healthy"} key={"healthy"}>
                              Healthy Signals
                            </option>{" "}
                            <option value={"tooHigh"} key={"tooHigh"}>
                              Too High
                            </option>{" "}
                            <option value={"upset"} key={"upset"}>
                              UPSET Signal
                            </option>{" "}
                            <option value={"bad"} key={"bad"}>
                              Bad Signal
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    ) : null}
                  </Row>
                  <Row className="mt-4">
                    <Col md={4} lg={4} xs={12} sm={12} xl={2} xxl={4}>
                      <span
                        className="active-tab-button-ont-list"
                        onClick={(e) => {
                          e.preventDefault();
                          switchTab(1);
                        }}
                      >
                        All onts
                      </span>
                      <span
                        className="inactive-tab-button-ont-list"
                        onClick={(e) => {
                          e.preventDefault();
                          switchTab(0);
                        }}
                      >
                        Block onts
                      </span>
                    </Col>
                  </Row>
                  <Card.Body>
                    <Row>
                      {allOnts.map((item: any) => {
                        return (
                          <Col
                            className="mt-5"
                            sm={12}
                            xs={12}
                            lg="6"
                            md="6"
                            xl={6}
                            xxl={6}
                          >
                            <>
                              {item.isOntDeleteInProcess == !true ? (
                                <>
                                  <Row
                                    style={{ justifyContent: "center" }}
                                    className="single-ont-container "
                                  >
                                    {isMultipleSelection === true ? (
                                      <>
                                        <Col
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          md={1}
                                          lg={1}
                                          xl={1}
                                          xxl={1}
                                          sm={1}
                                          xs={1}
                                        >
                                          <input
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                            type="checkbox"
                                            onChange={(e) => {
                                              handleSelectOrUnSelect(
                                                e.target.checked,
                                                item
                                              );
                                              console.log({
                                                checked: e.target.checked,
                                              });
                                            }}
                                          />
                                        </Col>
                                      </>
                                    ) : null}
                                    <Col
                                      md={11}
                                      lg={11}
                                      xl={11}
                                      xxl={11}
                                      sm={11}
                                      xs={11}
                                    >
                                      <Row>
                                        <Col
                                          className="mx-h-100"
                                          md={4}
                                          lg={4}
                                          xl={4}
                                          xxl={4}
                                          sm={4}
                                          xs={12}
                                        >
                                          <img
                                            style={{ width: "50px" }}
                                            src={item.ispLogoUrl}
                                          />
                                        </Col>

                                        <Col
                                          className="text-center mx-h-100"
                                          md={4}
                                          lg={4}
                                          xl={4}
                                          xxl={4}
                                          sm={4}
                                          xs={12}
                                        >
                                          {getSignalImage(
                                            item.ontStatus,
                                            item.isOntInActive,
                                            item.isActivationInProcess,
                                            item.intPkOntRegisterationID,
                                            item.isOntRebooted,
                                            isLoading
                                          )}
                                        </Col>

                                        <Col
                                          className="mx-h-100 mt-3"
                                          md={4}
                                          lg={4}
                                          xl={4}
                                          xxl={4}
                                          sm={4}
                                          xs={12}
                                        >
                                          {getSignalsBar(
                                            item.barsCount,
                                            item.isOntRebooted,
                                            item.ontStatus,
                                            isLoading
                                          )}
                                        </Col>
                                        <Col
                                          className="mt-3"
                                          md={7}
                                          lg={7}
                                          xl={7}
                                          xxl={7}
                                          sm={12}
                                          xs={12}
                                        >
                                          <h4>
                                            <span className=" end-user-name-heading ">
                                              PPPoEID
                                            </span>
                                            <br />
                                            <span
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setActiveEndUsers(
                                                  item.endUsers
                                                );
                                                setShow(true);
                                                setActiveBindingID(
                                                  item.intPkOntBindingID
                                                );
                                                setActiveBinding({
                                                  oltFrame: item.oltFrame,
                                                  oltSlot: item.oltSlot,
                                                  oltPonPort: item.oltPonPort,
                                                  ponNumber: item.ponNumber,
                                                  intPkOltID: item.intPkOltID,
                                                });
                                              }}
                                              className=" end-user-name-heading cursor-pointer-class"
                                            >
                                              {showUserName(
                                                item.endUsers,
                                                item.ontRegistrationDescInOLT
                                              )}
                                            </span>
                                            <CopyToClipboard
                                              text={
                                                showUserName(
                                                  item.endUsers,
                                                  item.ontRegistrationDescInOLT
                                                ) || ""
                                              }
                                              onCopy={() => {
                                                // notifySuccess(`Pon Number Copied`);
                                              }}
                                            >
                                              <img
                                                src={CopyButton}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                }}
                                                style={{ cursor: "pointer" }}
                                              />
                                            </CopyToClipboard>
                                          </h4>
                                        </Col>
                                        <Col
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          xxl={5}
                                          sm={5}
                                          xs={5}
                                        >
                                          {getBlockedImage(
                                            Number(item.blockState)
                                          )}
                                          {/* <img
                                    src={StaticRouter}
                                    className="image-float-right"
                                  /> */}
                                        </Col>
                                        <Col
                                          className="mt-3"
                                          md={7}
                                          lg={7}
                                          xl={7}
                                          xxl={7}
                                          sm={12}
                                          xs={12}
                                        >
                                          <span className="data-r-label">
                                            OLT
                                          </span>{" "}
                                          :{" "}
                                          <span className="data-r-field">
                                            {item.oltName}
                                          </span>
                                        </Col>
                                        <Col
                                          className="mt-2"
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          xxl={5}
                                          sm={12}
                                          xs={12}
                                        >
                                          {ontType === 2 ? (
                                            <>
                                              <span className="image-float-left">
                                                <span className="data-r-label">
                                                  FSP
                                                </span>{" "}
                                                :{" "}
                                                <span className="data-r-field">
                                                  {item.fsp}
                                                </span>
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span className="image-float-left">
                                                <span className="data-r-label">
                                                  Id
                                                </span>{" "}
                                                :{" "}
                                                <span className="data-r-field">
                                                  {item.fspId}
                                                </span>
                                              </span>
                                            </>
                                          )}

                                          <span className="image-float-right">
                                            <span className="data-r-label">
                                              ONT ID
                                            </span>{" "}
                                            :{" "}
                                            <span className="data-r-field">
                                              {item.ontID}
                                            </span>
                                          </span>
                                        </Col>
                                        {showPonOrMacAsciiPon(
                                          ontType,
                                          item.formattedOntEponMacAddressID,
                                          item.ponNumber,
                                          item.asciiHexaPonNumber
                                        )}

                                        <Col
                                          className="mt-1"
                                          style={{ position: "relative" }}
                                          md={12}
                                          lg={12}
                                          xl={12}
                                          xxl={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          {item.ontStatus == "UP" ||
                                          item.ontStatus == "UPSET" ? (
                                            <>
                                              {" "}
                                              <span className="data-r-label">
                                                Signal/Distance:
                                              </span>
                                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;
                                              <span
                                                className={`data-r-field ${
                                                  isLoading === true
                                                    ? "blinking-image"
                                                    : ""
                                                }`}
                                              >
                                                {item.ontRxSignalFromOLT} / ({" "}
                                                {convertMetersToKiloMeters(
                                                  item.ontDistanceFromOltInMeters
                                                )}
                                                ) {"{"}{" "}
                                                {getTimeAgo(
                                                  item.updateResultOntRxSignalFromOLTDate
                                                )}{" "}
                                                {"}"}
                                              </span>
                                            </>
                                          ) : null}
                                        </Col>
                                        <Col
                                          className="mt-1"
                                          md={12}
                                          lg={12}
                                          xl={12}
                                          xxl={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          <span className="data-r-label">
                                            Registered By
                                          </span>{" "}
                                          <span className="data-r-field">
                                            {item.userType}
                                          </span>{" "}
                                        </Col>

                                        <MacAddressesCard
                                          servicePortsArray={
                                            item.servicePortArray
                                          }
                                        />
                                        {item.intPkOntRegisterationID !==
                                        null ? (
                                          <Col
                                            className="mt-1"
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            sm={12}
                                            xs={12}
                                          >
                                            <img
                                              onClick={(e) => {
                                                e.preventDefault();
                                                navigate(
                                                  `/single-registration-by-id/${oltName}/${item.intPkOntBindingID}/${intPkOltID}`
                                                );
                                              }}
                                              className="image-float-right"
                                              src={LinkButtonImage}
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Col>
                                        ) : (
                                          <>
                                            <Col
                                              className="mt-1"
                                              md={12}
                                              lg={12}
                                              xl={12}
                                              xxl={12}
                                              sm={12}
                                              xs={12}
                                            >
                                              <Button
                                                className="image-float-left"
                                                variant="danger"
                                                disabled={deleteLoading}
                                                onClick={(e) => {
                                                  handleDeleteRegistration(
                                                    item.ponNumber,
                                                    Number(intPkOltID),
                                                    Number(
                                                      item.intPkOntBindingID
                                                    )
                                                  );
                                                }}
                                              >
                                                Delete
                                              </Button>
                                            </Col>
                                          </>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              ) : null}
                            </>
                          </Col>
                        );
                      })}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
export default BrowseOntsByPonPortsComponent;
