import React, { useEffect, useState, ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
const CreateCompanyWithAdminForAccountModal: React.FC<
  CreateCompanyWithAdminModalForAccountProp
> = ({ handleClose, show }) => {
  const [
    postBotData,
    linkedBotsResponse,
    linkingError,
    linkingLoading,
    vlanMessage,
    linkingErrorCount,
  ] = useAxiosPost<CreateNewCompanyWithAdminForAccountantProp>(
    "accountant/createCompanyWithAdmin"
  );
  const UpdatePasswordForStaffValidationSchema = yup.object().shape({
    password: yup.string().required(),
    country: yup.string().required(),
    city: yup.string().required(),
    streetAddress: yup.string().required(),
    userFullname: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().required(),
    contactNumber: yup
      .string()
      .required()
      .min(10, "Contact Must be 10 characters long"),
  });

  const [companyData, setCompanyData] =
    useState<CreateNewCompanyWithAdminForAccountantProp>({
      companyName: "",
      country: "",
      city: "",
      streetAddress: "",
      userFullname: "",
      username: "",
      email: "",
      contactNumber: "",
      password: "",
    });

  const handleCreateVlan = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateNewCompanyWithAdminForAccountantProp = companyData;
    try {
      const isValid = await UpdatePasswordForStaffValidationSchema.validate(
        dataToSend
      );

      postBotData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (linkingError !== null && linkingErrorCount !== 0) {
      notifyError(linkingError);
    }
  }, [linkingError, linkingErrorCount]);
  useEffect(() => {
    if (linkedBotsResponse !== null) {
      const { message } = linkedBotsResponse;
      if (message) {
        notifySuccess(message);
      }
      handleClose(true);
    }
  }, [linkedBotsResponse]);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      [name]: value,
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={companyData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };
  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Create New Company With Admin <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {renderInput(
                `companyName`,
                "Enter Company Name",
                `Enter Company Name`
              )}
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {renderInput(
                `country`,
                "Enter Country Name",
                `Enter Country Name`
              )}
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {" "}
              {renderInput(`city`, "Enter City Name", `Enter City Name`)}
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {renderInput(
                `streetAddress`,
                "Enter Street Address",
                `Enter Street Address`
              )}
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {" "}
              {renderInput(
                `userFullname`,
                "Enter Admin Full Name",
                "Enter Admin Full Name"
              )}
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {renderInput(
                `username`,
                "Enter Admin username",
                "Enter Admin username"
              )}
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {renderInput(`email`, "Enter Admin email", "Enter Admin email")}
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {renderInput(
                `contactNumber`,
                "Enter Admin Phone",
                "Enter Admin Phone"
              )}
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {renderInput(
                `password`,
                "Enter Admin Password",
                "Enter Admin Password"
              )}
            </Col>
          </Row>

          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateVlan}
              disabled={linkingLoading}
              className="button-primary"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateCompanyWithAdminForAccountModal;
