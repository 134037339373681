import React from "react";

import {
  faHome,
  faBriefcase,
  // faUser,
  faUserGroup,
  faUserPlus,
  faNetworkWired,
  faCheckToSlot,
  faPlus,
  faLocation,
  faSearchLocation,
  faUserAlt,
  faUserFriends,
  faUserCircle,
  faUserCheck,
  faGlobe,
  faWifi,
  faSearch,
  faRoute,
  faUser,
  faBuilding,
  faUserGear,
  faMoneyBillTrendUp,
  faMoneyCheck,
  faBank,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import Login from "./pages/Login";
import HomePage from "./pages/Home";
import CreateOLTPage from "./pages/CreateOLTPage";
import AllOltsPage from "./pages/AllOltsPage";
import CreateSlotPage from "./pages/CreateSlotPage";
import AllSlotsByOltPage from "./pages/AllSlotsByOltPage";
import PonPortsBySlotPage from "./pages/PonPortsBySlotPage";
import CreateNewManagerPage from "./pages/CreateNewManagerPage";
import AllManagersPage from "./pages/AllManagers";
import CreateAreaPage from "./pages/CreateAreaPage";
import AllAreasPage from "./pages/AllAreasPage";
import CreateEndUsersPage from "./pages/CreateEndUsersPage";
import AllEndUsersPage from "./pages/AllEndUsersPage";
import CreateISPPage from "./pages/CreateISPPage";
import AdminIspsPage from "./pages/AdminIspsPage";
import CreateNewVlanProfilePage from "./pages/CreateNewVlanProfilePage";
import VlansByISPPage from "./pages/VlansByISPPage";
import BrowseOLTsPage from "./pages/admin/BrowseOLTsPage";
import BrowseSlotsPage from "./pages/admin/BrowseSlotsPage";
import BrowsePonPortsPage from "./pages/admin/BrowsePonPortsPage";
import SearchONTPage from "./pages/SearchONTPage";
import FoundOntsPage from "./pages/admin/FoundOntsPage";
import BrowseOntsPage from "./pages/admin/BrowseOntsPage";
import BrowseSingleONTPage from "./pages/admin/BrowseSingleONTPage";
import CreateNewCOOPage from "./pages/admin/CreateNewCOOPage";
import AllSuperCooPage from "./pages/admin/AllSuperCooPage";
import NewOLTModelPage from "./pages/superAdmin/NewOLTModelPage";
import AllOLTModelsPage from "./pages/superAdmin/AllOLTModelsPage";
import CreateNewSlotModelPage from "./pages/superAdmin/CreateNewSlotModelPage";
import AllSlotsModelsPage from "./pages/superAdmin/AllSlotModels";
import CreateNewONTModelPage from "./pages/superAdmin/CreateNewONTModelPage";
import AllOntModelsPage from "./pages/superAdmin/AllOntModelsPage";
import AllOntsReportsPage from "./pages/reports/OntsReportsPage";
import CreateAdminWithCompanyPage from "./pages/CreateAdminWithCompanyPage";
import AllCompaniesCOOPage from "./pages/AllCompaniesPage";
import StaffByCompanyPage from "./pages/StaffByCompanyPage";
import VlansByOLTPage from "./pages/VlansByOLTPage";
import AllDealersPage from "./pages/AllDealersPage";
import UpdatePasswordPage from "./pages/auth/UpdatePasswordPage";
import SyncVlansWIthOltPage from "./pages/SyncVlansWIthOltPage";
import SearchOLTsForNOCPage from "./pages/SearchOLTForNOCPage";
import DiagnoseSingleOLTPage from "./pages/DiagnoseSingleOLTPage";
import { getUserType } from "./helpers/userDetails";
import NOCORCOOLoginAsCompanyPage from "./pages/auth/NOCORCOOLoginAsCompanyPage";
import ProcessingOltsPage from "./pages/ProcessingOltsPage";
import AllPricePolicyModelsPageForSuperAdmin from "./pages/admin/AllPricePolicyModelsPage";
import AllBanksPageForSuperAdmin from "./pages/admin/AllBanksPageForSuperAdmin";
import AllBankAccountsByBankPageForSuperAdmin from "./pages/admin/AllBankAccountsByBankPageForSuperAdmin";
import AllCompaniesPageForAccountant from "./pages/accountant/AllCompaniesPageForAccountant";
import PricePolicyPageForAccountant from "./pages/accountant/PricePolicyPageForAccountant";
import AllSanctionedOltsForAccountantPage from "./pages/accountant/AllSanctionedOltsForAccountantPage";
import CompanyAdminProcessingOlts from "./pages/CompanyAdminProcessingOlts";
import CompanyStaffPageForCompanyAdmin from "./pages/admin/CompanyStaffPageForCompanyAdmin";
import SignupPage from "./pages/SignupPage";
import UpdateEmailPage from "./pages/auth/UpdateEmailPage";
import AllVPNsPage from "./pages/AllVPNsPage";
import AllVpnsNocPage from "./pages/noc/AllVpnsNocPage";
import AdminDashboardPage from "./pages/AdminDashboardPage";

type Route = {
  path: String;
  Component: React.FC;
  roles: string[];
  icon: any;
  text: string;
  show: boolean;
};
const routesList: Array<Route> = [
  {
    path: "/login",
    Component: Login,
    icon: faUserPlus,
    roles: ["*"],
    text: "Login",
    show: false,
  },
  {
    path: "/register",
    Component: SignupPage,
    icon: faUserPlus,
    roles: ["*"],
    text: "Login",
    show: false,
  },
  {
    path: "/",
    Component: AdminDashboardPage,
    icon: faHome,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "Home",
    show: true,
  },
  {
    path: "/my-staff",
    Component: CompanyStaffPageForCompanyAdmin,
    icon: faUser,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "My Staff",
    show: true,
  },

  {
    path: "/found-onts",
    Component: FoundOntsPage,
    icon: faRoute,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "Found Onts",
    show: true,
  },

  {
    path: "/onts-reports",
    Component: AllOntsReportsPage,
    icon: faRoute,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "ONTs Quick Report",
    show: true,
  },

  {
    path: "/browse-olts-page",
    Component: BrowseOLTsPage,
    icon: faWifi,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "My OLTs",
    show: true,
  },

  {
    path: "/search-ont",
    Component: SearchONTPage,
    icon: faSearch,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "Search ONT",
    show: true,
  },
  {
    path: "/browse-slots-by-olt/:intPkOltID/:oltName",
    Component: BrowseSlotsPage,
    icon: faWifi,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "Browse SLOTs",
    show: false,
  },
  {
    path: "/browse-pon-ports-by-slot/:intPkSlotID/:oltSlot/:oltName/:intPkOltID/:skipSlot/:oltType",
    Component: BrowsePonPortsPage,
    icon: faWifi,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "Browse Pon Ports",
    show: false,
  },
  {
    path: "/browse-onts-by-pon-ports/:intPkSlotID/:oltSlot/:oltName/:intPkOltID/:intPkPonPortID/:oltPonPort",
    Component: BrowseOntsPage,
    icon: faWifi,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "Browse ONTs",
    show: false,
  },
  {
    path: "/single-registration-by-id/:oltName/:intPkOntBindingID/:intPkOltID",
    Component: BrowseSingleONTPage,
    icon: faWifi,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "Browse ONTs",
    show: false,
  },

  // {
  //   path: "/create-manager",
  //   Component: CreateNewManagerPage,
  //   icon: faUserPlus,
  //   roles: ["ADMIN", "DEMO_ADMIN"],
  //   text: "Create Manager",
  //   show: true,
  // },
  // {
  //   path: "/all-managers",
  //   Component: AllManagersPage,
  //   icon: faUserGroup,
  //   roles: ["ADMIN", "DEMO_ADMIN"],
  //   text: "All Managers",
  //   show: true,
  // },
  {
    path: "/create-new-area",
    Component: CreateAreaPage,
    icon: faLocation,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "Create New Area",
    show: false,
  },
  {
    path: "/all-areas",
    Component: AllAreasPage,
    icon: faSearchLocation,
    roles: ["ADMIN", "DEMO_ADMIN", "DEALER", "DEMO_DEALER"],
    text: "Areas",
    show: true,
  },

  {
    path: "/vpns",
    Component: AllVPNsPage,
    icon: faShieldAlt,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "VPNs",
    show: true,
  },
  {
    path: "/all-dealers",
    Component: AllDealersPage,
    icon: faUserGear,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "Dealers",
    show: true,
  },

  {
    path: "/sync-vlans-with-olt/:intPkOltID",
    Component: SyncVlansWIthOltPage,
    icon: faUserGear,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "Sync Vlans",
    show: false,
  },
  {
    path: "/create-end-user",
    Component: CreateEndUsersPage,
    icon: faUserCircle,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "Create End User",
    show: false,
  },
  {
    path: "/company-isps",
    Component: AdminIspsPage,
    icon: faGlobe,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "ISPs",
    show: true,
  },
  {
    path: "/inprocess-olts",
    Component: CompanyAdminProcessingOlts,
    icon: faNetworkWired,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "Processing OLTs",
    show: false,
  },
  {
    path: "/all-end-users",
    Component: AllEndUsersPage,
    icon: faUserCheck,
    roles: ["ADMIN", "DEMO_ADMIN"],
    text: "End-Users",
    show: true,
  },
  {
    path: "/",
    Component: AllSuperCooPage,
    icon: faHome,
    roles: ["SUPER_ADMIN"],
    text: "Home",
    show: true,
  },

  {
    path: "/price-policy",
    Component: AllPricePolicyModelsPageForSuperAdmin,
    icon: faMoneyCheck,
    roles: ["SUPER_ADMIN"],
    text: "Price Policy",
    show: true,
  },
  {
    path: "/all-banks",
    Component: AllBanksPageForSuperAdmin,
    icon: faBank,
    roles: ["SUPER_ADMIN"],
    text: "All Banks",
    show: true,
  },
  {
    path: "/bank-accounts-by-banks/:intPkBankID/:bankName",
    Component: AllBankAccountsByBankPageForSuperAdmin,
    icon: faBank,
    roles: ["SUPER_ADMIN"],
    text: "All Bank Accounts By Bank",
    show: false,
  },
  {
    path: "/all-super-coo",
    Component: AllSuperCooPage,
    icon: faUserCircle,
    roles: ["SUPER_ADMIN"],
    text: "All Super COO",
    show: true,
  },
  {
    path: "/new-olt-model",
    Component: NewOLTModelPage,
    icon: faWifi,
    roles: ["SUPER_ADMIN"],
    text: "New OLT Model",
    show: true,
  },
  {
    path: "/all-olt-models",
    Component: AllOLTModelsPage,
    icon: faWifi,
    roles: ["SUPER_ADMIN"],
    text: "All OLT Models",
    show: true,
  },
  {
    path: "/create-slot-model",
    Component: CreateNewSlotModelPage,
    icon: faCheckToSlot,
    roles: ["SUPER_ADMIN"],
    text: "Create Business Card Model",
    show: true,
  },
  {
    path: "/all-slot-models",
    Component: AllSlotsModelsPage,
    icon: faCheckToSlot,
    roles: ["SUPER_ADMIN"],
    text: "All Business Card Models",
    show: true,
  },
  {
    path: "/create-new-ont-model",
    Component: CreateNewONTModelPage,
    icon: faRoute,
    roles: ["SUPER_ADMIN"],
    text: "Create ONT Model",
    show: true,
  },
  {
    path: "/all-ont-models",
    Component: AllOntModelsPage,
    icon: faRoute,
    roles: ["SUPER_ADMIN"],
    text: "All ONT Models",
    show: true,
  },

  //
  // {
  //   path: "/create-isp",
  //   Component: CreateISPPage,
  //   icon: faGlobe,
  //   roles: ["*"],
  //   text: "Create New ISP",
  //   show: true,
  // },

  // {
  //   path: "/all-isps",
  //   Component: AllISPSPage,
  //   icon: faGlobe,
  //   roles: ["*"],
  //   text: "All ISPs",
  //   show: true,
  // },
  // {
  //   path: "/isp/create-vlan-profile/:intPkIspID",
  //   Component: CreateNewVlanProfilePage,
  //   icon: faWifi,
  //   roles: ["*"],
  //   text: "Create New Vlan",
  //   show: false,
  // },
  // {
  //   path: "/isp/view-vlan-profiles/:intPkIspID",
  //   Component: VlansByISPPage,
  //   icon: faWifi,
  //   roles: ["*"],
  //   text: "Create New Vlan",
  //   show: false,
  // },

  // {
  //   path:'/admin/dummy-stats',
  //   Component:DummyStatsPage,
  //   roles: ["ADMIN","DEMO_ADMIN"],
  //   icon: faUserPlus,
  //   text: "All Stats",
  // }
  {
    path: "/",
    Component: AllCompaniesCOOPage,
    icon: faHome,
    roles: ["SUPER_COO", "NOC"],
    text: "HOME",
    show: true,
  },
  {
    path: "/all-olts-by-company/:intPkCompanyID/:companyName",
    Component: AllOltsPage,
    icon: faNetworkWired,
    roles: ["SUPER_COO", "NOC"],
    text: "All OLTs",
    show: false,
  },
  {
    path: "/view-staff-by-company/:intPkCompanyID/:companyName",
    Component: StaffByCompanyPage,
    icon: faNetworkWired,
    roles: ["SUPER_COO"],
    text: "Staff By Company",
    show: false,
  },

  {
    path: "/create-slot-by-olt/:intPkOltID/:oltName",
    Component: CreateSlotPage,
    icon: faCheckToSlot,
    roles: ["SUPER_COO", "NOC"],
    text: "Create New SLot",
    show: false,
  },
  {
    path: "/all-slots-by-olt/:intPkOltID/:oltName",
    Component: AllSlotsByOltPage,
    icon: faCheckToSlot,
    roles: ["SUPER_COO", "NOC"],
    text: "All Slots",
    show: false,
  },
  {
    path: "/all-vlans-by-olt/:intPkOltID/:oltName/:brand",
    Component: VlansByOLTPage,
    icon: faCheckToSlot,
    roles: ["SUPER_COO", "NOC"],
    text: "All Vlans By OLT",
    show: false,
  },
  {
    path: "/ponports-by-slot/:intPkOltID/:intPkSlotID",
    Component: PonPortsBySlotPage,
    icon: faCheckToSlot,
    roles: ["SUPER_COO", "NOC"],
    text: "All Slots",
    show: false,
  },

  {
    path: "/create-company-with-admin",
    Component: CreateAdminWithCompanyPage,
    icon: faUser,
    roles: ["SUPER_COO"],
    text: "Create Company",
    show: true,
  },
  {
    path: "/all-companies",
    Component: AllCompaniesCOOPage,
    icon: faBuilding,
    roles: ["SUPER_COO", "NOC"],
    text: "All Companies",
    show: true,
  },
  {
    path: "/profile-settings",
    Component: UpdatePasswordPage,
    icon: faBuilding,
    roles: ["SUPER_COO", "ADMIN", "DEALER", "SUPER_ADMIN", "NOC", "ACCOUNTANT"],
    text: "Change Password",
    show: false,
  },
  {
    path: "/update-email",
    Component: UpdateEmailPage,
    icon: faBuilding,
    roles: ["SUPER_COO", "ADMIN", "DEALER", "SUPER_ADMIN", "NOC", "ACCOUNTANT"],
    text: "Change Password",
    show: false,
  },
  {
    path: "/search-olts",
    Component: SearchOLTsForNOCPage,
    icon: faSearch,
    roles: ["NOC", "SUPER_COO"],
    text: "Search OLTs",
    show: true,
  },
  {
    path: "/open-single-olt-for-diagnose/:intPkCompanyID/:companyName/:intPkOltID/:oltType",
    Component: DiagnoseSingleOLTPage,
    icon: faSearch,
    roles: ["NOC", "SUPER_COO"],
    text: "Diagnose OLT",
    show: false,
  },
  {
    path: "/company-login-through-noc/:intPkCompanyID",
    Component: NOCORCOOLoginAsCompanyPage,
    icon: faSearch,
    roles: ["NOC", "SUPER_COO"],
    text: "Login Company",
    show: false,
  },
  {
    path: "/processing-olts",
    Component: ProcessingOltsPage,
    icon: faNetworkWired,
    roles: ["NOC", "SUPER_COO"],
    text: "Processing OLTs",
    show: true,
  },
  {
    path: "/vpns",
    Component: AllVpnsNocPage,
    icon: faShieldAlt,
    roles: ["SUPER_COO", "NOC"],
    text: "VPNS",
    show: true,
  },
  {
    path: "/",
    Component: AllCompaniesPageForAccountant,
    icon: faHome,
    roles: ["ACCOUNTANT"],
    text: "Home",
    show: true,
  },
  {
    path: "/accountant-price-policy",
    Component: PricePolicyPageForAccountant,
    icon: faMoneyCheck,
    roles: ["ACCOUNTANT"],
    text: "Pricy Policy",
    show: true,
  },
  {
    path: "/accountant-sanctioned-olts",
    Component: AllSanctionedOltsForAccountantPage,
    icon: faNetworkWired,
    roles: ["ACCOUNTANT"],
    text: "Sanctioned OLTs",
    show: true,
  },
];
export const AppRoutes = () => {
  const role = getUserType();
  return routesList.filter((route: Route) => {
    const { roles } = route;
    if (roles.includes(role)) {
      return route;
    }
  });
  return routesList;
};
