import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, Table } from "react-bootstrap";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import PlusIcon from "./../../assets/svgs/icons/plusIcon.svg";
import NewIcon from "./../../assets/svgs/icons/NewIcon.svg";
import TickIcon from "./../../assets/svgs/icons/TickIcon.svg";
import CreateVlanFromOltResponseModal from "../Model/CreateVlanFromOltResponseModal";
import { ThreeDots } from "react-loader-spinner";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
import UpdateVlanDetailsFromOltModalComponent from "../Model/UpdateVlanDetailsFromOltModalComponent";

const ViewVlansByOltForCompanyAdmin: React.FC<GP> = ({}) => {
  const vlansFetchRef = useRef<any>(null);

  const [isFetchDisabled, setIsFetchDisabled] = useState<boolean>(false);
  const [
    getVlansData,
    vlanResponseData,
    vlanError,
    vlanLoading,
    vlanMessage,
    vlanErrorCount,
  ] = useAxiosPost<VlansByOLTProp>("vlans/getAllVlanProfilesByOLT");
  const [
    requestVlansFromOlt,
    vlansRequestFromOltResponse,
    requestError,
    requestLoading,
    requestMessage,
    requestErrorCount,
  ] = useAxiosPost<VlansByOLTProp>(
    "netflow/requests/fetchVlansOltWiseRequest",
    true
  );

  const [
    resultVlansFromOlt,
    vlansResultFromOlt,
    resultError,
    resultLoading,
    resultMessage,
    resultErrorCount,
  ] = useAxiosPost<VlansByOLTProp>("netflow/results/getVlansResultByOlt", true);

  const [allVlans, setAllVlans] = useState<any>([]);
  const [vlansFromOlt, setVlansFromOlt] = useState<any>([]);
  const [actualVlansFromOLT, setActualVlansFromOLT] = useState<any>([]);
  const [doWeHaveData, setDoWeHaveData] = useState<boolean>(false);
  const [activeVlanName, setActiveVlanName] = useState<string>("");
  const [activeVlan, setActiveVlan] = useState<string | number>("");
  const [activeIsp, setActiveIsp] = useState<string | number>("");
  const [intPkVlanID, setIntPkVlanID] = useState<string | number>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [checkForCreation, setCheckForCreation] = useState<boolean>(false);
  const handleClose = (isApiCall: boolean) => {
    setShow(false);
    setActiveVlan("");
    setActiveVlanName("");
    if (isApiCall == true) {
      setCheckForCreation(true);
      getVlansData({ intPkOltID: Number(intPkOltID) });
    }
  };
  const { intPkOltID } = useParams();
  useEffect(() => {
    getVlansData({ intPkOltID: Number(intPkOltID) });
  }, [intPkOltID]);
  useEffect(() => {
    if (vlanError !== null && vlanErrorCount !== 0) {
      notifyError(vlanError);
    }
  }, [vlanError, vlanErrorCount]);
  useEffect(() => {
    if (vlanResponseData !== null) {
      const responseData: any = vlanResponseData.responseData;
      // setAllVlans(responseData || []);
      const _data = responseData || [];
      //we'll check that if in response we have the vlan that is coming from olt , we'll tik it.
      const vlansWithTicksIncluded = _data.map((item: any) => {
        const isAvailable = actualVlansFromOLT.find(
          (_item: any) => _item.vlan == item.vlan
        );
        if (isAvailable) {
          return {
            ...item,
            isTick: true,
          };
        } else {
          return {
            ...item,
            isTick: false,
          };
        }
      });
      setAllVlans(vlansWithTicksIncluded);
      // if (checkForCreation == true) {
      let finalVlans = vlansFromOlt;
      for (const resObj of responseData) {
        const isAlready = finalVlans.find(
          (item: any) => item.vlan == resObj.vlan
        );
        if (isAlready) {
          finalVlans = finalVlans.filter(
            (item: any) => item.vlan != isAlready.vlan
          );
        }
      }
      setVlansFromOlt(finalVlans);
      // }
    }
  }, [vlanResponseData]);
  const requestVlansFromOltHandler = () => {
    requestVlansFromOlt({ intPkOltID: Number(intPkOltID) });
    setTimeout(() => {
      setIsFetchDisabled(false);
    }, 120000);
  };
  useEffect(() => {
    if (vlansRequestFromOltResponse !== null) {
      //   resultVlansFromOlt({ intPkOltID: Number(intPkOltID) });
      startIntervalHandler();
    }
  }, [vlansRequestFromOltResponse]);
  const startIntervalHandler = () => {
    vlansFetchRef.current = setInterval(() => {
      console.log("we are calling for vlans");
      resultVlansFromOlt({ intPkOltID: Number(intPkOltID) });
      console.log({ doWeHaveData });
    }, 2000);

    setTimeout(() => {
      clearInterval(vlansFetchRef.current);
      setIsLoading(false);
      getVlansData({ intPkOltID: Number(intPkOltID) });
    }, 30000);
  };
  useEffect(() => {
    if (vlansResultFromOlt !== null) {
      const responseData: any = vlansResultFromOlt.responseData;
      setActualVlansFromOLT(responseData?.vlansData || []);
      if (responseData?.vlansData?.length > 0) {
        setIsLoading(false);
        clearInterval(vlansFetchRef.current);
        console.log({ responseData: responseData?.vlansData });
        const finalVlans = [];
        for (const vlanObj of responseData?.vlansData) {
          const isCreatedAlready = allVlans.find(
            (item: any) => item.vlan == vlanObj.vlan
          );
          if (!isCreatedAlready) {
            if (vlanObj.vlan !== 1 && vlanObj.vlan !== 4095) {
              finalVlans.push(vlanObj);
            }
          }
        }
        console.log({ finalVlans });
        setVlansFromOlt(finalVlans);
        const _data = allVlans;
        const _vlans = responseData?.vlansData || [];
        const vlansWithTicksIncluded = _data.map((item: any) => {
          const isAvailable = _vlans.find(
            (_item: any) => _item.vlan == item.vlan
          );
          if (isAvailable) {
            return {
              ...item,
              isTick: true,
            };
          } else {
            return {
              ...item,
              isTick: false,
            };
          }
        });
        setAllVlans(vlansWithTicksIncluded);
        setActualVlansFromOLT(_vlans || []);
      }
    }
  }, [vlansResultFromOlt]);
  return (
    <>
      {show === true ? (
        <UpdateVlanDetailsFromOltModalComponent
          intPkOltID={Number(intPkOltID)}
          show={show}
          handleClose={handleClose}
          vlan={activeVlan}
          vlanName={activeVlanName}
          intPkIspID={activeIsp}
          intPkVlanID={intPkVlanID}
        />
      ) : null}
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Vlans By OLT
              <span style={{ float: "right" }}>
                <Button
                  variant="success"
                  disabled={isFetchDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    const role = getUserType();
                    if (
                      role === "" ||
                      role == "DEMO_DEALER" ||
                      role === "DEMO_ADMIN"
                    ) {
                      notifyDemoAccountError();
                      return;
                    }
                    setDoWeHaveData(false);
                    setIsFetchDisabled(true);
                    setIsLoading(true);
                    requestVlansFromOltHandler();
                  }}
                >
                  Fetch Vlans From OLT
                </Button>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                <Table style={{ border: "1px solid #dee2e6" }}>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Vlan</th>
                      <th className="table-header-standard">Vlan Name</th>
                      <th className="table-header-standard">ISP</th>
                      <th className="table-header-standard">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allVlans.map((item: any) => {
                      return (
                        <tr key={item.intPkVlanID}>
                          <td>{item.vlan}</td>
                          <td>{item.vlanName}</td>
                          <td>
                            <img
                              style={{ width: "40px" }}
                              src={`${item.ispLogoUrl}`}
                            />
                          </td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveVlan(item.vlan);
                                setActiveVlanName(item.vlanName);
                                setShow(true);
                                setActiveIsp(item.intPkIspID || "");
                                setIntPkVlanID(item.intPkVlanID);
                              }}
                              variant="primary"
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ViewVlansByOltForCompanyAdmin;
