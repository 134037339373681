import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import CapoltLogo from "./../../assets/svgs/logos/capolt-logo.svg";
import RouterImage from "./../../assets/svgs/logos/router-image.svg";
import "./../../assets/styles/login.css";
import { InputGroup, FormControl } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { notifySuccess } from "../toast/Success";
import GoogleReCaptcha from "react-google-recaptcha";
import { Formik, Field, ErrorMessage, FormikHelpers, Form } from "formik";
type SignupInputs = {
  password: string;
  email: string;
  contactNumber: string;
  fullName: string;
  companyName: string;
  streetAddress: string;
  intPkCityID: string;
  intPkCountryID: string;
};
type Props = {
  isLoggedIn?: boolean;
};
/**
 * 
 * @param param0 
 * 
 * 
 *   const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [streetAddress, setStreetAddress] = useState<string>("");
  const [allCountries, setAllCountries] = useState<any>([]);
  const [allCities, setAllCities] = useState<any>([]);
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
 * @returns 
 */

const SignUpFormComponent: React.FC<Props> = ({}) => {
  const [recaptchaToken, setRecaptchaToken] = useState<any>(null);

  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<SignupType>("users/auth/signup");

  const [
    getCountries,
    getCountriesResponse,
    countriesError,
    countriesLoading,
    countriesMessage,
    countriesErrorCount,
  ] = useAxiosPost<ResponseProp>("addresses/getCountries");

  const [
    getCities,
    citiesResponse,
    citiesError,
    citiesLoading,
    citiesMessage,
    citiesErrorCount,
  ] = useAxiosPost<GetCitiesByCountry>("addresses/country/citiesByCountry");

  const signUpValidationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is Required"),
    fullName: yup.string().required("User Full Name is Required"),
    companyName: yup.string().required("Company name is Required"),
    intPkCountryID: yup
      .string()
      .required("Invalid Country Selected")
      .min(1, "Invalid Country Selected"),
    intPkCityID: yup
      .string()
      .required("Invalid City Selected")
      .min(1, "Invalid City Selected"),
    streetAddress: yup.string().required("Address Is required"),
    contactNumber: yup
      .string()
      .required("Phone Number Is required")
      .min(10, "Should be 10 digits minimum")
      .max(11, "Should be 11 Digits maximum"),
  });

  useLayoutEffect(() => {
    if (localStorage.getItem("auth_token")) {
      window.location.href = "/";
    }
  }, []);
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      notifyError(loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      console.log(loginResponseData);
      notifySuccess(`Signed Up Successfully`);
      const data: any = loginResponseData;
      const responseData = data.responseData;
      const signedUpUser = responseData.intPkUserID;
      localStorage.setItem("signedUpUser", signedUpUser);
      localStorage.setItem("goToVerification", "1");

      window.location.href = "/verify";
    }
  }, [loginResponseData]);

  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [streetAddress, setStreetAddress] = useState<string>("");
  const [allCountries, setAllCountries] = useState<any>([]);
  const [allCities, setAllCities] = useState<any>([]);
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  useEffect(() => {
    if (getCountriesResponse !== null) {
      const data: any = getCountriesResponse.responseData;
      setAllCountries(data || []);
    }
  }, [getCountriesResponse]);
  useEffect(() => {
    if (citiesResponse !== null) {
      const data: any = citiesResponse.responseData;
      setAllCities(data || []);
    }
  }, [citiesResponse]);
  useEffect(() => {
    getCountries({});
  }, []);

  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  const handleVerify = (token: any) => {
    console.log({ token });
    setRecaptchaToken(token);
  };
  const handleSubmit = (
    values: SignupInputs,
    { setSubmitting, resetForm }: FormikHelpers<SignupInputs>
  ) => {
    console.log("everything is good", values);
    if (recaptchaToken === null) {
      notifyError(`Please Fill Out Captcha`);
      setSubmitting(false);
      return;
    }
    const dataToSend: SignupType = {
      username: values.email,
      email: values.email,
      intPkCityID: Number(values.intPkCityID),
      intPkCountryID: Number(values.intPkCountryID),
      userFullname: values.fullName,
      contactNumber: values.contactNumber,
      companyName: values.companyName,
      password: values.password,
      streetAddress: values.streetAddress,
    };
    setSubmitting(false);
    postLoginData(dataToSend);

    return;
  };

  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col
            className="routerContainer"
            md={5}
            lg={5}
            xxl={5}
            xl={5}
            sm={5}
            xs={5}
          >
            <Row>
              <Col md={1} lg={1} xl={1} xxl={1} sm={1} xs={1}></Col>
              <Col
                className="mt-10-custom"
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                sm={10}
                xs={11}
              >
                <h1>Welcome To Capolt</h1>
                <h6 style={{ fontWeight: "normal" }}>Create New Account</h6>
              </Col>
              <img src={RouterImage} className="h-450" />
            </Row>
          </Col>
          <Col
            className="mt-1-custom"
            md={7}
            lg={7}
            xxl={7}
            xl={7}
            sm={7}
            xs={7}
          >
            <img src={CapoltLogo} />
            <div>
              <div className="login-form-main-container">
                {/* <Form.Group className="mb-3 min-w-100">
                    <GoogleReCaptcha
                      sitekey="6Lei01goAAAAAPVm87-0WxlqD2euk0P0VTjVdHDN"
                      onChange={handleVerify}
                    />
                  </Form.Group>

                  <button className="button-primary" onClick={handleLogin}>
                    Sign Up
                  </button> */}
                <Formik
                  initialValues={{
                    fullName: "",
                    contactNumber: "",
                    email: "",
                    password: "",
                    companyName: "",
                    intPkCityID: "",
                    intPkCountryID: "",
                    streetAddress: "",
                  }}
                  validationSchema={signUpValidationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    isValid,
                    dirty,
                  }) => (
                    <Form>
                      <div className="form-group mb-3 min-w-100">
                        <Field
                          type="text"
                          name="fullName"
                          placeholder="Full Name"
                          className={`form-control ${
                            errors.fullName && touched.fullName && "is-invalid"
                          }`}
                        />
                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="custom-error-message"
                        />
                      </div>
                      <div className="form-group mb-3 min-w-100">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className={`form-control ${
                            errors.email && touched.email && "is-invalid"
                          }`}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="custom-error-message"
                        />
                      </div>
                      <div className="form-group mb-3 min-w-100">
                        <Field
                          type="password"
                          name="password"
                          autoComplete="off"
                          placeholder="Password"
                          className={`form-control ${
                            errors.password && touched.password && "is-invalid"
                          }`}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="custom-error-message"
                        />
                      </div>
                      <div className="form-group mb-3 min-w-100">
                        <Field
                          type="text"
                          name="companyName"
                          placeholder="Company Name"
                          className={`form-control ${
                            errors.companyName &&
                            touched.companyName &&
                            "is-invalid"
                          }`}
                        />
                        <ErrorMessage
                          name="companyName"
                          component="div"
                          className="custom-error-message"
                        />
                      </div>
                      <div className="form-group mb-3 min-w-100">
                        <Field
                          type="text"
                          name="contactNumber"
                          placeholder="Contact Number"
                          className={`form-control ${
                            errors.contactNumber &&
                            touched.contactNumber &&
                            "is-invalid"
                          }`}
                        />
                        <ErrorMessage
                          name="contactNumber"
                          component="div"
                          className="custom-error-message"
                        />
                      </div>
                      <div className="form-group mb-3 min-w-100">
                        <Field
                          type="text"
                          name="streetAddress"
                          placeholder="Address"
                          className={`form-control ${
                            errors.streetAddress &&
                            touched.streetAddress &&
                            "is-invalid"
                          }`}
                        />
                        <ErrorMessage
                          name="streetAddress"
                          component="div"
                          className="custom-error-message"
                        />
                      </div>
                      <div className="form-group mb-3 min-w-100">
                        <Field
                          as="select"
                          name="intPkCountryID"
                          onChange={(e: any) => {
                            if (e.target.value == "") {
                              setAllCities([]);
                              setFieldValue("intPkCountryID", e.target.value);
                              return;
                            } else {
                              setFieldValue("intPkCountryID", e.target.value);

                              getCities({ intPkCountryID: e.target.value });
                              //let's get cities bro
                            }
                            console.log({ v: e.target.value });
                          }}
                          className={`form-control ${
                            errors.intPkCountryID &&
                            touched.intPkCountryID &&
                            "is-invalid"
                          }`}
                        >
                          <option value="">Country</option>
                          {allCountries.map((item: any) => {
                            return (
                              <option
                                value={item.intPkCountryID}
                                key={item.intPkCountryID}
                              >
                                {item.countryName}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage
                          name="intPkCountryID"
                          component="div"
                          className="custom-error-message"
                        />
                      </div>
                      <div className="form-group mb-3 min-w-100">
                        <Field
                          as="select"
                          name="intPkCityID"
                          className={`form-control ${
                            errors.intPkCityID &&
                            touched.intPkCityID &&
                            "is-invalid"
                          }`}
                        >
                          <option value="">City</option>
                          {allCities.map((item: any) => {
                            return (
                              <option
                                value={item.intPkCityID}
                                key={item.intPkCityID}
                              >
                                {item.cityName}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage
                          name="intPkCityID"
                          component="div"
                          className="custom-error-message"
                        />
                      </div>
                      <div className="form-group mb-3 min-w-100">
                        <GoogleReCaptcha
                          sitekey="6Lei01goAAAAAPVm87-0WxlqD2euk0P0VTjVdHDN"
                          onChange={handleVerify}
                        />
                      </div>

                      <button
                        type="submit"
                        className="button-primary"
                        disabled={
                          isSubmitting || !isValid || !dirty || loginLoading
                        }
                      >
                        {isSubmitting ? "Loading..." : "Sign Up"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SignUpFormComponent;
