import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link, useNavigate } from "react-router-dom";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { InputGroup } from "react-bootstrap";
import { Card, Collapse, Table } from "react-bootstrap";
import {
  faPlus,
  faMinus,
  faSearch,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../helpers/date";
import Moment from "react-moment";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyIcon from "./../../assets/svgs/copy/Copy.svg";

import OntSVG from "./../../assets/svgs/search/ont.svg";
import FoundOntSvg from "./../../assets/svgs/search/found_onts.svg";
import LinkButtonImage from "./../../assets/svgs/goto/Link.svg";

import OneBar from "./../../assets/svgs/signals/oneBar.svg";
import TwoBars from "./../../assets/svgs/signals/twoBars.svg";
import ThreeBars from "./../../assets/svgs/signals/threeBars.svg";
import FourBars from "./../../assets/svgs/signals/fourBars.svg";
import FiveBars from "./../../assets/svgs/signals/fiveBars.svg";
import SixBars from "./../../assets/svgs/signals/sixBars.svg";
import MinusOneBar from "./../../assets/svgs/signals/minusOneBar.svg";
import LocationSvg from "./../../assets/svgs/maps/location.svg";
import RebootedStatus from "./../../assets/svgs/statuses/rebooting.svg";
import TimeAgo from "react-timeago";

//status
import UpStatus from "./../../assets/svgs/statuses/up.svg";
import DownStatus from "./../../assets/svgs/statuses/down.svg";
import InActiveStatus from "./../../assets/svgs/statuses/inactive.svg";
import InProcessStatus from "./../../assets/svgs/statuses/inprocess.svg";
import UpSetStatus from "./../../assets/svgs/statuses/upset.svg";
import InVisibleStatus from "./../../assets/svgs/statuses/invisible.svg";
import StaticRouter from "./../../assets/svgs/statuses/router-static.png";
import MacAddressesCard from "../Cards/MacAddressesCard";
import { ThreeDots } from "react-loader-spinner";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import EditEndUsersModal from "../Model/EditEndUsersModal";
import { showUserName } from "../../helpers/endUser";
import Pagination from "react-bootstrap/Pagination";
import _ from "lodash";
const initialSortState: SortType = {
  signal: "",
};

const ONTsReportComponent: React.FC<GP> = ({}) => {
  const navigate = useNavigate();
  const [sortType, setSortType] = useState<SortType>({
    signal: "",
  });
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
  const [ONTs, setONTs] = useState<any>([]);
  const [ONTsForPagination, setONTsForPagination] = useState<any>([]);
  const [actualONTs, setActualONTs] = useState<any>([]);
  const [allOlts, setAllOlts] = useState<any>([]);
  const [allSlots, setAllSlots] = useState<any>([]);
  const [allVlans, setAllVlans] = useState<any>([]);
  const [actualIspsData, setActualIspsData] = useState<any>([]);
  const [allIsps, setAllIsps] = useState<any>([]);
  const [filteredSlots, setFilteredSlots] = useState<any>([]);
  const [allPonPorts, setAllPonPorts] = useState<any>([]);
  const [filteredPonPorts, setFilteredPonPorts] = useState<any>([]);
  const [selectedOLT, setSelectedOLT] = useState("");
  const [filteredCount, setFilteredCount] = useState<number>(0);
  const [filteredOnts, setFilteredOnts] = useState<any>([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [ontType, setOntType] = useState<number>(2);
  const [selectedPonPort, setSelectedPonPort] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedIsp, setSelectedIsp] = useState("");
  const [selectedVlan, setSelectedVlan] = useState("");
  const [isReloadDisabled, setIsReloadDisabled] = useState<boolean>(false);
  const [
    deleteRegistration,
    deleteRegistrationResponse,
    deleteError,
    deleteLoading,
    deleteMessage,
    deleteErrorCount,
  ] = useAxiosPost<DeleteRegistrationFromSearchProp>("binding/deleteBinding");
  const handleDeleteRegistration = (
    ponNumber: string,
    intPkOltID: number,
    intPkOntBindingID: number
  ) => {
    const bondObj = {
      ponNumber,
      intPkOltID,
      intPkOntBindingID,
    };
    deleteRegistration({
      bondObj,
    });
  };
  useEffect(() => {
    if (deleteError !== null && deleteErrorCount !== 0) {
      notifyError(deleteError);
    }
  }, [deleteError, deleteErrorCount]);
  useEffect(() => {
    if (deleteRegistrationResponse !== null) {
      console.log({ deleteRegistrationResponse });
      notifySuccess("Ont Deleted Successfully");
      getReportsData({ intPkOltID: Number(selectedOLT) });
    }
  }, [deleteRegistrationResponse]);

  const [activeEndUsers, setActiveEndUsers] = useState<any>([]);
  const [downReasons, setDownReasons] = useState<any>([]);
  const [activeBindingId, setActiveBindingID] = useState<number>(-1);
  const [activeBinding, setActiveBinding] = useState<ActiveBindingForEndUser>({
    oltFrame: -1,
    oltSlot: -1,
    oltPonPort: -1,
    intPkOltID: -1,
    ponNumber: "",
  });
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isCall: boolean) => {
    if (isCall === true) {
      getReportsData({ intPkOltID: Number(selectedOLT) });
    }
    setShow(false);
    setActiveEndUsers([]);
    setActiveBindingID(-1);
    setActiveBinding({
      oltFrame: -1,
      oltSlot: -1,
      oltPonPort: -1,
      intPkOltID: -1,
      ponNumber: "",
    });
  };

  const [
    getAllOltsData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<ResponseProp>("reports/getAdminOLTsSlotsAndPonPorts");
  const [
    getIspsAndVlansData,
    ispsAndVlansResponse,
    ispsAndVlansError,
    ispsAndVlansLoading,
    ispsAndVlansMessage,
    ispsAndVlansErrorCount,
  ] = useAxiosPost<ReportsApiProp>("reports/getIspsAndVlansForAdminOltWise");

  useEffect(() => {
    getAllOltsData({});
  }, []);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const responseData: any = oltResponseData.responseData;
      const { olts, slots, ponPorts } = responseData;
      if (olts && Array.isArray(olts)) {
        olts.sort((a: any, b: any) => a.intPkOltID - b.intPkOltID);
        setAllOlts(olts || []);
      }
      if (slots && Array.isArray(slots)) {
        setAllSlots(slots || []);
      }
      if (ponPorts && Array.isArray(ponPorts)) {
        setAllPonPorts(ponPorts || []);
      }
    }
  }, [oltResponseData]);

  const [
    getReportsData,
    reportDataResponse,
    reportDataError,
    reportLoading,
    reportMessage,
    reportErrorCount,
  ] = useAxiosPost<ReportsApiProp>("reports/getOntsReportsForAdmin");
  useEffect(() => {
    if (reportDataError !== null && reportErrorCount !== 0) {
      notifyError(reportDataError);
    }
  }, [reportDataError, reportErrorCount]);
  useEffect(() => {
    if (reportDataResponse !== null) {
      const responseData: any = reportDataResponse.responseData;
      console.log({ responseData });
      if (responseData.length > 0) {
        const data = responseData[0];
        console.log({ data });
        setOntType(Number(data.isOntEponOrGpon));
      }
      const uniqueData = _.uniqBy(
        responseData || [],
        "frontendDisplayStatusString"
      );
      console.log({ uniqueData });
      const downStrings = uniqueData
        .filter((item: any) => item.ontStatus == "DOWN")
        .filter((item: any) => item.frontendDisplayStatusString !== null)
        .filter((item: any) => item.frontendDisplayStatusString !== "")
        .map((item: any) => item.frontendDisplayStatusString);
      console.log({ downStrings });
      setDownReasons(downStrings || []);
      // setONTs(responseData);
      setActualONTs(responseData);
      handleReportFilter(
        selectedOLT,
        selectedSlot,
        selectedPonPort,
        selectedStatus,
        downStrings,
        selectedIsp,
        selectedVlan,
        responseData
      );
    }
  }, [reportDataResponse]);
  useEffect(() => {
    if (ispsAndVlansResponse !== null) {
      const responseData: any = ispsAndVlansResponse.responseData;
      console.log({ responseData });
      const { vlans, isps, actualData } = responseData;
      setAllIsps(isps || []);
      // setAllVlans(vlans || []);
      setActualIspsData(actualData || []);
    }
  }, [ispsAndVlansResponse]);

  useEffect(() => {
    console.log("this ran");
    setCurrentPage(1);
    handleReportFilter(
      selectedOLT,
      selectedSlot,
      selectedPonPort,
      selectedStatus,
      downReasons,
      selectedIsp,
      selectedVlan,
      actualONTs
    );
  }, [
    // selectedOLT,
    selectedSlot,
    selectedPonPort,
    selectedStatus,
    selectedIsp,
    selectedVlan,
  ]);
  const convertMetersToKiloMeters = (meters: any) => {
    if (meters < 1000) {
      return `${meters} Meters`;
    } else {
      return `${meters / 1000} KM`;
    }
  };

  const getSignalImage = (
    status: any,
    isOntInActive: any,
    isActivationInProcess: any,
    intPkOntRegisterationID: any,
    isOntRebooted: any
  ) => {
    if (isOntRebooted === true) {
      return <img src={RebootedStatus} />;
    }

    if (isActivationInProcess == true) {
      return <img src={InProcessStatus} />;
    }
    if (isOntInActive == true) {
      return <img src={InActiveStatus} />;
    }
    if (status === "UP") {
      return <img src={UpStatus} />;
    }
    if (status === "DOWN") {
      return <img src={DownStatus} />;
    }
    if (status === "UPSET") {
      return <img src={UpSetStatus} />;
    }
    if (intPkOntRegisterationID == null) {
      return <img src={InVisibleStatus} />;
    }
  };
  const getSignalsBar = (bars: number, isOntRebooted: any, ontStatus: any) => {
    if (isOntRebooted === true) {
      return <img src={MinusOneBar} className="image-float-right" />;
    }
    if (ontStatus === "DOWN") {
      return <img src={MinusOneBar} className="image-float-right" />;
    }
    if (bars === -1) {
      return <img src={MinusOneBar} className="image-float-right" />;
    } else if (bars === 1) {
      return <img src={OneBar} className="image-float-right" />;
    } else if (bars === 2) {
      return <img src={TwoBars} className="image-float-right" />;
    } else if (bars === 3) {
      return <img src={ThreeBars} className="image-float-right" />;
    } else if (bars === 4) {
      return <img src={FourBars} className="image-float-right" />;
    } else if (bars === 5) {
      return <img src={FiveBars} className="image-float-right" />;
    } else if (bars === 6) {
      return <img src={SixBars} className="image-float-right" />;
    }
  };
  const handleReportFilter = (
    selectedOLT: string = "",
    selectedSlot: string = "",
    selectedPonPort: string = "",
    selectedStatus: string = "",
    downReasons: any[],
    selectedIsp: string = "",
    selectedVlan: string = "",
    actualONTs: any[]
  ) => {
    const filter = {
      selectedOLT,
      selectedSlot,
      selectedPonPort,
      selectedStatus,
    };
    console.log({ filter });
    // localStorage.setItem("ontsReportsFilter", JSON.stringify(filter));
    let onts = actualONTs;
    console.log({ actualONTs: actualONTs.length });
    if (selectedSlot) {
      console.log("slot selected");
      onts = onts.filter((item: any) => item.oltSlot == selectedSlot);
    }
    if (selectedPonPort) {
      console.log("in it", selectedPonPort);
      console.log({ before: onts.length });
      onts = onts.filter(
        (item: any) =>
          item.oltSlot == selectedSlot && item.oltPonPort == selectedPonPort
      );
      console.log({ after: onts.length });
    }
    console.log({ onts: onts.length });
    if (selectedStatus) {
      //"UP", "DOWN", "UPSET","INACTIVE","INVISIBLE","REBOOTED"
      console.log({ selectedStatus });
      if (selectedStatus === "healthy") {
        onts = onts.filter(
          (item: any) => item.barsCount == 5 || item.barsCount == 4
        );
      } else if (selectedStatus === "tooHigh") {
        onts = onts.filter((item: any) => item.barsCount == 6);
      } else if (selectedStatus === "upset") {
        onts = onts.filter((item: any) => item.barsCount == 3);
      } else if (selectedStatus === "bad") {
        onts = onts.filter(
          (item: any) => item.barsCount < 3 && item.barsCount >= 1
        );
      } else if (selectedStatus === "INACTIVE") {
        onts = onts.filter((item: any) => item.isOntInActive == true);
      } else if (selectedStatus === "INVISIBLE") {
        onts = onts.filter((item: any) => item.intPkOntRegisterationID == null);
      } else if (selectedStatus === "REBOOTED") {
        onts = onts.filter((item: any) => item.isOntRebooted == true);
      } else if (selectedStatus === "DOWN") {
        onts = onts.filter(
          (item: any) =>
            item.ontStatus == selectedStatus && item.isOntInActive == false
        );
      } else if (downReasons.includes(selectedStatus)) {
        onts = onts
          .filter((item: any) => item.ontStatus == "DOWN")
          .filter(
            (item: any) => item.frontendDisplayStatusString == selectedStatus
          );
        console.log({ _ont_: onts });
      } else {
        onts = onts.filter((item: any) => item.ontStatus == selectedStatus);
      }
    }
    if (selectedIsp) {
      console.log("ISP selected", selectedIsp);
      onts = onts.filter((item: any) => {
        const servicePortArray = item.servicePortArray;
        console.log({ servicePortArray });
        const isIspFound = servicePortArray.find(
          (_item: any) => _item.ispName == selectedIsp
        );
        console.log({ isIspFound });
        if (isIspFound) {
          return item;
        }
      });
    }
    if (selectedVlan) {
      console.log("VLAN selected", selectedVlan);
      onts = onts.filter((item: any) => {
        const servicePortArray = item.servicePortArray;
        console.log({ servicePortArray });
        if (servicePortArray.length > 0) {
          const isIspFound = servicePortArray[0].macAddressArray.find(
            (_item: any) => _item.vlan == selectedVlan
          );
          console.log({ isIspFound });
          if (isIspFound) {
            return item;
          }
        }
      });
    }
    console.log({ onts });
    setONTs(onts);
    setONTsForPagination(onts);
    setFilteredCount(onts.length);
    setFilteredOnts(onts);
    showOntsPageWise(
      onts,
      currentPage,
      selectedOLT,
      selectedSlot,
      selectedPonPort,
      selectedStatus,
      selectedIsp,
      selectedVlan,
      downReasons
    );
  };
  useEffect(() => {
    handleOLTChange(selectedOLT);
  }, [selectedOLT]);
  useEffect(() => {
    console.warn("slot selected", { selectedSlot });
    handleSlotChange(selectedSlot, selectedOLT);
  }, [selectedSlot]);
  const handleOLTChange = (oltId: any) => {
    if (oltId == "") {
      setAllIsps([]);
      setAllVlans([]);
      setDownReasons([]);
      setSelectedSlot("");
      setSelectedPonPort("");
      setSelectedStatus("");
      setSelectedVlan("");
      setSelectedIsp("");
      setONTs([]);
      setONTsForPagination([]);
      setActualONTs([]);
      setFilteredCount(0);
      setFilteredOnts([]);
      return;
    }
    setAllIsps([]);
    setAllVlans([]);
    setDownReasons([]);
    setSelectedVlan("");
    setSelectedIsp("");
    setSelectedSlot("");
    setSelectedStatus("");
    setSelectedPonPort("");
    setFilteredPonPorts([]);
    setFilteredCount(0);
    setFilteredOnts([]);

    const filteredSlots = allSlots.filter(
      (item: any) => item.intPkOltID == oltId
    );

    setFilteredSlots(filteredSlots);
  };
  const handleSlotChange = (slotId: any, selectedOLT: string) => {
    setSelectedPonPort("");
    if (slotId == "") {
      return;
    }
    const filteredPonPorts = allPonPorts.filter(
      (item: any) => item.oltSlot == slotId && item.intPkOltID == selectedOLT
    );

    setFilteredPonPorts(filteredPonPorts);
  };
  const getStatusString = (status: any) => {
    if (status === "UP") {
      return "Up Since";
    }
    if (status === "DOWN") {
      return "Down Since";
    }
    if (status === "UPSET") {
      return "Upset Since";
    }
  };
  const getTimeAgo = (_date: any) => {
    const startTime = moment(_date);
    const currentTime = moment();
    // <TimeAgo date={new Date(_date)} />;
    return (
      <TimeAgo date={new Date(_date)} />
      // <Moment from={currentTime} ago>
      //   {startTime}
      // </Moment>
    );
  };
  const handleSort = (type: string, onts: any[], SortType: string = "DESC") => {
    switch (type) {
      case "SIGNAL": {
        const _onts = onts.sort((a: any, b: any) => {
          let calculated = 0;

          if (SortType === "ASC") {
            calculated =
              Number(a.ontRxSignalFromOLT) - Number(b.ontRxSignalFromOLT);
          } else if (SortType === "DESC") {
            calculated =
              Number(b.ontRxSignalFromOLT) - Number(a.ontRxSignalFromOLT);
          }
          return calculated;
        });

        setONTs(_onts);
      }
    }
  };
  const getSortIcon = (_sortType: string) => {
    if (_sortType === "ASC") {
      return (
        <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: "10px" }} />
      );
    } else if (_sortType === "DESC") {
      return (
        <FontAwesomeIcon icon={faCaretUp} style={{ marginLeft: "10px" }} />
      );
    } else {
      return "";
    }
  };

  const exportToExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Generate a binary string from the workbook
    const excelBuffer = XLSX.write(workbook, { type: "array" });

    // Convert the binary string to a Blob
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the Blob as an Excel file
    saveAs(excelBlob, `onts-report-${new Date().getTime()}.xlsx`);
  };
  const getStatusForExcel = (
    status: any,
    isOntInActive: any,
    isActivationInProcess: any,
    intPkOntRegisterationID: any,
    isOntRebooted: any
  ) => {
    if (isOntRebooted === true) {
      return "Rebooted";
    }

    if (isActivationInProcess == true) {
      return "Activation In Process";
    }
    if (isOntInActive == true) {
      return "InActive";
    }
    if (status === "UP") {
      return "UP";
    }
    if (status === "DOWN") {
      return "DOWN";
    }
    if (status === "UPSET") {
      return "UPSET";
    }
    if (intPkOntRegisterationID == null) {
      return "INVISIBLE";
    }
  };

  const handleExportToExcel = (onts: any) => {
    if (onts.length < 1) {
      return;
    }
    const data = onts.map((item: any) => {
      const _item = {
        ponNumber: item.ponNumber,
        FSP: `${item.oltFrame}/${item.oltSlot}/${item.oltPonPort}`,
        ontID: item.ontID,
        Signal:
          item.ontRxSignalFromOLT !== null ? item.ontRxSignalFromOLT : "-",
        Distance:
          item.ontDistanceFromOltInMeters !== null
            ? convertMetersToKiloMeters(item.ontDistanceFromOltInMeters)
            : "-",
        Status: getStatusForExcel(
          item.ontStatus,
          item.isOntInActive,
          item.isActivationInProcess,
          item.intPkOntRegisterationID,
          item.isOntRebooted
        ),
        LastCause: item.frontendDisplayStatusString,
        LastActivityTime: `${getStatusString(item.ontStatus)}:${
          item.sinceLastStatusChangedDays
        } Days Ago`,
        Vlan: item.vlan,
        VlanName: item.vlanName,
        ponPortName: item.ponPortName !== null ? item.ponPortName : "-",

        ISP:
          item.servicePortArray.length > 0
            ? item.servicePortArray[0].ispName
            : "-",
        User: item.endUsers.length > 0 ? item.endUsers[0].endUserFullName : "-",
      };
      return _item;
    });
    console.log({ data });
    exportToExcel(data);
  };
  const showPonOrMacAddress = (
    ponNumber: string,
    hexPon: string,
    macAddress: string,
    ontType: number
  ) => {
    if (ontType == 1) {
      return macAddress;
    }
    if (ontType == 2) {
      return ponNumber;
    }
    if (ontType == 3) {
      return hexPon;
    }
  };
  const showMacAddresses = (
    ontType: number,
    macAddressesArray: any[],
    formattedOntEponMacAddressID: string
  ) => {
    if (ontType === 2) {
      return macAddressesArray.map((item: any, index: number) => {
        return (
          <p>
            {item?.macAddressArray?.length > 0
              ? item?.macAddressArray[0]?.windows
              : ""}
            {item?.macAddressArray?.length > 0 ? (
              <>
                {item?.macAddressArray[0]?.windows !== "" ? (
                  <>
                    <CopyToClipboard
                      text={item?.macAddressArray[0]?.windows || ""}
                      onCopy={() => {
                        notifySuccess(`Copied To Clipboard`);
                        console.log("copy default to clipboard");
                      }}
                    >
                      <img
                        src={CopyIcon}
                        style={{
                          cursor: "pointer",
                          width: "30px",
                          marginRight: "8px",
                          marginLeft: "8px",
                        }}
                      />
                    </CopyToClipboard>
                  </>
                ) : null}
              </>
            ) : (
              ""
            )}
          </p>
        );
      });
    }
    if (ontType === 1) {
      if (macAddressesArray.length > 0) {
        return macAddressesArray.map((item: any, index: number) => {
          return (
            <p>
              {item?.macAddressArray?.length > 0
                ? item?.macAddressArray[0]?.windows
                : ""}
              {item?.macAddressArray?.length > 0 ? (
                <>
                  {item?.macAddressArray[0]?.windows !== "" ? (
                    <>
                      <CopyToClipboard
                        text={item?.macAddressArray[0]?.windows || ""}
                        onCopy={() => {
                          notifySuccess(`Copied To Clipboard`);

                          console.log("copy default to clipboard");
                        }}
                      >
                        <img
                          src={CopyIcon}
                          style={{
                            cursor: "pointer",
                            width: "30px",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                        />
                      </CopyToClipboard>
                    </>
                  ) : null}
                </>
              ) : (
                ""
              )}
            </p>
          );
        });
      }
      return (
        <p>
          {formattedOntEponMacAddressID}
          <CopyToClipboard
            text={formattedOntEponMacAddressID || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
              console.log("copy default to clipboard");
            }}
          >
            <img
              src={CopyIcon}
              style={{
                cursor: "pointer",
                width: "30px",
                marginRight: "8px",
                marginLeft: "8px",
              }}
            />
          </CopyToClipboard>
        </p>
      );
    }
    if (ontType === 3) {
      return <p style={{ textAlign: "center" }}>-</p>;
    }
  };
  const handlePageClick = (page: number) => {
    console.log({ page });
    setCurrentPage(page);
  };
  const showPagination = (itemsPerPage: number, onts: any[]) => {
    const totalPages = Math.ceil(onts.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    let buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <>
          <Pagination.Item
            onClick={(e) => {
              e.preventDefault();
              handlePageClick(i);
            }}
            active={currentPage === i ? true : false}
          >
            {i}
          </Pagination.Item>
        </>
      );
    }
    return (
      <Pagination style={{ display: "flex", flexWrap: "wrap" }}>
        <Pagination.First
          onClick={(e) => {
            setCurrentPage(1);
          }}
          disabled={currentPage === 1 ? true : false}
        />
        <Pagination.Prev
          onClick={(e) => {
            setCurrentPage(currentPage - 1);
          }}
          disabled={currentPage === 1 ? true : false}
        />

        {buttons}
        <Pagination.Next
          onClick={(e) => {
            setCurrentPage(currentPage + 1);
          }}
          disabled={currentPage === totalPages ? true : false}
        />
        <Pagination.Last
          onClick={(e) => {
            setCurrentPage(totalPages);
          }}
          disabled={currentPage === totalPages ? true : false}
        />
      </Pagination>
    );
  };
  const showOntsPageWise = (
    actualONTs: any[],
    currentPage: number,
    selectedOLT: any,
    selectedSlot: any,
    selectedPonPort: any,
    selectedStatus: any,
    selectedIsp: any,
    selectedVlan: any,
    downReasons: any[]
  ) => {
    let onts = actualONTs;
    console.log({ actualONTs: actualONTs.length });
    if (selectedSlot) {
      console.log("slot selected");
      onts = onts.filter((item: any) => item.oltSlot == selectedSlot);
    }
    if (selectedPonPort) {
      console.log("in it", selectedPonPort);
      console.log({ before: onts.length });
      onts = onts.filter(
        (item: any) =>
          item.oltSlot == selectedSlot && item.oltPonPort == selectedPonPort
      );
      console.log({ after: onts.length });
    }
    console.log({ onts: onts.length });
    if (selectedStatus) {
      //"UP", "DOWN", "UPSET","INACTIVE","INVISIBLE","REBOOTED"
      console.log({ selectedStatus });
      if (selectedStatus === "healthy") {
        onts = onts.filter(
          (item: any) => item.barsCount == 5 || item.barsCount == 4
        );
      } else if (selectedStatus === "tooHigh") {
        onts = onts.filter((item: any) => item.barsCount == 6);
      } else if (selectedStatus === "upset") {
        onts = onts.filter((item: any) => item.barsCount == 3);
      } else if (selectedStatus === "bad") {
        onts = onts.filter(
          (item: any) => item.barsCount < 3 && item.barsCount >= 1
        );
      } else if (selectedStatus === "INACTIVE") {
        onts = onts.filter((item: any) => item.isOntInActive == true);
      } else if (selectedStatus === "INVISIBLE") {
        onts = onts.filter((item: any) => item.intPkOntRegisterationID == null);
      } else if (selectedStatus === "REBOOTED") {
        onts = onts.filter((item: any) => item.isOntRebooted == true);
      } else if (selectedStatus === "DOWN") {
        onts = onts.filter(
          (item: any) =>
            item.ontStatus == selectedStatus && item.isOntInActive == false
        );
      } else if (downReasons.includes(selectedStatus)) {
        onts = onts
          .filter((item: any) => item.ontStatus == "DOWN")
          .filter(
            (item: any) => item.frontendDisplayStatusString == selectedStatus
          );
        console.log({ _ont_: onts });
      } else {
        onts = onts.filter((item: any) => item.ontStatus == selectedStatus);
      }
    }
    if (selectedIsp) {
      console.log("ISP selected");
      onts = onts.filter((item: any) => {
        const servicePortArray = item.servicePortArray;
        console.log({ servicePortArray });
        const isIspFound = servicePortArray.find(
          (_item: any) => _item.ispName == selectedIsp
        );
        console.log({ isIspFound });
        if (isIspFound) {
          return item;
        }
      });
    }
    if (selectedVlan) {
      console.log("VLAN selected", selectedVlan);
      onts = onts.filter((item: any) => {
        const servicePortArray = item.servicePortArray;
        console.log({ servicePortArray });
        if (servicePortArray.length > 0) {
          const isIspFound = servicePortArray[0].macAddressArray.find(
            (_item: any) => _item.vlan == selectedVlan
          );
          console.log({ isIspFound });
          if (isIspFound) {
            return item;
          }
        }
      });
    }
    setFilteredCount(onts.length);
    setFilteredOnts(onts);

    setONTsForPagination(onts);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = onts.slice(startIndex, endIndex);
    setONTs(currentItems);
  };
  useEffect(() => {
    showOntsPageWise(
      actualONTs,
      currentPage,
      selectedOLT,
      selectedSlot,
      selectedPonPort,
      selectedStatus,
      selectedIsp,
      selectedVlan,
      downReasons
    );
  }, [currentPage, itemsPerPage]);
  // useEffect(() => {
  //   showOntsPageWise(
  //     actualONTs,
  //     currentPage,
  //     selectedOLT,
  //     selectedSlot,
  //     selectedPonPort,
  //     selectedStatus,
  //     selectedIsp,
  //     selectedVlan,
  //     downReasons
  //   );
  // }, [itemsPerPage]);
  return (
    <>
      <ToastContainer />
      {show === true ? (
        <EditEndUsersModal
          show={show}
          intPkOntBindingID={activeBindingId}
          handleClose={handleClose}
          endUsers={activeEndUsers}
          activeBinding={activeBinding}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Row className="mt-4">
              <span>
                <Button
                  disabled={isReloadDisabled}
                  variant="primary"
                  className="mb-3"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (isReloadDisabled === true) {
                      return;
                    }
                    if (selectedOLT === "") {
                      return;
                    }
                    getReportsData({ intPkOltID: Number(selectedOLT) });
                    getIspsAndVlansData({
                      intPkOltID: Number(selectedOLT),
                    });
                    setIsReloadDisabled(true);
                    setTimeout(() => {
                      setIsReloadDisabled(false);
                    }, 180000);
                  }}
                >
                  Reload
                </Button>
              </span>

              <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}>
                <Form.Select
                  value={selectedOLT}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      getReportsData({ intPkOltID: Number(e.target.value) });
                      getIspsAndVlansData({
                        intPkOltID: Number(e.target.value),
                      });
                    }
                    setSelectedOLT(e.target.value);
                    setCurrentPage(1);
                    // handleOLTChange(e.target.value);
                  }}
                >
                  <option value="">Select OLT</option>
                  {allOlts.map((item: any) => {
                    return (
                      <option value={item.intPkOltID} key={item.intPkOltID}>
                        {item.oltName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}>
                <Form.Select
                  value={selectedSlot}
                  onChange={(e) => {
                    setSelectedSlot(e.target.value);
                    // handleSlotChange(e.target.value, selectedOLT);
                  }}
                >
                  <option value="">Select Slot</option>
                  {filteredSlots.map((item: any, index: number) => {
                    return (
                      <option value={item.oltSlot} key={item.intPkSlotID}>
                        Slot #{item.oltSlot}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}>
                <Form.Select
                  value={selectedPonPort}
                  onChange={(e) => {
                    setSelectedPonPort(e.target.value);
                  }}
                >
                  <option value="">Select Pon Port</option>
                  {filteredPonPorts.map((item: any, index: number) => {
                    return (
                      <option value={item.oltPonPort} key={item.oltPonPort}>
                        {`${item.oltFrame}/${item.oltSlot}/${item.oltPonPort}`}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}>
                <Form.Select
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <option value="">Select Ont Status</option>
                  {[
                    { value: "UP", label: "UP" },
                    { value: "DOWN", label: "Show Down (All)" },
                    { value: "UPSET", label: "UPSET" },
                    { value: "INACTIVE", label: "INACTIVE" },
                    { value: "REBOOTED", label: "REBOOTED" },
                  ].map((item: any) => {
                    return (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                  <option value={"healthy"} key={"healthy"}>
                    Healthy Signals
                  </option>{" "}
                  <option value={"tooHigh"} key={"tooHigh"}>
                    Too High
                  </option>{" "}
                  <option value={"upset"} key={"upset"}>
                    UPSET Signal
                  </option>{" "}
                  <option value={"bad"} key={"bad"}>
                    Bad Signal
                  </option>
                  {downReasons.map((item: any) => {
                    return (
                      <option value={item} key={item}>
                        Show Down({item})
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col
                className="mt-2"
                md={3}
                lg={3}
                xs={12}
                sm={12}
                xl={3}
                xxl={3}
              >
                <Form.Select
                  value={selectedIsp}
                  onChange={(e) => {
                    setSelectedIsp(e.target.value);
                    //let's get the vlans too.
                    // handleSlotChange(e.target.value, selectedOLT);
                    const vlans = actualIspsData.filter(
                      (item: any) => item.ispName == e.target.value
                    );
                    console.log({ vlans });
                    setAllVlans(vlans);
                    setSelectedVlan("");
                  }}
                >
                  <option value="">Select ISP</option>
                  {allIsps.map((item: any, index: number) => {
                    return (
                      <option value={item.ispName} key={item.ispName}>
                        {item.ispName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col
                className="mt-2"
                md={3}
                lg={3}
                xs={12}
                sm={12}
                xl={3}
                xxl={3}
              >
                <Form.Select
                  value={selectedVlan}
                  onChange={(e) => {
                    setSelectedVlan(e.target.value);
                    // handleSlotChange(e.target.value, selectedOLT);
                  }}
                >
                  <option value="">Select Vlan</option>
                  {allVlans.map((item: any, index: number) => {
                    return (
                      <option value={item.vlan} key={item.vlan}>
                        {item.vlan}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-5">
              <h3>Total ONTs:{filteredCount} </h3>
              <Col md={6} lg={6} sm={12} xs={12} xl={6} xxl={6}>
                <div style={{ lineHeight: "80px" }}>
                  Items Per Page{" "}
                  <select
                    className="custom-select-for-pagination"
                    value={itemsPerPage}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setItemsPerPage(Number(e.target.value));
                    }}
                  >
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={300}>300</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                  </select>
                </div>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12} xl={6} xxl={6}>
                <span>
                  <button
                    className="button-primary"
                    style={{
                      maxWidth: "200px",
                      minWidth: "300px",
                      minHeight: "80px",
                      float: "right",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleExportToExcel(filteredOnts);
                    }}
                  >
                    Export As Excel
                  </button>
                </span>
              </Col>

              <Col
                md={12}
                lg={12}
                xs={12}
                sm={12}
                xl={12}
                xxl={12}
                className="mt-3"
              >
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">
                        {ontType === 1 ? "Mac Address" : "Pon#"}
                      </th>
                      <th className="table-header-standard">FSP</th>
                      <th className="table-header-standard">ONT ID</th>
                      <th className="table-header-standard">Status</th>
                      <th
                        style={{ minWidth: "100px", cursor: "pointer" }}
                        onClick={(e) => {
                          let sort = sortType.signal;
                          let selected = sort === "ASC" ? "DESC" : "ASC";
                          setSortType({
                            signal: selected,
                          });
                          handleSort("SIGNAL", ONTs, selected);
                        }}
                        className={
                          sortType.signal === ""
                            ? "show-caret-on-hover table-header-standard"
                            : "table-header-standard"
                        }
                      >
                        Signal {getSortIcon(sortType.signal)}
                        <span className="show-caret-down"></span>{" "}
                      </th>
                      <th className="table-header-standard">Distance</th>
                      <th className="table-header-standard">Last Cause</th>
                      <th className="table-header-standard">
                        Last Activity time
                      </th>
                      <th className="table-header-standard"> PonPort Name</th>

                      <th className="table-header-standard"> ISP</th>
                      <th className="table-header-standard"> Vlan</th>
                      <th className="table-header-standard"> Mac Address</th>
                      <th className="table-header-standard">PPPoE USER ID</th>
                      {selectedStatus === "INVISIBLE" ? (
                        <th className="table-header-standard">Delete</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {reportLoading === true ? (
                      <ThreeDots
                        height="100"
                        width="100"
                        radius="9"
                        color="#5ac8fa"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                      />
                    ) : (
                      <>
                        {ONTs.map((item: any) => {
                          return (
                            <tr key={item.intPkOntBindingID}>
                              <td
                                className="cursor-pointer-class standard-btn-coloured"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (item.intPkOntRegisterationID !== null) {
                                    const olt = allOlts.find(
                                      (item: any) =>
                                        item.intPkOltID == selectedOLT
                                    );
                                    if (!olt) {
                                      return;
                                    }
                                    const { oltName } = olt;
                                    navigate(
                                      `/single-registration-by-id/${oltName}/${item.intPkOntBindingID}/${selectedOLT}`
                                    );
                                  }
                                }}
                              >
                                {showPonOrMacAddress(
                                  item.ponNumber,
                                  item.asciiHexaPonNumber,
                                  item.formattedOntEponMacAddressID,
                                  Number(item.isOntEponOrGpon)
                                )}
                              </td>
                              <td>{`${item.oltFrame}/${item.oltSlot}/${item.oltPonPort}`}</td>
                              <td>{item.ontID}</td>
                              <td>
                                {" "}
                                {getSignalImage(
                                  item.ontStatus,
                                  item.isOntInActive,
                                  item.isActivationInProcess,
                                  item.intPkOntRegisterationID,
                                  item.isOntRebooted
                                )}
                              </td>
                              <td>
                                {" "}
                                {item.ontRxSignalFromOLT !== null
                                  ? item.ontRxSignalFromOLT + ":"
                                  : "-"}
                                {getSignalsBar(
                                  item.barsCount,
                                  item.isOntRebooted,
                                  item.ontStatus
                                )}
                              </td>
                              <td>
                                {item.ontDistanceFromOltInMeters !== null
                                  ? convertMetersToKiloMeters(
                                      item.ontDistanceFromOltInMeters
                                    )
                                  : "-"}
                              </td>
                              <td>
                                {item.frontendDisplayStatusString !== null
                                  ? item.frontendDisplayStatusString
                                  : "-"}
                              </td>
                              <td>
                                {item.ontStatus === "UP" ||
                                item.ontStatus === "DOWN" ||
                                item.ontStatus === "UPSET" ? (
                                  <>
                                    {getStatusString(item.ontStatus)}:
                                    {getTimeAgo(
                                      item.ontStatusLastStatusUpdateDate
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {item.ponPortName !== null
                                  ? item.ponPortName
                                  : "-"}
                              </td>
                              <td>
                                {item.servicePortArray.length > 0 ? (
                                  <>
                                    <img
                                      style={{ width: "50px" }}
                                      src={item.servicePortArray[0].ispLogoUrl}
                                    />
                                  </>
                                ) : null}
                              </td>
                              <td>{item.vlanName}</td>
                              <td style={{ minWidth: "200px" }}>
                                {showMacAddresses(
                                  Number(item.isOntEponOrGpon),
                                  item.servicePortArray || [],
                                  item.formattedOntEponMacAddressID
                                )}
                              </td>
                              {/* <td>{item.formattedOntEponMacAddressID}</td> */}
                              <td
                                onClick={(e) => {
                                  e.preventDefault();
                                  setActiveEndUsers(item.endUsers);
                                  setShow(true);
                                  setActiveBindingID(item.intPkOntBindingID);
                                  setActiveBinding({
                                    oltFrame: item.oltFrame,
                                    oltSlot: item.oltSlot,
                                    oltPonPort: item.oltPonPort,
                                    ponNumber: item.ponNumber,
                                    intPkOltID: item.intPkOltID,
                                  });
                                }}
                                className=" cursor-pointer-class"
                              >
                                {showUserName(
                                  item.endUsers,
                                  item.ontRegistrationDescInOLT
                                )}
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  style={{
                                    color: "#00acee",
                                    marginLeft: "5px",
                                  }}
                                />
                              </td>
                              {selectedStatus === "INVISIBLE" ? (
                                <td>
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDeleteRegistration(
                                        item.ponNumber,
                                        Number(item.intPkOltID),
                                        Number(item.intPkOntBindingID)
                                      );
                                    }}
                                    variant="danger"
                                  >
                                    Delete
                                  </Button>
                                </td>
                              ) : null}
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col
                md={10}
                lg={10}
                xs={10}
                sm={10}
                xl={10}
                xxl={10}
                className="mt-3"
              >
                {ONTsForPagination.length > 0
                  ? showPagination(itemsPerPage, ONTsForPagination)
                  : null}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ONTsReportComponent;
