import BrowsePonPortsComponents from "../../components/admin/BrowsePonPorts";

const BrowsePonPortsPage = () => {
  return (
    <>
      <BrowsePonPortsComponents />
    </>
  );
};
export default BrowsePonPortsPage;
