import SearchONTComponent from "../components/admin/SearchOnt";
const SearchONTPage = () => {
  return (
    <>
      <SearchONTComponent />
      {/* <BrowseOLTsComponent /> */}
    </>
  );
};
export default SearchONTPage;
