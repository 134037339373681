import FoundOntsComponent from "../../components/admin/FoundOnts";

const FoundOntsPage = () => {
  return (
    <>
      <FoundOntsComponent />
      {/* <BrowseOLTsComponent /> */}
    </>
  );
};
export default FoundOntsPage;
