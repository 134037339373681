import React from "react";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";

import UpdatePasswordComponent from "./ChangePassword";
import UpdateProfileComponent from "./UpdateProfileComponent";
import { Link } from "react-router-dom";

const ProfileSettingsComponent: React.FC<GP> = ({}) => {
  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row style={{ textAlign: "right" }}>
          <span>
            <Link className="btn btn-primary" to="/update-email">
              Update Email
            </Link>
          </span>
        </Row>
        <Row>
          <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
            <UpdatePasswordComponent />
          </Col>
          <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
            <UpdateProfileComponent />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ProfileSettingsComponent;
