import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { confirmAlert } from "react-confirm-alert"; // Import

const AllVPNsComponent: React.FC<GP> = ({}) => {
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");

  const [
    getApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("admin/getMyVpnUsers");

  const [
    postNewVpnRequest,
    vpnRequestResponse,
    vpnRequestError,
    vpnRequestLoading,
    vpnRequestMessage,
    vpnRequestErrorCount,
  ] = useAxiosPost<ResponseProp>("admin/requestNewVpnUser");
  const [allVpnUsers, setAllVpnUsers] = useState<any>([]);

  useEffect(() => {
    getApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const { responseData } = apiResponse;
      setAllVpnUsers(responseData || []);
    }
  }, [apiResponse]);
  const statusString = (requestStatus: number) => {
    if (requestStatus === 0) {
      return "Pending";
    }
    if (requestStatus === 1) {
      return "Active";
    }
    return "Pending";
  };
  const handleNewVpnRequest = () => {
    confirmAlert({
      title: "New Request",
      message: "You want to create a new Vpn Request ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Delete");
            postNewVpnRequest({});
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  useEffect(() => {
    if (vpnRequestResponse !== null) {
      const { message } = vpnRequestResponse;
      if (message) {
        notifySuccess(message);
      }
      getApiData({});
    }
  }, [vpnRequestResponse]);
  useEffect(() => {
    if (vpnRequestError !== null && vpnRequestErrorCount !== 0) {
      notifyError(vpnRequestError);
    }
  }, [vpnRequestError, vpnRequestErrorCount]);
  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Vpn Users
              <span style={{ float: "right" }}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleNewVpnRequest();
                  }}
                >
                  Request New VPN
                </Button>
              </span>
            </Card.Title>

            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">UserName</th>
                      <th className="table-header-standard">Password</th>
                      <th className="table-header-standard">
                        Client Router IP
                      </th>
                      <th className="table-header-standard">VPN Server IP</th>
                      <th className="table-header-standard">Status</th>
                      <th className="table-header-standard">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allVpnUsers.map((user: any) => {
                      return (
                        <tr key={user.intPkVpnUserID}>
                          <td>{user.vpnUsername}</td>
                          <td>{user.vpnPassword}</td>
                          <td>{user.vpnClientIp}</td>
                          <td>{user.vpnServerIp}</td>
                          <td>{statusString(Number(user.requestStatus))}</td>

                          <td>{formatDate(user.dateCreated)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllVPNsComponent;
