import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { useNavigate, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";

const CreateSlotComponent: React.FC<GP> = ({}) => {
  const navigate = useNavigate();
  const [models, setModels] = useState<any>([]);
  const [
    postCreateSlotData,
    slotResponseData,
    slotError,
    slotLoading,
    slotMessage,
    slotErrorCount,
  ] = useAxiosPost<CreateSlotProp>("slots/createSlotWithPonPorts");
  //intPkOltID
  const CreateOltValidationSchema = yup.object().shape({
    // numberOfPonPorts: yup.number().integer().required(),
    intPkBusinessCardModelID: yup.string().required(),
    oltSlot: yup.string().required(),
  });
  const { intPkOltID, oltName } = useParams();
  const [
    postSlotModelsData,
    modelsResponse,
    modelsError,
    modelsLoading,
    modelsMessage,
    modelsErrorCount,
  ] = useAxiosPost<ResponseProp>("superCOO/getAllSlotModels");
  useEffect(() => {
    if (modelsResponse !== null) {
      console.log({ modelsResponse });
      setModels(modelsResponse.responseData || []);
    }
  }, [modelsResponse]);
  useEffect(() => {
    postSlotModelsData({});
    setSlotData({
      ...slotData,
      intPkOltID: Number(intPkOltID),
    });
  }, [intPkOltID]);

  useEffect(() => {
    if (slotError !== null && slotErrorCount !== 0) {
      notifyError(slotError);
    }
  }, [slotError, slotErrorCount]);
  useEffect(() => {
    if (slotResponseData !== null) {
      const { message } = slotResponseData;
      if (message) {
        notifySuccess(message);
        navigate(`/all-slots-by-olt/${intPkOltID}/${oltName}`);
      }
    }
  }, [slotResponseData]);

  const [slotData, setSlotData] = useState<CreateSlotProp>({
    // numberOfPonPorts: 8,
    intPkBusinessCardModelID: "",
    oltSlot: "",
    intPkOltID: -1,
  });
  const handleCreateOLT = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateSlotProp = slotData;
    try {
      const isValid = await CreateOltValidationSchema.validate(dataToSend);
      if (slotData.intPkOltID === -1) {
        notifyError(`Invalid Olt Selected`);
        return;
      }
      postCreateSlotData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSlotData({
      ...slotData,
      [name]: value,
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={slotData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="6" md="6" xl="6" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Create New Slot
            </Card.Title>
            <Form className="mt-4">
              {renderInput(`oltSlot`, "Enter OLT Slot", `Enter OLT Slot`)}
              <Form.Group className="mb-3">
                <Form.Label> Choose Slot Model</Form.Label>
                <Form.Select
                  value={slotData.intPkBusinessCardModelID}
                  onChange={(e) => {
                    setSlotData({
                      ...slotData,
                      intPkBusinessCardModelID: e.target.value,
                    });
                  }}
                >
                  <option value={""}>Choose A Model</option>
                  {models.map((item: any) => {
                    return (
                      <option
                        value={item.intPkBusinessCardModelID}
                        key={item.intPkBusinessCardModelID}
                      >
                        {item.businessCardModelName}{" "}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <div style={{ textAlign: "center" }}>
                <button
                  disabled={slotLoading}
                  onClick={handleCreateOLT}
                  className="button-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default CreateSlotComponent;
