import AllPricePolicyModelsComponentForAccountant from "../../components/accountant/PricePolicyForAccountant";

const PricePolicyPageForAccountant = () => {
  return (
    <>
      <AllPricePolicyModelsComponentForAccountant />
    </>
  );
};
export default PricePolicyPageForAccountant;
