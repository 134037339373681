import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { formatDate } from "../../helpers/date";
import CreateNewDealerComponentModal from "../Model/CreateNewDealerModal";
type Props = {
  isLoggedIn?: boolean;
};

const AllDealersComponent: React.FC<Props> = ({}) => {
  const [
    getSuperCOOData,
    getSuperCOOResponse,
    COOError,
    COOLoading,
    COOMessage,
    COOErrorCount,
  ] = useAxiosPost<ResponseProp>("dealers/getMyDealers");
  const [
    getProfileData,
    profileResponseData,
    profileError,
    profileLoading,
    profileMessageMessage,
    profileErrorCount,
  ] = useAxiosPost<ResponseProp>("users/getMyProfile");
  const [superCOOs, setSuperCOOs] = useState<any>([]);
  const [intPkCompanyID, setIntPkCompanyID] = useState<number>(-1);
  useEffect(() => {
    getSuperCOOData({});
    getProfileData({});
  }, []);
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isCall: boolean) => {
    setShow(false);
    if (isCall === true) {
      getSuperCOOData({});
    }
  };
  useEffect(() => {
    if (COOError !== null && COOErrorCount !== 0) {
      notifyError(COOError);
    }
  }, [COOError, COOErrorCount]);
  useEffect(() => {
    if (getSuperCOOResponse !== null) {
      console.log({ getSuperCOOResponse });
      setSuperCOOs(getSuperCOOResponse.responseData || []);
    }
  }, [getSuperCOOResponse]);
  useEffect(() => {
    if (profileResponseData !== null) {
      const profileResponse: any = profileResponseData.responseData;
      console.log({ profileResponse });
      const intPkCompanyID = Number(profileResponse.intPkCompanyID) || -1;
      setIntPkCompanyID(intPkCompanyID);
    }
  }, [profileResponseData]);
  return (
    <>
      <ToastContainer />
      {show == true && intPkCompanyID != -1 ? (
        <CreateNewDealerComponentModal
          intPkCompanyID={intPkCompanyID}
          show={show}
          handleClose={handleClose}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Dealers
              <span style={{ float: "right" }}>
                <Button
                  variant="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                >
                  Create New Dealer
                </Button>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">User Full Name</th>
                      <th className="table-header-standard">UserName</th>
                      <th className="table-header-standard">Password</th>
                      <th className="table-header-standard">Contact Number</th>
                      <th className="table-header-standard">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {superCOOs.map((item: any) => {
                      return (
                        <tr key={item.intPkUserID}>
                          <td>{item.userFullname}</td>
                          <td>{item.username}</td>
                          <td>{item.password}</td>
                          <td>{item.contactNumber}</td>
                          <td>{formatDate(item.dateCreated)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllDealersComponent;
