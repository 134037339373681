import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import CapOLTLogo from "./../../assets/pngs/logo/logoPng.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import { AppRoutes } from "../../data";
import { TitleContext } from "../../App";
import useAxiosPost from "../../hooks/useAxiosPost";

const SideNavBar = ({}) => {
  const [
    getProfileData,
    profileResponseData,
    profileError,
    profileLoading,
    profileMessageMessage,
    profileErrorCount,
  ] = useAxiosPost<ResponseProp>("users/getMyProfile");
  const [profileData, setProfileData] = useState({
    companyName: "",
    userFullName: "",
    userType: "",
  });
  const [isExpanded, setExpendState] = useState(false);
  const [menuItems, setMenuItems] = useState<any>([]);
  useEffect(() => {
    if (profileResponseData !== null) {
      const profileResponse: any = profileResponseData.responseData;
      const email = profileResponse.email;
      localStorage.setItem("email", email);
      if (profileResponse) {
        const { companyName, userType, userFullname } = profileResponse;
        if (userType === "SUPER_ADMIN" || userType === "SUPER_COO") {
          setProfileData({
            companyName: "",
            userFullName: userFullname,
            userType: userType,
          });
        } else {
          setProfileData({
            companyName: companyName,
            userFullName: userFullname,
            userType: userType,
          });
        }
      }
    }
  }, [profileResponseData]);
  useEffect(() => {
    getProfileData({});
    const routes = AppRoutes();
    setMenuItems(routes);
  }, []);
  /**
   * 
   *  className={
        isExpanded
          ? "side-nav-container"
          : "side-nav-container side-nav-container-NX"
      }
   * 
   */
  const titleContextValues = useContext(TitleContext);
  function navItemHandler(text: any) {
    titleContextValues.titleTextChangeHandler(text);
  }

  return (
    <div
      className={
        isExpanded
          ? "side-nav-container"
          : "side-nav-container side-nav-container-NX"
      }
    >
      <div className="nav-upper">
        <div className="nav-heading">
          {isExpanded && (
            <div className="nav-brand">
              <img
                src={CapOLTLogo}
                style={{
                  width: "100%",
                  marginLeft: "20px",
                  padding: "0 10px",
                  paddingBottom: "8px",
                  marginTop: "20px",
                  height: "70%",
                }}
              />
              {/* <button
                style={{
                  color: "black",
                  width: "50px",
                  height: "30px",
                  border: "none",
                }}
                // onClick={sideNavShowHideHandler}
              >
                {isExpanded ? "collapse" : "show"}
              </button> */}
              {/* <h2 style={{ marginTop: "25px" }}>Capolt</h2> */}
            </div>
          )}
          <button
            className={
              isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
            }
            onClick={() => setExpendState(!isExpanded)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        {profileLoading === true ? (
          <p className={isExpanded ? "header-item" : "header-item"}>Loading</p>
        ) : (
          <>
            <p className={isExpanded ? "header-item" : "header-item"}>
              {isExpanded === true ? (
                <>
                  <p>{profileData.companyName}</p>
                  <p>
                    {profileData.userFullName}({profileData.userType})
                  </p>
                </>
              ) : (
                <>
                  {profileData.userType === "SUPER_ADMIN" ||
                  profileData.userType === "SUPER_COO" ||
                  profileData.userType === "NOC" ||
                  profileData.userType === "ACCOUNTANT" ? (
                    <>
                      <p style={{ fontSize: "8px" }}>
                        {profileData.userFullName}
                      </p>
                    </>
                  ) : (
                    <>
                      <p style={{ fontSize: "8px" }}>
                        {profileData.companyName}
                      </p>
                    </>
                  )}
                </>
              )}
            </p>
          </>
        )}
        <div className="nav-menu">
          {menuItems.map((item: any) => {
            const { text, icon, path, show } = item;
            const key = Math.random();
            return show === true ? (
              <Link
                className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
                to={path}
                key={key}
                //  href="#"
                onClick={() => {
                  if (path == "/onts-reports") {
                    console.log("path", path);
                    localStorage.removeItem("ontsReportsFilter");
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={icon}
                  style={{
                    marginRight: "10px",
                    marginTop: "5px",
                  }}
                />
                {isExpanded && <p>{text}</p>}
              </Link>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
