import { useParams } from "react-router-dom";
import AllVlanProfilesByOLTComponentForNOCAndSuperCooForHuawei from "../components/superCOO/VlansByOLT";
import AllVlanProfilesByOLTComponentForNOCAndSuperCooForVSOL from "../components/superCOO/VlansByOltForVsol";

const VlansByOLTPage = () => {
  const { brand } = useParams();
  console.log({ brand });
  const getPageRoleWise = () => {
    if (brand === "Huawei") {
      return <AllVlanProfilesByOLTComponentForNOCAndSuperCooForHuawei />;
    }
    if (brand === "VSOL") {
      return <AllVlanProfilesByOLTComponentForNOCAndSuperCooForVSOL />;
    }
    return <></>;
  };
  return <>{getPageRoleWise()}</>;
};
export default VlansByOLTPage;
