import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { removeSpacesFromString } from "../../helpers/strings";
import UpIcon from "./../../assets/svgs/icons/success.svg";
import AutoOLTCreationSecondStepCard from "../Cards/AutoOLTCreationSecondStepCard";
import AutoOLTCreationThirdStepCard from "../Cards/AutoOLTCreationThirdStepCard";
const ResumeCreateAutoOLTComponent: React.FC<ResumeCreateOLTComponentProp> = ({
  handleApiResponse,
  intPkCompanyID,
  companyName,
  intPkOltID,
}) => {
  const [
    postCreateOLTData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<ResumeCreateOltProp>("olt/updateAutoOLT");
  const [
    postSetForResumeProcess,
    resumeProcessResponse,
    resumeError,
    resumeLoading,
    resumeMessage,
    resumeErrorCount,
  ] = useAxiosPost<SingleOltProp>("olt/setForResumeProcess");

  const [
    getSingleOltDetails,
    singleOltDetailsResponse,
    singleOltError,
    singleOlLoading,
    singleOltMessage,
    singleOltErrorCount,
  ] = useAxiosPost<SingleOltProp>("olt/getSingleOLTDetails");
  const [newOltId, setNewOltId] = useState<number>(-1);
  const [activeStep, setActiveStep] = useState<string>("one");
  const [sysEventBotName, setSysEventBotName] = useState<string>("");
  const [netFlowBotName, setNetFlowBotName] = useState<string>("");
  const CreateOltValidationSchema = yup.object().shape({
    oltName: yup.string().required(),
    host: yup.string().required(),
    port: yup.number().integer().min(1).required(),
    netFlowPort: yup.number().integer().min(1).required(),
    oltWebAccessPort: yup.number().integer().min(1).required(),
    intPkCompanyID: yup.string().required(),
    telnetPassword: yup.string().required(),
    telnetUserName: yup.string().required(),
  });
  useEffect(() => {
    if (singleOltDetailsResponse != null) {
      const responseData: any = singleOltDetailsResponse.responseData;
      console.log({ responseData });
      const {
        host,
        netFlowPort,
        oltName,
        port,
        userInputTelentUserPassword,
        userInputTelnetUserName,
        oltWebAccessPort,
      } = responseData;
      setOltData({
        ...oltData,
        host,
        port,
        netFlowPort,
        oltName,
        telnetPassword: userInputTelentUserPassword,
        telnetUserName: userInputTelnetUserName,
        oltWebAccessPort,
      });
    }
  }, [singleOltDetailsResponse]);
  useEffect(() => {
    getSingleOltDetails({ oltId: intPkOltID });
  }, [intPkOltID]);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
      handleApiResponse(false);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { message } = oltResponseData;
      const responseData: any = oltResponseData.responseData;
      if (message) {
        notifySuccess(`Olt Updated Successfully`);
      }
      setActiveStep("two");
      setNewOltId(intPkOltID);
      // handleApiResponse(true);
    }
  }, [oltResponseData]);
  useEffect(() => {
    setOltData({
      ...oltData,
      intPkCompanyID: intPkCompanyID,
    });
  }, [intPkCompanyID]);
  const [oltData, setOltData] = useState<ResumeCreateOltProp>({
    oltName: "",
    host: "",
    intPkCompanyID: "",
    intPkBrandID: "",
    port: 46023,
    netFlowPort: 45161,
    netflowEventSecretKey: "private",
    netflowReadString: "private",
    netflowWriteString: "private",
    telnetPassword: "",
    telnetUserName: "",
    intPkOltID: intPkOltID,
    oltWebAccessPort: "",
  });

  const handleCreateOLT = async (event: any) => {
    event.preventDefault();
    const dataToSend: ResumeCreateOltProp = {
      ...oltData,
      oltName: oltData.oltName.trim(),
    };
    try {
      const isValid = await CreateOltValidationSchema.validate(dataToSend);
      postCreateOLTData(dataToSend);
      postSetForResumeProcess({ oltId: intPkOltID });
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOltData({
      ...oltData,
      [name]: removeSpacesFromString(value),
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={oltData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };
  const getActiveComponent = (option: string) => {
    console.log({ option });
    switch (option) {
      case "one":
        return getFirstStepComponent();
        break;
      case "two":
        return getComponentSecondStep();
        break;
      case "three":
        return getComponentThirdStep();
        break;
      default:
        return <></>;
    }
  };
  const getComponentSecondStep = () => {
    return (
      <AutoOLTCreationSecondStepCard
        oltName={oltData.oltName}
        host={oltData.host}
        intPkOltID={newOltId}
        handleMoveToNextStep={handleMoveToThirdStep}
        port={oltData.port}
        netFlowPort={oltData.netFlowPort}
        oltWebAccessPort={oltData.oltWebAccessPort}
      />
    );
  };
  const getComponentThirdStep = () => {
    return (
      <AutoOLTCreationThirdStepCard
        oltName={oltData.oltName}
        host={oltData.host}
        intPkOltID={newOltId}
        handleMoveToNextStep={handleMoveToFinalStep}
        port={oltData.port}
        netFlowPort={oltData.netFlowPort}
        sysEventBotName={sysEventBotName}
        netFlowBotName={netFlowBotName}
        oltWebAccessPort={oltData.oltWebAccessPort}
      />
    );
  };
  const handleMoveToThirdStep = (
    netFlowBotName: string,
    sysEventBotName: string,
    step: string
  ) => {
    setNetFlowBotName(netFlowBotName);
    setSysEventBotName(sysEventBotName);
    setActiveStep("three");
  };
  const handleMoveToFinalStep = () => {
    console.log("its all");
    handleApiResponse(true);
  };
  const getFirstStepComponent = () => {
    return (
      <Card className="p-5">
        <Card.Title className="text-center olt-heading fw-700 pt-3 ">
          {" "}
          Update Olt For <b style={{ color: "#ff4646" }}>{companyName}</b>
        </Card.Title>
        <Form className="mt-4">
          <Form.Group className="mb-3">
            <Form.Label> {`Enter OLT Name`}</Form.Label>
            <Form.Control
              placeholder={`Enter OLT Name`}
              type="text"
              value={oltData.oltName}
              onChange={(e) => {
                setOltData({
                  ...oltData,
                  oltName: e.target.value,
                });
              }}
            />
          </Form.Group>

          {renderInput(`host`, "Enter OLT IP", `Enter OLT IP`)}
          {renderInput(`port`, "Enter TEL/SSH PORT", `Enter TEL/SSH PORT`)}

          {renderInput(
            `netFlowPort`,
            "Enter Netflow PORT",
            `Enter Netflow PORT`
          )}
          {renderInput(
            `oltWebAccessPort`,
            "Enter Olt Web Access PORT",
            `Enter Olt Web Access PORT`
          )}

          {renderInput(
            `telnetUserName`,
            "Enter Telnet username",
            `Enter Telnet username`
          )}
          {renderInput(
            `telnetPassword`,
            "Enter Telnet password",
            `Enter Telnet password`
          )}
          <button
            onClick={handleCreateOLT}
            disabled={oltLoading}
            style={{ minWidth: "100%" }}
            className="button-primary"
            type="submit"
          >
            Update
          </button>
        </Form>
      </Card>
    );
  };

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          {getActiveComponent(activeStep)}
        </Col>
      </Row>
    </>
  );
};
export default ResumeCreateAutoOLTComponent;
