import { getUserType } from "./userDetails";
type UserType = "ADMIN" | "DEALER" | "DEMO_ADMIN" | "DEMO_DEALER";
const userTypeFromLocalStorage: string = getUserType();
const userType: UserType = userTypeFromLocalStorage as UserType;
export const apiRoutes = {
  DASHBOARD_PAGE: {
    ADMIN: "admin/getDashboardStats",
    DEMO_ADMIN: "admin/getDashboardStats",
    DEALER: "dealers/getDashboardStats",
    DEMO_DEALER: "dealers/getDashboardStats",
  },

  BROWSE_OLTS_PAGE: {
    ADMIN: "admin/getAdminOLTs",
    DEMO_ADMIN: "admin/getAdminOLTs",
    DEALER: "dealers/getOLTsDealerWise",
    DEMO_DEALER: "dealers/getOLTsDealerWise",
  },
  FOUND_ONTS_PAGE: {
    ADMIN: "onts/getFoundOntsForAdmin",
    DEMO_ADMIN: "onts/getFoundOntsForAdmin",
    DEALER: "onts/getFoundOntsForDealer",
    DEMO_DEALER: "onts/getFoundOntsForDealer",
  },
  SEARCH_ONTS_PAGE: {
    ADMIN: "onts/searchOnt",
    DEMO_ADMIN: "onts/searchOnt",
    DEALER: "onts/searchOnt",
    DEMO_DEALER: "onts/searchOnt",
  },
  BROWSE_SLOTS_PAGE: {
    ADMIN: "admin/getAdminSlots",
    DEMO_ADMIN: "admin/getAdminSlots",
    DEALER: "dealers/getSlotsDealerWise",
    DEMO_DEALER: "dealers/getSlotsDealerWise",
  },
  BROWSE_PON_PORTS_PAGE: {
    ADMIN: "admin/getAdminPonPorts",
    DEMO_ADMIN: "admin/getAdminPonPorts",
    DEALER: "dealers/getPonPortsDealerWise",
    DEMO_DEALER: "dealers/getPonPortsDealerWise",
  },
  BROWSE_ONTS_PAGE_ROUTE: {
    ADMIN: "admin/getRegistrationsByPonPortId",
    DEMO_ADMIN: "admin/getRegistrationsByPonPortId",
    DEALER: "binding/getOntsListByPonPortIdForDealer",
    DEMO_DEALER: "binding/getOntsListByPonPortIdForDealer",
  },
  BROWSE_PON_PORTS_BY_SLOTS_ONTS_PAGE: {
    ADMIN: "admin/getAdminPonPortsBySlotForOntsPage",
    DEMO_ADMIN: "admin/getAdminPonPortsBySlotForOntsPage",
    DEALER: "dealers/getPonPortsDealerWise",
    DEMO_DEALER: "dealers/getPonPortsDealerWise",
  },
  BROWSE_SINGLE_ONT_ROUTE: {
    ADMIN: "admin/getRegistrationById",
    DEMO_ADMIN: "admin/getRegistrationById",
    DEALER: "binding/getBindingByBindingId",
    DEMO_DEALER: "binding/getBindingByBindingId",
  },
};

export const getDashboardRoute = (): string => {
  return apiRoutes.DASHBOARD_PAGE[userType] || apiRoutes.DASHBOARD_PAGE.DEALER;
};

export const getBrowseOltsRoute = (): string => {
  return (
    apiRoutes.BROWSE_OLTS_PAGE[userType] || apiRoutes.BROWSE_OLTS_PAGE.DEALER
  );
};

export const getFoundOntsRoute = (): string => {
  return (
    apiRoutes.FOUND_ONTS_PAGE[userType] || apiRoutes.FOUND_ONTS_PAGE.DEALER
  );
};

export const getSearchOntsRoute = (): string => {
  return (
    apiRoutes.SEARCH_ONTS_PAGE[userType] || apiRoutes.SEARCH_ONTS_PAGE.DEALER
  );
};

export const getSlotsOltWiseRoute = (): string => {
  return (
    apiRoutes.BROWSE_SLOTS_PAGE[userType] || apiRoutes.BROWSE_SLOTS_PAGE.DEALER
  );
};

export const getPonPortRoute = (): string => {
  return (
    apiRoutes.BROWSE_PON_PORTS_PAGE[userType] ||
    apiRoutes.BROWSE_PON_PORTS_PAGE.DEALER
  );
};

export const getBrowseOntsByPonPortRoute = (): string => {
  return (
    apiRoutes.BROWSE_ONTS_PAGE_ROUTE[userType] ||
    apiRoutes.BROWSE_ONTS_PAGE_ROUTE.DEALER
  );
};

export const getPonPortsBySlotInBrowseOntsRoute = (): string => {
  return (
    apiRoutes.BROWSE_PON_PORTS_BY_SLOTS_ONTS_PAGE[userType] ||
    apiRoutes.BROWSE_PON_PORTS_BY_SLOTS_ONTS_PAGE.DEALER
  );
};

export const getSingleOntRoute = (): string => {
  return (
    apiRoutes.BROWSE_SINGLE_ONT_ROUTE[userType] ||
    apiRoutes.BROWSE_SINGLE_ONT_ROUTE.DEALER
  );
};
