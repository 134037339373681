import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { removeSpacesFromString } from "../../helpers/strings";
type Props = {
  isLoggedIn?: boolean;
};

const CreateOLTComponent: React.FC<CreateOLTComponentProp> = ({
  handleApiResponse,
  intPkCompanyID,
  companyName,
}) => {
  const [
    postCreateOLTData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<CreateOLTProp>("olt/createOLT");
  const [
    postBrandsData,
    brandsResponse,
    brandsError,
    brandsLoading,
    brandsMessage,
    brandsErrorCount,
  ] = useAxiosPost<ResponseProp>("superCOO/getAllBrands");

  const [
    postOltModelsData,
    modelsResponse,
    modelsError,
    modelsLoading,
    modelsMessage,
    modelsErrorCount,
  ] = useAxiosPost<ResponseProp>("superCOO/getAllOltModels");
  const [brands, setBrands] = useState<any>([]);
  const [oltModels, setOltModels] = useState<any>([]);
  const [actualOntModels, setActualModels] = useState<any>([]);
  const CreateOltValidationSchema = yup.object().shape({
    oltName: yup.string().required(),
    // model: yup.string().required(),
    host: yup.string().required(),
    port: yup.number().integer().min(1).required(),
    netFlowPort: yup.number().integer().min(1).required(),
    intPkCompanyID: yup.string().required(),
    intPkOLTModelID: yup.string().required(),
    intPkBrandID: yup.string().required(),
    // netflowEventSecretKey: yup.string().required(),
    // netflowReadString: yup.string().required(),
    // netflowWriteString: yup.string().required(),
    telnetPassword: yup.string().required(),
    telnetUserName: yup.string().required(),
  });

  useEffect(() => {
    if (brandsResponse !== null) {
      console.log({ brandsResponse });
      setBrands(brandsResponse.responseData || []);
    }
  }, [brandsResponse]);
  useEffect(() => {
    if (modelsResponse !== null) {
      console.log({ modelsResponse });
      setActualModels(modelsResponse.responseData || []);
    }
  }, [modelsResponse]);

  useEffect(() => {
    postOltModelsData({});
    postBrandsData({});
  }, []);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
      handleApiResponse(false);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { message } = oltResponseData;
      if (message) {
        notifySuccess(message);
      }
      handleApiResponse(true);
    }
  }, [oltResponseData]);
  useEffect(() => {
    setOltData({
      ...oltData,
      intPkCompanyID: intPkCompanyID,
    });
  }, [intPkCompanyID]);
  const [oltData, setOltData] = useState<CreateOLTProp>({
    oltName: "",
    host: "",
    intPkCompanyID: "",
    intPkOLTModelID: "",
    intPkBrandID: "",
    port: 46023,
    netFlowPort: 45161,
    netflowEventSecretKey: "private",
    netflowReadString: "private",
    netflowWriteString: "private",
    telnetPassword: "",
    telnetUserName: "",
    intPkOltSanctionID: "",
    oltWebAccessPort: "",
  });
  const handleCreateOLT = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateOLTProp = {
      ...oltData,
      oltName: oltData.oltName.trim(),
    };
    try {
      const isValid = await CreateOltValidationSchema.validate(dataToSend);
      postCreateOLTData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOltData({
      ...oltData,
      [name]: removeSpacesFromString(value),
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={oltData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };
  // useEffect(() => {
  //   const { netflowEventSecretKey } = oltData;
  //   setOltData({
  //     ...oltData,
  //     netflowReadString: netflowEventSecretKey,
  //     netflowWriteString: netflowEventSecretKey,
  //   });
  // }, [oltData.netflowEventSecretKey]);

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Create New OLT For{" "}
              <b style={{ color: "#ff4646" }}>{companyName}</b>
            </Card.Title>
            <Form className="mt-4">
              <Form.Group className="mb-3">
                <Form.Label> {`Enter OLT Name`}</Form.Label>
                <Form.Control
                  placeholder={`Enter OLT Name`}
                  type="text"
                  value={oltData.oltName}
                  onChange={(e) => {
                    setOltData({
                      ...oltData,
                      oltName: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label> Choose OLT Brand</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    const models = actualOntModels.filter(
                      (item: any) => item.intPkBrandID == e.target.value
                    );
                    setOltModels(models);
                    setOltData({
                      ...oltData,
                      intPkBrandID: e.target.value,
                    });
                  }}
                  value={oltData.intPkBrandID}
                >
                  <option value="">Choose OLT Brand</option>
                  {brands.map((item: any) => {
                    return (
                      <option value={item.intPkBrandID} key={item.intPkBrandID}>
                        {item.brandName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label> Choose OLT Model</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setOltData({
                      ...oltData,
                      intPkOLTModelID: e.target.value,
                    });
                  }}
                  value={oltData.intPkOLTModelID}
                >
                  <option value="">Choose OLT Model</option>
                  {oltModels.map((item: any) => {
                    return (
                      <option
                        value={item.intPkOLTModelID}
                        key={item.intPkOLTModelID}
                      >
                        {item.oltModelName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              {renderInput(`host`, "Enter OLT IP", `Enter OLT IP`)}
              {renderInput(`port`, "Enter TEL/SSH PORT", `Enter TEL/SSH PORT`)}

              <Form.Group className="mb-3">
                <Form.Label> Netflow</Form.Label>
                <Form.Control
                  placeholder={`Netflow`}
                  type="text"
                  value={oltData.netflowEventSecretKey}
                  onChange={(e) => {
                    setOltData({
                      ...oltData,
                      netflowEventSecretKey: e.target.value,
                      netflowReadString: e.target.value,
                      netflowWriteString: e.target.value,
                    });
                  }}
                />
              </Form.Group>

              {renderInput(
                `netFlowPort`,
                "Enter Netflow PORT",
                `Enter Netflow PORT`
              )}

              {renderInput(
                `oltWebAccessPort`,
                "Enter Olt Web Access PORT",
                `Enter Olt Web Access PORT`
              )}
              {/* {renderInput(`netflowEventSecretKey`, "Netflow", "Netflow")} */}
              {/* {renderInput(
                `netflowReadString`,
                "Enter Netflow Read String",
                "Enter Netflow Read String"
              )}
              {renderInput(
                `netflowWriteString`,
                "Enter Netflow Write String",
                "Enter Netflow Write String"
              )} */}
              {renderInput(
                `telnetUserName`,
                "Enter Telnet username",
                `Enter Telnet username`
              )}
              {renderInput(
                `telnetPassword`,
                "Enter Telnet password",
                `Enter Telnet password`
              )}
              <div style={{ textAlign: "center" }}>
                <button
                  onClick={handleCreateOLT}
                  disabled={oltLoading}
                  className="button-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default CreateOLTComponent;
