import AllSanctionedOltsForAccountantComponent from "../../components/accountant/AllSanctionedOltsForAccountant";

const AllSanctionedOltsForAccountantPage = () => {
  return (
    <>
      <AllSanctionedOltsForAccountantComponent />
    </>
  );
};
export default AllSanctionedOltsForAccountantPage;
