export const showUserName = (
  endUsers: any[],
  ontRegistrationDescInOLT: string
): string => {
  if (endUsers.length < 1) {
    // if (ontRegistrationDescInOLT) {
    //   return ontRegistrationDescInOLT;
    // }
    return "No User";
  }
  const endUser = endUsers[0];
  if (endUser.endUserPPPoEUserID) {
    return `${endUser.endUserPPPoEUserID} ${endUsers.length > 1 ? "..." : ""} `;
  }
  return "No User";
  // if (endUser.endUserFullName) {
  //   return `${endUser.endUserFullName}${endUsers.length > 1 ? "..." : ""} `;
  // }
  // return "No User";
};
