import CreateNewSlotModelComponent from "../../components/admin/models/CreateNewSlotModels";

const CreateNewSlotModelPage = () => {
  return (
    <>
      <CreateNewSlotModelComponent />
    </>
  );
};
export default CreateNewSlotModelPage;
