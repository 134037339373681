import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { formatDate } from "../../helpers/date";
import CreateCompanyWithAdminForAccountModal from "../Model/CreateCompanyWithAdminForAccountModal";
import SanctionOltToCompanyModal from "../Model/SanctionOltToCompanyModal";

const AllCompaniesComponentForAccount: React.FC<GP> = ({}) => {
  const [
    getSuperCOOData,
    getSuperCOOResponse,
    COOError,
    COOLoading,
    COOMessage,
    COOErrorCount,
  ] = useAxiosPost<ResponseProp>("accountant/getAllCompanies");
  const [superCOOs, setSuperCOOs] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showSanction, setShowSanction] = useState<boolean>(false);
  const [activeCompany, setActiveCompany] = useState<number>(-1);
  const handleClose = (isApiCall: boolean) => {
    setShow(false);
    setActiveCompany(-1);
    setShowSanction(false);

    if (isApiCall === true) {
      getSuperCOOData({});
    }
  };
  useEffect(() => {
    getSuperCOOData({});
  }, []);

  useEffect(() => {
    if (COOError !== null && COOErrorCount !== 0) {
      notifyError(COOError);
    }
  }, [COOError, COOErrorCount]);
  useEffect(() => {
    if (getSuperCOOResponse !== null) {
      console.log({ getSuperCOOResponse });
      setSuperCOOs(getSuperCOOResponse.responseData || []);
    }
  }, [getSuperCOOResponse]);

  return (
    <>
      {show === true ? (
        <CreateCompanyWithAdminForAccountModal
          show={show}
          handleClose={handleClose}
        />
      ) : null}
      {showSanction === true ? (
        <SanctionOltToCompanyModal
          show={showSanction}
          handleClose={handleClose}
          intPkCompanyID={activeCompany}
        />
      ) : null}
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Companies
              <span style={{ float: "right" }}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                  variant="success"
                >
                  Create New Company
                </Button>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Company Name</th>
                      <th className="table-header-standard">Country</th>
                      <th className="table-header-standard">City</th>
                      <th className="table-header-standard">Street</th>
                      <th className="table-header-standard">Sanction OLT</th>
                      <th className="table-header-standard">
                        Pending Sanctioned OLT
                      </th>
                      <th className="table-header-standard">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {superCOOs.map((item: any) => {
                      return (
                        <tr key={item.intPkCompanyID}>
                          <td>{item.companyName}</td>
                          <td>{item.country}</td>
                          <td>{item.city}</td>
                          <td>{item.streetAddress}</td>
                          <td>
                            {" "}
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setShowSanction(true);
                                setActiveCompany(Number(item.intPkCompanyID));
                              }}
                              style={{ minWidth: "130px" }}
                              className="button-primary"
                            >
                              Sanction OLT
                            </button>
                          </td>
                          <td> {item.stats.totalPendingSanctionsOlts}</td>
                          <td>{formatDate(item.dateCreated)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllCompaniesComponentForAccount;
