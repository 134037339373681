import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

import { toast } from "react-toastify";
import CreateAutoOLTCompanyAdminComponent from "../companyAdmin/CreateAutoOltCompanyAdmin";

const ModalForNewAutoOLTCreationForCompanyAdmin: React.FC<
  NewOLTModalPropForCompanyAdmin
> = ({ handleClose, show }) => {
  const handleApiResponse = (isSuccess: boolean) => {
    console.log({ isSuccess });
    if (isSuccess === true) {
      handleClose(true);
    }
  };
  return (
    <>
      <Modal
        show={show}
        keyboard={false}
        backdrop="static"
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> New OLT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateAutoOLTCompanyAdminComponent
            handleApiResponse={handleApiResponse}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalForNewAutoOLTCreationForCompanyAdmin;
