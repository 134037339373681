import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { confirmAlert } from "react-confirm-alert"; // Import

const AllVPNsComponentNoc: React.FC<GP> = ({}) => {
  const navigate = useNavigate();
  const [
    getCompaniesData,
    companiesData,
    companiesError,
    companiesLoading,
    companiesMessage,
    companiesErrorCount,
  ] = useAxiosPost<ResponseProp>("superCOO/getAllCompanies");
  useEffect(() => {
    if (companiesData !== null) {
      const { responseData } = companiesData;
      setAllCompanies(responseData || []);
    }
  }, [companiesData]);
  const [
    getApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("noc/getVpns");

  const [
    postNewVpnRequest,
    vpnRequestResponse,
    vpnRequestError,
    vpnRequestLoading,
    vpnRequestMessage,
    vpnRequestErrorCount,
  ] = useAxiosPost<ActiveVpnUserProp>("noc/activateVpn");
  const [allVpnUsers, setAllVpnUsers] = useState<any>([]);
  const [actualVpnUsers, setActualVpnUsers] = useState<any>([]);
  const [isFilteredTab, setIsFilteredTab] = useState<boolean>(false);
  const [allCompanies, setAllCompanies] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  useEffect(() => {
    getApiData({});
    getCompaniesData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const { responseData } = apiResponse;
      if (isFilteredTab === true) {
        const res: any = responseData || [];
        setAllVpnUsers(
          res.filter((item: any) => Number(item.requestStatus) === 0)
        );
      } else {
        setAllVpnUsers(responseData || []);
      }

      setActualVpnUsers(responseData || []);
    }
  }, [apiResponse]);
  const statusString = (requestStatus: number) => {
    if (requestStatus === 0) {
      return "Pending";
    }
    if (requestStatus === 1) {
      return "Active";
    }
    return "Pending";
  };
  const handleNewVpnRequest = (intPkVpnUserID: number) => {
    confirmAlert({
      title: "Active Vpn User",
      message: "You want to Activate this Vpn Request ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to activate");
            postNewVpnRequest({ intPkVpnUserID });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  useEffect(() => {
    if (vpnRequestResponse !== null) {
      const { message } = vpnRequestResponse;
      if (message) {
        notifySuccess(message);
      }
      getApiData({});
    }
  }, [vpnRequestResponse]);
  useEffect(() => {
    if (vpnRequestError !== null && vpnRequestErrorCount !== 0) {
      notifyError(vpnRequestError);
    }
  }, [vpnRequestError, vpnRequestErrorCount]);
  const handleFilter = (selectedCompany: string, isFilteredTab: boolean) => {
    const value = selectedCompany;
    let vpns = actualVpnUsers;
    if (isFilteredTab === true) {
      vpns = vpns.filter((item: any) => Number(item.requestStatus) === 0);
    }
    if (value === "") {
      setAllVpnUsers(vpns);
      return;
    }
    vpns = vpns.filter(
      (item: any) => Number(item.intPkCompanyID) === Number(value)
    );
    setAllVpnUsers(vpns);
  };
  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              {isFilteredTab === true ? "Pending" : "All"} Vpn Users
            </Card.Title>
            <Row className="mt-4">
              <Col md={4} lg={4} xs={12} sm={12} xl={4} xxl={4}>
                <span
                  className={`${
                    isFilteredTab === false
                      ? "active-tab-button-ont-list"
                      : "inactive-tab-button-ont-list"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsFilteredTab(false);
                    handleFilter(selectedCompany, false);
                  }}
                >
                  All
                </span>
                <span
                  className={`${
                    isFilteredTab === false
                      ? "inactive-tab-button-ont-list"
                      : "active-tab-button-ont-list"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsFilteredTab(true);
                    handleFilter(selectedCompany, true);
                  }}
                >
                  Pending
                </span>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={4} lg={4} xs={12} sm={12} xl={4} xxl={4}>
                <Form.Select
                  value={selectedCompany}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedCompany(value);
                    handleFilter(value, isFilteredTab);
                  }}
                >
                  <option value="">All Companies</option>
                  {allCompanies.map((item: any) => {
                    return (
                      <option
                        key={item.intPkCompanyID}
                        value={item.intPkCompanyID}
                      >
                        {item.companyName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">UserName</th>
                      <th className="table-header-standard">Password</th>
                      <th className="table-header-standard">
                        Client Router IP
                      </th>
                      <th className="table-header-standard">VPN Server IP</th>
                      <th className="table-header-standard">Company Name</th>
                      <th className="table-header-standard">Status</th>
                      <th className="table-header-standard">Activate</th>
                      <th className="table-header-standard">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allVpnUsers.map((user: any) => {
                      return (
                        <tr key={user.intPkVpnUserID}>
                          <td>{user.vpnUsername}</td>
                          <td>{user.vpnPassword}</td>
                          <td>{user.vpnClientIp}</td>
                          <td>{user.vpnServerIp}</td>
                          <td>{user.companyName}</td>
                          <td>{statusString(Number(user.requestStatus))}</td>
                          <td>
                            {Number(user.requestStatus) === 0 ? (
                              <Button
                                onClick={(e) => {
                                  handleNewVpnRequest(
                                    Number(user.intPkVpnUserID)
                                  );
                                }}
                              >
                                Activate
                              </Button>
                            ) : (
                              "Activated"
                            )}
                          </td>
                          <td>{formatDate(user.dateCreated)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllVPNsComponentNoc;
