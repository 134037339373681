import React, { useContext } from "react";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import PieChartComponent from "../Charts/Pie";
import { Col, Row, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./../../assets/styles/olt.css";
import SlotImage from "./../../assets/pngs/slot/slot.png";
import { TitleContext } from "../../App";
import LinkButtonImage from "./../../assets/svgs/goto/Link.svg";

const SingleSlotCard: React.FC<SingleSlotData> = ({
  healthStats,
  slotModel,
  slotNumber,
  slotHealthStats,
  intPkOltID,
  intPkSlotID,
  oltName,
}) => {
  const titleContextValues = useContext(TitleContext);
  const navigate = useNavigate();
  const handleOpenOLT = (e: any) => {
    e.preventDefault();
    navigate(
      `/browse-pon-ports-by-slot/${intPkSlotID}/${slotNumber}/${oltName}/${intPkOltID}/0/2`
    );

    // navigate(`//browse-pon-ports-by-slot/-1/0/:${oltName}/${intPkOltID}/1`);

    titleContextValues.titleTextChangeHandler(
      `${oltName} slot #${slotNumber} PonPorts`
    );
  };
  return (
    <>
      <Card style={{ marginBottom: "10px" }} className="text-center">
        <Card.Body>
          <Card.Title
            className="olt-heading"
            style={{ cursor: "pointer" }}
            onClick={(e: any) => handleOpenOLT(e)}
          >
            Slot#{slotNumber}
          </Card.Title>
        </Card.Body>

        {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12} xxl={12}>
            <img
              onClick={(e: any) => handleOpenOLT(e)}
              style={{ cursor: "pointer", width: "80%", maxWidth: "80%" }}
              src={SlotImage}
              className="olt-image-primary"
            />
            {/* <PieChartComponent pieChartData={healthStats} /> */}
          </Col>
          <Col md={12} lg={12} sm={12} xs={12} xl={12} xxl={12}>
            <Card.Title className="olt-heading-small">
              Card Model :{slotModel}
            </Card.Title>
          </Col>

          <Col
            className="mb-5 mt-2 olt-stats-container"
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            xxl={12}
          >
            <Container>
              <Row className="justify-content-center">
                <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                  <p>
                    <span className="online-circle"></span>{" "}
                    <span className="stats-counter">
                      {slotHealthStats.totalOnlineOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Online</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="offline-circle"></span>{" "}
                    <span className="stats-counter">
                      {slotHealthStats.totalOfflineOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Offline</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="upset-circle"></span>{" "}
                    <span className="stats-counter">
                      {slotHealthStats.totalUpsetOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Upset</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="inactive-circle"></span>{" "}
                    <span className="stats-counter">
                      {slotHealthStats.totalInActiveOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">InActive</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  {" "}
                  <p>
                    <span className="invisible-circle"></span>{" "}
                    <span className="stats-counter">
                      {slotHealthStats.totalInInvisibleOnts > 0
                        ? slotHealthStats.totalInInvisibleOnts
                        : 0}
                    </span>
                  </p>
                  <p className="stats-counter-label">InVisible</p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            className="mt-1 mb-3"
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            sm={12}
            xs={12}
          >
            <img
              onClick={(e: any) => handleOpenOLT(e)}
              style={{ cursor: "pointer" }}
              className="image-float-right"
              src={LinkButtonImage}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default SingleSlotCard;
