import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { Card } from "react-bootstrap";

import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import CreateCompanyIspModalComponent from "../Model/CreateCompanyIspModalComponent";
import UpdateCompanyIspModalComponent from "../Model/UpdateCompanyIspModalComponent";

const AdminIspsComponent: React.FC<GP> = ({}) => {
  const [
    getApiData,
    apiResponse,
    areasError,
    AreasLoading,
    areasMessage,
    areasErrorCount,
  ] = useAxiosPost<ResponseProp>("isps/getMyIsps");
  const [allIsps, setAllIsps] = useState<any>([]);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [activeIspName, setActiveIspName] = useState<string>("");
  const [activeIspId, setActiveIspId] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isCall: boolean) => {
    setShow(false);
    setShowUpdate(false);
    if (isCall === true) {
      getApiData({});
    }
  };
  useEffect(() => {
    getApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const { responseData } = apiResponse;
      setAllIsps(responseData || []);
    }
  }, [apiResponse]);
  return (
    <>
      <ToastContainer />
      {show === true ? (
        <CreateCompanyIspModalComponent show={show} handleClose={handleClose} />
      ) : null}
      {showUpdate === true ? (
        <UpdateCompanyIspModalComponent
          show={showUpdate}
          _ispName={activeIspName}
          handleClose={handleClose}
          intPkIspID={activeIspId}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Row>
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                <Card.Title className="text-center olt-heading fw-700 pt-3 ">
                  {" "}
                  Company ISPs
                  <span style={{ float: "right" }}>
                    <Button
                      onClick={(e) => {
                        setShow(true);
                      }}
                    >
                      {" "}
                      New ISP
                    </Button>
                  </span>
                </Card.Title>
                <Table className="mt-5" bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Isp Logo</th>
                      <th className="table-header-standard">ISP Name</th>
                      <th className="table-header-standard">Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allIsps.map((isp: any) => {
                      return (
                        <tr key={isp.intPkIspID}>
                          <td>
                            <img
                              style={{ width: "25px" }}
                              src={`${isp.ispLogoUrl}`}
                            />
                          </td>
                          <td>{isp.ispName}</td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveIspId(isp.intPkIspID);
                                setActiveIspName(isp.ispName);
                                setShowUpdate(true);
                              }}
                              variant="success"
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AdminIspsComponent;
