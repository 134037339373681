import NOCORCOOLoginAsCompanyComponent from "../../components/Auth/NOCORCOOLoginAsCompany";
const NOCORCOOLoginAsCompanyPage = () => {
  const role = localStorage.getItem("userType") || "";
  const getComponentRoleWise = () => {
    if (role === "NOC") {
      return <NOCORCOOLoginAsCompanyComponent />;
    }
    if (role === "SUPER_COO") {
      return <NOCORCOOLoginAsCompanyComponent />;
    }
    return <></>;
  };
  return <>{getComponentRoleWise()}</>;
};
export default NOCORCOOLoginAsCompanyPage;
