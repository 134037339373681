import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import ModalForNewAutoOLTCreationResume from "../Model/ResumeAutoOLTModal";

const ProcessingOLTsComponentNOC: React.FC<GP> = ({}) => {
  const [
    postDeleteOLT,
    oltDeleteResponse,
    oltDeleteError,
    oltDeleteLoading,
    olDeleteMessage,
    oltDeleteErrorCount,
  ] = useAxiosPost<DeleteOLTProp>("superCOO/deleteOLT");
  useEffect(() => {
    if (oltDeleteError !== null && oltDeleteErrorCount !== 0) {
      notifyError(oltDeleteError);
    }
  }, [oltDeleteError, oltDeleteErrorCount]);
  useEffect(() => {
    if (oltDeleteResponse !== null) {
      notifySuccess(oltDeleteResponse.message || "Olt Deleted Successfully");
      getAllOltsData({});
    }
  }, [oltDeleteResponse]);
  const [
    getAllOltsData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<ResponseProp>("olt/getProcessingOlts");

  const [intPkCompanyID, setIntPkCompanyID] = useState<number>(-1);
  const [companyName, setCompanyName] = useState<string>("");
  const [allOlts, setAllOlts] = useState<any>([]);

  const [show, setShow] = useState<boolean>(false);
  const [activeOlt, setActiveOlt] = useState<number>(-1);

  useEffect(() => {
    getAllOltsData({});
  }, []);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { responseData } = oltResponseData;
      setAllOlts(responseData || []);
    }
  }, [oltResponseData]);

  const handleClose = (isCall: boolean) => {
    setShow(false);
    setActiveOlt(-1);
    if (isCall === true) {
      getAllOltsData({});
    }
  };

  const deleteOLT = (oltName: string, intPkOltID: number) => {
    confirmAlert({
      title: `Delete OLT `,
      message: `Do you want to Delete ${oltName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just Delete it.");
            postDeleteOLT({ intPkOltID: Number(intPkOltID) });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  return (
    <>
      <ToastContainer />
      {show == true && activeOlt !== -1 ? (
        <ModalForNewAutoOLTCreationResume
          intPkCompanyID={intPkCompanyID}
          companyName={companyName}
          show={show}
          handleClose={handleClose}
          intPkOltID={activeOlt}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Processing OLTs
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">OLT Name</th>
                      <th className="table-header-standard">Brand</th>
                      <th className="table-header-standard">Model</th>
                      <th className="table-header-standard">Host</th>
                      <th className="table-header-standard">Port</th>
                      <th className="table-header-standard">Sys Events</th>
                      <th className="table-header-standard">NetFlowBotNode</th>
                      <th className="table-header-standard">Resume Process</th>
                      <th className="table-header-standard">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allOlts.map((olt: any) => {
                      return (
                        <tr key={olt.intPkOltID}>
                          <td>{olt.oltName}</td>
                          <td>{olt.brand}</td>
                          <td>{olt.model}</td>
                          <td>{olt.host}</td>
                          <td>{olt.port}</td>
                          <td>
                            {olt.sysEventLogControllerName !== null
                              ? olt.sysEventLogControllerName
                              : "-"}
                          </td>
                          <td>
                            {olt.nodes.netFlowBotNode !== false
                              ? olt.nodes.netFlowBotNode.botNodeName
                              : "-"}
                          </td>

                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveOlt(Number(olt.intPkOltID));
                                setCompanyName(olt.companyName);
                                setIntPkCompanyID(Number(olt.intPkCompanyID));
                                setShow(true);
                              }}
                              variant="warning"
                            >
                              Resume
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();

                                deleteOLT(olt.oltName, olt.intPkOltID);
                              }}
                              variant="danger"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ProcessingOLTsComponentNOC;
