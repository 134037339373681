// import BrowseOLTsComponent from "../../components/admin/BrowseOlts";
import BrowseSlotsComponent from "../../components/admin/BrowseSlots";

const BrowseSlotsPage = () => {
  return (
    <>
      <BrowseSlotsComponent />
    </>
  );
};
export default BrowseSlotsPage;
