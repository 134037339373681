import React, { useEffect, useState, ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";

import { toast } from "react-toastify";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
const CreateDealerAreaModal: React.FC<DealerAreaCreationModalProp> = ({
  handleClose,
  show,
  areas,
}) => {
  const [
    postApiData,
    apiResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateSubAreaForDealerApiProp>("areas/createSubArea");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [areaName, setAreaName] = useState<string>("");

  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponseData !== null) {
      const { message } = apiResponseData;
      if (message) {
        notifySuccess(message);
        handleClose(true);
      }
    }
  }, [apiResponseData]);

  const handleCreateDealerArea = async (event: any) => {
    event.preventDefault();
    if (areaName.trim() === "") {
      notifyError(`Area Name is required`);
      return;
    }
    if (selectedArea.trim() === "") {
      notifyError(`Parent Area is required`);
      return;
    }
    const dataToSend: CreateSubAreaForDealerApiProp = {
      areaName,
      areaTypeEnum: "SUB_AREA",
      parentArea: Number(selectedArea),
    };
    postApiData(dataToSend);
    try {
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  return (
    <>
      <Modal
        show={show}
        keyboard={false}
        backdrop="static"
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            New Area For Dealer <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label> Choose Area</Form.Label>
            <Form.Select
              value={selectedArea}
              onChange={(e) => {
                setSelectedArea(e.target.value);
                console.log(e.target.value);
              }}
            >
              <option value="">Choose Area</option>
              {areas.map((area: any) => {
                return (
                  <option value={area.intPkAreaID} key={area.intPkAreaID}>
                    {area.areaName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label> Sub Area Name</Form.Label>
            <Form.Control
              value={areaName}
              placeholder="Area Name"
              onChange={(e) => {
                setAreaName(e.target.value);
              }}
            />
          </Form.Group>

          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateDealerArea}
              disabled={apiLoading}
              className="button-primary"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateDealerAreaModal;
