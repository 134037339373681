import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost, {
  authTokenString,
  baseURLv1,
  baseURL,
} from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Axios from "axios";
import {
  faLocation,
  faLocationPin,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../helpers/date";
import Moment from "react-moment";
import moment from "moment";
import CreateRegistrationFromFoundOntsModal from "../Model/CreateRegistrationFromFoundOnts";
import RestoreFromLogsModal from "../Model/RestoreFromLogsModal";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
import { getFoundOntsRoute } from "../../helpers/apiRoutes";
const FOUND_ONTS_ROUTE = getFoundOntsRoute();
const FoundOntsComponent: React.FC<GP> = ({}) => {
  const [selectedOlt, setSelectedOlt] = useState("");
  const [selectedOltName, setSelectedOltName] = useState("");
  const [activePonNumber, setActivePonNumber] = useState("");
  const [searchString, setSearchString] = useState("");
  const [foundOnts, setFoundOnts] = useState<any>([]);
  const [endUsers, setEndUsers] = useState<any>([]);
  const [vlansArray, setVlansArray] = useState<any>([]);
  const [olts, setOlts] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);

  //binding logs restore states
  const [showLogsModel, setShowLogsModel] = useState<boolean>(false);
  const [activeLogs, setActiveLogs] = useState<any>([]);
  const [activeFSP, setActiveFSP] = useState({
    oltSlot: "",
    oltFrame: "",
    oltPonPort: "",
  });

  const handleClose = () => {
    setShow(false);
    setShowLogsModel(false);
    setActiveLogs([]);
    setActiveFSP({
      oltSlot: "",
      oltFrame: "",
      oltPonPort: "",
    });
    getFreeEndUsersData({});
  };

  const [
    getFoundOntsData,
    FoundOntsResponseData,
    foundOntsError,
    foundOntsLoading,
    foundOntsMessage,
    foundOntsErrorCount,
  ] = useAxiosPost<ResponseProp>(`${FOUND_ONTS_ROUTE}`);

  const [
    getFreeEndUsersData,
    freeEndUsersResponseData,
    freeEndUsersError,
    freeEndUsersLoading,
    freeEndUsersMessage,
    freeEndUsersErrorCount,
  ] = useAxiosPost<ResponseProp>("endUsers/getMyFreeEndUsers");

  useEffect(() => {
    if (freeEndUsersError !== null && freeEndUsersErrorCount !== 0) {
      notifyError(freeEndUsersError);
    }
  }, [freeEndUsersError, freeEndUsersErrorCount]);
  useEffect(() => {
    if (freeEndUsersResponseData !== null) {
      const endUsersResponse: any = freeEndUsersResponseData.responseData;
      setEndUsers(endUsersResponse || []);
    }
  }, [freeEndUsersResponseData]);

  useEffect(() => {
    getFoundOntsData({});
    getFreeEndUsersData({});
    const interval = setInterval(() => {
      getFoundOntsData({});
    }, 5000);
    return () => {
      // Clear the interval when the component is about to unmount
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (foundOntsError !== null && foundOntsErrorCount !== 0) {
      notifyError(foundOntsError);
    }
  }, [foundOntsError, foundOntsErrorCount]);
  useEffect(() => {
    if (FoundOntsResponseData !== null) {
      const responseData: any = FoundOntsResponseData.responseData;
      if (responseData?.length > 0) {
        //let's
        // console.log({ responseData });
        if (selectedOlt) {
          const filteredOLT = responseData.find(
            (item: any) => item.intPkOltID === selectedOlt
          );
          if (filteredOLT) {
            const { intPkOltID, oltName, vlansArray, foundOnts } = filteredOLT;
            setSelectedOlt(intPkOltID);
            setSelectedOltName(oltName);
            setFoundOnts(foundOnts);
            setVlansArray(vlansArray);
          } else {
            const _selectedOLT = responseData[0];
            const { intPkOltID, oltName, vlansArray, foundOnts } = _selectedOLT;
            setSelectedOlt(intPkOltID);
            setSelectedOltName(oltName);
            setFoundOnts(foundOnts);
            setVlansArray(vlansArray);
          }
        } else {
          const _selectedOLT = responseData[0];
          const { intPkOltID, oltName, vlansArray, foundOnts } = _selectedOLT;
          setSelectedOlt(intPkOltID);
          setSelectedOltName(oltName);
          setFoundOnts(foundOnts);
          setVlansArray(vlansArray);
        }
        // const selectedOLT = responseData[0];
      }
      setOlts(responseData);
    }
  }, [FoundOntsResponseData]);
  const convertMetersToKiloMeters = (meters: any) => {
    return meters / 1000;
  };
  const getTimeAgo = (_date: any) => {
    const startTime = moment(_date);
    const currentTime = moment();

    return (
      <Moment from={currentTime} ago>
        {startTime}
      </Moment>
    );
  };
  useEffect(() => {}, [selectedOlt]);
  const handleSetFoundOnts = (selectedOlt: any) => {
    const olt = olts.find((item: any) => item.intPkOltID == selectedOlt);
    if (olt) {
      const { intPkOltID, oltName, vlansArray, foundOnts } = olt;
      setSelectedOlt(intPkOltID);
      setSelectedOltName(oltName);
      setFoundOnts(foundOnts);
      setVlansArray(vlansArray);
    }
  };
  const createRegistration = (endUserId: number, vlanId: number) => {
    const foundOnt = foundOnts.find(
      (item: any) => item.ponNumber == activePonNumber
    );
    if (!foundOnt) {
      return;
    }
    //le'ts create binding first.

    let bindingsArray = [
      {
        ont: {
          intPkFoundOntID: foundOnt.intPkFoundOntID,
        },
        vlan: {
          intPkVlanID: vlanId,
          useVlanSpBundle: false,
        },
      },
    ];
    const dataToSend = {
      authTokenString,
      newBindingsRequestArray: bindingsArray,
    };
    Axios.post(`${baseURL}/createNewBinding`, dataToSend)
      .then((res) => {
        const data = res.data;
        const obj = data[0];
        if (obj.success === true) {
          //binding created let's do the other thing
          const m2mData = {
            authTokenString,
            ponNumber: activePonNumber,
            oltPonPort: foundOnt.oltPonPort,
            oltSlot: foundOnt.oltSlot,
            oltFrame: foundOnt.oltFrame,
            intPkOltID: foundOnt.intPkOltID,
            intPkEndUserID: endUserId,
          };
          assignEndUserToBinding(m2mData);
        } else {
          notifyError(`Server Error, Please Try Again`);
        }
        //let's do registration and binding
      })
      .catch((error) => {
        notifyError(`Server Error, Please Try Again`);
      });
  };
  const assignEndUserToBinding = (data: any) => {
    Axios.post(`${baseURL}/binding/assignBindingToEndUser`, data)
      .then((res) => {
        const data = res.data;
        const { success, message } = data;
        if (success === true) {
          notifySuccess(message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((error) => {
        notifyError(`Server Error, Please Try Again`);
      });
  };
  const handleSearch = (value: string) => {
    const olt = olts.find((item: any) => item.intPkOltID == selectedOlt);
    if (!olt) {
      return;
    }

    const { foundOnts } = olt;
    if (value == "") {
      setFoundOnts(foundOnts);
      return;
    }
    const _onts = foundOnts.filter(
      (item: any) => item.ponNumber.toLowerCase() == value.toLowerCase()
    );
    setFoundOnts(_onts);
  };

  return (
    <>
      <ToastContainer />
      {showLogsModel === true ? (
        <RestoreFromLogsModal
          oltSlot={activeFSP.oltSlot}
          oltFrame={activeFSP.oltFrame}
          oltPonPort={activeFSP.oltPonPort}
          show={showLogsModel}
          oltName={selectedOltName}
          handleClose={handleClose}
          // createRegistration={createRegistration}
          endUsers={endUsers}
          activeLogs={activeLogs}
        />
      ) : null}
      {show === true ? (
        <CreateRegistrationFromFoundOntsModal
          show={show}
          handleClose={handleClose}
          createRegistration={createRegistration}
          endUsers={endUsers}
          vlans={vlansArray}
        />
      ) : null}
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="12" md="12" xl="12" className="p-5">
            <Card className="p-5">
              <Card.Title className="text-center olt-heading pt-3 main-ont-heading">
                {" "}
                Found ONTs
              </Card.Title>
              <Row className="mt-4">
                <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
                <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder={`Enter Mac Address Or Pon Number`}
                      type="text"
                      value={searchString}
                      onChange={(e) => {
                        setSearchString(e.target.value);
                        handleSearch(e.target.value);
                      }}
                    />
                    <div className="input-group-append">
                      <button className="button-primary-search" type="button">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
                <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                  <Form.Label className="fw-700">Select OLT</Form.Label>
                  <Form.Select
                    value={selectedOlt}
                    style={{ height: "50px" }}
                    onChange={(e) => {
                      setSelectedOlt(e.target.value);
                      setSearchString("");
                      handleSetFoundOnts(e.target.value);
                    }}
                  >
                    {olts.map((item: any) => {
                      return (
                        <option value={item.intPkOltID} key={item.intPkOltID}>
                          {item.oltName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Card.Body>
                {foundOnts.map((item: any, index: number) => {
                  return (
                    <Row className="single-ont-container">
                      <Col md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
                        <h5 className="text-center text-blue-heading">
                          {selectedOltName}{" "}
                          <span className="olt-counter">
                            {index < 10 ? "0" + (index + 1) : index + 1}
                          </span>
                        </h5>
                      </Col>

                      <Col
                        className="mt-3"
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        sm={6}
                        xs={12}
                      >
                        <span className="data-r-label-green">Pon</span> :{" "}
                        <span className="data-r-field">{item.ponNumber}</span>{" "}
                      </Col>
                      <Col
                        className="mt-3"
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        sm={6}
                        xs={12}
                      >
                        <span className="image-float-right">
                          <span className="data-r-label-green">FSP</span> :{" "}
                          <span className="data-r-field">{item.fsp}</span>{" "}
                        </span>
                      </Col>

                      <Col
                        className="mt-3"
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        sm={12}
                        xs={12}
                      >
                        <span className="data-r-label-green">Date</span> :{" "}
                        <span className="data-r-field">
                          {getTimeAgo(item.datetime)} Ago
                        </span>{" "}
                      </Col>

                      <Col
                        className="mt-3"
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        sm={12}
                        xs={12}
                      >
                        <span className="data-r-label-green">Conflict</span> :{" "}
                        <span className="data-r-field-red">
                          {item.conflictMessage}
                        </span>{" "}
                      </Col>
                      {item.bindingLogs.length > 0 ? (
                        <>
                          <Col
                            className="justify-content-center"
                            style={{ marginTop: "1.5rem", textAlign: "center" }}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            sm={12}
                            xs={12}
                          >
                            <button
                              className="button-primary"
                              onClick={(e) => {
                                e.preventDefault();

                                const role = getUserType();
                                if (
                                  role === "" ||
                                  role == "DEMO_DEALER" ||
                                  role === "DEMO_ADMIN"
                                ) {
                                  notifyDemoAccountError();
                                  return;
                                }
                                setShow(true);
                                setActivePonNumber(item.ponNumber);
                              }}
                            >
                              Create Registration
                            </button>
                          </Col>
                          <Col
                            className="justify-content-center"
                            style={{ marginTop: "1.5rem", textAlign: "center" }}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            sm={12}
                            xs={12}
                          >
                            <button
                              className="button-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowLogsModel(true);
                                setActiveLogs(item.bindingLogs);
                                setActiveFSP({
                                  oltSlot: item.oltSlot,
                                  oltFrame: item.oltFrame,
                                  oltPonPort: item.oltPonPort,
                                });
                              }}
                            >
                              Restore From Logs
                            </button>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col
                            className="justify-content-center"
                            style={{ marginTop: "1.5rem", textAlign: "center" }}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            sm={12}
                            xs={12}
                          >
                            <button
                              className="button-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                const role = getUserType();
                                if (
                                  role === "" ||
                                  role == "DEMO_DEALER" ||
                                  role === "DEMO_ADMIN"
                                ) {
                                  notifyDemoAccountError();
                                  return;
                                }
                                setShow(true);
                                setActivePonNumber(item.ponNumber);
                              }}
                            >
                              Create Registration
                            </button>
                          </Col>
                        </>
                      )}
                    </Row>
                  );
                })}
              </Card.Body>
            </Card>
            {/* New Design End */}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default FoundOntsComponent;
