import AdminDashboardComponent from "../components/admin/AdminDashboard";

const AdminDashboardPage = () => {
  return (
    <>
      <AdminDashboardComponent />
    </>
  );
};
export default AdminDashboardPage;
