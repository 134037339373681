import React, { useEffect, useState, ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";

import { toast } from "react-toastify";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
const CreateVlanForOLTModalComponent: React.FC<NewVlanCreationByOLTProp> = ({
  handleClose,
  show,
  intPkOltID,
  brand,
}) => {
  const [
    getApiData,
    apiResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("isps/getAllIsps");

  const [
    postCreateVlanData,
    vlanResponseData,
    vlanError,
    vlanLoading,
    vlanMessage,
    vlanErrorCount,
  ] = useAxiosPost<CreateISPVlanProp>("vlans/createNewVlanProfile");
  const CreateVlanValidationSchema = yup.object().shape({
    intPkIspID: yup.number().integer().moreThan(0, "Invalid Isp").required(),
    vlan: yup.number().integer().moreThan(0, "Invalid Vlan").required(),
    ontLineProfile: yup
      .number()
      .moreThan(0, "Invalid Line Profile")
      .lessThan(8123, "Invalid Line Profile")
      .integer()
      .required(),
    ontServiceProfile: yup
      .number()
      .moreThan(0, "Invalid Service Profile")
      .lessThan(8123, "Invalid Service Profile")
      .integer()
      .required(),
    gemPort: yup
      .number()
      .moreThan(0, "Invalid Gem Port")
      .lessThan(1023, "Invalid Gem Port")
      .integer()
      .required(),
    intPkOltID: yup.number().integer().required(),
    vlanName: yup.string().required(),
  });
  const CreateVlanValidationSchemaForVsol = yup.object().shape({
    intPkIspID: yup.number().integer().moreThan(0, "Invalid Isp").required(),
    vlan: yup.number().integer().moreThan(0, "Invalid Vlan").required(),
    intPkOltID: yup.number().integer().required(),
    vlanName: yup.string().required(),
  });
  const [allIsps, setAllISPS] = useState<any>([]);

  const [vlanProfileData, setVlanProfileData] = useState<CreateISPVlanProp>({
    intPkIspID: "",
    vlanName: "",
    vlan: "",
    ontLineProfile: "",
    ontServiceProfile: "",
    gemPort: "",
    dbaProfile: 10,
    vlanType: "INTERNET",
    intPkOltID: "",
  });
  useEffect(() => {
    getApiData({});
  }, []);

  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponseData !== null) {
      const { responseData } = apiResponseData;
      setAllISPS(responseData || []);
    }
  }, [apiResponseData]);

  useEffect(() => {
    setVlanProfileData({
      ...vlanProfileData,
      intPkOltID: intPkOltID,
    });
  }, [intPkOltID]);
  const handleCreateVlan = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateISPVlanProp = {
      ...vlanProfileData,
      intPkOltID: Number(vlanProfileData.intPkOltID),
      vlan: Number(vlanProfileData.vlan),
      ontLineProfile: Number(vlanProfileData.ontLineProfile),
      ontServiceProfile: Number(vlanProfileData.ontServiceProfile),
      gemPort: Number(vlanProfileData.gemPort),
    };
    try {
      if (brand == "VSOL") {
        const isValid = await CreateVlanValidationSchemaForVsol.validate(
          dataToSend
        );
      } else {
        const isValid = await CreateVlanValidationSchema.validate(dataToSend);
      }

      postCreateVlanData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVlanProfileData({
      ...vlanProfileData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (vlanError !== null && vlanErrorCount !== 0) {
      notifyError(vlanError);
    }
  }, [vlanError, vlanErrorCount]);
  useEffect(() => {
    if (vlanResponseData !== null) {
      const { message } = vlanResponseData;
      if (message) {
        notifySuccess(message);
      }
      handleClose(true);
    }
  }, [vlanResponseData]);
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={vlanProfileData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            New Vlan For OLT <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label> Select An ISP</Form.Label>
            <Form.Select
              value={vlanProfileData.intPkIspID}
              onChange={(e) => {
                setVlanProfileData({
                  ...vlanProfileData,
                  intPkIspID: e.target.value,
                });
              }}
            >
              <option value={""}>Choose ISP</option>
              {allIsps.map((olt: any) => {
                return (
                  <option key={olt.intPkIspID} value={olt.intPkIspID}>
                    {olt.ispName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          {renderInput(`vlanName`, "Enter Vlan Name", `Enter Vlan Name`)}
          {brand !== "VSOL" ? (
            <>
              {renderInput(`vlan`, "Enter Vlan Value", `Enter Vlan Value`)}
              {renderInput(
                `ontLineProfile`,
                "Enter Ont Line Profile",
                `Enter Ont Line Profile`
              )}
              {renderInput(
                `ontServiceProfile`,
                "Enter Ont Service Profile",
                `Enter Ont Service Profile`
              )}
              {renderInput(`gemPort`, "Enter Gem Port", `Enter Gem Port`)}
            </>
          ) : (
            <>
              <Form.Group className="mb-3">
                <Form.Label> Vlan</Form.Label>
                <Form.Control
                  placeholder={`Vlan`}
                  type="text"
                  value={vlanProfileData.vlan}
                  onChange={(e) => {
                    setVlanProfileData({
                      ...vlanProfileData,
                      gemPort: e.target.value,
                      ontLineProfile: e.target.value,
                      ontServiceProfile: e.target.value,
                      vlan: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </>
          )}

          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateVlan}
              disabled={vlanLoading}
              className="button-primary"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateVlanForOLTModalComponent;
