import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { formatDate } from "../../helpers/date";
import CompanyAdminResetStaffPasswordModal from "../Model/CompanyAdminResetStaffPasswordModal";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
type Props = {
  isLoggedIn?: boolean;
};

const AllStaffComponent: React.FC<Props> = ({}) => {
  const [
    getStaff,
    getStaffResponse,
    getStaffError,
    getStaffLoading,
    getStaffMessage,
    getStaffErrorCount,
  ] = useAxiosPost<ResponseProp>("admin/getMyStaff");
  const [allStaff, setAllStaff] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [activeUserName, setActiveUserName] = useState<string>("");
  const [intPkUserID, setIntPkUserID] = useState<string | number>("");
  const handleClose = (isApiCall: boolean) => {
    setShow(false);
    setActiveUserName("");
    setIntPkUserID("");
  };
  useEffect(() => {
    getStaff({});
  }, []);

  useEffect(() => {
    if (getStaffError !== null && getStaffErrorCount !== 0) {
      notifyError(getStaffError);
    }
  }, [getStaffError, getStaffErrorCount]);
  useEffect(() => {
    if (getStaffResponse !== null) {
      console.log({ getStaffResponse });
      setAllStaff(getStaffResponse.responseData || []);
    }
  }, [getStaffResponse]);

  return (
    <>
      <ToastContainer />
      {show === true ? (
        <CompanyAdminResetStaffPasswordModal
          show={show}
          handleClose={handleClose}
          userFullname={activeUserName}
          intPkUserID={intPkUserID}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Staff List
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Full Name</th>
                      <th className="table-header-standard">UserName</th>
                      <th className="table-header-standard">Email</th>
                      <th className="table-header-standard">Contact Number</th>
                      <th className="table-header-standard">User Role</th>
                      <th className="table-header-standard">Joining Date</th>
                      <th className="table-header-standard">Reset Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allStaff.map((item: any) => {
                      return (
                        <tr key={item.intPkUserID}>
                          <td>{item.userFullname}</td>
                          <td>{item.username}</td>
                          <td>{item.email}</td>
                          <td>{item.contactNumber}</td>
                          <td>{item.userType}</td>
                          <td>{formatDate(item.dateCreated)}</td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                const role = getUserType();
                                if (
                                  role === "" ||
                                  role == "DEMO_DEALER" ||
                                  role === "DEMO_ADMIN"
                                ) {
                                  notifyDemoAccountError();
                                  return;
                                }
                                setIntPkUserID(item.intPkUserID);
                                setActiveUserName(item.userFullname);
                                setShow(true);
                              }}
                              variant="danger"
                            >
                              Reset
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllStaffComponent;
