import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { formatDate } from "../../helpers/date";
import CreateNewBankModalComponent from "../Model/CreateNewBankModal";
import { Link } from "react-router-dom";
const AllBanksComponentForSuperAdmin: React.FC<GP> = ({}) => {
  const [
    getBanksData,
    banksResponse,
    banksError,
    COOLoading,
    COOMessage,
    banksErrorCount,
  ] = useAxiosPost<ResponseProp>("superAdmin/getAllBanks");
  const [allBanks, setAllBanks] = useState<any>([]);
  useEffect(() => {
    getBanksData({});
  }, []);
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isApiCall: boolean) => {
    setShow(false);
    if (isApiCall === true) {
      getBanksData({});
    }
  };
  useEffect(() => {
    if (banksError !== null && banksErrorCount !== 0) {
      notifyError(banksError);
    }
  }, [banksError, banksErrorCount]);
  useEffect(() => {
    if (banksResponse !== null) {
      console.log({ banksResponse });
      setAllBanks(banksResponse.responseData || []);
    }
  }, [banksResponse]);

  return (
    <>
      <ToastContainer />
      {show === true ? (
        <CreateNewBankModalComponent show={show} handleClose={handleClose} />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Banks
              <span style={{ float: "right" }}>
                {" "}
                <Button
                  onClick={(e) => {
                    setShow(true);
                  }}
                  variant="success"
                >
                  Create New Bank
                </Button>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Bank</th>
                      <th className="table-header-standard">Created</th>
                      <th className="table-header-standard">View Accounts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allBanks.map((item: any) => {
                      return (
                        <tr key={item.intPkBankID}>
                          <td>{item.bankName}</td>
                          <td>{formatDate(item.dateCreated)}</td>
                          <td>
                            <Link
                              to={`/bank-accounts-by-banks/${item.intPkBankID}/${item.bankName}`}
                              className="btn btn-primary"
                            >
                              View Account
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllBanksComponentForSuperAdmin;
