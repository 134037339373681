import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";

const UpdatePricePolicyModalComponent: React.FC<
  ShowModalForUpdatePricePolicy
> = ({ show, handleClose, intPkOltPricePolicyID }) => {
  const [
    postBrandsData,
    brandsResponse,
    brandsError,
    brandsLoading,
    brandsMessage,
    brandsErrorCount,
  ] = useAxiosPost<GetSinglePricePolicyProp>(
    "superAdmin/getSinglePricePolicyModel"
  );

  const [brands, setBrands] = useState<any>([]);

  useEffect(() => {
    if (brandsResponse !== null) {
      console.log({ brandsResponse: brandsResponse.responseData });
      const res: any = brandsResponse.responseData;
      const {
        discountPercentage,
        price,
        isTrialAllowedOrNot,
        trialPeriodDays,
        invoiceDueDaysBeforeDisable,
      } = res;
      setPricePolicyModelData({
        ...pricePolicyModelData,
        discountPercentage,
        price,
        isTrialAllowedOrNot: isTrialAllowedOrNot,
        trialPeriodDays,
        invoiceDueDaysBeforeDisable,
      });
    }
  }, [brandsResponse]);

  useEffect(() => {
    postBrandsData({ intPkOltPricePolicyID });
  }, []);
  const [
    postCrateNewPricePolicy,
    pricePolicyResponse,
    pricePolicyError,
    pricePolicyLoading,
    endUserMessage,
    pricePolicyErrorCount,
  ] = useAxiosPost<UpdatePricePolicyModelProp>("superAdmin/updatePricePolicy");

  useEffect(() => {
    if (pricePolicyError !== null && pricePolicyErrorCount !== 0) {
      notifyError(pricePolicyError);
    }
  }, [pricePolicyError, pricePolicyErrorCount]);
  useEffect(() => {
    if (pricePolicyResponse !== null) {
      const response: any = pricePolicyResponse;
      const { message, responseData } = response;

      if (message) {
        notifySuccess(message);

        handleClose(true);
      }
    }
  }, [pricePolicyResponse]);

  const createEndUserValidationSchema = yup.object().shape({
    trialPeriodDays: yup.number().integer().required().min(0).max(10),
    isTrialAllowedOrNot: yup.number().integer().required().min(0),
    price: yup.number().integer().required().min(0),
    invoiceDueDaysBeforeDisable: yup
      .number()
      .integer()
      .required()
      .min(0)
      .max(10),
    discountPercentage: yup.number().integer().required().min(0).max(10),
  });

  const [pricePolicyModelData, setPricePolicyModelData] =
    useState<UpdatePricePolicyModelProp>({
      trialPeriodDays: "",
      isTrialAllowedOrNot: "",
      price: "0",
      invoiceDueDaysBeforeDisable: "0",
      discountPercentage: "0",
      intPkOltPricePolicyID: intPkOltPricePolicyID,
      enable: 1,
    });
  const handleCreateArea = async (event: any) => {
    event.preventDefault();
    if (pricePolicyModelData.isTrialAllowedOrNot == "") {
      notifyError(`Either Choose yes or no for Give Trail  `);
      return;
    }
    const dataToSend: UpdatePricePolicyModelProp = pricePolicyModelData;
    try {
      const isValid = await createEndUserValidationSchema.validate(dataToSend);
      postCrateNewPricePolicy(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPricePolicyModelData({
      ...pricePolicyModelData,
      [name]: value,
    });
  };
  const renderInput = (
    name: string,
    placeholder: string,
    label: string,
    type: string = "text"
  ) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type={type}
          value={pricePolicyModelData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Update Price Policy Model <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className=" p-5">
                <h3 className="mb-5 text-center">Update Price Policy Model</h3>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Trail Period Days</Form.Label>
                    <Form.Control
                      placeholder={`Trail Period Days`}
                      type={"number"}
                      disabled={
                        pricePolicyModelData.isTrialAllowedOrNot == 0
                          ? true
                          : false
                      }
                      value={pricePolicyModelData.trialPeriodDays}
                      onChange={(e) => {
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          trialPeriodDays: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  {renderInput(`price`, "Enter Price", "Enter Price", "number")}
                  <Form.Group className="mb-3">
                    <Form.Label> Choose</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        if (e.target.value == "0") {
                          setPricePolicyModelData({
                            ...pricePolicyModelData,
                            isTrialAllowedOrNot: e.target.value,
                            trialPeriodDays: 0,
                          });
                        } else {
                          setPricePolicyModelData({
                            ...pricePolicyModelData,
                            isTrialAllowedOrNot: e.target.value,
                          });
                        }
                      }}
                      value={pricePolicyModelData.isTrialAllowedOrNot}
                    >
                      <option value="">Choose</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {" "}
                      Choose Invoice Days Before Olt Disable
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          invoiceDueDaysBeforeDisable: e.target.value,
                        });
                      }}
                      value={pricePolicyModelData.invoiceDueDaysBeforeDisable}
                    >
                      <option value="0">No Day</option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: number) => {
                        return (
                          <option value={item} key={item}>
                            {`${item} Days`}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label> Select Discount Percentage</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          discountPercentage: e.target.value,
                        });
                      }}
                      value={pricePolicyModelData.discountPercentage}
                    >
                      <option value="0">0%</option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: number) => {
                        return (
                          <option value={item} key={item}>
                            {`${item} %`}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Button
                    onClick={handleCreateArea}
                    variant="primary"
                    type="submit"
                    disabled={pricePolicyLoading}
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UpdatePricePolicyModalComponent;
