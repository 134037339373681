import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";

import UpStatus from "./../../assets/svgs/dashboard/up.svg";
import DownStatus from "./../../assets/svgs/dashboard/down.svg";
import InActiveStatus from "./../../assets/svgs/dashboard/inactive.svg";
import InProcessStatus from "./../../assets/svgs/statuses/inprocess.svg";
import UpSetStatus from "./../../assets/svgs/dashboard/upset.svg";
import InVisibleStatus from "./../../assets/svgs/dashboard/invisible.svg";
import OneBar from "./../../assets/svgs/signals/oneBar.svg";
import TwoBars from "./../../assets/svgs/signals/twoBars.svg";
import ThreeBars from "./../../assets/svgs/signals/threeBars.svg";
import FourBars from "./../../assets/svgs/signals/fourBars.svg";
import FiveBars from "./../../assets/svgs/signals/fiveBars.svg";
import SixBars from "./../../assets/svgs/signals/sixBars.svg";
import TotalEndUsers from "./../../assets/svgs/dashboard/totalEndUsers.svg";
import RelatedEndUsers from "./../../assets/svgs/dashboard/relatedEndUsers.svg";
import PieChartComponent from "../Charts/Pie";
import { getDashboardRoute } from "../../helpers/apiRoutes";
type Props = {
  isLoggedIn?: boolean;
};
const DASHBOARD_STATS_ROUTE = getDashboardRoute();

const AdminDashboardComponent: React.FC<Props> = ({}) => {
  const [
    postStatsApiData,
    statsApiResponse,
    statsError,
    statsLoading,
    statsMessage,
    statsErrorCount,
  ] = useAxiosPost<ResponseProp>(`${DASHBOARD_STATS_ROUTE}`);
  const [statsData, setStatsData] = useState<DashboardStats>({
    badSignal: 0,
    freeEndUsers: 0,
    healthSignal: 0,
    tooHighSignal: 0,
    totalEndUsers: 0,
    totalInActiveOnts: 0,
    totalInInvisibleOnts: 0,
    totalInProcessOnts: 0,
    totalOfflineOnts: 0,
    totalOnlineOnts: 0,
    totalRegisteredOnts: 0,
    totalUpsetOnts: 0,
    upsetSignal: 0,
    totalRelatedUsers: 0,
  });
  useEffect(() => {
    if (statsError !== null && statsErrorCount !== 0) {
      notifyError(statsError);
    }
  }, [statsError, statsErrorCount]);
  useEffect(() => {
    if (statsApiResponse !== null) {
      const responseData: any = statsApiResponse.responseData;
      const {
        badSignal = 0,
        freeEndUsers = 0,
        healthSignal = 0,
        tooHighSignal = 0,
        totalEndUsers = 0,
        totalInActiveOnts = 0,
        totalInInvisibleOnts = 0,
        totalInProcessOnts = 0,
        totalOfflineOnts = 0,
        totalOnlineOnts = 0,
        totalRegisteredOnts = 0,
        totalUpsetOnts = 0,
        upsetSignal = 0,
        totalRelatedUsers = 0,
      } = responseData;
      setStatsData({
        badSignal,
        freeEndUsers,
        healthSignal,
        tooHighSignal,
        totalEndUsers,
        totalInActiveOnts,
        totalInInvisibleOnts,
        totalInProcessOnts,
        totalOfflineOnts,
        totalOnlineOnts,
        totalRegisteredOnts,
        totalUpsetOnts,
        upsetSignal,
        totalRelatedUsers,
      });
    }
  }, [statsApiResponse]);
  useEffect(() => {
    postStatsApiData({});
  }, []);

  const prepareOntHealthStats = (): pieData[] => {
    const { totalOnlineOnts, totalOfflineOnts, totalUpsetOnts } = statsData;
    const total = totalOnlineOnts + totalOfflineOnts + totalUpsetOnts;
    const onlinePercent = ((totalOnlineOnts / total) * 100).toFixed(1);
    const offlinePercent = ((totalOfflineOnts / total) * 100).toFixed(1);
    const upsetPercent = ((totalUpsetOnts / total) * 100).toFixed(1);
    const data = [
      {
        // title: "Offline",
        value: Number(offlinePercent),
        color: "#ff262b",
      },
      {
        // title: "Upset",
        value: Number(upsetPercent),
        color: "#ffd120",
      },
      {
        // title: "Online",
        value: Number(onlinePercent),
        color: "#11dc00",
      },
    ];
    return data;
  };
  const prepareProcessOntsStats = (): pieData[] => {
    const { totalInActiveOnts, totalInInvisibleOnts, totalInProcessOnts } =
      statsData;
    const total = totalInActiveOnts + totalInInvisibleOnts + totalInProcessOnts;
    const inactivePercent = ((totalInActiveOnts / total) * 100).toFixed(1);
    const inprocessPercent = ((totalInProcessOnts / total) * 100).toFixed(1);
    const data = [
      {
        // title: "Offline",
        value: Number(inactivePercent),
        color: "#b7b7b7",
      },

      {
        // title: "Online",
        value: Number(inprocessPercent),
        color: "#1e5cff",
      },
    ];
    return data;
  };
  const prepareSignalStats = (): pieData[] => {
    const { healthSignal, upsetSignal, badSignal, tooHighSignal } = statsData;
    const total = healthSignal + upsetSignal + badSignal + tooHighSignal;
    const healthPercent = ((healthSignal / total) * 100).toFixed(1);
    const upsetPercent = ((upsetSignal / total) * 100).toFixed(1);
    const badPercent = ((badSignal / total) * 100).toFixed(1);
    const tooHighPercent = ((tooHighSignal / total) * 100).toFixed(1);
    const data = [
      {
        // title: "Offline",
        value: Number(healthPercent),
        color: "#00cc60",
      },
      {
        // title: "Upset",
        value: Number(upsetPercent),
        color: "#fe7f00",
      },
      {
        // title: "Online",
        value: Number(badPercent),
        color: "#ff4e12",
      },
      {
        // title: "Online",
        value: Number(tooHighPercent),
        color: "#1e5cff",
      },
    ];
    return data;
  };

  return (
    <>
      <ToastContainer />
      <Container
        style={{
          background: "white",
        }}
      >
        <Row style={{ paddingLeft: "48px" }}>
          {/* <h3>Stats</h3> */}
          {/* Basic Statuses */}
          <Row
            className="mt-5"
            // style={{ paddingLeft: "48px", paddingRight: "20px" }}
          >
            <Col
              className="single-stats-item-container"
              md={3}
              lg={3}
              xl={3}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-up">
                  Up Status{" "}
                </span>
                <img
                  src={UpStatus}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-up">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.totalOnlineOnts}
                </span>
              </div>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>

            <Col
              className="single-stats-item-container"
              md={3}
              lg={3}
              xl={3}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-down">
                  Down Status{" "}
                </span>
                <img
                  src={DownStatus}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-down">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.totalOfflineOnts}
                </span>
              </div>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>
            <Col
              className="single-stats-item-container"
              md={3}
              lg={3}
              xl={3}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-upset-dashboard">
                  Upset Status{" "}
                </span>
                <img
                  src={UpSetStatus}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-upset-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.totalUpsetOnts}
                </span>
              </div>
            </Col>
          </Row>

          {/* Processing statuses */}
          <Row className="mt-5">
            <Col
              className="single-stats-item-container"
              md={3}
              lg={3}
              xl={3}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-inactive-dashboard">
                  InActive Status{" "}
                </span>
                <img
                  src={InActiveStatus}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-inactive-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.totalInActiveOnts}
                </span>
              </div>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>

            <Col
              className="single-stats-item-container"
              md={3}
              lg={3}
              xl={3}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-inprocess-dashboard">
                  In Process Status{" "}
                </span>
                <img
                  src={InProcessStatus}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-inprocess-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.totalInProcessOnts}
                </span>
              </div>
            </Col>
          </Row>
          {/* Signals */}
          <Row className="mt-5">
            <Col
              className="single-stats-item-container"
              md={2}
              lg={2}
              xl={2}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-healthy-signals-dashboard">
                  Healthy Signals{" "}
                </span>
                <img
                  src={FiveBars}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-healthy-signals-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.healthSignal}
                </span>
              </div>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>

            <Col
              className="single-stats-item-container"
              md={2}
              lg={2}
              xl={2}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-upset-signals-dashboard">
                  Upset Signals{" "}
                </span>
                <img
                  src={ThreeBars}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-upset-signals-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.upsetSignal}
                </span>
              </div>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>

            <Col
              className="single-stats-item-container"
              md={2}
              lg={2}
              xl={2}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-bad-signals-dashboard">
                  Bad Signals{" "}
                </span>
                <img
                  src={OneBar}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-bad-signals-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.badSignal}
                </span>
              </div>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>

            <Col
              className="single-stats-item-container "
              md={2}
              lg={2}
              xl={2}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-tooHigh-signals-dashboard">
                  Too High{" "}
                </span>
                <img
                  src={SixBars}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-tooHigh-signals-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of ONTs{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.tooHighSignal}
                </span>
              </div>
            </Col>
          </Row>
          {/* End Users */}
          <Row className="mt-5">
            <Col
              className="single-stats-item-container"
              md={3}
              lg={3}
              xl={3}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-totalEndUsers-dashboard">
                  EndUsers{" "}
                </span>
                <img
                  src={TotalEndUsers}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-totalEndUsers-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of Users{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.totalEndUsers}
                </span>
              </div>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>

            <Col
              className="single-stats-item-container"
              md={3}
              lg={3}
              xl={3}
              sm={12}
              xs={12}
            >
              <h3>
                {" "}
                <span className="single-stats-item-container-status-text color-relatedEndUsers-dashboard">
                  Related EndUsers{" "}
                </span>
                <img
                  src={RelatedEndUsers}
                  className="stats-image-style image-float-right"
                />
              </h3>
              <div className="single-stats-item-container-bottom-counter bg-relatedEndUsers-dashboard">
                <span className="single-stats-item-bottom-counter-text pl-15">
                  No. Of Users{" "}
                </span>
                <span className="single-stats-item-bottom-counter-text image-float-right pr-15">
                  {statsData.totalRelatedUsers}
                </span>
              </div>
            </Col>
          </Row>
          {/* Charts */}
          <Row
            className="mt-5"
            style={{
              minHeight: "300px",
              height: "300px",
            }}
          >
            <Col className="" md={3} lg={3} xl={3} sm={12} xs={12}>
              <div className="pie-chart-parent-container">
                <PieChartComponent pieChartData={prepareOntHealthStats()} />
              </div>
              <Row className="justify-content-center mt-3">
                <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="online-dashboard-circle"></span>{" "}
                    <span className="">Up</span>
                  </p>
                </Col>
                <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="offline-dashboard-circle"></span>{" "}
                    <span className="">Down</span>
                  </p>
                </Col>
                <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="upset-dashboard-circle"></span>{" "}
                    <span className="">Upset</span>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>

            <Col className="" md={3} lg={3} xl={3} sm={12} xs={12}>
              <div className="pie-chart-parent-container">
                <PieChartComponent pieChartData={prepareProcessOntsStats()} />
              </div>
              <Row className="justify-content-center mt-3">
                <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="inactive-dashboard-circle"></span>{" "}
                    <span className="">InActive</span>
                  </p>
                </Col>

                <Col md={4} lg={4} sm={12} xs={12} xl={4} xxl={4}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="inprocess-dashboard-circle"></span>{" "}
                    <span className="">In Process</span>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={1} lg={1} xl={1} sm={12} xs={12}></Col>

            <Col className="" md={3} lg={3} xl={3} sm={12} xs={12}>
              <div className="pie-chart-parent-container">
                <PieChartComponent pieChartData={prepareSignalStats()} />
              </div>

              <Row className="justify-content-center mt-3">
                <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="healthy-signal-dashboard-circle"></span>{" "}
                    <span className="">Healthy</span>
                  </p>
                </Col>
                <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="upset-signal-dashboard-circle"></span>{" "}
                    <span className="">Upset</span>
                  </p>
                </Col>
                <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="bad-signal-dashboard-circle"></span>{" "}
                    <span className="">Bad</span>
                  </p>
                </Col>
                <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <span className="too-high-signal-dashboard-circle"></span>{" "}
                    <span className="">Too High</span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};
export default AdminDashboardComponent;
