import React from "react";
import { Line } from "react-chartjs-2";

const AreaChatComponent = (props: any) => {
  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: `Live Data Speed Chart`,
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
        beginAtZero: true,
      },
      y1: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
      },
    },
  };
  return <Line options={options} data={props.data} />;
};
export default AreaChatComponent;
