import CreateSlotComponent from "../components/admin/CreateSlot";

const CreateSlotPage = () => {
  return (
    <>
      <CreateSlotComponent />
    </>
  );
};
export default CreateSlotPage;
