import AllDealersComponent from "../components/admin/AllDealers";

const AllDealersPage = () => {
  return (
    <>
      <AllDealersComponent />
    </>
  );
};
export default AllDealersPage;
