import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { useParams } from "react-router-dom";
import SingleSlotCard from "../Cards/SingleSlotCard";
import SinglePonPortCard from "../Cards/SinglePonPortCard";
import { confirmAlert } from "react-confirm-alert"; // Import
import { getPonPortRoute } from "../../helpers/apiRoutes";
import { getUserType } from "../../helpers/userDetails";
const PON_PORTS_ROUTE = getPonPortRoute();
const userType = getUserType();
const BrowsePonPortsComponents: React.FC<GP> = ({}) => {
  const [
    getAllSlotsData,
    slotsResponseData,
    slotError,
    slotLoading,
    oltMessage,
    slotErrorCount,
  ] = useAxiosPost<BrowsePonPortResponseProp>(`${PON_PORTS_ROUTE}`);

  const [
    requestRebootOfPonPort,
    responseRebootOfPonPort,
    rebootPonPortError,
    rebootLoading,
    rebootMessage,
    rebootErrorCount,
  ] = useAxiosPost<RebootAllPonPortProp>(
    "netflow/requests/requestMultipleOntsOfAPonPortOrWholePonPortReboot",
    true
  );

  const [
    requestDeleteOfPonPort,
    responseDeleteOfPonPort,
    deletePonPortError,
    deleteLoading,
    deleteMessage,
    deleteErrorCount,
  ] = useAxiosPost<DeleteAllPonPortProp>(
    "netflow/requests/requestMultipleOntsOfAPonPortOrWholePonPortDelete",
    true
  );
  const handleRebootOfWholePonPort = (ponPortId: number | string) => {
    confirmAlert({
      title: " Reboot",
      message: "Do you Want to Reboot All ONTs of this PonPort ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Delete");
            requestRebootOfPonPort({
              ponPortId: ponPortId,
              rebootAllOntsOfThisPonPort: true,
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const handleDeleteOfWholePonPort = (ponPortId: number | string) => {
    confirmAlert({
      title: "Delete",
      message: "Do you Want to Delete All ONTs of this PonPort ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Delete");
            requestDeleteOfPonPort({
              ponPortId: ponPortId,
              deleteUnAuthAllOntsOfThisPonPort: true,
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  useEffect(() => {
    if (responseRebootOfPonPort !== null) {
      notifySuccess(`Pon Port Rebooted`);
      getAllSlotsData({
        intPkSlotID: Number(intPkSlotID),
        intPkOltID: Number(intPkOltID),
        skipSlot: Number(skipSlot) === 1 ? true : false,
      });
    }
  }, [responseRebootOfPonPort]);

  useEffect(() => {
    if (responseDeleteOfPonPort !== null) {
      notifySuccess(`Pon Port Onts Deleted`);
      getAllSlotsData({
        intPkSlotID: Number(intPkSlotID),
        intPkOltID: Number(intPkOltID),
        skipSlot: Number(skipSlot) === 1 ? true : false,
      });
    }
  }, [responseDeleteOfPonPort]);

  const [
    getDealersData,
    dealersResponseData,
    dealersError,
    dealersLoading,
    dealersMessage,
    dealersErrorCount,
  ] = useAxiosPost<ResponseProp>("dealers/getMyDealers");

  const [allPonPorts, setAllPonPorts] = useState<any>([]);
  const [dealers, setAllDealers] = useState<any>([]);
  const { intPkSlotID, oltSlot, oltName, skipSlot, intPkOltID, oltType } =
    useParams();
  useEffect(() => {
    getAllSlotsData({
      intPkSlotID: Number(intPkSlotID),
      intPkOltID: Number(intPkOltID),
      skipSlot: Number(skipSlot) === 1 ? true : false,
    });
    if (userType === "ADMIN") {
      getDealersData({});
    }
  }, [intPkSlotID]);
  useEffect(() => {
    if (slotError !== null && slotErrorCount !== 0) {
      notifyError(slotError);
    }
  }, [slotError, slotErrorCount]);
  useEffect(() => {
    if (slotsResponseData !== null) {
      const { responseData } = slotsResponseData;
      if (responseData && Array.isArray(responseData))
        responseData.sort(
          (a: any, b: any) => a.intPkPonPortID - b.intPkPonPortID
        );
      setAllPonPorts(responseData || []);
    }
  }, [slotsResponseData]);

  useEffect(() => {
    if (dealersResponseData !== null) {
      const { responseData } = dealersResponseData;
      setAllDealers(responseData || []);
    }
  }, [dealersResponseData]);

  const prepareHealthStatsForPonPort = (healthStats: any): pieData[] => {
    const {
      totalRegisteredOnts,
      totalOfflineOnts,
      totalUpsetOnts,
      totalOnlineOnts,
      totalInActiveOnts,
    } = healthStats;
    const data = [
      {
        // title: "Offline",
        value: Number(totalOfflineOnts) || 0,
        color: "red",
      },
      {
        // title: "Upset",
        value: Number(totalUpsetOnts) || 0,
        color: "yellow",
      },
      {
        // title: "Online",
        value: Number(totalOnlineOnts) || 0,
        color: "green",
      },
      {
        // title: "InActive",
        value: Number(totalInActiveOnts) || 0,
        color: "grey",
      },
    ];
    return data;
  };
  const reloadTheApi = () => {
    getAllSlotsData({
      intPkSlotID: Number(intPkSlotID),
      intPkOltID: Number(intPkOltID),
      skipSlot: Number(skipSlot) === 1 ? true : false,
    });
  };
  return (
    <>
      <ToastContainer />
      <Container fluid className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="12" md="12" xl={12} xxl={12}>
            <h3 className="mb-5 text-center">
              {`${oltName || ""} - Slot# ${oltSlot || 1} - `} PonPorts
            </h3>
            <Container>
              <Row>
                {allPonPorts.map((pp: any, index: number) => {
                  return (
                    <Col sm={12} xs={12} lg="6" md="6" xl={4} xxl={4}>
                      <SinglePonPortCard
                        key={Math.random()}
                        healthStats={prepareHealthStatsForPonPort(
                          pp.ponPortHealthStats
                        )}
                        slotNumber={pp.oltSlot}
                        dealers={dealers}
                        intPkUserID={pp.intPkUserID || ""}
                        oltName={oltName}
                        oltPonPort={pp.oltPonPort}
                        ponPortHealthStats={pp.ponPortHealthStats}
                        intPkOltID={pp.intPkOltID}
                        intPkSlotID={pp.intPkSlotID}
                        ponPortName={pp.ponPortName}
                        intPkPonPortID={pp.intPkPonPortID}
                        reloadTheApi={reloadTheApi}
                        oltType={Number(oltType)}
                        handleRebootOfWholePonPort={handleRebootOfWholePonPort}
                        handleDeleteOfWholePonPort={handleDeleteOfWholePonPort}
                        isDisabled={pp.isDisabled}
                        oltPonPortSfpMilliAmp={pp.oltPonPortSfpMilliAmp}
                        oltPonPortSfpTemperature={pp.oltPonPortSfpTemperature}
                        oltPonPortSfpTxSignal={pp.oltPonPortSfpTxSignal}
                        oltPonPortSfpVoltage={pp.oltPonPortSfpVoltage}
                        oltPonPortStatus={pp.oltPonPortStatus}
                        defaultPonPortVLAN={Number(pp.defaultPonPortVLAN)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BrowsePonPortsComponents;
