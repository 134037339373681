import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import CapoltLogo from "./../../assets/svgs/logos/capolt-logo.svg";
import RouterImage from "./../../assets/svgs/logos/router-image.svg";
import "./../../assets/styles/login.css";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";

type Props = {
  isLoggedIn?: boolean;
};

const NOCORCOOLoginAsCompanyComponent: React.FC<Props> = ({}) => {
  const { intPkCompanyID } = useParams();
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<CompanyLoginType>("noc/auth/LoginCompanyAdmin");

  useLayoutEffect(() => {
    if (sessionStorage.getItem("CompanyAdminToken")) {
      window.location.href = "/";
      return;
    }
    postLoginData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      notifyError(loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      console.log({ loginResponseData });
      const { authTokenString, admin } = loginResponseData.responseData;
      sessionStorage.setItem("CompanyAdminToken", authTokenString || "");
      sessionStorage.setItem("CompanyAdminType", admin?.userType || "");
      //   localStorage.setItem("userType", userType || "");
      window.location.href = "/";
    }
  }, [loginResponseData]);

  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };

  return (
    <>
      <ToastContainer />
      <ThreeDots
        height="200"
        width="200"
        radius="50"
        color="#5ac8fa"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </>
  );
};
export default NOCORCOOLoginAsCompanyComponent;
