import ProcessingOLTsComponentNOC from "../components/NOC/ProcessingOLTsNOC";

const ProcessingOltsPage = () => {
  const role = localStorage.getItem("userType") || "";
  const getPageRoleWise = () => {
    if (role === "NOC") {
      return <ProcessingOLTsComponentNOC />;
    }
    if (role === "SUPER_COO") {
      return <ProcessingOLTsComponentNOC />;
    }
    return <></>;
  };
  return <>{getPageRoleWise()}</>;
};
export default ProcessingOltsPage;
