import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
type Props = {
  isLoggedIn?: boolean;
};

const CreateCompanyWithAdminComponent: React.FC<Props> = ({}) => {
  const [
    postCreateAdminWithCompanyData,
    adminWithCompanyResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateAdminWithAdminProp>("superCOO/createCompanyWithAdmin");

  const CreateCompanyWithAdminSchema = yup.object().shape({
    companyName: yup.string().required(),
    userFullname: yup.string().required(),
    username: yup.string().required(),
    password: yup.string().required(),
    contactNumber: yup.string().required(),
  });

  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (adminWithCompanyResponseData !== null) {
      const { message } = adminWithCompanyResponseData;
      if (message) {
        notifySuccess(message);
      }
      setTimeout(() => {
        window.location.href = "/all-companies";
      }, 5000);
    }
  }, [adminWithCompanyResponseData]);

  const [inputData, setInputData] = useState<CreateAdminWithAdminProp>({
    companyName: "",
    userFullname: "",
    username: "",
    contactNumber: "",
    password: "",
  });
  const handleCreate = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateAdminWithAdminProp = inputData;
    try {
      const isValid = await CreateCompanyWithAdminSchema.validate(dataToSend);
      postCreateAdminWithCompanyData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };
  const renderInput = (name: string, placeholder: string, type: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={`${placeholder}`}
          type={type}
          value={inputData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="6" md="6" xl="6" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Create New Company With Admin
            </Card.Title>
            <Form className="mt-4">
              {renderInput(
                `companyName`,
                "Enter Company Name",
                `Enter Company Name`
              )}
              {renderInput(`userFullname`, "Enter User Full Name", `text`)}
              {renderInput(`username`, "Enter UserName", `text`)}
              {renderInput(`password`, "Enter Password", `password`)}

              {renderInput(`contactNumber`, "Enter Contact Number", `text`)}
              <div className="text-center">
                <button
                  onClick={handleCreate}
                  disabled={apiLoading}
                  className="button-primary "
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default CreateCompanyWithAdminComponent;
