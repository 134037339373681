import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import CapoltLogo from "./../../assets/svgs/icons/VerificationIcon.svg";
import VerificationMessageUrdu from "./../../assets/svgs/icons/emailVerificationUrduMessage.svg";
import RouterImage from "./../../assets/svgs/logos/router-image.svg";
import "./../../assets/styles/login.css";
import { InputGroup, FormControl } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { notifySuccess } from "../toast/Success";
type Props = {
  isLoggedIn?: boolean;
};

const EmailAndPhoneNumberVerificationComponent: React.FC<Props> = ({}) => {
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<VerifyEmailAndPhoneProp>("users/auth/updateEmailAndPhone");

  const loginValidationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Email Is required"),
    phone: yup.string().required("Phone Number is required"),
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!localStorage.getItem("goForEmailAndPhoneVerification")) {
      window.location.href = "/login";
    }
    if (localStorage.getItem("auth_token")) {
      window.location.href = "/";
    }
  }, []);
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      notifyError(loginError);
      setIsDisabled(false);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      console.log({ loginResponseData });
      notifySuccess(`Email And Phone Updated Successfully`);
      localStorage.setItem("goToVerification", "1");
      localStorage.removeItem("goForEmailAndPhoneVerification");
      setTimeout(() => {
        window.location.href = "/verify";
      }, 3000);
    }
  }, [loginResponseData]);

  const [otp, setOtp] = useState<any>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const handleLogin = async (event: any) => {
    event.preventDefault();

    try {
      const isValid = await loginValidationSchema.validate({
        email: email,
        phone: phone,
      });
      setIsDisabled(true);
      postLoginData({
        intPkUserID: localStorage.getItem("signedUpUser") || null,
        email,
        phone,
      });
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };
  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };

  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col
            className="routerContainer"
            md={5}
            lg={5}
            xxl={5}
            xl={5}
            sm={5}
            xs={5}
          >
            <Row>
              <Col md={1} lg={1} xl={1} xxl={1} sm={1} xs={1}></Col>
              <Col
                className="mt-10-custom"
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                sm={10}
                xs={11}
              >
                <h1>Welcome To Capolt</h1>
                <h6 style={{ fontWeight: "normal" }}>Login To Your Account</h6>
              </Col>
              <img src={RouterImage} className="h-450" />
            </Row>
          </Col>
          <Col
            className="mt-6-custom"
            md={7}
            lg={7}
            xxl={7}
            xl={7}
            sm={7}
            xs={7}
          >
            <div>
              <img src={VerificationMessageUrdu} />
            </div>
            <img src={CapoltLogo} />
            <h3>Email Verification</h3>
            <p style={{ color: "#696969" }}>We Need To Verify Your Email</p>
            <div>
              <Form>
                <div className="login-form-main-container ">
                  <Form.Group
                    className="mb-3 min-w-100"
                    controlId="formBasicEmail"
                  >
                    <Form.Control
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 min-w-100"
                    controlId="formPhoneBasic"
                  >
                    <Form.Control
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="text"
                      placeholder="Enter Phone"
                    />
                  </Form.Group>
                  <button
                    disabled={isDisabled}
                    className="button-primary"
                    onClick={handleLogin}
                  >
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default EmailAndPhoneNumberVerificationComponent;
