import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDefaultMAc } from "../../helpers/macAddress";
const AllEndUsersComponent: React.FC<GP> = ({}) => {
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");

  const [
    getMyEndUsersData,
    endUsersResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("endUsers/getMyEndUsers");
  const [allEndUsers, setAllEndUsers] = useState<any>([]);
  const [actualEndUsers, setActualEndUsers] = useState<any>([]);

  useEffect(() => {
    getMyEndUsersData({});
  }, []);
  useEffect(() => {
    if (endUsersResponse !== null) {
      const { responseData } = endUsersResponse;
      setAllEndUsers(responseData || []);
      setActualEndUsers(responseData || []);
    }
  }, [endUsersResponse]);
  useEffect(() => {
    handleSearch();
  }, [searchString]);
  const handleSearch = () => {
    if (searchString === "") {
      setAllEndUsers(actualEndUsers);
      return;
    }
    let searchedEndUsers = actualEndUsers.filter((item: any) => {
      if (
        item.endUserFullName.toLowerCase().indexOf(searchString.toLowerCase()) >
          -1 ||
        item.endUserPhoneNumber
          .toLowerCase()
          .indexOf(searchString.toLowerCase()) > -1 ||
        item?.binding?.ponNumber
          ?.toLowerCase()
          ?.indexOf(searchString.toLowerCase()) > -1
      ) {
        return item;
      }
    });
    setAllEndUsers(searchedEndUsers);
  };
  const getPonNumber = (binding: any) => {
    if (binding.isOntEponOrGpon == 2) {
      return binding?.ponNumber;
    }
    if (binding.isOntEponOrGpon == 1) {
      return formatDefaultMAc(binding?.ontEponMacAddressID || "");
    }
    if (binding.isOntEponOrGpon == 3) {
      return binding?.asciiHexaPonNumber;
    }
    return "";
  };
  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All End Users
              <span style={{ float: "right" }}>
                <Link className="btn btn-primary" to="/create-end-user">
                  {" "}
                  New End-User
                </Link>
              </span>
            </Card.Title>
            <Row className="mt-4">
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                <div className="input-group">
                  <input
                    className="form-control"
                    placeholder={`Enter User FullName Or Contact Number Or Pon Number`}
                    type="text"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSearch();
                      }}
                      className="button-primary-search"
                      type="button"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">User PPPoEID</th>
                      <th className="table-header-standard">Full Name</th>
                      <th
                        className="table-header-standard"
                        style={{ maxWidth: "200px" }}
                      >
                        Address
                      </th>
                      <th className="table-header-standard">Contact Number</th>
                      <th className="table-header-standard">Area Name</th>
                      <th className="table-header-standard">
                        Assigned ONT PonNumber
                      </th>
                      <th className="table-header-standard">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allEndUsers.map((user: any) => {
                      return (
                        <tr key={user.intPkEndUserID}>
                          <td>{user.endUserPPPoEUserID}</td>
                          <td>{user.endUserFullName}</td>
                          <td style={{ maxWidth: "200px" }}>
                            {user.endUserAddress}
                          </td>
                          <td>{user.endUserPhoneNumber}</td>
                          <td>{user.areaName}</td>
                          <td
                            className={
                              user.binding !== null
                                ? "cursor-pointer-class"
                                : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              if (user.binding == null) {
                                return;
                              }
                              navigate(
                                `/single-registration-by-id/${user.binding.oltName}/${user.binding.intPkOntBindingID}/${user.binding.intPkOltID}`
                              );
                            }}
                          >
                            {user.binding !== null
                              ? getPonNumber(user.binding)
                              : ""}
                          </td>
                          <td>{formatDate(user.dateCreated)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllEndUsersComponent;
