import { TitleContext } from "../../App";
import { useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./index.css";
import { getTitleFromPaths } from "../../helpers/getTitleFromPath";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import BackButtonSVG from "./../../assets/svgs/back/back.svg";
import { handleLogout } from "../../helpers/userDetails";
//changes
function OffcanvasExample() {
  const location = useLocation();
  const path = getTitleFromPaths(location.pathname);
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      {["md"].map((expand: any) => (
        <Navbar
          key={expand}
          style={{
            background: "#00ACEE",
          }}
          expand={expand}
          className="mb-3 navbar-sticky"
        >
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="nav-bar-custom-style"
            >
              <div
                onClick={(e) => {
                  handleGoBack();
                }}
                style={{
                  height: "50px",
                  width: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={BackButtonSVG}
                  style={{ width: "100px", maxHeight: "40px" }}
                />
              </div>
              <Offcanvas.Header
                style={{
                  background: "#00ACEE",
                  minHeight: "120px",
                }}
                closeButton
              >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Capolt
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body
                style={{
                  background: "#00ACEE",
                }}
              >
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavDropdown
                    title="User"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/profile-settings">
                      Settings
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action4"
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleLogout();
                        window.location.href = "/login";
                      }}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;
