import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Row, Col, Table } from "react-bootstrap";

import { toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifySuccess } from "../toast/Success";
import { notifyError } from "../toast/Error";
import { formatDate } from "../../helpers/date";
const PushVlanToVsolEponOntModal: React.FC<PushVlanToOntModalProp> = ({
  handleClose,
  show,
  intPkOntRegisterationID,
  intPkOltID,
}) => {
  const [
    linkBindingToEndUserPost,
    linkBindingToEndUserResponse,
    linkBindingToEndUserError,
    linkBindingToEndUsersLoading,
    linkBindingToEndUsersMessage,
    linkBindingToEndUsersErrorCount,
  ] = useAxiosPost<CreateVlanPushRequest>("vlans/pushVlanToOntRequest");
  //getLatestPushVlanRequest
  const [
    getApiData,
    apiResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<VlansByOLTProp>("vlans/getAllVlanProfilesByOLT");
  const [
    getLatestData,
    latestDataResponse,
    latestDataError,
    latestDataLoading,
    latestDataMessage,
    latestDataErrorCount,
  ] = useAxiosPost<LatestDataForVlanPushRequest>(
    "vlans/getLatestPushVlanRequest"
  );

  const [allVlans, setAllVlans] = useState<any>([]);

  useEffect(() => {
    getApiData({ intPkOltID: Number(intPkOltID) });
    getLatestData({ intPkOntRegisterationID });
    const interval = setInterval(() => {
      // if (requestData?.requestStatus !== "PENDING") {
      //   clearInterval(interval);
      // }
      getLatestData({ intPkOntRegisterationID });
    }, 2000);
    return () => {
      // Clear the interval when the component is about to unmount
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponseData !== null) {
      const { responseData } = apiResponseData;
      setAllVlans(responseData || []);
    }
  }, [apiResponseData]);

  useEffect(() => {
    if (latestDataError !== null && latestDataErrorCount !== 0) {
      notifyError(latestDataError);
    }
  }, [latestDataError, latestDataErrorCount]);
  useEffect(() => {
    if (latestDataResponse !== null) {
      const responseData: any = latestDataResponse.responseData;
      const logs = responseData.logs;
      const request = responseData.request;
      setRequestHistory(logs || []);
      setRequestData(request || {});
    }
  }, [latestDataResponse]);

  const [requestHistory, setRequestHistory] = useState<any>([]);
  const [requestData, setRequestData] = useState<any>([]);
  const [isAllDisabled, setIsAllDisabled] = useState<boolean>(true);
  const [portsHandler, setPortsHandler] = useState({
    p1: false,
    p2: false,
    p3: false,
    p4: false,
  });
  const [config, setConfig] = useState<any>({
    all: {
      vlanValue: "",
      vlanType: "",
    },
    p1: {
      vlanValue: "",
      vlanType: "",
    },
    p2: {
      vlanValue: "",
      vlanType: "",
    },
    p3: {
      vlanValue: "",
      vlanType: "",
    },
    p4: {
      vlanValue: "",
      vlanType: "",
    },
  });
  const handlePushVlanToOnt = () => {
    const data = [];

    if (isAllDisabled) {
      const all = config.all;
      const { vlanValue, vlanType } = all;
      if (vlanType !== "TRANSPARENT") {
        if (vlanType === "" || vlanValue == "") {
          notifyError(`You need to select both Vlan Type and Vlan Value`);
          return;
        }
      }

      [1, 2, 3, 4].map((item) => {
        data.push({
          port: item,
          vlanType,
          vlanValue: vlanType === "TRANSPARENT" ? "" : vlanValue,
        });
      });
      console.log("we are good");
    }
    const { p1, p2, p3, p4 } = config;
    if (portsHandler.p1) {
      //if it's checked.
      const { vlanValue, vlanType } = p1;
      if (vlanType !== "TRANSPARENT") {
        if (vlanType === "" || vlanValue == "") {
          notifyError(
            `You need to select both Vlan Type and Vlan Value For ETH #1`
          );
          return;
        }
      }

      data.push({
        port: 1,
        vlanType,
        vlanValue: vlanType === "TRANSPARENT" ? "" : vlanValue,
      });
    }
    if (portsHandler.p2) {
      //if it's checked.
      const { vlanValue, vlanType } = p2;
      if (vlanType !== "TRANSPARENT") {
        if (vlanType === "" || vlanValue == "") {
          notifyError(
            `You need to select both Vlan Type and Vlan Value For ETH # 2`
          );
          return;
        }
      }

      data.push({
        port: 2,
        vlanType,
        vlanValue: vlanType === "TRANSPARENT" ? "" : vlanValue,
      });
    }
    if (portsHandler.p3) {
      //if it's checked.
      const { vlanValue, vlanType } = p3;
      if (vlanType !== "TRANSPARENT") {
        if (vlanType === "" || vlanValue == "") {
          notifyError(
            `You need to select both Vlan Type and Vlan Value For ETH # 3`
          );
          return;
        }
      }
      data.push({
        port: 3,
        vlanType,
        vlanValue: vlanType === "TRANSPARENT" ? "" : vlanValue,
      });
    }
    if (portsHandler.p4) {
      //if it's checked.
      const { vlanValue, vlanType } = p4;

      if (vlanType !== "TRANSPARENT") {
        if (vlanType === "" || vlanValue == "") {
          notifyError(
            `You need to select both Vlan Type and Vlan Value For ETH # 4`
          );
          return;
        }
      }
      data.push({
        port: 4,
        vlanType,
        vlanValue: vlanType === "TRANSPARENT" ? "" : vlanValue,
      });
    }
    linkBindingToEndUserPost({
      intPkOntRegisterationID,
      vlansArray: data,
    });
    console.log({ data });
  };
  useEffect(() => {
    if (
      linkBindingToEndUserError !== null &&
      linkBindingToEndUsersErrorCount !== 0
    ) {
      notifyError(linkBindingToEndUserError);
    }
  }, [linkBindingToEndUserError, linkBindingToEndUsersErrorCount]);

  useEffect(() => {
    if (linkBindingToEndUserResponse !== null) {
      const message: any = linkBindingToEndUserResponse.message;
      notifySuccess(message);
      getLatestData({ intPkOntRegisterationID });

      // handleClose(true);
    }
  }, [linkBindingToEndUserResponse]);
  const checkIfControlDisabled = (parent: boolean, child: boolean): boolean => {
    if (child === false && parent === false) {
      return true;
    }
    if (parent === true && child === true) {
      return false;
    }
    if (child === true) {
      return false;
    }
    console.log({ child, parent });
    if (parent === true && child === false) {
      return true;
    }
    return false;
  };
  const showErrorMessage = (errorMessage: any) => {
    if (
      errorMessage == "undefined" ||
      errorMessage == null ||
      errorMessage == undefined
    ) {
      return "-";
    }
    return errorMessage;
  };
  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Push Vlan <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col
                  className="push-vlan-to-ont-modal-heading"
                  md={11}
                  lg={11}
                  sm={11}
                  xxl={11}
                  xs={11}
                >
                  Select Ports
                </Col>
              </Row>
            </Col>
            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col
                  className="push-vlan-to-ont-modal-heading"
                  md={11}
                  lg={11}
                  sm={11}
                  xxl={11}
                  xs={11}
                >
                  Select Action
                </Col>
              </Row>
            </Col>
            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col
                  className="push-vlan-to-ont-modal-heading"
                  md={11}
                  lg={11}
                  sm={11}
                  xxl={11}
                  xs={11}
                >
                  Select Vlan
                </Col>
              </Row>
            </Col>
          </Row>
          {/* All Start */}
          <Row className="mt-5 push-vlan-modal-form-row">
            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col
                  className="push-vlan-modal-form-row-all-port-label"
                  md={11}
                  lg={11}
                  sm={11}
                  xxl={11}
                  xs={11}
                >
                  <input
                    onChange={(e) => {
                      console.log({ val: e.target.checked });
                      if (e.target.checked === true) {
                        setPortsHandler({
                          p1: false,
                          p2: false,
                          p3: false,
                          p4: false,
                        });
                      }
                      setIsAllDisabled(e.target.checked);
                    }}
                    type="checkbox"
                    checked={isAllDisabled}
                    style={{ marginRight: "10px" }}
                  />
                  All
                </Col>
              </Row>
            </Col>

            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                  <Form.Select
                    disabled={!isAllDisabled}
                    value={config.all.vlanType}
                    onChange={(e) => {
                      const value = e.target.value;
                      const all = config.all;
                      const newValue = {
                        vlanType: value,
                        vlanValue: all.vlanValue,
                      };
                      setConfig({
                        ...config,
                        all: newValue,
                      });
                    }}
                  >
                    <option value={""}>Choose</option>
                    <option value={"TAG"}>Tag</option>
                    <option value={"TRANSPARENT"}>Transparent</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            {config.all.vlanType !== "TRANSPARENT" ? (
              <>
                <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
                  <Row>
                    <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                    <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                      <Form.Select
                        value={config.all.vlanValue}
                        onChange={(e) => {
                          const value = e.target.value;
                          const all = config.all;
                          const newValue = {
                            vlanType: all.vlanType,
                            vlanValue: value,
                          };
                          setConfig({
                            ...config,
                            all: newValue,
                          });
                        }}
                        disabled={!isAllDisabled}
                      >
                        <option value={""}>Select Vlan</option>
                        {allVlans.map((item: any) => {
                          return (
                            <option key={item.intPkVlanID} value={item.vlan}>
                              {item.vlan}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}
          </Row>
          {/* All End */}
          {/* 1 Start */}
          <Row className="mt-5 push-vlan-modal-form-row">
            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col
                  className="push-vlan-modal-form-row-single-port-label"
                  md={11}
                  lg={11}
                  sm={11}
                  xxl={11}
                  xs={11}
                >
                  <input
                    disabled={isAllDisabled}
                    onChange={(e) => {
                      setPortsHandler({
                        ...portsHandler,
                        p1: e.target.checked,
                      });
                    }}
                    checked={portsHandler.p1}
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                  />
                  Eth # 1
                </Col>
              </Row>
            </Col>

            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                  <Form.Select
                    value={config.p1.vlanType}
                    onChange={(e) => {
                      const value = e.target.value;
                      const p1 = config.p1;
                      const newValue = {
                        vlanType: value,
                        vlanValue: p1.vlanValue,
                      };
                      setConfig({
                        ...config,
                        p1: newValue,
                      });
                    }}
                    disabled={checkIfControlDisabled(
                      isAllDisabled,
                      portsHandler.p1
                    )}
                  >
                    <option value={""}>Choose</option>
                    <option value={"TAG"}>Tag</option>
                    <option value={"TRANSPARENT"}>Transparent</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            {config.p1.vlanType !== "TRANSPARENT" ? (
              <>
                <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
                  <Row>
                    <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                    <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                      <Form.Select
                        disabled={checkIfControlDisabled(
                          isAllDisabled,
                          portsHandler.p1
                        )}
                        value={config.p1.vlanValue}
                        onChange={(e) => {
                          const value = e.target.value;
                          const p1 = config.p1;
                          const newValue = {
                            vlanType: p1.vlanType,
                            vlanValue: value,
                          };
                          setConfig({
                            ...config,
                            p1: newValue,
                          });
                        }}
                      >
                        <option value={""}>Select Vlan</option>

                        {allVlans.map((item: any) => {
                          return (
                            <option key={item.intPkVlanID} value={item.vlan}>
                              {item.vlan}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}
          </Row>
          {/* 1 End */}
          {/* 2 Start */}
          <Row className="mt-2 push-vlan-modal-form-row">
            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col
                  className="push-vlan-modal-form-row-single-port-label"
                  md={11}
                  lg={11}
                  sm={11}
                  xxl={11}
                  xs={11}
                >
                  <input
                    disabled={isAllDisabled}
                    onChange={(e) => {
                      setPortsHandler({
                        ...portsHandler,
                        p2: e.target.checked,
                      });
                    }}
                    checked={portsHandler.p2}
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                  />
                  Eth # 2
                </Col>
              </Row>
            </Col>

            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                  <Form.Select
                    value={config.p2.vlanType}
                    onChange={(e) => {
                      const value = e.target.value;
                      const p2 = config.p2;
                      const newValue = {
                        vlanType: value,
                        vlanValue: p2.vlanValue,
                      };
                      setConfig({
                        ...config,
                        p2: newValue,
                      });
                    }}
                    disabled={checkIfControlDisabled(
                      isAllDisabled,
                      portsHandler.p2
                    )}
                  >
                    <option value={""}>Choose</option>
                    <option value={"TAG"}>Tag</option>
                    <option value={"TRANSPARENT"}>Transparent</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            {config.p2.vlanType !== "TRANSPARENT" ? (
              <>
                <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
                  <Row>
                    <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                    <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                      <Form.Select
                        value={config.p2.vlanValue}
                        onChange={(e) => {
                          const value = e.target.value;
                          const p2 = config.p2;
                          const newValue = {
                            vlanType: p2.vlanType,
                            vlanValue: value,
                          };
                          setConfig({
                            ...config,
                            p2: newValue,
                          });
                        }}
                        disabled={checkIfControlDisabled(
                          isAllDisabled,
                          portsHandler.p2
                        )}
                      >
                        <option value={""}>Select Vlan</option>

                        {allVlans.map((item: any) => {
                          return (
                            <option key={item.intPkVlanID} value={item.vlan}>
                              {item.vlan}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}
          </Row>
          {/* 2 End */}
          {/* 3 Start */}
          <Row className="mt-2 push-vlan-modal-form-row">
            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col
                  className="push-vlan-modal-form-row-single-port-label"
                  md={11}
                  lg={11}
                  sm={11}
                  xxl={11}
                  xs={11}
                >
                  <input
                    disabled={isAllDisabled}
                    onChange={(e) => {
                      setPortsHandler({
                        ...portsHandler,
                        p3: e.target.checked,
                      });
                    }}
                    checked={portsHandler.p3}
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                  />
                  Eth # 3
                </Col>
              </Row>
            </Col>

            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                  <Form.Select
                    disabled={checkIfControlDisabled(
                      isAllDisabled,
                      portsHandler.p3
                    )}
                    value={config.p3.vlanType}
                    onChange={(e) => {
                      const value = e.target.value;
                      const p3 = config.p3;
                      const newValue = {
                        vlanType: value,
                        vlanValue: p3.vlanValue,
                      };
                      setConfig({
                        ...config,
                        p3: newValue,
                      });
                    }}
                  >
                    <option value={""}>Choose</option>
                    <option value={"TAG"}>Tag</option>
                    <option value={"TRANSPARENT"}>Transparent</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            {config.p3.vlanType !== "TRANSPARENT" ? (
              <>
                <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
                  <Row>
                    <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                    <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                      <Form.Select
                        value={config.p3.vlanValue}
                        onChange={(e) => {
                          const value = e.target.value;
                          const p3 = config.p3;
                          const newValue = {
                            vlanType: p3.vlanType,
                            vlanValue: value,
                          };
                          setConfig({
                            ...config,
                            p3: newValue,
                          });
                        }}
                        disabled={checkIfControlDisabled(
                          isAllDisabled,
                          portsHandler.p3
                        )}
                      >
                        <option value={""}>Select Vlan</option>
                        {allVlans.map((item: any) => {
                          return (
                            <option key={item.intPkVlanID} value={item.vlan}>
                              {item.vlan}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}
          </Row>
          {/* 3 End */}
          {/* 4 Start */}
          <Row className="mt-2 push-vlan-modal-form-row">
            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col
                  className="push-vlan-modal-form-row-single-port-label"
                  md={11}
                  lg={11}
                  sm={11}
                  xxl={11}
                  xs={11}
                >
                  <input
                    disabled={isAllDisabled}
                    onChange={(e) => {
                      setPortsHandler({
                        ...portsHandler,
                        p4: e.target.checked,
                      });
                    }}
                    checked={portsHandler.p4}
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                  />
                  Eth # 4
                </Col>
              </Row>
            </Col>

            <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
              <Row>
                <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                  <Form.Select
                    disabled={checkIfControlDisabled(
                      isAllDisabled,
                      portsHandler.p4
                    )}
                    value={config.p4.vlanType}
                    onChange={(e) => {
                      const value = e.target.value;
                      const p4 = config.p4;
                      const newValue = {
                        vlanType: value,
                        vlanValue: p4.vlanValue,
                      };
                      setConfig({
                        ...config,
                        p4: newValue,
                      });
                    }}
                  >
                    <option value={""}>Choose</option>
                    <option value={"TAG"}>Tag</option>
                    <option value={"TRANSPARENT"}>Transparent</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            {config.p4.vlanType !== "TRANSPARENT" ? (
              <>
                <Col md={4} lg={4} sm={4} xl={4} xxl={4} xs={4}>
                  <Row>
                    <Col md={1} lg={1} sm={1} xxl={1} xs={1}></Col>
                    <Col md={11} lg={11} sm={11} xxl={11} xs={11}>
                      <Form.Select
                        value={config.p4.vlanValue}
                        onChange={(e) => {
                          const value = e.target.value;
                          const p4 = config.p4;
                          const newValue = {
                            vlanType: p4.vlanType,
                            vlanValue: value,
                          };
                          setConfig({
                            ...config,
                            p4: newValue,
                          });
                        }}
                        disabled={checkIfControlDisabled(
                          isAllDisabled,
                          portsHandler.p4
                        )}
                      >
                        <option value={""}>Select Vlan</option>

                        {allVlans.map((item: any) => {
                          return (
                            <option key={item.intPkVlanID} value={item.vlan}>
                              {item.vlan}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}
          </Row>
          {/* 4 End */}
          <Row className="mt-5">
            <span className="image-float-right">
              <button
                disabled={
                  requestData?.requestStatus === "PENDING" ? true : false
                }
                onClick={(e) => {
                  e.preventDefault();
                  handlePushVlanToOnt();
                }}
                className="button-primary image-float-right"
                type="button"
              >
                Push
              </button>
            </span>
          </Row>
          <Row className="mt-5">
            <Table bordered>
              <thead>
                <tr>
                  <th className="table-header-standard">Request Date</th>

                  <th className="table-header-standard">Approve Date</th>
                  <th className="table-header-standard">Port#</th>
                  <th className="table-header-standard">Action</th>
                  <th className="table-header-standard">Vlan</th>
                  <th className="table-header-standard">Error Message</th>
                  <th className="table-header-standard">Status</th>
                </tr>
              </thead>
              <tbody>
                {requestHistory.map((item: any) => {
                  return (
                    <tr key={item.intPkVlanRequestLogID}>
                      <td>{formatDate(item.requestCreated)}</td>
                      <td>
                        {item.requestUpdated !== null
                          ? formatDate(item.requestUpdated)
                          : "-"}
                      </td>
                      <td>{item.port}</td>
                      <td>{item.vlanType}</td>
                      <td>{item.vlanValue}</td>
                      <td>{showErrorMessage(item.errorMessage)}</td>
                      <td>
                        {requestData?.requestStatus !== "PENDING"
                          ? item.logStatus
                          : requestData?.requestStatus}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PushVlanToVsolEponOntModal;
