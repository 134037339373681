import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import OneBar from "./../../assets/svgs/signals/oneBar.svg";
import TwoBars from "./../../assets/svgs/signals/twoBars.svg";
import ThreeBars from "./../../assets/svgs/signals/threeBars.svg";
import FourBars from "./../../assets/svgs/signals/fourBars.svg";
import FiveBars from "./../../assets/svgs/signals/fiveBars.svg";
import SixBars from "./../../assets/svgs/signals/sixBars.svg";
import MinusOneBar from "./../../assets/svgs/signals/minusOneBar.svg";
import RefreshSVG from "./../../assets/svgs/refresh/refresh.svg";
import RefreshDisabledSVG from "./../../assets/svgs/refresh/refreshDisabled.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

import TimeAgo from "react-timeago";
// import frenchStrings from "react-timeago/lib/language-strings/fr";
// import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { useParams, Link, useNavigate } from "react-router-dom";
import { InputGroup, Table } from "react-bootstrap";
import { Card } from "react-bootstrap";

import { formatDate } from "../../helpers/date";
import Moment from "react-moment";
import moment from "moment";
import AreaChatComponent from "../Charts/Line";

import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
//status
import UpStatus from "./../../assets/svgs/statuses/up.svg";
import DownStatus from "./../../assets/svgs/statuses/down.svg";
import InActiveStatus from "./../../assets/svgs/statuses/inactive.svg";
import InProcessStatus from "./../../assets/svgs/statuses/inprocess.svg";
import UpSetStatus from "./../../assets/svgs/statuses/upset.svg";
import InVisibleStatus from "./../../assets/svgs/statuses/invisible.svg";
import RebootedStatus from "./../../assets/svgs/statuses/rebooting.svg";

// Statics
import StaticIspURL from "./../../assets/svgs/statuses/ispLogo.png";
import StaticRouter from "./../../assets/svgs/statuses/router-static.png";
import CopyButton from "./../../assets/svgs/copy/Copy.svg";
import LinkButtonImage from "./../../assets/svgs/goto/Link.svg";
import MacAddressesCard from "../Cards/MacAddressesCard";
import { ThreeDots } from "react-loader-spinner";
import EditEndUsersModal from "../Model/EditEndUsersModal";
import { showUserName } from "../../helpers/endUser";
import PushVlanToVsolEponOntModal from "../Model/PushVlanToVsolEponOntModal";
//block svgs

import BlockInProcessOntIcon from "./../../assets/svgs/router/blockInProcess.svg";
import UnBlockInProcessOntIcon from "./../../assets/svgs/router/unblockInProcess.svg";
import BlockedOntIcon from "./../../assets/svgs/router/blocked.svg";
import { notifyDemoAccountError } from "../toast/demoAccountError";
import { getUserType } from "../../helpers/userDetails";
import { getSingleOntRoute } from "../../helpers/apiRoutes";
const initialChartData = {
  labels: [],
  datasets: [
    {
      label: "Download Speed",
      data: [],
      borderColor: "rgb(50,205,50)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Upload Speed",
      data: [],
      borderColor: "rgb(173,216,230)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
const BROWSE_ONT_DETAILS_ROUTE = getSingleOntRoute();
const BrowseSingleONTComponent: React.FC<GP> = ({}) => {
  const generalStatusRef = useRef<any>(null);
  const navigate = useNavigate();
  let iterations = 0;
  const { intPkOntBindingID, intPkPonPortID, oltName, intPkOltID } =
    useParams();
  const [isRebootDisabled, setIsRebootDisable] = useState(true);
  const [isDeleteDisable, setIsDeleteDisable] = useState(false);
  const [isLiveDataDisabled, setIsLiveDataDisabled] = useState(false);
  const [selectedStreamDuration, setSelectedStreamDuration] = useState(30);
  const [isPushVlanDisabled, setIsPushVlanDisabled] = useState<boolean>(true);
  const [isChartShow, setIsChartShow] = useState(false);
  const [allOnts, setAllOnts] = useState<any>([]);
  const [chartData, setChartData] = useState<any>(initialChartData);
  const [intPkOntRegisterationID, setIntPkOntId] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [liveDataDur, setLiveDataDur] = useState(0);
  const [packetsReceived, setPacketReceived] = useState({
    generalStatus: false,
  });

  const [activeEndUsers, setActiveEndUsers] = useState<any>([]);
  const [activeBindingId, setActiveBindingID] = useState<number>(-1);
  const [activeRegistration, setActiveRegistration] = useState<number>(-1);
  const [activeBinding, setActiveBinding] = useState<ActiveBindingForEndUser>({
    oltFrame: -1,
    oltSlot: -1,
    oltPonPort: -1,
    intPkOltID: -1,
    ponNumber: "",
  });
  const [ontType, setOntType] = useState<number>(1);

  const [show, setShow] = useState<boolean>(false);
  const [showPushVlanModel, setShowPushVlanModel] = useState<boolean>(false);
  const handleClose = (isCall: boolean) => {
    setShowPushVlanModel(false);
    if (isCall === true) {
      getFoundOntsData({ intPkOntBindingID: Number(intPkOntBindingID) });
    }
    setShow(false);
    setActiveEndUsers([]);
    setActiveBindingID(-1);
    setActiveBinding({
      oltFrame: -1,
      oltSlot: -1,
      oltPonPort: -1,
      intPkOltID: -1,
      ponNumber: "",
    });
  };

  const [
    requestPonPortData,
    ponPortRequestResponse,
    ponPortRequestError,
    ponPortRequestLoading,
    ponPortRequestMessage,
    ponPortRequestErrorCount,
  ] = useAxiosPost<RequestGeneralStatusEnumsProp>(
    "netflow/requests/requestGeneralStatusOfONT",
    true
  );
  const [
    resultPonPortData,
    ponPortResultResponse,
    ponPortResultError,
    ponPortResultLoading,
    ponPortResultMessage,
    ponPortResultErrorCount,
  ] = useAxiosPost<RequestGeneralStatusEnumsProp>(
    "netflow/results/getGeneralStatusOfONT",
    true
  );
  useEffect(() => {
    if (ponPortRequestError !== null && ponPortRequestErrorCount !== 0) {
      setIsLoading(false);
    }
  }, [ponPortRequestError, ponPortRequestErrorCount]);
  useEffect(() => {
    if (ponPortRequestResponse !== null) {
      const success: any = ponPortRequestResponse.success;
      if (success === true) {
        //let's call the interval baby
        setTimeout(() => {
          console.log("we are going to call interval from now on.");
          startIntervalHandler();
        }, 5000);
      }
    }
  }, [ponPortRequestResponse]);
  const startIntervalHandler = () => {
    generalStatusRef.current = setInterval(() => {
      console.log({ iterations });
      if (iterations > 12) {
        setIsLoading(false);
      } else {
        console.log("new api call");
        //we'll call the result api here
        resultPonPortData({
          intPkOntRegisterationID: intPkOntRegisterationID,
          intPkOltID: Number(intPkOltID),
        });
      }
      iterations++;
    }, 2000);
    setTimeout(() => {
      clearInterval(generalStatusRef.current);
    }, 30000);
  };
  useEffect(() => {
    if (ponPortResultResponse !== null) {
      const data: any = ponPortResultResponse.responseData;
      const { generalStatusData } = data;
      if (packetsReceived.generalStatus === true) {
        iterations = 15;
        console.log(
          "We have received all of the packets , so no more further api calls ."
        );
        return;
      }
      if (generalStatusData.length > 0) {
        setIsLoading(false);

        const _allOnts = allOnts.map((item: any) => {
          const ponNumber = item.ponNumber;
          const foundItem = generalStatusData.find(
            (item: any) =>
              item.ponNumber.toLowerCase() == ponNumber.toLowerCase()
          );
          if (foundItem) {
            item.ontStatus = foundItem.ontUpDownUpsetStatus;
            item.ontLastDownCauseCode = foundItem.ontLastDownCauseCode;
            item.updateResultOntDistanceFromOltInMetersDate =
              foundItem.updateResultOntDistanceFromOltInMetersDate;
            item.ontDistanceFromOltInMeters =
              foundItem.ontDistanceFromOltInMeters;
            item.updateResultOntLastDownCauseCodeDate =
              foundItem.updateResultOntLastDownCauseCodeDate;
            item.updateResultOntStatusUpdateDate =
              foundItem.updateResultOntStatusUpdateDate;
            item.ontRxSignalFromOLT = foundItem.ontRxSignalFromOLT;
            item.updateResultOntRxSignalFromOLTDate =
              foundItem.updateResultOntRxSignalFromOLTDate;
            item.barsCount =
              foundItem.ontRxSignalFromOLT !== null
                ? getSignalBars(foundItem.ontRxSignalFromOLT)
                : -1;
            return item;
          } else {
            console.log("not found");

            return item;
          }
        });
        setAllOnts(_allOnts);
        setPacketReceived({
          generalStatus: true,
        });
        setIsLoading(false);
        clearInterval(generalStatusRef.current);
      }
    }
  }, [ponPortResultResponse]);

  useEffect(() => {
    localStorage.setItem("SingleOnts", JSON.stringify(allOnts));
    if (allOnts.length > 0) {
      const ont = allOnts[0];
      const { ontStatus, intPkOntRegisterationID } = ont;
      rebootDisableHandler(intPkOntRegisterationID, ontStatus);
    }
  }, [allOnts]);
  const [
    getFoundOntsData,
    FoundOntsResponseData,
    foundOntsError,
    foundOntsLoading,
    foundOntsMessage,
    foundOntsErrorCount,
  ] = useAxiosPost<GetSingleRegistrationById>(`${BROWSE_ONT_DETAILS_ROUTE}`);

  const [
    deleteRegistration,
    deleteRegistrationResponse,
    deleteError,
    deleteLoading,
    deleteMessage,
    deleteErrorCount,
  ] = useAxiosPost<DeleteRegistrationFromSearchProp>("binding/deleteBinding");

  useEffect(() => {
    if (deleteError !== null && deleteErrorCount !== 0) {
      notifyError(deleteError);
    }
  }, [deleteError, deleteErrorCount]);
  useEffect(() => {
    if (deleteRegistrationResponse !== null) {
      console.log({ deleteRegistrationResponse });
      notifySuccess("Ont Deleted Successfully");
      setTimeout(() => {
        navigate(-1);
      }, 2500);
    }
  }, [deleteRegistrationResponse]);
  const getSignalsBar = (
    bars: number,
    isOntRebooted: any,
    ontStatus: any,
    isLoading: boolean
  ) => {
    if (isOntRebooted === true) {
      return (
        <img
          src={MinusOneBar}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    }
    if (ontStatus === "DOWN") {
      return (
        <img
          src={MinusOneBar}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    }
    if (bars === -1) {
      return (
        <img
          src={MinusOneBar}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 1) {
      return (
        <img
          src={OneBar}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 2) {
      return (
        <img
          src={TwoBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 3) {
      return (
        <img
          src={ThreeBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 4) {
      return (
        <img
          src={FourBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 5) {
      return (
        <img
          src={FiveBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    } else if (bars === 6) {
      return (
        <img
          src={SixBars}
          className={`${
            isLoading === true
              ? "blinking-image image-float-right"
              : "image-float-right"
          }`}
        />
      );
    }
  };

  useEffect(() => {
    getFoundOntsData({ intPkOntBindingID: Number(intPkOntBindingID) });
  }, [intPkOntBindingID]);
  useEffect(() => {
    if (foundOntsError !== null && foundOntsErrorCount !== 0) {
      notifyError(foundOntsError);
    }
  }, [foundOntsError, foundOntsErrorCount]);
  useEffect(() => {
    if (FoundOntsResponseData !== null) {
      const responseData: any = FoundOntsResponseData.responseData;
      if (responseData) {
        const {
          intPkOntRegisterationID,
          ontStatus,
          isOntEponOrGpon,
          isOntRebooted,
        } = responseData;
        console.log({ isOntEponOrGpon });
        setOntType(Number(isOntEponOrGpon));
        if (isOntRebooted == true) {
          setIsRefreshDisabled(true);
        }
        if (ontStatus !== "UP" && ontStatus !== "UPSET") {
          setIsRefreshDisabled(true);
          setIsLoading(false);
        }
        if (ontStatus === "UP" || ontStatus === "UPSET") {
          setIsPushVlanDisabled(false);
          if (isOntRebooted !== true) {
            requestPonPortData({ intPkOntRegisterationID });
          } else {
            setIsLoading(false);
          }
        }
        setIntPkOntId(intPkOntRegisterationID);
      }
      setAllOnts([responseData]);
    }
  }, [FoundOntsResponseData]);
  const convertMetersToKiloMeters = (meters: any) => {
    if (meters < 1000) {
      return `${meters} Meters`;
    } else {
      return `${meters / 1000} KM`;
    }
  };

  const rebootDisableHandler = (id: any, status: any) => {
    if (status === "DOWN") {
      setIsRebootDisable(true);
      setIsDeleteDisable(false);
      setIsLiveDataDisabled(true);
      return;
    }
    const item = `rebootKey:${id}`;

    const value = localStorage.getItem(item);
    if (!value) {
      setIsRebootDisable(false);
      return;
    }

    const start = new Date(value);
    const end = new Date();
    const differenceInSeconds = Math.abs(
      (end.getTime() - start.getTime()) / 1000
    );
    if (differenceInSeconds > 180) {
      setIsRebootDisable(false);
      return;
    }
    setIsRebootDisable(true);
    setIsDeleteDisable(true);
    setIsLiveDataDisabled(true);
    return;
  };
  const getTimeAgo = (_date: any) => {
    const startTime = moment(_date);
    const currentTime = moment();
    // <TimeAgo date={new Date(_date)} />;
    return (
      <TimeAgo date={new Date(_date)} />
      // <Moment from={currentTime} ago>
      //   {startTime}
      // </Moment>
    );
  };
  const getSignalBars = (signal: any) => {
    if (signal > -8) {
      return 6;
    } else if (signal >= -24.5 && signal <= -8) {
      return 5;
    } else if (signal >= -26.5 && signal <= -24.5) {
      return 4;
    } else if (signal >= -27.5 && signal <= -26.5) {
      return 3;
    } else if (signal >= -28.0 && signal <= -27.5) {
      return 2;
    } else if (signal <= -28.0) {
      return 1;
    }
  };

  const [
    postLiveDataRequest,
    LiveDataRequestResponse,
    liveDataRequestError,
    liveDataRequestLoading,
    liveDataRequestMessage,
    liveDataRequestErrorCount,
  ] = useAxiosPost<RequestLiveDataProp>(
    "netflow/requests/requestLiveBytesInAndOutSpeed",
    true
  );
  const [
    postLiveDataResponseData,
    liveDataResultResponse,
    liveDataResultError,
    liveDataResultLoading,
    liveDataResultMessage,
    liveDataResultErrorCount,
  ] = useAxiosPost<RequestLiveDataProp>(
    "netflow/results/getLiveBytesInAndOutSpeed",
    true
  );
  useEffect(() => {
    if (liveDataResultResponse !== null) {
      console.log({ liveDataResultResponse });
      const responseData: any = liveDataResultResponse?.responseData;
      const { liveSpeedInOutData } = responseData;
      if (liveSpeedInOutData?.length > 0) {
        const labels = [];
        const uploads = [];
        const downloads = [];
        const totals = [];
        for (let i = 0; i < liveSpeedInOutData.length; i++) {
          labels.push(i + 1);
          const item = liveSpeedInOutData[i];
          downloads.push(item.downloadSpeedMBPs);
          uploads.push(item.uploadSpeedMBPs);
          // totals.push(
          //   Number(item.downloadSpeedMBPs) + Number(item.uploadSpeedMBPs)
          // );
        }

        const _chartData = {
          labels,
          datasets: [
            {
              label: "Download Speed",
              data: downloads,
              borderColor: "rgb(50,205,50)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: "Upload Speed",
              data: uploads,
              borderColor: "rgb(173,216,230)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
          ],
        };
        setChartData(_chartData);
      }
    }
  }, [liveDataResultResponse]);
  useEffect(() => {
    if (LiveDataRequestResponse !== null) {
      const success: any = LiveDataRequestResponse.success;
      if (success === true) {
        //let's call the interval
        setTimeout(() => {
          console.log("we are going to call interval from now on.");
          startLiveDataIntervalHandler();
        }, 5000);
      }
    }
  }, [LiveDataRequestResponse]);

  const startLiveDataIntervalHandler = () => {
    console.log({ dur: liveDataDur }, "starting live data calls");
    const liveDataInterval = setInterval(() => {
      console.log("we are calling for live data");
      postLiveDataResponseData({
        intPkOntRegisterationID,
        intPkOltID: Number(intPkOltID),
      });
    }, 2000);
    setTimeout(() => {
      clearInterval(liveDataInterval);
    }, liveDataDur);
  };
  const handleLiveDataRequest = (
    id: any,
    status: any,
    streamDuration: number
  ) => {
    if (status === "DOWN") {
      return;
    }

    const dur = (streamDuration + 30) * 1000;

    setLiveDataDur(dur);
    postLiveDataRequest({
      intPkOntRegisterationID,
      streamDuration: streamDuration,
      intPkOltID: Number(intPkOltID),
    });
    setTimeout(() => {
      setIsRebootDisable(false);
      setIsDeleteDisable(false);
      setIsLiveDataDisabled(false);
    }, dur);
  };
  const handleDeleteRegistration = (
    ponNumber: string,
    intPkOltID: number,
    intPkOntBindingID: number
  ) => {
    setIsRebootDisable(true);
    setIsDeleteDisable(true);
    setIsLiveDataDisabled(true);
    const bondObj = {
      ponNumber,
      intPkOltID,
      intPkOntBindingID,
    };
    deleteRegistration({
      bondObj,
    });
  };
  const getSignalImage = (
    status: any,
    isOntInActive: any,
    isActivationInProcess: any,
    intPkOntRegisterationID: any,
    isOntRebooted: any,
    isLoading: boolean
  ) => {
    if (isOntRebooted === true) {
      return (
        <img
          src={RebootedStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }

    if (isActivationInProcess == true) {
      return (
        <img
          src={InProcessStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (isOntInActive == true) {
      return (
        <img
          src={InActiveStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (status === "UP") {
      return (
        <img
          src={UpStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (status === "DOWN") {
      return (
        <img
          src={DownStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (status === "UPSET") {
      return (
        <img
          src={UpSetStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
    if (intPkOntRegisterationID == null) {
      return (
        <img
          src={InVisibleStatus}
          className={`${isLoading === true ? "blinking-image" : ""}`}
        />
      );
    }
  };
  const getStatusString = (status: any) => {
    if (status === "UP") {
      return "Up Since";
    }
    if (status === "DOWN") {
      return "Down Since";
    }
    if (status === "UPSET") {
      return "Upset Since";
    }
  };
  const handleRefresh = () => {
    setIsRefreshDisabled(true);
    setIsLoading(true);
    setPacketReceived({
      generalStatus: false,
    });
    iterations = 0;
    requestPonPortData({
      intPkOntRegisterationID,
      isRefresh: true,
    });
    setTimeout(() => {
      setIsRefreshDisabled(false);
    }, 45000);
  };

  //reboot ont
  const [
    postRebootRequest,
    rebootRequestResponse,
    rebootRequestError,
    rebootRequestLoading,
    rebootRequestMessage,
    rebootRequestErrorCount,
  ] = useAxiosPost<RequestLiveDataProp>(
    "netflow/requests/requestSingleONTReboot",
    true
  );
  const [
    postRebootResponseData,
    rebootResultResponse,
    rebootResultError,
    rebootResultLoading,
    rebootResultMessage,
    rebootResultErrorCount,
  ] = useAxiosPost<RequestLiveDataProp>(
    "netflow/results/getSingleONTRebootStatus",
    true
  );
  const handleReboot = () => {
    console.log({ intPkOntRegisterationID });
    if (!intPkOntRegisterationID) {
      return;
    }
    setIsRebootDisable(true);
    setIsRefreshDisabled(true);
    setIsDeleteDisable(true);
    setIsChartShow(false);
    setIsLiveDataDisabled(true);
    const item = `rebootKey:${intPkOntRegisterationID}`;
    const date = new Date();
    localStorage.setItem(item, date.toString());
    postRebootRequest({
      intPkOntRegisterationID,
      intPkOltID: Number(intPkOltID),
    });
  };

  useEffect(() => {
    if (rebootResultResponse !== null) {
      console.log({ rebootResultResponse });
      const responseData: any = rebootResultResponse?.responseData;
      if (responseData?.reboot?.reboot === true) {
        setIsRefreshDisabled(true);

        console.log("ont rebooted");
        const _allOnts = allOnts.map((item: any) => {
          return {
            ...item,
            isOntRebooted: true,
          };
        });
        setAllOnts(_allOnts);
      }
      console.log({ responseData });
    }
  }, [rebootResultResponse]);
  useEffect(() => {
    if (rebootRequestResponse !== null) {
      const success: any = rebootRequestResponse.success;
      if (success === true) {
        //let's call the interval
        setTimeout(() => {
          console.log("we are going to call interval from now on.");
          rebootStatusIntervalHandler();
        }, 5000);
      }
    }
  }, [rebootRequestResponse]);

  const rebootStatusIntervalHandler = () => {
    const rebootInterval = setInterval(() => {
      console.log("we are calling for live data");
      postRebootResponseData({
        intPkOntRegisterationID,
        intPkOltID: Number(intPkOltID),
      });
    }, 2000);
    setTimeout(() => {
      clearInterval(rebootInterval);
    }, 60000);
  };
  const showPonOrMacAsciiPon = (
    ontType: number,
    mac: any,
    pon: any,
    asciiPon: any
  ) => {
    if (ontType === 1) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">MacAddress</span> :{" "}
          <span className="data-r-field">{mac}</span>{" "}
          <CopyToClipboard
            text={mac || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
    if (ontType === 2) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">Pon</span> :{" "}
          <span className="data-r-field">{pon}</span>{" "}
          <CopyToClipboard
            text={pon || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
    if (ontType === 3) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">Pon</span> :{" "}
          <span className="data-r-field">{asciiPon}</span>{" "}
          <CopyToClipboard
            text={asciiPon || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
  };
  const getBlockedImage = (blockState: number) => {
    if (blockState == 0) {
      return <img src={StaticRouter} className="image-float-right" />;
    }
    if (blockState == 1) {
      return <img src={BlockedOntIcon} className="image-float-right" />;
    }
    if (blockState == 2) {
      return <img src={BlockInProcessOntIcon} className="image-float-right" />;
    }
    if (blockState == 3) {
      return (
        <img src={UnBlockInProcessOntIcon} className="image-float-right" />
      );
    }
  };
  return (
    <>
      <ToastContainer />
      {showPushVlanModel === true ? (
        <PushVlanToVsolEponOntModal
          intPkOltID={Number(intPkOltID)}
          handleClose={handleClose}
          show={showPushVlanModel}
          intPkOntRegisterationID={activeRegistration}
        />
      ) : null}
      {show === true ? (
        <EditEndUsersModal
          show={show}
          intPkOntBindingID={activeBindingId}
          handleClose={handleClose}
          endUsers={activeEndUsers}
          activeBinding={activeBinding}
        />
      ) : null}
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="12" md="12" xl="12" className="p-5">
            <Card className="p-5">
              <Card.Title className="text-center olt-heading pt-3 main-ont-heading">
                {" "}
                {oltName}{" "}
                <span>
                  <img
                    style={{ cursor: "pointer" }}
                    src={
                      isRefreshDisabled === true
                        ? RefreshDisabledSVG
                        : RefreshSVG
                    }
                    onClick={(e) => {
                      console.log({ isRefreshDisabled });
                      if (isRefreshDisabled === true) {
                        return;
                      }
                      handleRefresh();
                    }}
                    className="image-float-right"
                  />
                </span>
              </Card.Title>
              <Card.Body>
                {allOnts.map((item: any) => {
                  return (
                    <>
                      <Row
                        className="single-ont-container-extended"
                        key={item.intPkOntBindingID}
                      >
                        <Col
                          className="mx-h-100"
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                          xs={12}
                        ></Col>

                        <Col
                          className="mx-h-100"
                          md={4}
                          lg={4}
                          xl={4}
                          xxl={4}
                          sm={4}
                          xs={12}
                        >
                          <img
                            style={{ width: "50px" }}
                            src={item.ispLogoUrl}
                          />
                        </Col>

                        <Col
                          className="text-center mx-h-100"
                          md={4}
                          lg={4}
                          xl={4}
                          xxl={4}
                          sm={4}
                          xs={12}
                        >
                          {getSignalImage(
                            item.ontStatus,
                            item.isOntInActive,
                            item.isActivationInProcess,
                            item.intPkOntRegisterationID,
                            item.isOntRebooted,
                            isLoading
                          )}
                        </Col>
                        <Col
                          className="mx-h-100 mt-3"
                          md={4}
                          lg={4}
                          xl={4}
                          xxl={4}
                          sm={4}
                          xs={12}
                        >
                          {getSignalsBar(
                            item.barsCount,
                            item.isOntRebooted,
                            item.ontStatus,
                            isLoading
                          )}
                        </Col>

                        <Col
                          className="mt-3"
                          md={7}
                          lg={7}
                          xl={7}
                          xxl={7}
                          sm={12}
                          xs={12}
                        >
                          <h4>
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveEndUsers(item.endUsers);
                                setShow(true);
                                setActiveBindingID(item.intPkOntBindingID);
                                setActiveBinding({
                                  oltFrame: item.oltFrame,
                                  oltSlot: item.oltSlot,
                                  oltPonPort: item.oltPonPort,
                                  ponNumber: item.ponNumber,
                                  intPkOltID: item.intPkOltID,
                                });
                              }}
                              className=" end-user-name-heading cursor-pointer-class"
                            >
                              PPPoEID :
                              {showUserName(
                                item.endUsers,
                                item.ontRegistrationDescInOLT
                              )}
                            </span>

                            <CopyToClipboard
                              text={
                                showUserName(
                                  item.endUsers,
                                  item.ontRegistrationDescInOLT
                                ) || ""
                              }
                              onCopy={() => {
                                // notifySuccess(`Pon Number Copied`);
                              }}
                            >
                              <img
                                src={CopyButton}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </CopyToClipboard>
                          </h4>
                        </Col>
                        <Col md={5} lg={5} xl={5} xxl={5} sm={5} xs={5}>
                          {getBlockedImage(Number(item.blockState))}
                        </Col>
                        <Col
                          className="mt-3"
                          md={7}
                          lg={7}
                          xl={7}
                          xxl={7}
                          sm={12}
                          xs={12}
                        >
                          <span className="data-r-label">OLT</span> :{" "}
                          <span className="data-r-field">{item.oltName}</span>
                        </Col>
                        <Col
                          className="mt-2"
                          md={5}
                          lg={5}
                          xl={5}
                          xxl={5}
                          sm={12}
                          xs={12}
                        >
                          {ontType === 2 ? (
                            <>
                              <span className="image-float-left">
                                <span className="data-r-label">FSP</span> :{" "}
                                <span className="data-r-field">{item.fsp}</span>
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="image-float-left">
                                <span className="data-r-label">Id</span> :{" "}
                                <span className="data-r-field">
                                  {item.fspId}
                                </span>
                              </span>
                            </>
                          )}
                          <span className="image-float-right">
                            <span className="data-r-label">ONT ID</span> :{" "}
                            <span className="data-r-field">{item.ontID}</span>
                          </span>
                        </Col>
                        {showPonOrMacAsciiPon(
                          ontType,
                          item.formattedOntEponMacAddressID,
                          item.ponNumber,
                          item.asciiHexaPonNumber
                        )}

                        <Col
                          className="mt-3"
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                          xs={12}
                        >
                          <span className="data-r-label">Last Cause</span> :{" "}
                          <span className="data-r-field">
                            {item.frontendDisplayStatusString}
                          </span>{" "}
                          {/* <img
                            src={CopyButton}
                            onClick={(e) => {
                              e.preventDefault();
                              handlePonNumberCopy(item.ponNumber);
                            }}
                            style={{ cursor: "pointer" }}
                          /> */}
                        </Col>
                        <Col
                          className="mt-3"
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                          xs={12}
                        >
                          <span className="data-r-label">
                            {" "}
                            Registration Time
                          </span>{" "}
                          :{" "}
                          <span className="data-r-field">
                            {formatDate(item.bindCreatedDatetime)}
                          </span>{" "}
                          {/* <img
                            src={CopyButton}
                            onClick={(e) => {
                              e.preventDefault();
                              handlePonNumberCopy(item.ponNumber);
                            }}
                            style={{ cursor: "pointer" }}
                          /> */}
                        </Col>

                        <Col
                          className="mt-3"
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                          xs={12}
                        >
                          <span className="data-r-label"> Activation Time</span>{" "}
                          :{" "}
                          <span className="data-r-field">
                            {formatDate(item.OntRegisterationInsertDatetime)}
                          </span>{" "}
                          {/* <img
                            src={CopyButton}
                            onClick={(e) => {
                              e.preventDefault();
                              handlePonNumberCopy(item.ponNumber);
                            }}
                            style={{ cursor: "pointer" }}
                          /> */}
                        </Col>
                        <Col
                          className="mt-3"
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                          xs={12}
                        >
                          {item.ontStatus == "UP" ||
                          item.ontStatus == "UPSET" ? (
                            <>
                              {" "}
                              <span className="data-r-label">
                                Signal/Distance
                              </span>
                              &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;
                              <span
                                className={`data-r-field ${
                                  isLoading === true ? "blinking-image" : ""
                                }`}
                              >
                                {item.ontRxSignalFromOLT} / ({" "}
                                {convertMetersToKiloMeters(
                                  item.ontDistanceFromOltInMeters
                                )}
                                ) {"{"}{" "}
                                {getTimeAgo(
                                  item.updateResultOntRxSignalFromOLTDate
                                )}{" "}
                                {"}"}
                              </span>
                            </>
                          ) : null}
                        </Col>
                        <Col
                          className="mt-3"
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                          xs={12}
                        >
                          <span className="data-r-label">Registered By</span> :{" "}
                          <span className="data-r-field">{item.userType}</span>{" "}
                        </Col>
                        {item.ontStatus === "UP" ||
                        item.ontStatus === "DOWN" ||
                        item.ontStatus === "UPSET" ? (
                          <>
                            <Col
                              className="mt-3"
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sm={12}
                              xs={12}
                            >
                              <span className="data-r-label">
                                {getStatusString(item.ontStatus)}
                              </span>{" "}
                              :{" "}
                              <span className="data-r-field">
                                {getTimeAgo(item.ontStatusLastStatusUpdateDate)}
                              </span>{" "}
                            </Col>
                          </>
                        ) : null}

                        {/* Showing Mac Addresses */}

                        {/* <Col
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                          className="mt-5"
                        >
                          <Table>
                            <thead>
                              <th></th>
                              <th className="data-r-label">Mac Address</th>
                              <th className="data-r-label">Vlan</th>
                              <th className="data-r-label">ISP</th>
                            </thead>
                            <tbody>
                              {showMacAddresses(item.servicePortArray)}
                            </tbody>
                          </Table>
                        </Col> */}
                        <MacAddressesCard
                          servicePortsArray={item.servicePortArray}
                        />
                        {/* Showing Mac Addresses End */}
                        {ontType === 2 ? (
                          <>
                            <Col
                              className="text-center"
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sm={12}
                            >
                              <Button
                                variant="warning"
                                disabled={isDeleteDisable}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const role = getUserType();

                                  if (
                                    role === "" ||
                                    role == "DEMO_DEALER" ||
                                    role === "DEMO_ADMIN"
                                  ) {
                                    notifyDemoAccountError();
                                    return;
                                  }
                                  handleDeleteRegistration(
                                    item.ponNumber,
                                    Number(item.intPkOltID),
                                    Number(item.intPkOntBindingID)
                                  );
                                }}
                              >
                                Delete
                              </Button>
                            </Col>

                            <Col
                              className="text-center mt-3"
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sm={12}
                            >
                              <Button
                                disabled={isRebootDisabled}
                                variant="warning"
                                onClick={(e) => {
                                  console.log(item.ontStatus);
                                  if (item.ontStatus !== "DOWN") {
                                    // setIsRebootDisable(true);
                                    handleReboot();
                                  }
                                }}
                              >
                                Reboot Now
                              </Button>
                            </Col>
                          </>
                        ) : null}
                        {ontType === 1 ? (
                          <>
                            <Col
                              className="text-center mt-3"
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sm={12}
                            >
                              <Button
                                disabled={isRebootDisabled}
                                variant="warning"
                                onClick={(e) => {
                                  const role = getUserType();

                                  if (
                                    role === "" ||
                                    role == "DEMO_DEALER" ||
                                    role === "DEMO_ADMIN"
                                  ) {
                                    notifyDemoAccountError();
                                    return;
                                  }
                                  console.log(item.ontStatus);
                                  if (item.ontStatus !== "DOWN") {
                                    // setIsRebootDisable(true);
                                    handleReboot();
                                  }
                                }}
                              >
                                Reboot Now
                              </Button>
                            </Col>
                          </>
                        ) : null}

                        <Col
                          className="text-center mt-3"
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                        >
                          <Form.Group>
                            <Form.Label>Choose Stream Duration</Form.Label>
                            <Form.Select
                              value={selectedStreamDuration}
                              onChange={(e) => {
                                setSelectedStreamDuration(
                                  Number(e.target.value)
                                );
                              }}
                            >
                              <option value={30} key={"30"}>
                                30 Seconds
                              </option>{" "}
                              <option value={60} key={"60"}>
                                1 Minute
                              </option>{" "}
                              <option value={120} key={"120"}>
                                2 Minutes
                              </option>{" "}
                              <option value={300} key={"300"}>
                                5 Minutes
                              </option>{" "}
                              <option value={420} key={"420"}>
                                7 Minutes
                              </option>{" "}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col
                          className="text-center mt-3"
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sm={12}
                        >
                          <Button
                            disabled={isLiveDataDisabled}
                            variant="warning"
                            onClick={(e) => {
                              if (item.ontStatus !== "DOWN") {
                                setIsRebootDisable(true);
                                setIsDeleteDisable(true);
                                setIsLiveDataDisabled(true);
                                setIsChartShow(true);
                                handleLiveDataRequest(
                                  item.intPkOntRegisterationID,
                                  item.ontStatus,
                                  selectedStreamDuration
                                );
                              }
                            }}
                          >
                            Graph(Mb)
                          </Button>
                        </Col>
                        {isChartShow === true ? (
                          <>
                            <Col
                              style={{
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "20px",
                              }}
                              className="text-center mt-3"
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sm={12}
                            >
                              <AreaChatComponent data={chartData} />
                            </Col>
                          </>
                        ) : null}
                        {ontType === 1 &&
                        item.intPkOntRegisterationID !== null ? (
                          <>
                            <Col
                              className="text-center mt-5"
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sm={12}
                            >
                              <Button
                                variant="success"
                                disabled={isPushVlanDisabled}
                                onClick={(e) => {
                                  const role = getUserType();

                                  if (
                                    role === "" ||
                                    role == "DEMO_DEALER" ||
                                    role === "DEMO_ADMIN"
                                  ) {
                                    notifyDemoAccountError();
                                    return;
                                  }
                                  if (isPushVlanDisabled) {
                                    return;
                                  }
                                  e.preventDefault();
                                  setActiveRegistration(
                                    Number(item.intPkOntRegisterationID)
                                  );
                                  setShowPushVlanModel(true);
                                }}
                              >
                                Push Vlan
                              </Button>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    </>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BrowseSingleONTComponent;
