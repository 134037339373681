import { logout } from "../hooks/logout";

export const getAuthToken = (): string => {
  const sessionToken = sessionStorage.getItem("CompanyAdminToken") || false;
  if (sessionToken) {
    return sessionToken;
  }
  const token = localStorage.getItem("auth_token") || false;
  if (token) {
    return token;
  }
  return "";
};

export const getUserType = (): string => {
  const sessionType = sessionStorage.getItem("CompanyAdminType") || false;
  if (sessionType) {
    return sessionType;
  }
  const userType = localStorage.getItem("userType") || false;

  if (userType) {
    return userType;
  }
  return "";
};
export const handleLogout = async () => {
  const sessionToken = sessionStorage.getItem("CompanyAdminToken") || false;
  if (sessionToken) {
    sessionStorage.clear();
    await logout(sessionToken);
    return;
  }
  const token = localStorage.getItem("auth_token") || false;
  if (token) {
    localStorage.clear();

    await logout(token);
    return;
  }
};
