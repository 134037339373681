import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { notifySuccess } from "../toast/Success";
import { notifyError } from "../toast/Error";
type Props = {
  isLoggedIn?: boolean;
};

const UpdateProfileComponent: React.FC<Props> = ({}) => {
  const [
    postUpdateProfile,
    updateProfileResponse,
    updateProfileError,
    updateProfileLoading,
    updateProfileMessage,
    updateProfileErrorCount,
  ] = useAxiosPost<UpdateUserProfileData>("users/updateProfile");
  const [
    getProfileData,
    profileResponseData,
    profileError,
    profileLoading,
    profileMessageMessage,
    profileErrorCount,
  ] = useAxiosPost<ResponseProp>("users/getMyProfile");
  const UpdatePasswordValidationSchema = yup.object().shape({
    userFullname: yup.string().required(),
    contactNumber: yup.string().required(),
  });
  useEffect(() => {
    getProfileData({});
  }, []);
  useEffect(() => {
    if (profileResponseData !== null) {
      const profileResponse: any = profileResponseData.responseData;
      if (profileResponse) {
        const { userFullname, username, contactNumber, email } =
          profileResponse;
        setProfileData({
          ...profileData,
          userFullname,
          username,
          contactNumber,
          email,
        });
      }
    }
  }, [profileResponseData]);
  useEffect(() => {
    if (updateProfileError !== null && updateProfileErrorCount !== 0) {
      notifyError(updateProfileError);
    }
  }, [updateProfileError, updateProfileErrorCount]);
  useEffect(() => {
    if (updateProfileResponse !== null) {
      const { message } = updateProfileResponse;
      if (message) {
        notifySuccess(message);
      }
    }
  }, [updateProfileResponse]);

  const [profileData, setProfileData] = useState<UpdateUserProfileData>({
    userFullname: "",
    username: "",
    contactNumber: "",
    email: "",
  });
  const handleCreateCOO = async (event: any) => {
    event.preventDefault();
    const dataToSend: UpdateUserProfileData = profileData;
    try {
      const isValid = await UpdatePasswordValidationSchema.validate(dataToSend);

      postUpdateProfile(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Update Profile Details
            </Card.Title>
            <Form className="mt-4">
              <Row className="mt-2">
                <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                  <Row>
                    <Col
                      style={{ lineHeight: "50px" }}
                      md={3}
                      lg={3}
                      xl={3}
                      xxl={3}
                      xs={12}
                      sm={12}
                    >
                      Full Name
                    </Col>
                    <Col md={9} lg={9} xl={9} xxl={9} xs={12} sm={12}>
                      <Form.Control
                        className="min-height-50p input-shade-gray bd-35p"
                        placeholder={`Enter FullName`}
                        type="text"
                        value={profileData.userFullname}
                        onChange={(e) => {
                          setProfileData({
                            ...profileData,
                            userFullname: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                  <Row>
                    <Col
                      style={{ lineHeight: "50px" }}
                      md={3}
                      lg={3}
                      xl={3}
                      xxl={3}
                      xs={12}
                      sm={12}
                    >
                      Email
                    </Col>
                    <Col md={9} lg={9} xl={9} xxl={9} xs={12} sm={12}>
                      <Form.Control
                        className="min-height-50p input-shade-gray bd-35p"
                        placeholder={`Email`}
                        type="text"
                        value={profileData.email}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                  <Row>
                    <Col
                      style={{ lineHeight: "50px" }}
                      md={3}
                      lg={3}
                      xl={3}
                      xxl={3}
                      xs={12}
                      sm={12}
                    >
                      UserName
                    </Col>
                    <Col md={9} lg={9} xl={9} xxl={9} xs={12} sm={12}>
                      <Form.Control
                        className="min-height-50p input-shade-gray bd-35p"
                        placeholder={`UserName`}
                        type="text"
                        value={profileData.username}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                  <Row>
                    <Col
                      style={{ lineHeight: "50px" }}
                      md={3}
                      lg={3}
                      xl={3}
                      xxl={3}
                      xs={12}
                      sm={12}
                    >
                      Contact
                    </Col>
                    <Col md={9} lg={9} xl={9} xxl={9} xs={12} sm={12}>
                      <Form.Control
                        className="min-height-50p input-shade-gray bd-35p"
                        placeholder={`Enter Contact Number`}
                        type="text"
                        value={profileData.contactNumber}
                        onChange={(e) => {
                          setProfileData({
                            ...profileData,
                            contactNumber: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col
                  className="text-center mt-5"
                  md={12}
                  lg={12}
                  xs={12}
                  sm={12}
                  xl={12}
                  xxl={12}
                >
                  <button
                    className="button-primary"
                    type="submit"
                    disabled={updateProfileLoading}
                    onClick={handleCreateCOO}
                  >
                    Update Details
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default UpdateProfileComponent;
