import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import CapoltLogo from "./../../assets/svgs/logos/capolt-logo.svg";
import RouterImage from "./../../assets/svgs/logos/router-image.svg";
import "./../../assets/styles/login.css";
import { InputGroup, FormControl } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { notifySuccess } from "../toast/Success";

type Props = {
  isLoggedIn?: boolean;
};

const ForgotPasswordComponent: React.FC<Props> = ({}) => {
  const [
    updatePasswordApi,
    updatePasswordResponse,
    updatePasswordError,
    updatePasswordLoading,
    updatePasswordMessage,
    updatePasswordErrorCount,
  ] = useAxiosPost<ResetPasswordProp>("users/auth/resetPassword");
  useEffect(() => {
    if (loginError !== null && updatePasswordErrorCount !== 0) {
      notifyError(loginError);
    }
  }, [updatePasswordError, updatePasswordErrorCount]);
  useEffect(() => {
    if (updatePasswordResponse !== null) {
      const message = updatePasswordResponse.message;
      if (message) {
        notifySuccess(message);
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      }
    }
  }, [updatePasswordResponse]);
  const [step, setStep] = useState<number>(1);
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<ForgotPasswordProp>("users/auth/forgotPassword");

  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      notifyError(loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      const message = loginResponseData.message;
      if (message) {
        notifySuccess(message);
        setStep(2);
      }
    }
  }, [loginResponseData]);

  const [
    postVerifyCode,
    verifyCodeResponse,
    verifyError,
    verifyLoading,
    verifyMessage,
    verifyErrorCount,
  ] = useAxiosPost<VerifyForgotCodeProp>("users/auth/verify/forgotCode");

  const loginValidationSchema = yup.object().shape({
    email: yup.string().required(),
  });
  const verifyCodeValidationSchema = yup.object().shape({
    code: yup
      .string()
      .required()
      .min(8, "Must be 8 characters")
      .max(8, "Must be 8 Characters"),
  });
  const resetPasswordValidationSchema = yup.object().shape({
    password: yup
      .string()
      .required()
      .min(6, "At least 6 characters")
      .max(30, "At Most be 30 Characters"),
  });

  useLayoutEffect(() => {
    if (localStorage.getItem("auth_token")) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (verifyError !== null && verifyErrorCount !== 0) {
      notifyError(verifyError);
    }
  }, [verifyError, verifyErrorCount]);
  useEffect(() => {
    if (verifyCodeResponse !== null) {
      const message = verifyCodeResponse.message;
      if (message) {
        notifySuccess(message);
        setStep(3);
      }
    }
  }, [verifyCodeResponse]);

  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const handleLogin = async (event: any) => {
    event.preventDefault();
    const dataToSend: ForgotPasswordProp = {
      email: email,
    };
    try {
      const isValid = await loginValidationSchema.validate(dataToSend);
      postLoginData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleVerifyCode = async (event: any) => {
    event.preventDefault();
    const dataToSend: VerifyForgotCodeProp = {
      email: email,
      code,
    };
    try {
      const isValid = await verifyCodeValidationSchema.validate(dataToSend);
      postVerifyCode(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    const dataToSend: any = {
      password,
    };
    try {
      const isValid = await resetPasswordValidationSchema.validate(dataToSend);
      if (password !== confirmPassword) {
        notifyError(`Password MisMatch`);
        return;
      }
      dataToSend.email = email;
      dataToSend.code = code;
      updatePasswordApi(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  const getActiveStep = () => {
    switch (step) {
      case 1:
        return step1();
      case 2:
        return step2();
      case 3:
        return step3();
    }
  };
  const step1 = () => {
    return (
      <>
        <div className="login-form-main-container">
          <Form.Group className="mb-3 min-w-100" controlId="formBasicEmail">
            <Form.Control
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
            />
          </Form.Group>

          <button className="button-primary" onClick={handleLogin}>
            Send Code
          </button>
        </div>
      </>
    );
  };
  const step2 = () => {
    return (
      <>
        <div className="login-form-main-container">
          <Form.Group className="mb-3 min-w-100">
            <Form.Control
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter code"
            />
          </Form.Group>

          <button className="button-primary" onClick={handleVerifyCode}>
            Verify Code
          </button>
        </div>
      </>
    );
  };
  const step3 = () => {
    return (
      <>
        <div className="login-form-main-container">
          <Form.Group className="mb-3 min-w-100">
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New Password"
            />
          </Form.Group>
          <Form.Group className="mb-3 min-w-100">
            <Form.Control
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
            />
          </Form.Group>

          <button className="button-primary" onClick={handleResetPassword}>
            Update Password
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col
            className="routerContainer"
            md={5}
            lg={5}
            xxl={5}
            xl={5}
            sm={5}
            xs={5}
          >
            <Row>
              <Col md={1} lg={1} xl={1} xxl={1} sm={1} xs={1}></Col>
              <Col
                className="mt-10-custom"
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                sm={10}
                xs={11}
              >
                <h1>Welcome To Capolt</h1>
                <h6 style={{ fontWeight: "normal" }}>Reset Your Password</h6>
              </Col>
              <img src={RouterImage} className="h-450" />
            </Row>
          </Col>
          <Col
            className="mt-15-custom"
            md={7}
            lg={7}
            xxl={7}
            xl={7}
            sm={7}
            xs={7}
          >
            <img src={CapoltLogo} />
            <div>
              <Form>{getActiveStep()}</Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ForgotPasswordComponent;
