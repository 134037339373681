import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { removeSpacesFromString } from "../../helpers/strings";

import CompanyAdminAutoOLTCreationSecondStepCard from "../Cards/CompanyAdminAutoOLTCreationSecondStepCard";
import CompanyAdminAutoOLTCreationThirdStepCard from "../Cards/CompanyAdminAutoOLTCreationThirdStepCard";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
const CreateAutoOLTCompanyAdminComponent: React.FC<
  CreateOLTComponentPropForCompanyAdmin
> = ({ handleApiResponse }) => {
  const [
    postCreateOLTData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<CreateOLTPropForCompanyAdmin>(
    "olt/createAutoOLTForCompanyAdmin"
  );
  const [
    postBrandsData,
    brandsResponse,
    brandsError,
    brandsLoading,
    brandsMessage,
    brandsErrorCount,
  ] = useAxiosPost<ResponseProp>("superCOO/getAllBrands");

  // const [
  //   postOltModelsData,
  //   modelsResponse,
  //   modelsError,
  //   modelsLoading,
  //   modelsMessage,
  //   modelsErrorCount,
  // ] = useAxiosPost<ResponseProp>("superCOO/getAllOltModels");
  const [allSanctions, setAllSanctions] = useState<any>([]);
  const [selectedSanctionId, setSelectedSanctionId] = useState<string>("");
  const [brands, setBrands] = useState<any>([]);
  const [oltModels, setOltModels] = useState<any>([]);
  const [actualOntModels, setActualModels] = useState<any>([]);
  const [newOltId, setNewOltId] = useState<number>(-1);
  const [activeStep, setActiveStep] = useState<string>("one");
  const [sysEventBotName, setSysEventBotName] = useState<string>("");
  const [netFlowBotName, setNetFlowBotName] = useState<string>("");
  const [selectedProtocol, setSelectedProtocol] = useState<string>("");
  const [isVsolProtocol, setIsVsolProtocol] = useState<boolean>(false);
  const CreateOltValidationSchema = yup.object().shape({
    oltName: yup.string().required(),
    // model: yup.string().required(),
    host: yup.string().required(),
    // intPkOLTModelID: yup.string().required(),
    intPkBrandID: yup.string().required(),
    telnetPassword: yup.string().required(),
    telnetUserName: yup.string().required(),
    technology: yup.string().required(),
  });

  useEffect(() => {
    if (brandsResponse !== null) {
      console.log({ brandsResponse });
      const data: any = brandsResponse.responseData;
      setBrands(
        data.filter(
          (item: any) => item?.brandName?.toLowerCase() !== "huawei"
        ) || []
      );
    }
  }, [brandsResponse]);

  useEffect(() => {
    postBrandsData({});
  }, []);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
      handleApiResponse(false);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { message } = oltResponseData;
      const responseData: any = oltResponseData.responseData;
      if (message) {
        notifySuccess(message);
      }
      setActiveStep("two");
      setOltData({
        ...oltData,
        port: responseData.port,
        netFlowPort: responseData.netFlowPort,
        oltWebAccessPort: responseData.oltWebAccessPort,
      });
      setNewOltId(Number(responseData.intPkOltID));
      // handleApiResponse(true);
    }
  }, [oltResponseData]);

  const [oltData, setOltData] = useState<CreateOLTPropForCompanyAdmin>({
    oltName: "",
    host: "",
    intPkOLTModelID: "",
    intPkBrandID: "",
    port: 46023,
    netFlowPort: 45161,
    netflowEventSecretKey: "private",
    netflowReadString: "private",
    netflowWriteString: "private",
    telnetPassword: "",
    telnetUserName: "",
    intPkOltSanctionID: "",
    oltWebAccessPort: 50001,
    technology: "",
  });
  const [isNetflowAuto, setIsNetflowAuto] = useState<boolean>(true);
  const [isTelNetAuto, setIsTelNetAuto] = useState<boolean>(true);
  const [isWebAccessAuto, setIsWebAccessAuto] = useState<boolean>(true);

  const handleCreateOLT = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateOLTPropForCompanyAdmin = {
      ...oltData,
      oltName: oltData.oltName.trim(),
      intPkOltSanctionID: selectedSanctionId,
      isNetflowAuto,
      isTelNetAuto,
      isWebAccessAuto,
      technology: selectedProtocol,
    };
    try {
      const isValid = await CreateOltValidationSchema.validate(dataToSend);
      const role = getUserType();
      if (role === "" || role == "DEMO_DEALER" || role === "DEMO_ADMIN") {
        notifyDemoAccountError();
        return;
      }
      postCreateOLTData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOltData({
      ...oltData,
      [name]: removeSpacesFromString(value),
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={oltData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };
  const getActiveComponent = (option: string) => {
    switch (option) {
      case "one":
        return getFirstStepComponent();
        break;
      case "two":
        return getComponentSecondStep();
        break;
      case "three":
        return getComponentThirdStep();
        break;
      default:
        return <></>;
    }
  };
  const getComponentSecondStep = () => {
    return (
      <CompanyAdminAutoOLTCreationSecondStepCard
        oltName={oltData.oltName}
        host={oltData.host}
        intPkOltID={newOltId}
        handleMoveToNextStep={handleMoveToThirdStep}
        port={oltData.port}
        netFlowPort={oltData.netFlowPort}
        oltWebAccessPort={oltData.oltWebAccessPort}
      />
    );
  };
  const getComponentThirdStep = () => {
    return (
      <CompanyAdminAutoOLTCreationThirdStepCard
        oltName={oltData.oltName}
        host={oltData.host}
        intPkOltID={newOltId}
        handleMoveToNextStep={handleMoveToFinalStep}
        port={oltData.port}
        netFlowPort={oltData.netFlowPort}
        sysEventBotName={sysEventBotName}
        netFlowBotName={netFlowBotName}
        oltWebAccessPort={oltData.oltWebAccessPort}
      />
    );
  };
  const handleMoveToThirdStep = (
    netFlowBotName: string,
    sysEventBotName: string,
    step: string
  ) => {
    setNetFlowBotName(netFlowBotName);
    setSysEventBotName(sysEventBotName);
    setActiveStep("three");
  };
  const handleMoveToFinalStep = () => {
    console.log("its all");
    handleApiResponse(true);
  };
  const getFirstStepComponent = () => {
    return (
      <Card className="p-5">
        <Card.Title className="text-center olt-heading fw-700 pt-3 ">
          {" "}
          Scan OLT
        </Card.Title>
        <Form className="mt-4">
          <Form.Group className="mb-3">
            <Form.Label> {`Enter OLT Name`}</Form.Label>
            <Form.Control
              placeholder={`Enter OLT Name`}
              type="text"
              value={oltData.oltName}
              onChange={(e) => {
                setOltData({
                  ...oltData,
                  oltName: e.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label> Choose OLT Brand</Form.Label>
            <Form.Select
              onChange={(e) => {
                if (e.target.value !== "") {
                  const brand = brands.find(
                    (item: any) => item.intPkBrandID == e.target.value
                  );
                  if (brand?.brandName === "Huawei") {
                    setIsVsolProtocol(false);
                  } else {
                    //it's a vsol
                    setSelectedProtocol("");
                    setIsVsolProtocol(true);
                  }
                }

                setOltData({
                  ...oltData,
                  intPkBrandID: e.target.value,
                });
              }}
              value={oltData.intPkBrandID}
            >
              <option value="">Choose OLT Brand</option>
              {brands.map((item: any) => {
                return (
                  <option value={item.intPkBrandID} key={item.intPkBrandID}>
                    {item.brandName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          {isVsolProtocol == true ? (
            <Form.Group className="mb-3">
              <Form.Label> Choose Technology</Form.Label>
              <Form.Select
                onChange={(e) => {
                  const models = actualOntModels.filter(
                    (item: any) => item.technology == e.target.value
                  );
                  setOltModels(models);
                  setSelectedProtocol(e.target.value);
                }}
                value={selectedProtocol}
              >
                <option value="">Choose Technology</option>
                <option value="EPON">EPON</option>
                <option value="GPON">GPON</option>
              </Form.Select>
            </Form.Group>
          ) : null}

          {renderInput(`host`, "Enter OLT IP", `Enter OLT IP`)}
          <Row>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  <input
                    style={{
                      width: "25px",
                      height: "25px",
                      margin: "0 12px 0 0",
                      verticalAlign: "middle",
                      position: "relative",
                    }}
                    type="checkbox"
                    checked={isTelNetAuto}
                    onChange={(e) => {
                      setIsTelNetAuto(e.target.checked);
                    }}
                  />
                  Tel/SSH Auto Generated
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {isTelNetAuto === false ? (
                <Form.Group className="mb-3">
                  <Form.Label>Enter TEL/SSH PORT</Form.Label>
                  <Form.Control
                    placeholder={`${`Enter TEL/SSH PORT`}`}
                    type="text"
                    value={oltData.port}
                    disabled={isTelNetAuto}
                    onChange={(e) => {
                      setOltData({
                        ...oltData,
                        port: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              <Form.Group className="mb-3">
                <input
                  style={{
                    width: "25px",
                    height: "25px",
                    margin: "0 12px 0 0",
                    verticalAlign: "middle",
                    position: "relative",
                  }}
                  type="checkbox"
                  checked={isNetflowAuto}
                  onChange={(e) => {
                    setIsNetflowAuto(e.target.checked);
                  }}
                />
                <Form.Label>SNMP Auto Generated</Form.Label>
              </Form.Group>
            </Col>{" "}
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {isNetflowAuto === false ? (
                <Form.Group className="mb-3">
                  <Form.Label>Enter SNMP PORT</Form.Label>
                  <Form.Control
                    placeholder={`${`Enter SNPM PORT`}`}
                    type="text"
                    value={oltData.netFlowPort}
                    disabled={isNetflowAuto}
                    onChange={(e) => {
                      setOltData({
                        ...oltData,
                        netFlowPort: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              ) : null}
            </Col>{" "}
          </Row>
          <Row>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              <Form.Group className="mb-3">
                <input
                  style={{
                    width: "25px",
                    height: "25px",
                    margin: "0 12px 0 0",
                    verticalAlign: "middle",
                    position: "relative",
                  }}
                  type="checkbox"
                  checked={isWebAccessAuto}
                  onChange={(e) => {
                    setIsWebAccessAuto(e.target.checked);
                  }}
                />
                <Form.Label>Web Access Auto Generated</Form.Label>
              </Form.Group>
            </Col>
            <Col md={6} lg={6} xl={6} xxl={6} sm={12} xs={12}>
              {isWebAccessAuto === false ? (
                <Form.Group className="mb-3">
                  <Form.Label>Enter Web Access PORT</Form.Label>
                  <Form.Control
                    placeholder={`${`Enter Web Access PORT`}`}
                    type="text"
                    disabled={isWebAccessAuto}
                    value={oltData.oltWebAccessPort}
                    // disabled={}
                    onChange={(e) => {
                      setOltData({
                        ...oltData,
                        oltWebAccessPort: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              ) : null}
            </Col>
          </Row>
          {/* {renderInput(`netflowEventSecretKey`, "Netflow", "Netflow")} */}
          {/* {renderInput(
                `netflowReadString`,
                "Enter Netflow Read String",
                "Enter Netflow Read String"
              )}
              {renderInput(
                `netflowWriteString`,
                "Enter Netflow Write String",
                "Enter Netflow Write String"
              )} */}
          {renderInput(
            `telnetUserName`,
            "Enter Telnet username",
            `Enter Telnet username`
          )}
          {renderInput(
            `telnetPassword`,
            "Enter Telnet password",
            `Enter Telnet password`
          )}
          <button
            onClick={handleCreateOLT}
            disabled={oltLoading}
            style={{ minWidth: "100%" }}
            className="button-primary"
            type="submit"
          >
            Create
          </button>
        </Form>
      </Card>
    );
  };

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          {getActiveComponent(activeStep)}
        </Col>
      </Row>
    </>
  );
};
export default CreateAutoOLTCompanyAdminComponent;
