import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table } from "react-bootstrap";
import "./../../assets/styles/olt.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
import Alert from "react-bootstrap/Alert";
import UpIcon from "./../../assets/svgs/icons/success.svg";
import FailedIcon from "./../../assets/svgs/icons/failedIcon.svg";
import FailedIconWhiteBG from "./../../assets/svgs/icons/failedIconWhiteBG.svg";
import LoadingIconWhiteBG from "./../../assets/svgs/icons/LoadingIconWhiteBG.svg";
import LoadingIcon from "./../../assets/svgs/icons/loadingIcon.svg";
import { Form } from "react-bootstrap";
import { notifyError } from "../toast/Error";

const AutoOLTCreationThirdStepCard: React.FC<OLTThirdStepCardProp> = ({
  intPkOltID,
  oltName,
  host,
  handleMoveToNextStep,
  port,
  netFlowPort,
  netFlowBotName,
  sysEventBotName,
  oltWebAccessPort,
}) => {
  const oltThirdStepRef = useRef<any>(null);

  // ! Delete olt
  const [
    postDeleteOLT,
    oltDeleteResponse,
    oltDeleteError,
    oltDeleteLoading,
    olDeleteMessage,
    oltDeleteErrorCount,
  ] = useAxiosPost<DeleteOLTProp>(
    "olt/deleteOltAndEmailAfterFailingInstallation"
  );
  useEffect(() => {
    if (oltDeleteError !== null && oltDeleteErrorCount !== 0) {
      notifyError(oltDeleteError);
    }
  }, [oltDeleteError, oltDeleteErrorCount]);
  useEffect(() => {
    if (oltDeleteResponse !== null) {
      notifySuccess(
        oltDeleteResponse.message ||
          "Olt Deleted Successfully And Emailed Details"
      );
      setTimeout(() => {
        // window.location.reload();
      }, 3000);
    }
  }, [oltDeleteResponse]);

  // ! Delete olt end
  const [
    postUpdateOLTStatus,
    oltStatusResponse,
    statusError,
    statusLoading,
    statusMessage,
    statusErrorCount,
  ] = useAxiosPost<UpdateOLTStatus>(
    "olt/createSubscriptionForOltWithStartMonitoring"
  );

  useEffect(() => {
    if (statusError !== null && statusErrorCount !== 0) {
      notifyError(statusError);
    }
  }, [statusError, statusErrorCount]);
  useEffect(() => {
    if (oltStatusResponse !== null) {
      const { responseData, message } = oltStatusResponse;
      notifySuccess(`Monitoring Started`);
      handleMoveToNextStep();
    }
  }, [oltStatusResponse]);
  const [currentStepState, setCurrentStepState] = useState<number>(1);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [isMessageShown, setIsMessageShown] = useState<boolean>(false);
  const [conflictMessage, setConflictMessage] = useState<any>(null);
  const [installationStatus, setInstallationStatus] = useState<any>({
    telNetStatus: 0,
    netflowStatus: 0,
    fireWallRulesStatus: 0,
    sysEventsConfigStatus: 0,
    ntpServerStatus: 0,
    serialNumberMacAddressConflictStatus: 0,
    portsConflictStatus: 0,
  });
  // * First Step
  const [
    postFirstStepConfig,
    firstStepConfigResponse,
    firstStepConfigError,
    firstStepConfigLoading,
    firstStepConfigMessage,
    firstStepConfigErrorCount,
  ] = useAxiosPost<PreTestOltProp>("configure/configureTelentInOLT", true);
  useEffect(() => {
    if (firstStepConfigError !== null && firstStepConfigErrorCount !== 0) {
      notifyError(firstStepConfigError);
    }
  }, [firstStepConfigError, firstStepConfigErrorCount]);
  // * First Step End

  // * Second Step
  const [
    postSecondStepConfig,
    secondStepConfigResponse,
    secondStepConfigError,
    secondStepConfigLoading,
    secondStepConfigMessage,
    secondStepConfigErrorCount,
  ] = useAxiosPost<PreTestOltProp>("configure/configureNetflowInOLT", true);
  useEffect(() => {
    if (secondStepConfigError !== null && secondStepConfigErrorCount !== 0) {
      notifyError(secondStepConfigError);
    }
  }, [secondStepConfigError, secondStepConfigErrorCount]);
  // * Second Step End

  // * Third Step

  const [
    postThirdStepConfig,
    thirdStepConfigResponse,
    thirdStepConfigError,
    thirdStepConfigLoading,
    thirdStepConfigMessage,
    thirdStepConfigErrorCount,
  ] = useAxiosPost<PreTestOltProp>("configure/configureFireWallInOLT", true);
  useEffect(() => {
    if (thirdStepConfigError !== null && thirdStepConfigErrorCount !== 0) {
      notifyError(thirdStepConfigError);
    }
  }, [thirdStepConfigError, thirdStepConfigErrorCount]);
  // * Third Step End

  // * Fourth Step

  const [
    postFourthStepConfig,
    fourthStepConfigResponse,
    fourthStepConfigError,
    fourthStepConfigLoading,
    fourthStepConfigMessage,
    fourthStepConfigErrorCount,
  ] = useAxiosPost<PreTestOltProp>("configure/configureSysEventsInOLT", true);
  useEffect(() => {
    if (fourthStepConfigError !== null && fourthStepConfigErrorCount !== 0) {
      notifyError(fourthStepConfigError);
    }
  }, [fourthStepConfigError, fourthStepConfigErrorCount]);
  // * Fourth Step End

  // * Fifth Step

  const [
    postFifthStepConfig,
    fifthStepConfigResponse,
    fifthStepConfigError,
    fifthStepConfigLoading,
    fifthStepConfigMessage,
    fifthStepConfigErrorCount,
  ] = useAxiosPost<PreTestOltProp>("configure/configureNTPServerIPInOLT", true);
  useEffect(() => {
    if (fifthStepConfigError !== null && fifthStepConfigErrorCount !== 0) {
      notifyError(fifthStepConfigError);
    }
  }, [fifthStepConfigError, fifthStepConfigErrorCount]);
  // * Fifth Step End

  // * Sixth Step

  const [
    postSixthStepConfig,
    sixthStepConfigResponse,
    sixthStepConfigError,
    sixthStepConfigLoading,
    sixthStepConfigMessage,
    sixthStepConfigErrorCount,
  ] = useAxiosPost<PreTestOltProp>(
    "configure/checkMacAddressSerialNumberConflict",
    true
  );
  useEffect(() => {
    if (sixthStepConfigError !== null && sixthStepConfigErrorCount !== 0) {
      notifyError(sixthStepConfigError);
    }
  }, [sixthStepConfigError, sixthStepConfigErrorCount]);
  // * Sixth Step End

  // * Seventh Step

  const [
    postSeventhStepConfig,
    SeventhStepConfigResponse,
    SeventhStepConfigError,
    SeventhStepConfigLoading,
    SeventhStepConfigMessage,
    SeventhStepConfigErrorCount,
  ] = useAxiosPost<PreTestOltProp>("configure/checkNoOfPortsInOlt", true);
  useEffect(() => {
    if (SeventhStepConfigError !== null && SeventhStepConfigErrorCount !== 0) {
      notifyError(SeventhStepConfigError);
    }
  }, [SeventhStepConfigError, SeventhStepConfigErrorCount]);
  // * Seventh Step End

  // * Install Status Step
  const [
    getInstallStatus,
    getInstallStatusResponse,
    getInstallStatusError,
    getInstallStatusLoading,
    getInstallStatusMessage,
    getInstallStatusErrorCount,
  ] = useAxiosPost<PreTestOltProp>("superCoo/getInstallStatus");
  useEffect(() => {
    if (getInstallStatusError !== null && getInstallStatusErrorCount !== 0) {
      // notifyError(getInstallStatusError);
    }
  }, [getInstallStatusError, getInstallStatusErrorCount]);
  useEffect(() => {
    if (isFailed === true) {
      return;
    }
    if (currentStepState !== 1) {
      if (currentStepState === 2) {
        postSecondStepConfig({ intPkOltID });
      }
      if (currentStepState === 3) {
        postThirdStepConfig({ intPkOltID });
      }
      if (currentStepState === 4) {
        postFourthStepConfig({ intPkOltID });
      }
      if (currentStepState === 5) {
        postFifthStepConfig({ intPkOltID });
      }
      if (currentStepState === 6) {
        postSixthStepConfig({ intPkOltID });
      }
      if (currentStepState === 7) {
        postSeventhStepConfig({ intPkOltID });
      }
    }
  }, [currentStepState]);
  useEffect(() => {
    console.log("here we are 2");
    console.log({ installationStatus });
    const { serialNumberMacAddressConflictStatus } = installationStatus;
    if (Number(serialNumberMacAddressConflictStatus) === -1) {
      //it means we need to delete this
      postDeleteOLT({ intPkOltID });
    }
  }, [installationStatus]);
  useEffect(() => {
    if (getInstallStatusResponse !== null) {
      const responseData: any = getInstallStatusResponse.responseData;
      const olt = responseData.olt;
      const step = responseData.step;
      const isFailedAtAnyStep = responseData.isFailedAtAnyStep;
      const conflictMessage = responseData.conflictMessage || null;
      setConflictMessage(conflictMessage);
      setIsFailed(isFailedAtAnyStep);
      if (isFailedAtAnyStep == true) {
        clearInterval(oltThirdStepRef.current);

        if (isMessageShown === false) {
          notifyError(`Installation Failed`);
          setIsMessageShown(true);
          clearInterval(oltThirdStepRef.current);
        }
      }
      if (step !== 1) {
        setCurrentStepState(step);
      }
      console.log({ olt });
      console.log("new new new");

      const {
        telNetStatus,
        netflowStatus,
        fireWallRulesStatus,
        sysEventsConfigStatus,
        ntpServerStatus,
        serialNumberMacAddressConflictStatus,
        portsConflictStatus,
      } = olt;
      console.log("new new new 2");

      setInstallationStatus({
        telNetStatus,
        netflowStatus,
        fireWallRulesStatus,
        sysEventsConfigStatus,
        ntpServerStatus,
        serialNumberMacAddressConflictStatus,
        portsConflictStatus,
      });
    }
  }, [getInstallStatusResponse]);
  // * Install Status Step End
  useEffect(() => {
    postFirstStepConfig({ intPkOltID });
    oltThirdStepRef.current = setInterval(() => {
      getInstallStatus({ intPkOltID });
    }, 3000);

    return () => {
      clearInterval(oltThirdStepRef.current); // Clear the interval when the component unmounts
    };
  }, []);

  const getStats = () => {
    return (
      <>
        <Table bordered>
          <thead>
            <tr>
              <th className="table-header-standard">Olt Name</th>
              <th className="table-header-standard">Host</th>
              <th className="table-header-standard">Sys Events Bot</th>
              <th className="table-header-standard">Netflow Bot Name</th>
            </tr>
            <tr>
              <td>{oltName}</td>
              <td>{host}</td>
              <td>{sysEventBotName}</td>
              <td>{netFlowBotName}</td>
            </tr>
          </thead>
        </Table>
        <Table bordered className="mt-5">
          <thead>
            <tr>
              <th className="table-header-standard">Port</th>
              <th className="table-header-standard">Netflow Port</th>
              <th className="table-header-standard">Olt Web Access Port</th>
            </tr>
            <tr>
              <td>{port}</td>
              <td>{netFlowPort}</td>
              <td>{oltWebAccessPort}</td>
            </tr>
          </thead>
        </Table>
      </>
    );
  };
  const SuccessAlert = (text: string) => {
    return (
      <>
        <div
          style={{
            background: "#00CC604D",
            color: "#696969",
            minHeight: "60px",
            lineHeight: "60px",
            borderRadius: "5px",
            border: "1px solid #4CC4674D",
          }}
          className="mt-3 text-center"
        >
          <span style={{ float: "left", marginLeft: "15px" }}>{text}</span>
          <span style={{ float: "right", marginRight: "15px" }}>
            {" "}
            <img src={UpIcon} />
          </span>
        </div>
      </>
    );
  };
  const DangerAlert = (text: string) => {
    return (
      <>
        <div
          style={{
            background: "#FF262B4D",
            color: "#696969",
            minHeight: "60px",
            lineHeight: "60px",
            borderRadius: "5px",
            border: "1px solid #FF262B4D",
          }}
          className="mt-3 text-center"
        >
          <span style={{ float: "left", marginLeft: "15px" }}>{text}</span>
          <span style={{ float: "right", marginRight: "15px" }}>
            {" "}
            <img src={FailedIcon} />
          </span>
        </div>
      </>
    );
  };
  const LoadingAlert = (text: string) => {
    return (
      <>
        <div
          style={{
            background: "#00ACEE4D",
            color: "#696969",
            minHeight: "60px",
            lineHeight: "60px",
            borderRadius: "5px",
            border: "1px solid #00ACEE4D",
          }}
          className="mt-3 text-center"
        >
          <span style={{ float: "left", marginLeft: "15px" }}>{text}</span>
          <span style={{ float: "right", marginRight: "15px" }}>
            {" "}
            <img src={LoadingIcon} />
          </span>
        </div>
      </>
    );
  };
  const StruckAlert = (text: string) => {
    return (
      <>
        <div
          style={{
            background: "#e2e3e5",
            color: "#a6a6a6",
            minHeight: "60px",
            lineHeight: "60px",
            borderRadius: "5px",
            border: "1px solid #d6d8db",
          }}
          className="mt-3 text-center"
        >
          <span style={{ float: "left", marginLeft: "15px" }}>{text}</span>
        </div>
      </>
    );
  };
  const getAlert = (status: number, text: string) => {
    console.log({ status, text, isFailed });
    if (isFailed === true && status === 0) {
      return StruckAlert(text);
    }
    if (status === 1) {
      return SuccessAlert(text);
    }
    if (status === -1) {
      return DangerAlert(text);
    }
    if (status === 0) {
      return LoadingAlert(text);
    }
  };
  const getButtonText = () => {
    if (isFailed === true) {
      return (
        <span>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              marginRight: "13px",
              lineHeight: "58px",
              display: "inline-block",
              height: "60px",
            }}
          >
            Failed
          </span>
          <img src={FailedIconWhiteBG} style={{ marginTop: "-10px" }} />
        </span>
      );
    }
    if (currentStepState !== 8) {
      return (
        <span>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              marginRight: "13px",
              lineHeight: "58px",
              display: "inline-block",
              height: "60px",
            }}
          >
            Installing
          </span>
          <img src={LoadingIconWhiteBG} style={{ marginTop: "-10px" }} />
        </span>
      );
    }
    return "Start Monitoring";
  };

  const getButtonClass = () => {
    if (isFailed === true) {
      return `button-primary failed-process`;
    }
    if (currentStepState !== 8) {
      return `button-primary installing-process`;
    }
    return `button-primary`;
  };

  return (
    <>
      <ToastContainer />

      {/* <Alert
        style={{ background: "#00CC604D", color: "#00CC60" }}
        className="mt-3 text-center"
        variant={"success"}
      >
        Success <img src={UpIcon} />
      </Alert> */}
      {getStats()}

      <Row>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          {getAlert(
            Number(installationStatus.telNetStatus || 0),
            "TelNet Configuration"
          )}
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          {getAlert(
            Number(installationStatus.netflowStatus || 0),
            "Netflow Configuration"
          )}
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          {getAlert(
            Number(installationStatus.fireWallRulesStatus || 0),
            "FireWall"
          )}
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          {getAlert(
            Number(installationStatus.sysEventsConfigStatus || 0),
            "Sys Events"
          )}
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          {getAlert(
            Number(installationStatus.ntpServerStatus || 0),
            "NTP Server"
          )}
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          {getAlert(
            Number(
              installationStatus.serialNumberMacAddressConflictStatus || 0
            ),
            "Technology , Serial Number and Mac Address Conflict Checking "
          )}
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          {getAlert(
            Number(installationStatus.portsConflictStatus || 0),
            "Olt Ports Checking"
          )}
        </Col>
        <Row className="mb-3 mt-1"></Row>
        {conflictMessage !== null ? (
          <Row className="mb-3 mt-1">
            <h3
              style={{
                fontSize: "20px",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              Conflict:{" "}
              <span style={{ color: "#ff262b" }}> {conflictMessage}</span>{" "}
            </h3>
          </Row>
        ) : null}
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (isFailed == true) {
                return;
              }
              postUpdateOLTStatus({ intPkOltID, value: 1 });
            }}
            disabled={currentStepState !== 8 || isFailed === true}
            className={getButtonClass()}
            style={{
              minWidth: "100%",
            }}
          >
            {getButtonText()}
          </button>
        </Col>
      </Row>
    </>
  );
};
export default AutoOLTCreationThirdStepCard;
