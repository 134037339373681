import BrowseSingleONTComponent from "../../components/admin/BrowseSingleONT";

const BrowseSingleONTPage = () => {
  return (
    <>
      <BrowseSingleONTComponent />
    </>
  );
};
export default BrowseSingleONTPage;
