import AllONTsModelsComponent from "../../components/admin/models/AllOntModels";

const AllOntModelsPage = () => {
  return (
    <>
      <AllONTsModelsComponent />
    </>
  );
};
export default AllOntModelsPage;
