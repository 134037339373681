import React, { useEffect, useState, ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";

import { toast } from "react-toastify";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
const AssignBotsToOLTModal: React.FC<BotAssignmentModelProp> = ({
  handleClose,
  show,
  intPkOltID,
  intPkBotNodeID,
  intPkNetFlowBotNodeID,
  intPkBotNodeM2MOLTID,
  intPkNetFlowBotNodeM2MOLTID,
  oltName,
}) => {
  const [
    getApiData,
    apiResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("superCoo/getNodes");

  const [
    postBotData,
    linkedBotsResponse,
    linkingError,
    linkingLoading,
    vlanMessage,
    linkingErrorCount,
  ] = useAxiosPost<AssignNodesToOLT>("superCoo/assignBotNodeToOLT");
  const CreateVlanValidationSchema = yup.object().shape({
    intPkBotNodeID: yup
      .number()
      .integer()
      .required()
      .min(1, "Invalid Bot Node ID"),
    intPkNetFlowBotNodeID: yup
      .number()
      .integer()
      .required()
      .min(1, "Invalid NetflowbotNodeId"),
    intPkOltID: yup.number().integer().required().min(1, "Invalid OLT"),
  });
  const [botNodes, setBotNodes] = useState<any>([]);
  const [netFlowBotNodes, setNetFlowBotNodes] = useState<any>([]);

  const [botsData, setBotsData] = useState<AssignNodesToOLT>({
    intPkBotNodeID: "",
    intPkNetFlowBotNodeID: "",
    intPkOltID: "",
    intPkBotNodeM2MOLTID: "",
    intPkNetFlowBotNodeM2MOLTID: "",
  });
  useEffect(() => {
    getApiData({});
  }, []);

  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponseData !== null) {
      const responseData: any = apiResponseData.responseData;
      setNetFlowBotNodes(responseData.netFlowBotNodes || []);
      setBotNodes(responseData.botNodes || []);
      setBotsData({
        intPkOltID: intPkOltID,
        intPkBotNodeID: intPkBotNodeID,
        intPkNetFlowBotNodeID: intPkNetFlowBotNodeID,
        intPkBotNodeM2MOLTID: intPkBotNodeM2MOLTID,
        intPkNetFlowBotNodeM2MOLTID: intPkNetFlowBotNodeM2MOLTID,
      });
      //   setAllISPS(responseData || []);
    }
  }, [apiResponseData]);

  //   useEffect(() => {
  //     console.log({ intPkNetFlowBotNodeID, intPkBotNodeID, intPkOltID });
  //     setBotsData({
  //       intPkOltID: intPkOltID,
  //       intPkBotNodeID: intPkBotNodeID,
  //       intPkNetFlowBotNodeID: intPkNetFlowBotNodeID,
  //     });
  //   }, []);
  const handleCreateVlan = async (event: any) => {
    event.preventDefault();
    const dataToSend: AssignNodesToOLT = {
      intPkOltID: Number(botsData.intPkOltID),
      intPkBotNodeID: Number(botsData.intPkBotNodeID),
      intPkNetFlowBotNodeID: Number(botsData.intPkNetFlowBotNodeID),
      intPkBotNodeM2MOLTID: Number(botsData.intPkBotNodeM2MOLTID),
      intPkNetFlowBotNodeM2MOLTID: Number(botsData.intPkNetFlowBotNodeM2MOLTID),
    };
    console.log({ dataToSend });
    try {
      const isValid = await CreateVlanValidationSchema.validate(dataToSend);

      postBotData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (linkingError !== null && linkingErrorCount !== 0) {
      notifyError(linkingError);
    }
  }, [linkingError, linkingErrorCount]);
  useEffect(() => {
    if (linkedBotsResponse !== null) {
      const { message } = linkedBotsResponse;
      if (message) {
        notifySuccess(message);
      }
      handleClose(true);
    }
  }, [linkedBotsResponse]);

  return (
    <>
      <Modal
        show={show}
        keyboard={false}
        backdrop="static"
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Select Nodes For {oltName} <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label> Select Bot Node</Form.Label>
            <Form.Select
              value={botsData.intPkBotNodeID}
              onChange={(e) => {
                setBotsData({
                  ...botsData,
                  intPkBotNodeID: e.target.value,
                });
              }}
            >
              <option value={""}>Choose Bot Node</option>
              {botNodes.map((item: any) => {
                return (
                  <option key={item.intPkBotNodeID} value={item.intPkBotNodeID}>
                    {item.botNodeName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label> Select NetFlow Bot Node</Form.Label>
            <Form.Select
              value={botsData.intPkNetFlowBotNodeID}
              onChange={(e) => {
                setBotsData({
                  ...botsData,
                  intPkNetFlowBotNodeID: e.target.value,
                });
              }}
            >
              <option value={""}>Choose Bot Node</option>
              {netFlowBotNodes.map((item: any) => {
                return (
                  <option
                    key={item.intPkNetFlowBotNodeID}
                    value={item.intPkNetFlowBotNodeID}
                  >
                    {item.botNodeName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateVlan}
              disabled={linkingLoading}
              className="button-primary"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AssignBotsToOLTModal;
