import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { notifyError } from "../../toast/Error";
import { notifySuccess } from "../../toast/Success";
import "./../../../assets/styles/olt.css";
import "./../../../assets/styles/ont-list.css";
import { formatDate } from "../../../helpers/date";
type Props = {
  isLoggedIn?: boolean;
};

const AllSuperCOOsComponent: React.FC<Props> = ({}) => {
  const [
    getSuperCOOData,
    getSuperCOOResponse,
    COOError,
    COOLoading,
    COOMessage,
    COOErrorCount,
  ] = useAxiosPost<ResponseProp>("superAdmin/getAllSuperCOOs");
  const [superCOOs, setSuperCOOs] = useState<any>([]);
  useEffect(() => {
    getSuperCOOData({});
  }, []);

  useEffect(() => {
    if (COOError !== null && COOErrorCount !== 0) {
      notifyError(COOError);
    }
  }, [COOError, COOErrorCount]);
  useEffect(() => {
    if (getSuperCOOResponse !== null) {
      console.log({ getSuperCOOResponse });
      setSuperCOOs(getSuperCOOResponse.responseData || []);
    }
  }, [getSuperCOOResponse]);

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Super COOs
            </Card.Title>
            <Row className="mt-5">
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">User Full Name</th>
                      <th className="table-header-standard">UserName</th>
                      <th className="table-header-standard">Password</th>
                      <th className="table-header-standard">Contact Number</th>
                      <th className="table-header-standard">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {superCOOs.map((item: any) => {
                      return (
                        <tr key={item.intPkUserID}>
                          <td>{item.userFullname}</td>
                          <td>{item.username}</td>
                          <td>{item.password}</td>
                          <td>{item.contactNumber}</td>
                          <td>{formatDate(item.dateCreated)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllSuperCOOsComponent;
