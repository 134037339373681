import { useState } from "react";
import axios, { AxiosResponse, AxiosError } from "axios";
import { getAuthToken, handleLogout } from "../helpers/userDetails";
// const baseURL='http://localhost:5011/api/v1'
export const baseURL =
  process.env.REACT_APP_API_URL || "https://nms.capolt.com/api/v2";
export const baseURLv3 =
  process.env.REACT_APP_API_URL_V3 || "https://nms.capolt.com/api/v3";

export const baseURLv1 =
  process.env.REACT_APP_API_URL_V1 || "http://mobile.capolt.fielder.live:8108";

export const authTokenString = getAuthToken();

interface ErrorResponse {
  error: string;
}

const useAxiosPost = <T>(
  url: string,
  isV3: boolean = false
): [
  (body: T) => Promise<void>,
  T | null,
  string | null,
  boolean,
  string,
  number
] => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errorCount, setErrorCount] = useState<number>(0);
  const API_URL = isV3 === true ? baseURLv3 : baseURL;
  const postData = async (body: T): Promise<void> => {
    setIsLoading(true);
    const axiosBody: any = {
      ...body,
      authTokenString,
    };
    try {
      const response: AxiosResponse<T> = await axios.post(
        `${API_URL}/${url}`,
        axiosBody,
        {
          headers: {
            Authorization: `Bearer ${authTokenString}`,
          },
        }
      );
      setData(response.data);
      setError(null);
    } catch (error: AxiosError<ErrorResponse> | any) {
      console.log(`Error Code`);
      if (
        Number(error?.response?.status) === 403 ||
        Number(error?.response?.status) === 401
      ) {
        //it means the call rejected with unauthorized so we need to logout this user.
        handleLogout();
      }
      setError(error.response?.data?.message || "Server Error");
      setErrorCount((prevCount) => prevCount + 1);
      setData(null);
    } finally {
      setIsLoading(false);

      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 5000);
    }
  };

  return [postData, data, error, isLoading, message, errorCount];
};

export default useAxiosPost;
