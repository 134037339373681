import AllEndUsersComponent from "../components/admin/AllEndUsers";

const AllEndUsersPage = () => {
  return (
    <>
      <AllEndUsersComponent />
    </>
  );
};
export default AllEndUsersPage;
