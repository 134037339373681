import AllVPNsComponentNoc from "../../components/NOC/AllVpnsComponentNOC";

const AllVpnsNocPage = () => {
  return (
    <>
      <AllVPNsComponentNoc />
    </>
  );
};
export default AllVpnsNocPage;
