import React, { useContext } from "react";
import { Col, Row, Table } from "react-bootstrap";
import "./../../assets/styles/olt.css";
import WindowsIcon from "./../../assets/svgs/logos/windows.svg";
import CiscoIcon from "./../../assets/svgs/logos/cisco.svg";
import HuaweiIcon from "./../../assets/svgs/logos/huawei.svg";
import CopyIcon from "./../../assets/svgs/copy/Copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { notifySuccess } from "../toast/Success";

const MacAddressesCard: React.FC<MacAddressesCardProp> = ({
  servicePortsArray,
}) => {
  const showMacAddresses = () => {
    return servicePortsArray.map((item: any, index: number) => {
      return (
        <tr>
          <td>{index + 1}</td>
          <td>
            {item?.macAddressArray?.length > 0
              ? item?.macAddressArray[0]?.defaultMac
              : ""}
            {item?.macAddressArray?.length > 0 ? (
              <>
                <CopyToClipboard
                  text={item?.macAddressArray[0]?.defaultMac || ""}
                  onCopy={() => {
                    console.log("copy default to clipboard");
                    notifySuccess(`Default Mac Address Copied`);
                  }}
                >
                  <img
                    src={CopyIcon}
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      marginRight: "8px",
                      marginLeft: "8px",
                    }}
                  />
                </CopyToClipboard>

                <CopyToClipboard
                  text={item?.macAddressArray[0]?.windows || ""}
                  onCopy={() => {
                    console.log("copy windows to clipboard");
                    notifySuccess(`Windows Mac Address Copied`);
                  }}
                >
                  <img
                    src={WindowsIcon}
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      marginRight: "8px",
                    }}
                  />
                </CopyToClipboard>

                <CopyToClipboard
                  text={item?.macAddressArray[0]?.cisco || ""}
                  onCopy={() => {
                    console.log("copy cisco to clipboard");
                    notifySuccess(`Cisco Mac Address Copied`);
                  }}
                >
                  <img
                    src={CiscoIcon}
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      marginRight: "8px",
                    }}
                  />
                </CopyToClipboard>
                <CopyToClipboard
                  text={item?.macAddressArray[0]?.huawei || ""}
                  onCopy={() => {
                    console.log("copy huawei to clipboard");
                    notifySuccess(`Huawei Mac Address Copied`);
                  }}
                >
                  <img
                    src={HuaweiIcon}
                    style={{
                      cursor: "pointer",

                      width: "30px",
                      marginRight: "8px",
                    }}
                  />
                </CopyToClipboard>
              </>
            ) : (
              ""
            )}
          </td>
          <td>{item?.vlanName}</td>
          <td>
            {" "}
            <img src={item?.ispLogoUrl} style={{ maxWidth: "50px" }} />
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <ToastContainer />

      <Row>
        <Col md={12} lg={12} xl={12} xxl={12} sm={12} className="mt-5">
          <Table>
            <thead>
              <th></th>
              <th className="data-r-label">Mac Address</th>
              <th className="data-r-label">Vlan</th>
              <th className="data-r-label">ISP</th>
            </thead>
            <tbody>{showMacAddresses()}</tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
export default MacAddressesCard;
