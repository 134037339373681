import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Routes from "./routes";
import { createContext } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const TitleContext = createContext<any>("default contextvalue");

function App() {
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const [titleText, setTitleText] = useState("OLTs");

  function titleTextChangeHandler(newTextValue: any) {
    setTitleText(newTextValue);
  }

  const titleContextValues = {
    titleText,
    titleTextChangeHandler,
  };
  return (
    <TitleContext.Provider value={titleContextValues}>
      <Routes />
    </TitleContext.Provider>
  );
}

export default App;
