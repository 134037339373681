import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import ModalForOLTUpdate from "../Model/UpdateOLTModal";
import ModalForNewAutoOLTCreation from "../Model/CreateNewAutoOLTModal";
import ModalForNewOLTCreation from "../Model/CreateNewOLTModal";

const AllOLTsComponentNOC: React.FC<GP> = ({}) => {
  const [showAuto, setShowAuto] = useState<boolean>(false);
  const [
    postDeleteOLT,
    oltDeleteResponse,
    oltDeleteError,
    oltDeleteLoading,
    olDeleteMessage,
    oltDeleteErrorCount,
  ] = useAxiosPost<DeleteOLTProp>("superCOO/deleteOLT");
  useEffect(() => {
    if (oltDeleteError !== null && oltDeleteErrorCount !== 0) {
      notifyError(oltDeleteError);
    }
  }, [oltDeleteError, oltDeleteErrorCount]);
  useEffect(() => {
    if (oltDeleteResponse !== null) {
      notifySuccess(oltDeleteResponse.message || "Olt Deleted Successfully");
      getAllOltsData({ intPkCompanyID: Number(intPkCompanyID) });
    }
  }, [oltDeleteResponse]);
  const [
    getAllOltsData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<OLTsByCompany>("olt/getAllOltsByCompany");

  const [
    postUpdateOLTStatus,
    oltStatusResponse,
    statusError,
    statusLoading,
    statusMessage,
    statusErrorCount,
  ] = useAxiosPost<UpdateOLTStatus>("superCOO/enableOrDisableOLT");

  const [
    postOltSync,
    syncOltResponse,
    syncOltError,
    syncOltLoading,
    syncOltMessage,
    syncOltErrorCount,
  ] = useAxiosPost<SyncOltProp>("olt/handleOLTSync");
  useEffect(() => {
    if (syncOltError !== null && syncOltErrorCount !== 0) {
      notifyError(syncOltError);
    }
  }, [syncOltError, syncOltErrorCount]);
  useEffect(() => {
    if (syncOltResponse !== null) {
      notifySuccess(`Olt Sync Start`);
    }
  }, [syncOltResponse]);
  const { intPkCompanyID, companyName } = useParams();
  const [allOlts, setAllOlts] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [activeOlt, setActiveOlt] = useState<any>({});

  useEffect(() => {
    getAllOltsData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { responseData } = oltResponseData;
      setAllOlts(responseData || []);
    }
  }, [oltResponseData]);

  useEffect(() => {
    if (statusError !== null && statusErrorCount !== 0) {
      notifyError(statusError);
    }
  }, [statusError, statusErrorCount]);
  useEffect(() => {
    if (oltStatusResponse !== null) {
      const { responseData, message } = oltStatusResponse;
      notifySuccess(message || "OLT Status Updated");
      getAllOltsData({ intPkCompanyID: Number(intPkCompanyID) });
    }
  }, [oltStatusResponse]);

  const handleClose = (isCall: boolean) => {
    setShow(false);
    setShowEdit(false);
    setShowAuto(false);
    setActiveOlt({});
    if (isCall === true) {
      getAllOltsData({ intPkCompanyID: Number(intPkCompanyID) });
    }
  };
  const handleUpdateOLTStatus = (intPkOltID: number, value: number) => {
    postUpdateOLTStatus({ intPkOltID, value });
  };
  const enableOLT = (oltName: string, intPkOltID: number) => {
    confirmAlert({
      title: `Enable OLT `,
      message: `Do you want to Enable ${oltName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just Enable it.");
            handleUpdateOLTStatus(intPkOltID, 1);
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const disableOLT = (oltName: string, intPkOltID: number) => {
    confirmAlert({
      title: `Disable OLT `,
      message: `Do you want to Disable ${oltName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just Disable it.");
            handleUpdateOLTStatus(intPkOltID, 0);
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const deleteOLT = (oltName: string, intPkOltID: number) => {
    confirmAlert({
      title: `Delete OLT `,
      message: `Do you want to Delete ${oltName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just Delete it.");
            postDeleteOLT({ intPkOltID: Number(intPkOltID) });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  return (
    <>
      <ToastContainer />
      {showEdit === true ? (
        <ModalForOLTUpdate
          companyName={companyName || ""}
          intPkCompanyID={Number(intPkCompanyID)}
          show={showEdit}
          handleClose={handleClose}
          oltData={activeOlt}
        />
      ) : null}
      {show === true ? (
        <ModalForNewOLTCreation
          companyName={companyName || ""}
          intPkCompanyID={Number(intPkCompanyID)}
          show={show}
          handleClose={handleClose}
        />
      ) : null}
      {showAuto === true ? (
        <ModalForNewAutoOLTCreation
          companyName={companyName || ""}
          intPkCompanyID={Number(intPkCompanyID)}
          show={showAuto}
          handleClose={handleClose}
        />
      ) : null}

      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All OLTs Of {companyName}
              <span style={{ float: "right", marginLeft: "20px" }}>
                <Button
                  variant="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                >
                  Create New OLT
                </Button>
              </span>
              <span style={{ float: "right" }}>
                <Button
                  variant="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAuto(true);
                  }}
                >
                  Create New OLT With Configuration
                </Button>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">OLT Name</th>
                      <th className="table-header-standard">Brand</th>
                      <th className="table-header-standard">Model</th>
                      <th className="table-header-standard">Host</th>
                      <th className="table-header-standard">Port</th>
                      <th className="table-header-standard">BotNode</th>
                      <th className="table-header-standard">NetFlowBotNode</th>
                      <th className="table-header-standard">Sync</th>
                      <th className="table-header-standard">View Slots</th>
                      <th className="table-header-standard">Manage Vlans</th>
                      <th className="table-header-standard">Status</th>
                      <th className="table-header-standard">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allOlts.map((olt: any) => {
                      return (
                        <tr key={olt.intPkOltID}>
                          <td>{olt.oltName}</td>
                          <td>{olt.brand}</td>
                          <td>{olt.model}</td>
                          <td>{olt.host}</td>
                          <td>{olt.port}</td>
                          <td>
                            {olt.nodes.botNode !== false
                              ? olt.nodes.botNode.botNodeName
                              : "-"}
                          </td>
                          <td>
                            {olt.nodes.netFlowBotNode !== false
                              ? olt.nodes.netFlowBotNode.botNodeName
                              : "-"}
                          </td>
                          <td>
                            {olt.brand === "VSOL" ? (
                              <Button
                                variant="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  postOltSync({
                                    intPkOltID: Number(olt.intPkOltID),
                                  });
                                }}
                              >
                                Sync
                              </Button>
                            ) : (
                              "-"
                            )}
                          </td>

                          <td>
                            {olt.brand === "Huawei" ? (
                              <Link
                                className="button-blue link-class"
                                to={`/all-slots-by-olt/${olt.intPkOltID}/${olt.oltName}`}
                              >
                                Manage Slots
                              </Link>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            <Link
                              className="button-blue link-class"
                              to={`/all-vlans-by-olt/${olt.intPkOltID}/${olt.oltName}/${olt.brand}`}
                            >
                              Manage Vlans
                            </Link>
                          </td>
                          <td>
                            {olt.enable == 0 ? (
                              <>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    enableOLT(olt.oltName, olt.intPkOltID);
                                  }}
                                  variant="primary"
                                >
                                  Enable
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    disableOLT(olt.oltName, olt.intPkOltID);
                                  }}
                                  variant="danger"
                                >
                                  Disable
                                </Button>
                              </>
                            )}
                          </td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveOlt(olt);
                                setShowEdit(true);
                              }}
                              variant="warning"
                            >
                              Update
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();

                                deleteOLT(olt.oltName, olt.intPkOltID);
                              }}
                              variant="danger"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllOLTsComponentNOC;
