import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import PieChartComponent from "../Charts/Pie";
import { Col, Row, Container, Form, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./../../assets/styles/olt.css";
import PonPortImage from "./../../assets/pngs/ponport/ponport.png";
import VsolPonPortImage from "./../../assets/pngs/ponport/VSOL_ponport.png";
import UnlockIcon from "./../../assets/svgs/icons/unlockIcon.svg";
import RebootActionIcon from "./../../assets/svgs/icons/rebootOntActionIcon.svg";
import DeleteActionIcon from "./../../assets/svgs/icons/deleteOntActionIcon.svg";
import LinkButtonImage from "./../../assets/svgs/goto/Link.svg";
import ColdTemprature from "./../../assets/svgs/temprature/cold.svg";
import NormalTemprature from "./../../assets/svgs/temprature/normal.svg";
import UpsetTemprature from "./../../assets/svgs/temprature/upset.svg";
import TooHightTemprature from "./../../assets/svgs/temprature/tooHigh.svg";
import { confirmAlert } from "react-confirm-alert"; // Import
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import {
  faEllipsis,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UnlockPortModalComponent from "../Model/OpenUnlockPortModal";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";

const SinglePonPortCard: React.FC<SinglePonPortData> = ({
  healthStats,
  slotNumber,
  ponPortHealthStats,
  intPkOltID,
  intPkSlotID,
  oltName,
  oltPonPort,
  ponPortName,
  intPkPonPortID,
  dealers,
  intPkUserID,
  oltType,
  reloadTheApi,
  handleRebootOfWholePonPort,
  handleDeleteOfWholePonPort,
  isDisabled,
  oltPonPortSfpTxSignal,
  oltPonPortSfpTemperature,
  defaultPonPortVLAN,
}) => {
  const navigate = useNavigate();
  const [dealer, setDealer] = useState<number | string>(intPkUserID);
  const handleOpenOLT = (e: any) => {
    e.preventDefault();
    navigate(
      `/browse-onts-by-pon-ports/${intPkSlotID}/${slotNumber}/${oltName}/${intPkOltID}/${intPkPonPortID}/${oltPonPort}`
    );
  };
  const [showUnlockModal, setShowUnlockModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<number>(0);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateDealerOwnerShipProp>("admin/assignPonPortToDealer");
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponse !== null) {
      const message: any = apiResponse.message;
      notifySuccess(message || `Dealer Assigned To Pon Port`);
      reloadTheApi();
      //  handleClose();
    }
  }, [apiResponse]);
  const showTemperatureImage = () => {
    const temp = Number(oltPonPortSfpTemperature);
    if (temp < 1) {
      return (
        <img
          src={ColdTemprature}
          style={{ maxHeight: "27px", height: "27px" }}
        />
      );
    }
    if (temp >= 1 && temp <= 30) {
      return (
        <img
          src={NormalTemprature}
          style={{ maxHeight: "27px", height: "27px" }}
        />
      );
    }
    if (temp >= 31 && temp < 50) {
      return (
        <img
          src={UpsetTemprature}
          style={{ maxHeight: "27px", height: "27px" }}
        />
      );
    }
    if (temp >= 50) {
      return (
        <img
          src={TooHightTemprature}
          style={{ maxHeight: "27px", height: "27px" }}
        />
      );
    }
  };
  const getClassName = () => {
    const temp = Number(oltPonPortSfpTemperature);
    if (temp < 1) {
      return "cold-temprature";
    }
    if (temp >= 1 && temp <= 30) {
      return "normal-temprature";
    }
    if (temp >= 31 && temp <= 50) {
      return "upset-temprature";
    }
    if (temp > 50) {
      return "too-high-temprature";
    }
  };
  const handleClose = (isApiCall: boolean) => {
    setModalType(0);
    setShowUnlockModal(false);
  };
  const userRole = getUserType();
  return (
    <>
      {showUnlockModal === true ? (
        <>
          <UnlockPortModalComponent
            show={showUnlockModal}
            handleClose={handleClose}
            intPkPonPortID={intPkPonPortID}
            modalType={modalType}
          />
        </>
      ) : null}
      <Card style={{ marginBottom: "10px" }} className="text-center">
        <Card.Body>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12} xl={12} xxl={12}>
              <Card.Title
                className="olt-heading"
                style={{ cursor: "pointer" }}
                onClick={(e: any) => handleOpenOLT(e)}
              >
                {oltName}
              </Card.Title>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={12} sm={12} xs={12} xl={12} xxl={12}>
              <Card.Title
                className="olt-heading-small"
                style={{ cursor: "pointer" }}
                onClick={(e: any) => handleOpenOLT(e)}
              >
                <span style={{ float: "left" }}>PonPort #{oltPonPort}</span>{" "}
                <span style={{ float: "right" }}> {ponPortName}</span>
              </Card.Title>
            </Col>
            {oltType === 3 || oltType === 1 ? (
              <>
                <Col md={6} lg={6} sm={12} xs={12} xl={6} xxl={6}>
                  <span
                    style={{ float: "left" }}
                    className={`data-r-field olt-card-data-labels ${getClassName()}`}
                  >
                    {" "}
                    {showTemperatureImage()} {oltPonPortSfpTemperature}&deg;C
                  </span>
                </Col>
                <Col md={6} lg={6} sm={12} xs={12} xl={6} xxl={6}>
                  <span style={{ float: "right" }}>
                    <span className="signal-label">Tx Signal</span>:
                    <span className="signal-value-label">
                      {oltPonPortSfpTxSignal !== null
                        ? Number(oltPonPortSfpTxSignal) > 0
                          ? "+" + oltPonPortSfpTxSignal
                          : "-" + oltPonPortSfpTxSignal
                        : "No Signal"}
                    </span>
                  </span>
                </Col>
              </>
            ) : null}
            {userRole === "DEMO_ADMIN" || userRole === "ADMIN" ? (
              <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                <Form.Select
                  value={userRole === "DEMO_ADMIN" || "" ? "" : dealer}
                  disabled={userRole === "DEMO_ADMIN" || "" ? true : false}
                  onChange={(e) => {
                    setDealer(e.target.value);
                    confirmAlert({
                      title: "Change Dealer Ownership",
                      message: "Do you want to update the Dealer ?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            console.log(
                              "User clicked Yes so we just need to update"
                            );
                            console.log({ sss: e.target.value });
                            postApiData({
                              userId: e.target.value,
                              intPkPonPortID: intPkPonPortID,
                            });
                          },
                        },
                        {
                          label: "No",
                          onClick: () => {
                            setDealer(intPkUserID);
                            console.log(
                              "User clicked no so we need to just close everything"
                            );
                          },
                        },
                      ],
                      overlayClassName: "overlay-custom-class-name-for-alert",
                    });
                  }}
                >
                  <option value="">
                    {userRole === "DEMO_ADMIN" || ""
                      ? "Dealer Assigned"
                      : "Choose Dealer"}{" "}
                  </option>
                  {dealers.map((item: any) => {
                    return (
                      <option value={item.intPkUserID} key={item.intPkUserID}>
                        {item.userFullname}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            ) : null}

            {oltType === 1 ? (
              <>
                <Col md={9} lg={9} sm={12} xs={12} xl={9} xxl={9}>
                  <span style={{ float: "right" }}>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        {/* Three dots icon */}
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          style={{
                            marginRight: "10px",
                            marginTop: "5px",
                          }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className={`${
                            isDisabled === true
                              ? "pon-port-action-item-disabled"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            const role = getUserType();
                            if (
                              role === "" ||
                              role == "DEMO_DEALER" ||
                              role === "DEMO_ADMIN"
                            ) {
                              notifyDemoAccountError();
                              return;
                            }
                            if (isDisabled === true) {
                              return;
                            }
                            handleRebootOfWholePonPort(intPkPonPortID);
                          }}
                        >
                          <img src={RebootActionIcon} /> Reboot All ONTs Of Pon
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`${
                            isDisabled === true
                              ? "pon-port-action-item-disabled"
                              : ""
                          }`}
                          onClick={(e) => {
                            const role = getUserType();
                            if (
                              role === "" ||
                              role == "DEMO_DEALER" ||
                              role === "DEMO_ADMIN"
                            ) {
                              notifyDemoAccountError();
                              return;
                            }
                            e.preventDefault();
                            if (isDisabled === true) {
                              return;
                            }
                            handleDeleteOfWholePonPort(intPkPonPortID);
                          }}
                        >
                          <img src={DeleteActionIcon} /> Delete All ONTs Of Pon
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </Col>
              </>
            ) : null}
            {userRole === "DEMO_ADMIN" || userRole === "ADMIN" ? (
              <>
                {oltType === 2 ? (
                  <>
                    <Col md={9} lg={9} sm={12} xs={12} xl={9} xxl={9}>
                      <span style={{ float: "right" }}>
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            {/* Three dots icon */}
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              style={{
                                marginRight: "10px",
                                marginTop: "5px",
                              }}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();

                                setShowUnlockModal(true);
                                setModalType(1);
                              }}
                            >
                              <img
                                style={{
                                  marginTop: "-5px",
                                  marginRight: "5px",
                                }}
                                src={UnlockIcon}
                              />
                              Unlock Port
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();

                                setShowUnlockModal(true);
                                setModalType(2);
                              }}
                            >
                              <img
                                style={{
                                  marginTop: "-5px",
                                  marginRight: "5px",
                                }}
                                src={UnlockIcon}
                              />
                              Open Default Vlan
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </span>
                    </Col>
                  </>
                ) : null}
              </>
            ) : null}
          </Row>
        </Card.Body>
        {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}

        <Row>
          <Col md={12} lg={12} sm={12} xs={12} xl={12} xxl={12}>
            {oltType == 2 ? (
              <>
                <img
                  onClick={(e: any) => handleOpenOLT(e)}
                  style={{ cursor: "pointer" }}
                  src={PonPortImage}
                  className="olt-image-primary"
                />
              </>
            ) : (
              <img
                onClick={(e: any) => handleOpenOLT(e)}
                style={{ cursor: "pointer" }}
                src={VsolPonPortImage}
                className="olt-image-primary"
              />
            )}
            {/* <PieChartComponent pieChartData={healthStats} /> */}
          </Col>
          {/* <Col
            className="signal-value-label"
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            xxl={12}
          >
            Default Vlan :{defaultPonPortVLAN}
          </Col> */}

          <Col
            className="mb-5 mt-2 olt-stats-container"
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            xxl={12}
          >
            <Container>
              <Row className="justify-content-center">
                <Col md={3} lg={3} sm={12} xs={12} xl={3} xxl={3}>
                  <p>
                    <span className="online-circle"></span>{" "}
                    <span className="stats-counter">
                      {ponPortHealthStats.totalOnlineOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Online</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="offline-circle"></span>{" "}
                    <span className="stats-counter">
                      {ponPortHealthStats.totalOfflineOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Offline</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="upset-circle"></span>{" "}
                    <span className="stats-counter">
                      {ponPortHealthStats.totalUpsetOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">Upset</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  <p>
                    <span className="inactive-circle"></span>{" "}
                    <span className="stats-counter">
                      {ponPortHealthStats.totalInActiveOnts}
                    </span>
                  </p>
                  <p className="stats-counter-label">InActive</p>
                </Col>
                <Col md={2} lg={2} sm={12} xs={12} xl={2} xxl={2}>
                  {" "}
                  <p>
                    <span className="invisible-circle"></span>{" "}
                    <span className="stats-counter">
                      {ponPortHealthStats.totalInInvisibleOnts > 0
                        ? ponPortHealthStats.totalInInvisibleOnts
                        : 0}
                    </span>
                  </p>
                  <p className="stats-counter-label">InVisible</p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            className="mt-1 mb-3"
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            sm={12}
            xs={12}
          >
            <img
              onClick={(e: any) => handleOpenOLT(e)}
              style={{ cursor: "pointer" }}
              className="image-float-right"
              src={LinkButtonImage}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default SinglePonPortCard;
