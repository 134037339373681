import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import CreateDealerAreaModal from "../Model/CreateDealerAreaModal";

const DealerAreasComponent: React.FC<GP> = ({}) => {
  const [
    getMyAreasData,
    areasResponse,
    areasError,
    AreasLoading,
    areasMessage,
    areasErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getDealerAreas");
  const [allAreas, setAllAreas] = useState<any>([]);
  const [subAreas, setSubAreas] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isApiCall: boolean) => {
    if (isApiCall === true) {
      getMyAreasData({});
    }
    setShow(false);
  };
  const [selectedArea, setSelectedArea] = useState<string>("");
  useEffect(() => {
    getMyAreasData({});
  }, []);
  useEffect(() => {
    if (areasResponse !== null) {
      const responseData: any = areasResponse.responseData;
      setAllAreas(responseData || []);
      if (responseData.length > 0) {
        const area = responseData[0];
        setSelectedArea(area.intPkAreaID);
        setSubAreas(area.subAreas || []);
      }
    }
  }, [areasResponse]);
  return (
    <>
      <ToastContainer />
      {show === true ? (
        <CreateDealerAreaModal
          show={show}
          handleClose={handleClose}
          areas={allAreas}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              My Areas
              <span style={{ float: "right" }}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                  variant="primary"
                >
                  {" "}
                  New Area
                </Button>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}>
                <Form.Select
                  value={selectedArea}
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                    console.log(e.target.value);
                    if (e.target.value === "") {
                      setSubAreas([]);
                      return;
                    }
                    const area = allAreas.find(
                      (item: any) => item.intPkAreaID === Number(e.target.value)
                    );
                    console.log({ area });
                    setSubAreas(area.subAreas || []);
                  }}
                >
                  <option value="">Choose Area</option>
                  {allAreas.map((area: any) => {
                    return (
                      <option value={area.intPkAreaID} key={area.intPkAreaID}>
                        {area.areaName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Area Name</th>
                      <th className="table-header-standard">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subAreas.map((area: any) => {
                      return (
                        <tr key={area.intPkAreaID}>
                          <td>{area.areaName}</td>
                          <td>{formatDate(area.dateCreated)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default DealerAreasComponent;
