import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import { formatDate } from "../../helpers/date";

const AllAreasComponent: React.FC<GP> = ({}) => {
  const [
    getMyAreasData,
    areasResponse,
    areasError,
    AreasLoading,
    areasMessage,
    areasErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAllAreas");
  const [allAreas, setAllAreas] = useState<any>([]);

  useEffect(() => {
    getMyAreasData({});
  }, []);
  useEffect(() => {
    if (areasResponse !== null) {
      const { responseData } = areasResponse;
      setAllAreas(responseData || []);
    }
  }, [areasResponse]);
  return (
    <>
      <ToastContainer />

      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              My Areas
              <span style={{ float: "right" }}>
                <Link className="btn btn-primary" to="/create-new-area">
                  {" "}
                  New Area
                </Link>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Area Name</th>
                      <th className="table-header-standard">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allAreas.map((area: any) => {
                      return (
                        <tr key={area.intPkAreaID}>
                          <td>{area.areaName}</td>
                          <td>{formatDate(area.dateCreated)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllAreasComponent;
