import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { formatDate } from "../../helpers/date";
import { confirmAlert } from "react-confirm-alert"; // Import

const AllSanctionedOltsForAccountantComponent: React.FC<GP> = ({}) => {
  const [
    getPricePolicyData,
    getSuperCOOResponse,
    COOError,
    COOLoading,
    COOMessage,
    COOErrorCount,
  ] = useAxiosPost<ResponseProp>("accountant/getPendingSanctions");
  const [pricePolicies, setPricePolicies] = useState<any>([]);
  useEffect(() => {
    getPricePolicyData({});
  }, []);

  useEffect(() => {
    if (COOError !== null && COOErrorCount !== 0) {
      notifyError(COOError);
    }
  }, [COOError, COOErrorCount]);
  useEffect(() => {
    if (getSuperCOOResponse !== null) {
      console.log({ getSuperCOOResponse });
      setPricePolicies(getSuperCOOResponse.responseData || []);
    }
  }, [getSuperCOOResponse]);
  const [
    requestDeleteSanction,
    deleteSanctionResponse,
    deleteSanctionError,
    deleteSanctionLoading,
    deleteSanctionMessage,
    deleteSanctionErrorCount,
  ] = useAxiosPost<DeleteSanction>("accountant/deleteSanctionedOlt");
  useEffect(() => {
    if (deleteSanctionError !== null && deleteSanctionErrorCount !== 0) {
      notifyError(deleteSanctionError);
    }
  }, [deleteSanctionError, deleteSanctionErrorCount]);
  useEffect(() => {
    if (deleteSanctionResponse !== null) {
      console.log({ deleteSanctionResponse });
      notifySuccess(deleteSanctionResponse.message || "Sanction Deleted");
      getPricePolicyData({});
    }
  }, [deleteSanctionResponse]);
  const handleDelete = (intPkOltSanctionID: number) => {
    confirmAlert({
      title: "Delete",
      message: "Do you Want to Delete This Sanctioned OLT ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Delete");

            requestDeleteSanction({
              intPkOltSanctionID: Number(intPkOltSanctionID),
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  return (
    <>
      <ToastContainer />

      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Pending Sanctioned
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Company Name</th>
                      <th className="table-header-standard">Brand</th>
                      <th className="table-header-standard">Model</th>
                      <th className="table-header-standard">Price</th>
                      <th className="table-header-standard">
                        Trial Period Days
                      </th>
                      <th className="table-header-standard">Trail Allowed</th>
                      <th className="table-header-standard">
                        Disable Before Due Days
                      </th>
                      <th className="table-header-standard">Discount%</th>
                      <th className="table-header-standard">Sanctioned Date</th>
                      <th className="table-header-standard">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pricePolicies.map((item: any) => {
                      return (
                        <tr key={item.intPkOltSanctionID}>
                          <td>{item.companyName}</td>
                          <td>{item.brandName}</td>
                          <td>{item.oltModelName}</td>
                          <td>{item.price}</td>
                          <td>{item.trialPeriodDays}</td>
                          <td>
                            {item.isTrialAllowedOrNot == 1 ? "Yes" : "No"}
                          </td>
                          <td>{item.invoiceDueDaysBeforeDisable}</td>
                          <td>{item.discountPercentage}</td>
                          <td>{formatDate(item.dateCreated)}</td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                handleDelete(Number(item.intPkOltSanctionID));
                              }}
                              variant="danger"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllSanctionedOltsForAccountantComponent;
