import AllBanksComponentForSuperAdmin from "../../components/admin/AllBanksForSuperAdmin";

const AllBanksPageForSuperAdmin = () => {
  return (
    <>
      <AllBanksComponentForSuperAdmin />
    </>
  );
};
export default AllBanksPageForSuperAdmin;
