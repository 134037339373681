import React from "react";
import EmailAndPhoneNumberVerificationComponent from "../components/Auth/EmailAndPhoneNumberVerification";
const VerifyEmailAndPhoneNumberPage = () => {
  return (
    <>
      <EmailAndPhoneNumberVerificationComponent />
    </>
  );
};
export default VerifyEmailAndPhoneNumberPage;
