import AllVPNsComponent from "../components/admin/AllVPNsComponent";

const AllVPNsPage = () => {
  return (
    <>
      <AllVPNsComponent />
    </>
  );
};
export default AllVPNsPage;
