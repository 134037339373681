//NewOLTModelPage
import CreateNEWOLTModelComponent from "../../components/admin/models/CreateNEWOLTModel";

const NewOLTModelPage = () => {
  return (
    <>
      <CreateNEWOLTModelComponent />
    </>
  );
};
export default NewOLTModelPage;
