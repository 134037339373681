import React, { useEffect, useState, ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";

import { toast } from "react-toastify";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
import { removeSpacesFromString } from "../../helpers/strings";
const CompanyAdminResetStaffPasswordModal: React.FC<
  StaffPasswordChangeModalProp
> = ({ handleClose, show, intPkUserID, userFullname }) => {
  const [
    postBotData,
    linkedBotsResponse,
    linkingError,
    linkingLoading,
    vlanMessage,
    linkingErrorCount,
  ] = useAxiosPost<ResetPasswordPropForCompanyAdmin>(
    "admin/resetStaffPassword"
  );
  const UpdatePasswordForStaffValidationSchema = yup.object().shape({
    password: yup.string().required(),
  });

  const [passwordData, setPasswordData] =
    useState<ResetPasswordPropForCompanyAdmin>({
      password: "",
      intPkUserID: "",
      confirmPassword: "",
    });

  const handleCreateVlan = async (event: any) => {
    event.preventDefault();
    const dataToSend: ResetPasswordPropForCompanyAdmin = {
      password: passwordData.password,
      intPkUserID: passwordData.intPkUserID,
      confirmPassword: passwordData.confirmPassword,
    };

    try {
      const isValid = await UpdatePasswordForStaffValidationSchema.validate(
        dataToSend
      );
      if (dataToSend.password !== dataToSend.confirmPassword) {
        notifyError(`Password Mismatch`);
        return;
      }

      postBotData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };
  useEffect(() => {
    setPasswordData({
      ...passwordData,
      intPkUserID: intPkUserID,
    });
  }, []);
  useEffect(() => {
    if (linkingError !== null && linkingErrorCount !== 0) {
      notifyError(linkingError);
    }
  }, [linkingError, linkingErrorCount]);
  useEffect(() => {
    if (linkedBotsResponse !== null) {
      const { message } = linkedBotsResponse;
      if (message) {
        notifySuccess(message);
      }
      handleClose(true);
    }
  }, [linkedBotsResponse]);

  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Reset Password For {userFullname} <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label> Enter New Password</Form.Label>
            <Form.Control
              placeholder={`New Password`}
              type="text"
              value={passwordData.password}
              onChange={(e) => {
                setPasswordData({
                  ...passwordData,
                  password: removeSpacesFromString(e.target.value),
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label> Confirm Password</Form.Label>
            <Form.Control
              placeholder={`Confirm PasswordPassword`}
              type="text"
              value={passwordData.confirmPassword}
              onChange={(e) => {
                setPasswordData({
                  ...passwordData,
                  confirmPassword: removeSpacesFromString(e.target.value),
                });
              }}
            />
          </Form.Group>

          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateVlan}
              disabled={linkingLoading}
              className="button-primary"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CompanyAdminResetStaffPasswordModal;
