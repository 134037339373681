import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { useLocation, useParams } from "react-router-dom";
import SingleSlotCard from "../Cards/SingleSlotCard";
import { getSlotsOltWiseRoute } from "../../helpers/apiRoutes";
const BROWSE_SLOTS_ROUTE = getSlotsOltWiseRoute();
const BrowseSlotsComponent: React.FC<GP> = ({}) => {
  const [
    getAllSlotsData,
    slotsResponseData,
    slotError,
    slotLoading,
    oltMessage,
    slotErrorCount,
  ] = useAxiosPost<SlotResponseProp>(`${BROWSE_SLOTS_ROUTE}`);

  const [allSlots, setAllSlots] = useState<any>([]);
  const { intPkOltID, oltName } = useParams();

  useEffect(() => {
    getAllSlotsData({ intPkOltID: Number(intPkOltID) });
  }, [intPkOltID]);
  useEffect(() => {
    if (slotError !== null && slotErrorCount !== 0) {
      notifyError(slotError);
    }
  }, [slotError, slotErrorCount]);
  useEffect(() => {
    if (slotsResponseData !== null) {
      const { responseData } = slotsResponseData;
      if (responseData && Array.isArray(responseData))
        responseData.sort((a: any, b: any) => a.intPkSlotID - b.intPkSlotID);
      setAllSlots(responseData || []);
    }
  }, [slotsResponseData]);

  const prepareHealthStatsForSlot = (healthStats: any): pieData[] => {
    const {
      totalRegisteredOnts,
      totalOfflineOnts,
      totalUpsetOnts,
      totalOnlineOnts,
      totalInActiveOnts,
    } = healthStats;
    const data = [
      {
        // title: "Offline",
        value: Number(totalOfflineOnts) || 0,
        color: "red",
      },
      {
        // title: "Upset",
        value: Number(totalUpsetOnts) || 0,
        color: "yellow",
      },
      {
        // title: "Online",
        value: Number(totalOnlineOnts) || 0,
        color: "green",
      },
      {
        // title: "InActive",
        value: Number(totalInActiveOnts) || 0,
        color: "grey",
      },
    ];
    return data;
  };

  localStorage.setItem("activePage", `${oltName} slots`);
  return (
    <>
      <ToastContainer />
      <Container fluid className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="12" md="12" xl={12} xxl={12}>
            <h3 className="mb-5 text-center"> {oltName || ""} Slots</h3>
            <Container>
              <Row>
                {allSlots.map((slot: any, index: number) => {
                  return (
                    <Col sm={12} xs={12} lg="6" md="6" xl={4} xxl={4}>
                      <SingleSlotCard
                        key={Math.random()}
                        healthStats={prepareHealthStatsForSlot(
                          slot.slotHealthStats
                        )}
                        slotModel={slot.slotModel}
                        slotNumber={slot.oltSlot}
                        slotHealthStats={slot.slotHealthStats}
                        intPkOltID={slot.intPkOltID}
                        intPkSlotID={slot.intPkSlotID}
                        oltName={oltName}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BrowseSlotsComponent;
