import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { removeSpacesFromString } from "../../helpers/strings";
type Props = {
  isLoggedIn?: boolean;
};

const UpdateOLTComponent: React.FC<UpdateOLTComponentProp> = ({
  handleApiResponse,
  intPkCompanyID,
  companyName,
  updateOLTData,
}) => {
  const [oltData, setOltData] = useState<UpdateOLTProp>({
    oltName: "",
    host: "",
    intPkCompanyID: "",
    intPkOLTModelID: "",
    intPkBrandID: "",
    port: 46023,
    netFlowPort: 45161,
    netflowEventSecretKey: "private",
    netflowReadString: "private",
    netflowWriteString: "private",
    intPkOltID: -1,
    oltWebAccessPort: "",
  });
  const [
    postCreateOLTData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<CreateOLTProp>("olt/updateOLT");
  const [
    postCreateOrUpdateTelNet,
    createOrUpdateTelNetResponse,
    createOrUpdateTelNetError,
    createOrUpdateTelNetLoading,
    createOrUpdateTelNetMessage,
    createOrUpdateTelNetErrorCount,
  ] = useAxiosPost<CreateOrUpdateTelNetDetails>(
    "olt/createOrUpdateTelNetInfoOltWise"
  );
  const [
    getTelnetDetails,
    telnetResponse,
    telnetError,
    telNetLoading,
    telNetMessage,
    telNetErrorCount,
  ] = useAxiosPost<getTelNetDetails>("olt/getTelNetDetailsOltWise");
  const CreateOltValidationSchema = yup.object().shape({
    oltName: yup.string().required(),
    // model: yup.string().required(),
    host: yup.string().required(),
    port: yup.number().integer().min(1).required(),
    netFlowPort: yup.number().integer().min(1).required(),
    oltWebAccessPort: yup.number().integer().min(1).required(),
    netflowEventSecretKey: yup.string().required(),
    netflowReadString: yup.string().required(),
    netflowWriteString: yup.string().required(),
  });
  const ValidateTelNetSchema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });
  useEffect(() => {
    if (telnetResponse !== null) {
      const res: any = telnetResponse.responseData;

      setUsername(res.username || "");
      setPassword(res.password || "");
    }
  }, [telnetResponse]);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
      handleApiResponse(false);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { message } = oltResponseData;
      if (message) {
        notifySuccess(message);
      }
      handleApiResponse(true);
    }
  }, [oltResponseData]);

  useEffect(() => {
    const {
      intPkOltID,
      netFlowPort = 45161,
      netflowEventSecretKey,
      netflowReadString,
      netflowWriteString,
      oltName = "",
      port = 46023,
      host = "",
      oltWebAccessPort = "",
    } = updateOLTData;
    getTelnetDetails({ intPkOltID });

    console.log("hi");
    setOltData({
      ...oltData,
      oltName,
      netFlowPort,
      netflowEventSecretKey,
      netflowReadString,
      netflowWriteString,
      port,
      host,
      intPkOltID,
      oltWebAccessPort,
    });
  }, []);
  useEffect(() => {
    console.log({ oltData });
  }, [oltData]);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const handleCreateOLT = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateOLTProp = {
      ...oltData,
      oltName: oltData.oltName.trim(),
      intPkOltSanctionID: -1,
      oltWebAccessPort: oltData.oltWebAccessPort,
    };
    try {
      console.log({ dataToSend });
      const isValid = await CreateOltValidationSchema.validate(dataToSend);
      const dataForTelnet = {
        username,
        password,
        intPkOltID: oltData.intPkOltID,
      };
      const isValidTelnet = await ValidateTelNetSchema.validate(dataForTelnet);

      postCreateOLTData(dataToSend);
      postCreateOrUpdateTelNet(dataForTelnet);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOltData({
      ...oltData,
      [name]: removeSpacesFromString(value),
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={oltData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Update OLT Of <b style={{ color: "#ff4646" }}>{companyName}</b>
            </Card.Title>
            <Form className="mt-4">
              <Form.Group className="mb-3">
                <Form.Label> {`Enter OLT Name`}</Form.Label>
                <Form.Control
                  placeholder={`Enter OLT Name`}
                  type="text"
                  value={oltData.oltName}
                  onChange={(e) => {
                    setOltData({
                      ...oltData,
                      oltName: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              {renderInput(`host`, "Enter OLT IP", `Enter OLT IP`)}
              {renderInput(`port`, "Enter OLT PORT", `Enter OLT PORT`)}
              {renderInput(
                `netFlowPort`,
                "Enter Netflow PORT",
                `Enter Netflow PORT`
              )}
              {renderInput(
                `oltWebAccessPort`,
                "Enter Web Access Port",
                `Enter Web Access Port`
              )}
              {renderInput(
                `netflowEventSecretKey`,
                "Enter Netflow Events Secret Key",
                "Enter Netflow Events Secret Key"
              )}
              {renderInput(
                `netflowReadString`,
                "Enter Netflow Read String",
                "Enter Netflow Read String"
              )}
              {renderInput(
                `netflowWriteString`,
                "Enter Netflow Write String",
                "Enter Netflow Write String"
              )}
              <Form.Group className="mb-3">
                <Form.Label> Telnet UseName</Form.Label>
                <Form.Control
                  placeholder={`TelNet UserName`}
                  type="text"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label> Telnet Password</Form.Label>
                <Form.Control
                  placeholder={`TelNet Password`}
                  type="text"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Group>
              <div style={{ textAlign: "center" }}>
                <button
                  onClick={handleCreateOLT}
                  disabled={oltLoading}
                  className="button-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default UpdateOLTComponent;
