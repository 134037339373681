import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, Table } from "react-bootstrap";

import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import CreateVlanForOLTModalComponent from "../Model/CreateVlanForOLTModal";

const AllVlanProfilesByOLTComponentForNOCAndSuperCooForHuawei: React.FC<
  GP
> = ({}) => {
  const [show, setShow] = useState<boolean>(false);

  const [
    getApiData,
    apiResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<VlansByOLTProp>("vlans/getAllVlanProfilesByOLT");
  const [allVlans, setAllVlans] = useState<any>([]);
  const { intPkOltID, oltName, brand } = useParams();

  useEffect(() => {
    getApiData({ intPkOltID: Number(intPkOltID) });
  }, []);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponseData !== null) {
      const { responseData } = apiResponseData;
      setAllVlans(responseData || []);
    }
  }, [apiResponseData]);
  const handleClose = (isCall: boolean) => {
    setShow(false);
    if (isCall === true) {
      getApiData({ intPkOltID: Number(intPkOltID) });
    }
  };

  return (
    <>
      <ToastContainer />
      {show === true ? (
        <CreateVlanForOLTModalComponent
          show={show}
          handleClose={handleClose}
          brand={brand}
          intPkOltID={Number(intPkOltID)}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Vlans of {oltName}
              <span style={{ float: "right" }}>
                <Button
                  variant="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                >
                  Create New VLAN
                </Button>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Vlan Name</th>
                      <th className="table-header-standard">vlan</th>
                      <th className="table-header-standard">
                        ONT Line Profile
                      </th>
                      <th className="table-header-standard">
                        ONT Service Profile
                      </th>
                      <th className="table-header-standard">Gem Port</th>
                      <th className="table-header-standard">dba Profile</th>
                      <th className="table-header-standard">vlan Type</th>
                      <th className="table-header-standard">ISP Name</th>
                      <th className="table-header-standard">ISP Logo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allVlans.map((vlan: any) => {
                      return (
                        <tr key={vlan.intPkVlanID}>
                          <td>{vlan.vlanName}</td>
                          <td>{vlan.vlan}</td>
                          <td>{vlan.ontLineProfile}</td>
                          <td>{vlan.ontServiceProfile}</td>
                          <td>{vlan.gemPort}</td>
                          <td>{vlan.dbaProfile}</td>
                          <td>{vlan.vlanType}</td>
                          <td>{vlan.ispName}</td>
                          <td>
                            <img
                              style={{ width: "50px" }}
                              src={`${vlan.ispLogoUrl}`}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllVlanProfilesByOLTComponentForNOCAndSuperCooForHuawei;
