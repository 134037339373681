import AdminIspsComponent from "../components/admin/AdminIspsComponent";

const AdminIspsPage = () => {
  return (
    <>
      <AdminIspsComponent />
    </>
  );
};
export default AdminIspsPage;
