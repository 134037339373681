import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const AllSlotsByOLTComponent: React.FC<GP> = ({}) => {
  const [
    getAllSlotsData,
    slotsResponseData,
    slotError,
    slotLoading,
    oltMessage,
    slotErrorCount,
  ] = useAxiosPost<SlotResponseProp>("slots/getAllSlotsOltWise");
  const [allSlots, setAllSlots] = useState<any>([]);
  const { intPkOltID, oltName } = useParams();

  useEffect(() => {
    getAllSlotsData({ intPkOltID: Number(intPkOltID) });
  }, [intPkOltID]);
  useEffect(() => {
    if (slotError !== null && slotErrorCount !== 0) {
      notifyError(slotError);
    }
  }, [slotError, slotErrorCount]);
  useEffect(() => {
    if (slotsResponseData !== null) {
      const { responseData } = slotsResponseData;
      setAllSlots(responseData || []);
    }
  }, [slotsResponseData]);

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              {oltName} Slots
              <Link
                className="btn btn-success image-float-right"
                to={`/create-slot-by-olt/${intPkOltID}/${oltName}`}
              >
                Create Slot
              </Link>
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Olt Frame</th>
                      <th className="table-header-standard">Olt Slot</th>
                      <th className="table-header-standard">slot Model</th>
                      <th className="table-header-standard">
                        Number Of PonPorts
                      </th>
                      <th className="table-header-standard">
                        Slot Default VLAN
                      </th>
                      <th className="table-header-standard">View Pon Ports</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSlots.map((olt: any) => {
                      return (
                        <tr key={olt.intPkOltID}>
                          <td>{olt.oltFrame}</td>
                          <td>{olt.oltSlot}</td>
                          <td>{olt.slotModel}</td>
                          <td>{olt.numberOfPonPorts}</td>
                          <td>{olt.slotDefaultVLAN}</td>
                          <td>
                            <Link
                              className="btn btn-primary"
                              to={`/ponports-by-slot/${olt.intPkOltID}/${olt.intPkSlotID}`}
                            >
                              View Pon Ports
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllSlotsByOLTComponent;
