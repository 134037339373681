import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import SideBar from "./components/SideBar/index";
import NavBar from "./components/NavBar/index";
//Auth Routes
import Login from "./pages/Login";

import { AppRoutes } from "./data";
import NotFoundPage from "./pages/NotFoundPage";
import HomePage from "./pages/Home";
import BrowseOLTsPage from "./pages/admin/BrowseOLTsPage";
import AllSuperCooPage from "./pages/admin/AllSuperCooPage";
import MyComponent from "./MultipleModels.Example";
import AllCompaniesCOOPage from "./pages/AllCompaniesPage";
import AdminDashboardPage from "./pages/AdminDashboardPage";
import { getAuthToken, getUserType } from "./helpers/userDetails";
import AllCompaniesPageForAccountant from "./pages/accountant/AllCompaniesPageForAccountant";
import SignupPage from "./pages/SignupPage";
import VerifyCodePage from "./pages/VerifyCodePage";
import VerifyEmailAndPhoneNumberPage from "./pages/VerifyEmailAndPhoneNumberPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LoginFormComponentToDeleteAccount from "./deleteAccountModule/LoginToDeleteComponent";
import DeleteAccountPage from "./deleteAccountModule/DeleteAccountPage";
import PolicyPage from "./privacy-policy/PrivacyPolicyPage";

const RoutesWrapper = () => {
  const isLoggedIn = getAuthToken() || false;
  const isLoggedInForAccountDelete =
    localStorage.getItem("authTokenToDeleteAccount") || false;
  const role = getUserType() || false;
  const [routes, setRoutes] = useState<any>([]);

  const getHomePage = () => {
    if (!isLoggedIn) {
      return <Login />;
    }
    switch (role) {
      case "SUPER_ADMIN":
        return <AllSuperCooPage />;
        break;
      case "ADMIN":
        return <AdminDashboardPage />;
      case "DEALER":
        return <AdminDashboardPage />;
      case "DEMO_DEALER":
        return <AdminDashboardPage />;
      case "DEMO_ADMIN":
        return <AdminDashboardPage />;
      case "SUPER_COO":
        return <AllCompaniesCOOPage />;
        break;
      case "NOC":
        return <AllCompaniesCOOPage />;
      case "ACCOUNTANT":
        return <AllCompaniesPageForAccountant />;
        break;
      default:
        return <BrowseOLTsPage />;
    }
  };
  useEffect(() => {
    setRoutes(AppRoutes());
  }, []);

  return (
    <Router>
      {isLoggedIn ? (
        <>
          <SideBar />
          <NavBar />
        </>
      ) : null}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/login-to-delete-account"
          element={<LoginFormComponentToDeleteAccount />}
        />
        {isLoggedInForAccountDelete !== false ? (
          <Route path="/delete-account" element={<DeleteAccountPage />} />
        ) : null}
        <Route path="/register" element={<SignupPage />} />
        <Route path="/verify" element={<VerifyCodePage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="/verify-email-phone"
          element={<VerifyEmailAndPhoneNumberPage />}
        />
        <Route path="/models" element={<MyComponent />} />
        <Route path="/" element={getHomePage()} />
        <Route path="/privacy-policy" element={<PolicyPage />} />

        {isLoggedIn &&
          routes.map((route: any) => {
            return (
              <Route
                path={route.path}
                key={Math.random()}
                element={<route.Component />}
              />
            );
          })}
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default RoutesWrapper;
