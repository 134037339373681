import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";

const SanctionOltToCompanyModal: React.FC<ShowModalForOltSanctionToCompany> = ({
  show,
  handleClose,
  intPkCompanyID,
}) => {
  const [
    postBrandsData,
    brandsResponse,
    brandsError,
    brandsLoading,
    brandsMessage,
    brandsErrorCount,
  ] = useAxiosPost<ResponseProp>("accountant/getPricePolicy");

  const [brands, setBrands] = useState<any>([]);
  const [oltModels, setOltModels] = useState<any>([]);
  const [actualOntModels, setActualModels] = useState<any>([]);
  const [activePolicy, setActivePolicy] = useState<any>(null);
  const [selectedProtocol, setSelectedProtocol] = useState<string>("");
  const [isVsolProtocol, setIsVsolProtocol] = useState<boolean>(false);
  useEffect(() => {
    if (brandsResponse !== null) {
      console.log({ brandsResponse });
      const brands: any = brandsResponse.responseData;
      const uniqueData = _.uniqBy(brands || [], "intPkBrandID");
      setBrands(uniqueData);
      setActualModels(brandsResponse.responseData || []);
    }
  }, [brandsResponse]);

  useEffect(() => {
    postBrandsData({});
  }, []);
  const [
    postCrateNewPricePolicy,
    pricePolicyResponse,
    pricePolicyError,
    pricePolicyLoading,
    endUserMessage,
    pricePolicyErrorCount,
  ] = useAxiosPost<SanctionOltToCompanyModelProp>(
    "accountant/SanctionNewOltToCompany"
  );

  useEffect(() => {
    if (pricePolicyError !== null && pricePolicyErrorCount !== 0) {
      notifyError(pricePolicyError);
    }
  }, [pricePolicyError, pricePolicyErrorCount]);
  useEffect(() => {
    if (pricePolicyResponse !== null) {
      const response: any = pricePolicyResponse;
      const { message, responseData } = response;

      if (message) {
        notifySuccess(message);

        handleClose(true);
      }
    }
  }, [pricePolicyResponse]);

  const createEndUserValidationSchema = yup.object().shape({
    intPkBrandID: yup.number().integer().required().min(1),
    intPkOLTModelID: yup.number().integer().required().min(1),
    trialPeriodDays: yup.number().integer().required().min(0),
    isTrialAllowedOrNot: yup.number().integer().required().min(0),
    price: yup.number().integer().required().min(0),
    invoiceDueDaysBeforeDisable: yup
      .number()
      .integer()
      .required()
      .min(0)
      .max(10),
    discountPercentage: yup.number().integer().required().min(0).max(10),
  });

  const [pricePolicyModelData, setPricePolicyModelData] =
    useState<SanctionOltToCompanyModelProp>({
      intPkBrandID: "",
      intPkOLTModelID: "",
      trialPeriodDays: "0",
      isTrialAllowedOrNot: "",
      price: "0",
      invoiceDueDaysBeforeDisable: "0",
      discountPercentage: "0",
      intPkCompanyID: intPkCompanyID,
      intPkOltPricePolicyID: -1,
      quantity: 1,
    });
  const [isBrandAndModelSelected, setIsBrandAndModelSelected] =
    useState<boolean>(false);

  const handleCreateArea = async (event: any) => {
    event.preventDefault();
    if (isBrandAndModelSelected === false || activePolicy == null) {
      notifyError(`Invalid Data Selected`);
      return;
    }
    console.log({
      isTrialAllowedOrNot: pricePolicyModelData.isTrialAllowedOrNot,
    });
    if (pricePolicyModelData.isTrialAllowedOrNot === "") {
      notifyError(`Either Choose yes or no for Give Trail  `);
      return;
    }
    if (pricePolicyModelData.trialPeriodDays > activePolicy.trialPeriodDays) {
      notifyError(
        `Invalid Trail days the max you can choose are ${activePolicy.trialPeriodDays} days   `
      );
      return;
    }
    const dataToSend: SanctionOltToCompanyModelProp = pricePolicyModelData;
    try {
      const isValid = await createEndUserValidationSchema.validate(dataToSend);
      postCrateNewPricePolicy(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPricePolicyModelData({
      ...pricePolicyModelData,
      [name]: value,
    });
  };
  const renderInput = (
    name: string,
    placeholder: string,
    label: string,
    type: string = "text"
  ) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type={type}
          value={pricePolicyModelData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };
  useEffect(() => {
    if (
      pricePolicyModelData.intPkOLTModelID !== "" &&
      pricePolicyModelData.intPkBrandID !== ""
    ) {
      setIsBrandAndModelSelected(true);
    } else {
      setIsBrandAndModelSelected(false);
    }
  }, [pricePolicyModelData.intPkOLTModelID, pricePolicyModelData.intPkBrandID]);
  const createArrayUpToNumber = (num: number) => {
    // Check if the input is a valid number
    if (typeof num !== "number" || isNaN(num) || num < 0) {
      return [];
    }

    // Create an array with numbers from 0 to num
    const resultArray = [];
    for (let i = 0; i <= num; i++) {
      resultArray.push(i);
    }

    return resultArray;
  };

  return (
    <>
      <ToastContainer />
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Sanction Olt <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="">
            <Row className="justify-content-center">
              <Col className=" p-5">
                <h3 className="mb-5 text-center">Sanction Olt</h3>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label> Choose OLT Brand</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        const models = actualOntModels.filter(
                          (item: any) => item.intPkBrandID == e.target.value
                        );
                        setOltModels(models);
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          intPkOLTModelID: "",
                          intPkBrandID: e.target.value,
                        });

                        const brand = brands.find(
                          (item: any) => item.intPkBrandID == e.target.value
                        );
                        if (brand?.brandName === "Huawei") {
                          setIsVsolProtocol(false);
                        } else {
                          //it's a vsol
                          setSelectedProtocol("");
                          setIsVsolProtocol(true);
                        }
                      }}
                      value={pricePolicyModelData.intPkBrandID}
                    >
                      <option value="">Choose OLT Brand</option>
                      {brands.map((item: any) => {
                        return (
                          <option
                            value={item.intPkBrandID}
                            key={item.intPkBrandID}
                          >
                            {item.brandName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  {isVsolProtocol == true ? (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label> Choose OLT Protocol</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            const models = actualOntModels.filter(
                              (item: any) => item.technology == e.target.value
                            );
                            if (e.target.value === "EPON") {
                              const _models = _.uniqBy(models, "eponPorts");
                              console.log({ _models });
                              const ports = _models
                                .map((item: any) => {
                                  return {
                                    ...item,
                                    port: item.eponPorts,
                                  };
                                })
                                .sort((a: any, b: any) => a.port - b.port);
                              setOltModels(ports);
                            } else if (e.target.value === "GPON") {
                              const _models = _.uniqBy(models, "gponPorts");
                              const ports = _models
                                .map((item: any) => {
                                  return {
                                    ...item,
                                    port: item.gponPorts,
                                  };
                                })
                                .sort((a: any, b: any) => a.port - b.port);
                              console.log({ _models });
                              setOltModels(ports);
                            }

                            setSelectedProtocol(e.target.value);
                          }}
                          value={selectedProtocol}
                        >
                          <option value="">Choose OLT Protocol</option>
                          <option value="EPON">EPON</option>
                          <option value="GPON">GPON</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label> Choose No. Of Pon Ports</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            const _activePolicy = actualOntModels.find(
                              (item: any) =>
                                item.intPkBrandID ==
                                  pricePolicyModelData.intPkBrandID &&
                                item.intPkOLTModelID == e.target.value
                            );
                            console.log({ _activePolicy });

                            if (!_activePolicy) {
                              return;
                            }
                            console.log({ _activePolicy });

                            setActivePolicy(_activePolicy);
                            const isTrialAllowedOrNot =
                              _activePolicy.isTrialAllowedOrNot;
                            setPricePolicyModelData({
                              ...pricePolicyModelData,
                              intPkOLTModelID: e.target.value,
                              isTrialAllowedOrNot: isTrialAllowedOrNot,
                              intPkOltPricePolicyID:
                                _activePolicy.intPkOltPricePolicyID,
                              trialPeriodDays: "0",
                            });
                          }}
                          value={pricePolicyModelData.intPkOLTModelID}
                        >
                          <option value="">Choose No. Of Pon Ports</option>
                          {oltModels.map((item: any) => {
                            return (
                              <option
                                value={item.intPkOLTModelID}
                                key={item.intPkOLTModelID}
                              >
                                {item.port}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      {isBrandAndModelSelected === true &&
                      activePolicy !== null ? (
                        <>
                          <Form.Group className="mb-3">
                            <Form.Label>Trail Period Days</Form.Label>
                            <Form.Control
                              placeholder={`Trail Period Days`}
                              type={"number"}
                              disabled={
                                activePolicy?.isTrialAllowedOrNot == 0
                                  ? true
                                  : false
                              }
                              value={pricePolicyModelData.trialPeriodDays}
                              onChange={(e) => {
                                setPricePolicyModelData({
                                  ...pricePolicyModelData,
                                  trialPeriodDays: e.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                          {renderInput(
                            `price`,
                            "Enter Price",
                            "Enter Price",
                            "number"
                          )}
                          {/* <Form.Group className="mb-3">
                        <Form.Label> Choose</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            if (e.target.value == "0") {
                              setPricePolicyModelData({
                                ...pricePolicyModelData,
                                isTrialAllowedOrNot: e.target.value,
                                trialPeriodDays: 0,
                              });
                            } else {
                              setPricePolicyModelData({
                                ...pricePolicyModelData,
                                isTrialAllowedOrNot: e.target.value,
                              });
                            }
                          }}
                          value={pricePolicyModelData.isTrialAllowedOrNot}
                        >
                          <option value="">Choose</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Select>
                      </Form.Group> */}
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {" "}
                              Choose Invoice Days Before Olt Disable
                            </Form.Label>
                            <Form.Select
                              onChange={(e) => {
                                setPricePolicyModelData({
                                  ...pricePolicyModelData,
                                  invoiceDueDaysBeforeDisable: e.target.value,
                                });
                              }}
                              value={
                                pricePolicyModelData.invoiceDueDaysBeforeDisable
                              }
                            >
                              <option value="0">No Day</option>
                              {createArrayUpToNumber(
                                Number(
                                  activePolicy?.invoiceDueDaysBeforeDisable
                                )
                              ).map((item: number) => {
                                return (
                                  <option value={item} key={item}>
                                    {`${item} Days`}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label> Select Discount Percentage</Form.Label>
                            <Form.Select
                              onChange={(e) => {
                                setPricePolicyModelData({
                                  ...pricePolicyModelData,
                                  discountPercentage: e.target.value,
                                });
                              }}
                              value={pricePolicyModelData.discountPercentage}
                            >
                              <option value="0">0%</option>
                              {createArrayUpToNumber(
                                Number(activePolicy?.discountPercentage)
                              ).map((item: number) => {
                                return (
                                  <option value={item} key={item}>
                                    {`${item} %`}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                          {renderInput(
                            `quantity`,
                            "Enter Quantity",
                            "Enter Quantity",
                            "number"
                          )}
                          <Button
                            onClick={handleCreateArea}
                            variant="primary"
                            type="submit"
                            disabled={pricePolicyLoading}
                          >
                            Submit
                          </Button>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SanctionOltToCompanyModal;
