import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";

const CreateEndUserModalComponent: React.FC<ShowModelForCreatingEndUser> = ({
  show,
  handleClose,
  createEndUser,
}) => {
  const [
    postCrateEndUserData,
    endUserResponse,
    endUserError,
    endUserLoading,
    endUserMessage,
    endUserErrorCount,
  ] = useAxiosPost<EndUserProp>("endUsers/createEndUser");

  useEffect(() => {
    if (endUserError !== null && endUserErrorCount !== 0) {
      notifyError(endUserError);
    }
  }, [endUserError, endUserErrorCount]);
  useEffect(() => {
    if (endUserResponse !== null) {
      const response: any = endUserResponse;
      const { message, responseData } = response;
      if (responseData.length > 0) {
        const data: any = responseData[0];
        const {
          areaName,
          dateCreated,
          endUserAddress,
          endUserFullName,
          endUserPhoneNumber,
          intPkAreaID,
          intPkEndUserID,
          intPkUserID,
        } = data;

        if (message) {
          notifySuccess(message);

          createEndUser(
            endUserFullName,
            endUserAddress,
            endUserPhoneNumber,
            intPkAreaID,
            intPkEndUserID
          );
          handleClose();
        }
      }
    }
  }, [endUserResponse]);

  const [
    getMyAreasData,
    areasResponse,
    areasError,
    AreasLoading,
    areasMessage,
    areasErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAllAreas");
  const [allAreas, setAllAreas] = useState<any>([]);

  const createEndUserValidationSchema = yup.object().shape({
    endUserFullName: yup
      .string()
      .required()
      .min(5)
      .max(40, "Maximum 40 characters allowed in User Full Name"),
    endUserAddress: yup
      .string()
      .required()
      .min(5)
      .max(200, "Maximum 200 characters allowed in Address"),
    endUserPhoneNumber: yup
      .string()
      .required()
      .min(10)
      .max(20, "Maximum 20 characters allowed in phone"),
    intPkAreaID: yup.string().required("Area Is Required"),
  });

  useEffect(() => {
    getMyAreasData({});
  }, []);
  useEffect(() => {
    if (areasResponse !== null) {
      const { responseData } = areasResponse;
      setAllAreas(responseData || []);
    }
  }, [areasResponse]);

  const [endUserData, setEndUserData] = useState<EndUserProp>({
    endUserFullName: "",
    endUserAddress: "",
    endUserPhoneNumber: "",
    intPkAreaID: "",
    endUserPPPoEUserID: "",
  });
  const handleCreateArea = async (event: any) => {
    event.preventDefault();
    const dataToSend: EndUserProp = endUserData;
    try {
      const isValid = await createEndUserValidationSchema.validate(dataToSend);
      const role = getUserType();
      if (role === "" || role == "DEMO_DEALER" || role === "DEMO_ADMIN") {
        notifyDemoAccountError();
        return;
      }
      postCrateEndUserData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEndUserData({
      ...endUserData,
      [name]: value,
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={endUserData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            New End User <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className="bg-dark text-white p-5">
                <h3 className="mb-5 text-center">Create New End User</h3>
                <Form>
                  {renderInput(
                    `endUserPPPoEUserID`,
                    "Enter User PPPeE Id",
                    `Enter User PPPeE Id`
                  )}
                  {renderInput(
                    `endUserFullName`,
                    "Enter User Full Name",
                    `Enter User Full Name`
                  )}
                  {renderInput(
                    `endUserAddress`,
                    "Enter User Address",
                    `Enter User Address`
                  )}
                  {renderInput(
                    `endUserPhoneNumber`,
                    "Enter Phone Number",
                    `Enter Phone Number`
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label> Select An Area</Form.Label>
                    <Form.Select
                      value={endUserData.intPkAreaID}
                      onChange={(e) => {
                        setEndUserData({
                          ...endUserData,
                          intPkAreaID: e.target.value,
                        });
                      }}
                    >
                      <option value={""}>Choose Area</option>
                      {allAreas.map((area: any) => {
                        return (
                          <option
                            key={area.intPkAreaID}
                            value={area.intPkAreaID}
                          >
                            {area.areaName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Button
                    onClick={handleCreateArea}
                    variant="primary"
                    type="submit"
                    disabled={endUserLoading}
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateEndUserModalComponent;
