import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table } from "react-bootstrap";
import "./../../assets/styles/olt.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
import Alert from "react-bootstrap/Alert";
import UpIcon from "./../../assets/svgs/icons/success.svg";
import { Form } from "react-bootstrap";
import { notifyError } from "../toast/Error";
const AutoOLTCreationSecondStepCard: React.FC<OLTSecondStepCardProp> = ({
  intPkOltID,
  oltName,
  host,
  handleMoveToNextStep,
  port,
  netFlowPort,
  oltWebAccessPort,
}) => {
  const installStatusRef = useRef<any>(null);

  const [sysEventBotName, setSysEventBotName] = useState<string>("");
  const [netFlowBotName, setNetFlowBotName] = useState<string>("");
  const [counter, setCounter] = useState(0);

  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);

  const [
    getApiData,
    apiResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("superCoo/getNodes");
  const [
    postBotData,
    linkedBotsResponse,
    linkingError,
    linkingLoading,
    vlanMessage,
    linkingErrorCount,
  ] = useAxiosPost<AssignNetflowAndSysEvents>(
    "superCoo/assignNetFlowBotAndSysEventToOLT"
  );

  const [
    postTestResult,
    preTestApiResponse,
    postPreTestApiError,
    preTestLoading,
    preTestResultMessage,
    preTestErrorCount,
  ] = useAxiosPost<PreTestOltProp>("superCoo/getPreTestStatus");
  const [
    postTestOLT,
    testOLTResponse,
    testErrorError,
    testLoading,
    testMessage,
    testErrorCount,
  ] = useAxiosPost<PreTestOltProp>("netflow/requests/preTestOLT", true);
  useEffect(() => {
    if (testOLTResponse !== null) {
      installStatusRef.current = setInterval(() => {
        postTestResult({ intPkOltID });
      }, 1500);
      setTimeout(() => {
        clearInterval(installStatusRef.current);
      }, 60000);
    }
  }, [testOLTResponse]);

  useEffect(() => {
    if (preTestApiResponse !== null) {
      console.log(preTestApiResponse);
      const responseData: any = preTestApiResponse.responseData;
      console.log({
        responseData: responseData,
        isOltPreTested: responseData.isOltPreTested,
      });
      if (responseData.isOltPreTested !== 0) {
        clearInterval(installStatusRef.current);
        if (counter === 0) {
          if (responseData.isOltPreTested === -1) {
            notifyError(`Test Failed, Please Try Again`);
            setIsNextDisabled(false);
            // handleMoveToNextStep(netFlowBotName, sysEventBotName, "three"); //needs to remove from here
          } else if (responseData.isOltPreTested === 1) {
            notifySuccess(`Test Success, Let's Install the configurations`);
            handleMoveToNextStep(netFlowBotName, sysEventBotName, "three");
          }
          setCounter(1);
        }
        // setIsNextDisabled(false)
      }
    }
  }, [preTestApiResponse]);
  const [netFlowBotNodes, setNetFlowBotNodes] = useState<any>([]);
  const [sysEventsBots, setSysEventsBots] = useState<any>([]);
  const [botsData, setBotsData] = useState<any>({
    intPkNetFlowBotNodeID: "",
    intPkSysEventLogControllerID: "",
  });
  useEffect(() => {
    if (apiResponseData !== null) {
      const responseData: any = apiResponseData.responseData;
      setNetFlowBotNodes(responseData.netFlowBotNodes || []);
      setSysEventsBots(responseData.sysEventsBots || []);
    }
  }, [apiResponseData]);
  useEffect(() => {
    getApiData({});
    // getSingleOltDetails({ oltId: intPkOltID });
  }, []);
  useEffect(() => {
    if (linkedBotsResponse !== null) {
      // notifySuccess(`Bots Assigned`);
      setIsNextDisabled(true);
      postTestOLT({ intPkOltID });
    }
  }, [linkedBotsResponse]);

  useEffect(() => {
    if (linkingError !== null && linkingErrorCount !== 0) {
      notifyError(linkingError);
    }
  }, [linkingError, linkingErrorCount]);
  const handleAssignBots = () => {
    if (
      !botsData.intPkNetFlowBotNodeID ||
      !botsData.intPkSysEventLogControllerID
    ) {
      notifyError(`Please Select Both NetflowBot And SysEvents Controller`);
    }
    const dataToSend = {
      intPkOltID,
      intPkNetFlowBotNodeID: Number(botsData.intPkNetFlowBotNodeID),
      intPkSysEventLogControllerID: Number(
        botsData.intPkSysEventLogControllerID
      ),
    };
    postBotData(dataToSend);
  };
  return (
    <>
      <ToastContainer />

      {/* <Alert
        style={{ background: "#00CC604D", color: "#00CC60" }}
        className="mt-3 text-center"
        variant={"success"}
      >
        Success <img src={UpIcon} />
      </Alert> */}
      <Table bordered className="mt-5">
        <thead>
          <tr>
            <th className="table-header-standard">Olt Name</th>
            <th className="table-header-standard">Host</th>
            <th className="table-header-standard">Port</th>
            <th className="table-header-standard">Netflow Port</th>
            <th className="table-header-standard">Olt Web Access Port</th>
          </tr>
          <tr>
            <td>{oltName}</td>
            <td>{host}</td>
            <td>{port}</td>
            <td>{netFlowPort}</td>
            <td>{oltWebAccessPort}</td>
          </tr>
        </thead>
      </Table>
      <Row>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          <Form.Group>
            <Form.Label> Select NetFlow Bot Node</Form.Label>
            <Form.Select
              value={botsData.intPkNetFlowBotNodeID}
              onChange={(e) => {
                const bot = netFlowBotNodes.find(
                  (item: any) => item.intPkNetFlowBotNodeID == e.target.value
                );
                if (bot) {
                  setNetFlowBotName(bot.botNodeName);
                }
                setBotsData({
                  ...botsData,
                  intPkNetFlowBotNodeID: e.target.value,
                });
              }}
            >
              <option value={""}>Choose Netflow Bot Node</option>
              {netFlowBotNodes.map((item: any) => {
                return (
                  <option
                    key={item.intPkNetFlowBotNodeID}
                    value={item.intPkNetFlowBotNodeID}
                  >
                    {item.botNodeName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          <Form.Group className="mb-3">
            <Form.Label> Select Sys Event Controller</Form.Label>
            <Form.Select
              value={botsData.intPkSysEventLogControllerID}
              onChange={(e) => {
                const bot = sysEventsBots.find(
                  (item: any) =>
                    item.intPkSysEventLogControllerID == e.target.value
                );
                if (bot) {
                  setSysEventBotName(bot.sysEventLogControllerName);
                }
                setBotsData({
                  ...botsData,
                  intPkSysEventLogControllerID: e.target.value,
                });
              }}
            >
              <option value={""}>Select Sys Event Controller</option>
              {sysEventsBots.map((item: any) => {
                return (
                  <option
                    key={item.intPkSysEventLogControllerID}
                    value={item.intPkSysEventLogControllerID}
                  >
                    {item.sysEventLogControllerName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} xs={12} sm={12}>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleAssignBots();
            }}
            className="button-primary"
            style={{
              minWidth: "100%",
            }}
            disabled={linkingLoading || isNextDisabled}
          >
            {linkingLoading === true || isNextDisabled == true
              ? "Loading"
              : "Test"}
          </button>
        </Col>
      </Row>
    </>
  );
};
export default AutoOLTCreationSecondStepCard;
/*

  const [
    getSingleOltDetails,
    singleOltDetailsResponse,
    singleOltError,
    singleOlLoading,
    singleOltMessage,
    singleOltErrorCount,
  ] = useAxiosPost<SingleOltProp>("olt/getSingleOLTDetails");
  useEffect(() => {
    if (singleOltDetailsResponse !== null) {
      const res: any = singleOltDetailsResponse.responseData;
      console.log({ res });
    }
  }, [singleOltDetailsResponse]);
  */
