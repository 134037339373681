import React from "react";
import SignUpFormComponent from "../components/Auth/SignupForm";
const SignupPage = () => {
  return (
    <>
      <SignUpFormComponent />
    </>
  );
};
export default SignupPage;
