//NewOLTModelPage
import AllOLTModelsComponent from "../../components/admin/models/AllOLTModels";

const AllOLTModelsPage = () => {
  return (
    <>
      <AllOLTModelsComponent />
    </>
  );
};
export default AllOLTModelsPage;
