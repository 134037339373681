import ONTsReportComponent from "../../components/reports/OntsReport";

const AllOntsReportsPage = () => {
  return (
    <>
      <ONTsReportComponent />
    </>
  );
};
export default AllOntsReportsPage;
