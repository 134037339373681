import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

import { toast } from "react-toastify";
import CreateEndUserModalComponent from "./CreateEndUserModal";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";

const CreateRegistrationFromFoundOntsModal: React.FC<
  showModalForCreateBindingFromFoundOnts
> = ({ handleClose, show, endUsers, vlans, createRegistration }) => {
  const [selectedEndUser, setSelectedEndUser] = useState<any>("");
  const [selectedVlan, setSelectedVlan] = useState<any>("");
  const [showEndUserModel, setShowEndUserModel] = useState(false);
  const handleCloseEndUserModel = () => {
    setShowEndUserModel(false);
  };
  useEffect(() => {}, [endUsers]);
  const createEndUser = (
    endUserFullName: string,
    endUserAddress: string,
    endUserPhoneNumber: string | number,
    intPkAreaID: string | number,
    intPkEndUserID: number
  ) => {
    const endUser = {
      endUserFullName,
      endUserAddress,
      endUserPhoneNumber,
      intPkAreaID,
      intPkEndUserID,
    };
    endUsers.push(endUser);
    setSelectedEndUser(intPkEndUserID);
  };
  return (
    <>
      {showEndUserModel === true ? (
        <CreateEndUserModalComponent
          show={showEndUserModel}
          handleClose={handleCloseEndUserModel}
          createEndUser={createEndUser}
        />
      ) : null}
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            New Registration <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Select End User</Form.Label>
            <Form.Select
              value={selectedEndUser}
              onChange={(e) => {
                setSelectedEndUser(e.target.value);
              }}
            >
              <option value="">Select End user</option>
              {endUsers.map((item: any) => {
                return (
                  <option value={item.intPkEndUserID} key={item.intPkEndUserID}>
                    {item.endUserFullName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Select Vlan</Form.Label>
            <Form.Select
              value={selectedVlan}
              onChange={(e) => {
                setSelectedVlan(e.target.value);
              }}
            >
              <option value="">Select Vlan</option>
              {vlans.map((item: any) => {
                return (
                  <option value={item.intPkVlanID} key={item.intPkVlanID}>
                    {item.vlanName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowEndUserModel(true);
              }}
              className="button-primary"
              style={{ minWidth: "200px", float: "right" }}
            >
              New End User
            </button>
          </Form.Group>
          <Form.Group className="mb-3">
            <Button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                const role = getUserType();
                if (
                  role === "" ||
                  role == "DEMO_DEALER" ||
                  role === "DEMO_ADMIN"
                ) {
                  notifyDemoAccountError();
                  return;
                }
                if (selectedEndUser !== "" && selectedVlan !== "") {
                  createRegistration(
                    Number(selectedEndUser),
                    Number(selectedVlan)
                  );
                  handleClose();
                }
              }}
            >
              Create
            </Button>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateRegistrationFromFoundOntsModal;
