import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
const AllCompaniesComponent: React.FC<GP> = ({}) => {
  const [
    getCompaniesData,
    companiesData,
    companiesError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("superCOO/getAllCompanies");
  const [allCompanies, setAllCompanies] = useState<any>([]);
  useEffect(() => {
    getCompaniesData({});
  }, []);
  useEffect(() => {
    if (companiesError !== null && apiErrorCount !== 0) {
      notifyError(companiesError);
    }
  }, [companiesError, apiErrorCount]);
  useEffect(() => {
    if (companiesData !== null) {
      const { responseData } = companiesData;
      setAllCompanies(responseData || []);
    }
  }, [companiesData]);

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Companies
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Id</th>
                      <th className="table-header-standard">Company Name</th>
                      <th className="table-header-standard"> View OLTs</th>
                      <th className="table-header-standard">View Staff</th>
                      <th className="table-header-standard">Login</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCompanies.map((olt: any) => {
                      return (
                        <tr key={olt.intPkCompanyID}>
                          <td>{olt.intPkCompanyID}</td>
                          <td>{olt.companyName}</td>
                          <td>
                            <Link
                              className="button-primary link-class"
                              to={`/all-olts-by-company/${olt.intPkCompanyID}/${olt.companyName}`}
                            >
                              View OLTs
                            </Link>
                          </td>

                          <td>
                            <Link
                              className="button-blue link-class"
                              to={`/view-staff-by-company/${olt.intPkCompanyID}/${olt.companyName}`}
                            >
                              View Staff
                            </Link>
                          </td>
                          <td>
                            <td>
                              <Link
                                target="_blank"
                                className="button-primary link-class"
                                to={`/company-login-through-noc/${olt.intPkCompanyID}`}
                              >
                                Login
                              </Link>
                            </td>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllCompaniesComponent;
