import AllSlotsByOLTComponent from "../components/admin/SlotsByOlt";

const AllSlotsByOltPage = () => {
  return (
    <>
      <AllSlotsByOLTComponent />
    </>
  );
};
export default AllSlotsByOltPage;
