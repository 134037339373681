import AllOLTsComponentNOC from "../components/NOC/AllOltsNOC";
import AllOLTsComponent from "../components/superCOO/AllOlts";
const AllOltsPage = () => {
  const role = localStorage.getItem("userType") || "";
  const getPageRoleWise = () => {
    if (role === "NOC") {
      return <AllOLTsComponentNOC />;
    }
    if (role === "SUPER_COO") {
      return <AllOLTsComponent />;
    }
    return <></>;
  };
  return <>{getPageRoleWise()}</>;
};
export default AllOltsPage;
