import SearchOltsComponentForNOC from "../components/NOC/SearchOLTsForNOC";
import SearchOltsComponentForSuperCOO from "../components/superCOO/SearchOLTsForSuperCOO";

const SearchOLTsForNOCPage = () => {
  const role = localStorage.getItem("userType") || "";
  const getPageRoleWise = () => {
    if (role === "NOC") {
      return <SearchOltsComponentForNOC />;
    }
    if (role === "SUPER_COO") {
      return <SearchOltsComponentForSuperCOO />;
    }
    return <></>;
  };
  return <>{getPageRoleWise()}</>;
};
export default SearchOLTsForNOCPage;
