import SingleOLTForDiagnoseForNOC from "../components/NOC/SingleOLTForDiagnoseForNOC";

const DiagnoseSingleOLTPage = () => {
  const role = localStorage.getItem("userType") || "";
  const getComponentRoleWise = () => {
    if (role === "NOC") {
      return <SingleOLTForDiagnoseForNOC />;
    }
    if (role === "SUPER_COO") {
      return <SingleOLTForDiagnoseForNOC />;
    }
    return <></>;
  };
  return <>{getComponentRoleWise()}</>;
};
export default DiagnoseSingleOLTPage;
