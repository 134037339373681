import AllCompaniesComponentNOC from "../components/NOC/AllCompaniesNoc";
import AllCompaniesComponent from "../components/superCOO/AllCompanies";
const AllCompaniesCOOPage = () => {
  const role = localStorage.getItem("userType") || "";
  const getComponentRoleWise = () => {
    if (role === "NOC") {
      return <AllCompaniesComponentNOC />;
    }
    if (role === "SUPER_COO") {
      return <AllCompaniesComponent />;
    }
    return <></>;
  };
  return <>{getComponentRoleWise()}</>;
};
export default AllCompaniesCOOPage;
