export const formatDefaultMAc = (inputString: string) => {
  // Convert the string to uppercase
  const upperCaseString = inputString.toUpperCase();

  let result = "";
  const chunkSize = 2;

  for (let i = 0; i < upperCaseString.length; i += chunkSize) {
    const chunk = upperCaseString.slice(i, i + chunkSize);
    result += chunk + ":";
  }

  // Remove the trailing colon and return the result
  return result.slice(0, -1);
};
