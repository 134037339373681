import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import Axios from "axios";
import { baseURL } from "../hooks/useAxiosPost";
import { ToastContainer, toast } from "react-toastify";

function DeleteAccountPage() {
  const deleteAccount = async () => {
    try {
      const dataToSend: any = {
        authTokenString:
          localStorage.getItem("authTokenToDeleteAccount") || null,
      };
      const res = await Axios.post(
        `${baseURL}/users/auth/deleteMyAccount`,
        dataToSend
      );
      console.log({ res });
      localStorage.removeItem("authTokenToDeleteAccount");
      window.location.href = "/login";
    } catch (error: any) {
      console.log({ error: error.response.data.message });
      notifyError(error.response.data.message || `Some Error Occurs`);
    }
  };
  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };

  const handleDeleteAccount = () => {
    // Handle the actual account deletion logic here.
    // You can make an API request or perform any other actions needed.
    // Once the account is deleted, you can redirect the user to another page.
    // For this example, we will just close the modal.
    confirmAlert({
      title: "Delete Account",
      message: "You want Delete Account ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Delete");
            deleteAccount();
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };

  return (
    <>
      <ToastContainer />

      <Row>
        <Col lg={3} md={3} xl={3} xxl={3} sm={12} xs={12}></Col>
        <Col
          style={{ padding: "50px" }}
          lg={6}
          md={6}
          xl={6}
          xxl={6}
          sm={12}
          xs={12}
        >
          <div>
            <h1>Delete Your Account</h1>
            <p>
              We're sorry to see you go. Are you sure you want to delete your
              account? This action is irreversible, and you will lose all of
              your data. If you're sure, please click the button below to delete
              your account.
            </p>

            <Button
              variant="danger"
              onClick={(e) => {
                e.preventDefault();
                handleDeleteAccount();
              }}
            >
              Delete Account
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default DeleteAccountPage;
