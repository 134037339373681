import AllPonPortsBySlotComponent from "../components/admin/PonportsBySlots";

const PonPortsBySlotPage = () => {
  return (
    <>
      <AllPonPortsBySlotComponent />
    </>
  );
};
export default PonPortsBySlotPage;
