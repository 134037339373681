import React from "react";
import VerificationComponent from "../components/Auth/CodeVerification";
const VerifyCodePage = () => {
  return (
    <>
      <VerificationComponent />
    </>
  );
};
export default VerifyCodePage;
