import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";

import RefreshDisabledSVG from "./../../assets/svgs/refresh/refreshDisabled.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Statics
import StaticRouter from "./../../assets/svgs/statuses/router-static.png";
import CopyButton from "./../../assets/svgs/copy/Copy.svg";

//block svgs

import BlockedOntIcon from "./../../assets/svgs/router/offlineBlockWithoutRouter.svg";

// Statics End
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "react-bootstrap";
import { formatDate } from "../../helpers/date";

import { confirmAlert } from "react-confirm-alert"; // Import

const BrowseBlockedOntsByPonPortsComponent: React.FC<
  OntsByPonPortComponent
> = ({ switchTab }) => {
  const [
    requestBlockOnts,
    blockedOntsResponse,
    blockedOntsError,
    blockedOntsLoading,
    blockedOntsMessage,
    blockedOntsErrorCount,
  ] = useAxiosPost<BlockMultipleOntsProp>(
    "netflow/requests/requestMultipleOntsOfAPonPortToUnBlock",
    true
  );
  const {
    intPkSlotID,
    oltSlot,
    oltName,
    intPkPonPortID,
    oltPonPort,
    intPkOltID,
  } = useParams();
  const navigate = useNavigate();

  const [allOnts, setAllOnts] = useState<any>([]);

  const [
    getOntsListByPonPort,
    FoundOntsResponseData,
    foundOntsError,
    foundOntsLoading,
    foundOntsMessage,
    foundOntsErrorCount,
  ] = useAxiosPost<GetOntsBlockListByPonPortsProp>(
    "admin/getBlockListForVsolEpon"
  );
  useEffect(() => {
    if (FoundOntsResponseData !== null) {
      const responseData: any = FoundOntsResponseData.responseData;
      console.log({ responseData });
      setAllOnts(responseData || []);
    }
  }, [FoundOntsResponseData]);
  const getBlockedImage = (status: string) => {
    return (
      <img src={BlockedOntIcon} className="blocked-onts-list-image-icon" />
    );
  };
  useEffect(() => {
    getOntsListByPonPort({
      oltPonPort: Number(oltPonPort),
      intPkOltID: Number(intPkOltID),
    });
  }, []);
  useEffect(() => {
    if (blockedOntsResponse != null) {
      notifySuccess(`UnBlock Ont Request Logged`);
      getOntsListByPonPort({
        oltPonPort: Number(oltPonPort),
        intPkOltID: Number(intPkOltID),
      });
    }
  }, [blockedOntsResponse]);
  const handleBlockMultipleOnts = (macAddress: any) => {
    confirmAlert({
      title: "Unblock",
      message: "Do you Want to UnBlock This Ont ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just need to Block");

            requestBlockOnts({
              ponPortId: Number(intPkPonPortID),
              macAddressArray: [{ macAddress: macAddress }],
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  return (
    <>
      <ToastContainer />

      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="12" md="12" xl="12" className="p-5">
            <Card className="p-5">
              <Card.Title className="text-center olt-heading pt-3 main-ont-heading">
                {" "}
                {oltName} - Slot {oltSlot} - PonPort {oltPonPort}
                <span>
                  <img
                    style={{ cursor: "pointer" }}
                    src={RefreshDisabledSVG}
                    className="image-float-right"
                  />
                </span>
              </Card.Title>
              <Row className="mt-4">
                <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
                <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                    <div className="input-group-append">
                      <button className="button-primary-search" type="button">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={4} lg={4} xs={12} sm={12} xl={2} xxl={4}>
                  <span
                    className="inactive-tab-button-ont-list"
                    onClick={(e) => {
                      e.preventDefault();
                      switchTab(1);
                    }}
                  >
                    All onts
                  </span>
                  <span
                    className="active-tab-button-ont-list"
                    onClick={(e) => {
                      e.preventDefault();
                      switchTab(0);
                    }}
                  >
                    Block onts
                  </span>
                </Col>
              </Row>
              <Card.Body>
                {allOnts.map((item: any) => {})}

                <Table>
                  <tbody>
                    {allOnts.map((item: any) => {
                      return (
                        <tr key={item.intPkVsolOntBlockID}>
                          <td className="single-td-blocked-list">
                            <span className="data-r-label single-item-blocked-list">
                              User:
                            </span>
                            <span className="data-r-label-black single-item-blocked-list">
                              {item.userFullname}
                            </span>
                          </td>
                          <td className="single-td-blocked-list ">
                            <span className="data-r-label single-item-blocked-list">
                              Mac Address:
                            </span>
                            <span className="data-r-label-black single-item-blocked-list">
                              {item.formattedOntEponMacAddressID}
                            </span>
                          </td>
                          <td className="single-td-blocked-list ">
                            <span className="data-r-label single-item-blocked-list">
                              Date:
                            </span>
                            <span className="data-r-label-black single-item-blocked-list">
                              {formatDate(item.createdDate)}
                            </span>
                          </td>
                          <td className="single-td-blocked-list">
                            <span
                              style={{ color: "#696969" }}
                              onClick={(e) => {
                                e.preventDefault();
                                if (item.blockState == 3) {
                                  return;
                                }
                                handleBlockMultipleOnts(
                                  item.ontEponMacAddressID
                                );
                              }}
                              className={`${
                                item.blockState == 3
                                  ? "inactive-tab-button-ont-list-disabled"
                                  : "inactive-tab-button-ont-list"
                              }`}
                            >
                              {item.blockState == 3 ? "Unblocking" : "Unblock"}{" "}
                              {getBlockedImage(item.ontStatus)}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BrowseBlockedOntsByPonPortsComponent;
