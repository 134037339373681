import CreateCompanyWithAdminComponent from "../components/superCOO/CreateCompanyWithAdmin";

const CreateAdminWithCompanyPage = () => {
  return (
    <>
      <CreateCompanyWithAdminComponent />
    </>
  );
};
export default CreateAdminWithCompanyPage;
