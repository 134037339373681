import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link, useNavigate } from "react-router-dom";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { InputGroup } from "react-bootstrap";
import { Card, Collapse } from "react-bootstrap";
import { faPlus, faMinus, faSearch } from "@fortawesome/free-solid-svg-icons";
import CopyButton from "./../../assets/svgs/copy/Copy.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { formatDate } from "../../helpers/date";
import Moment from "react-moment";
import moment from "moment";
import OntSVG from "./../../assets/svgs/search/ont.svg";
import FoundOntSvg from "./../../assets/svgs/search/found_onts.svg";
import LinkButtonImage from "./../../assets/svgs/goto/Link.svg";

import OneBar from "./../../assets/svgs/signals/oneBar.svg";
import TwoBars from "./../../assets/svgs/signals/twoBars.svg";
import ThreeBars from "./../../assets/svgs/signals/threeBars.svg";
import FourBars from "./../../assets/svgs/signals/fourBars.svg";
import FiveBars from "./../../assets/svgs/signals/fiveBars.svg";
import SixBars from "./../../assets/svgs/signals/sixBars.svg";
import MinusOneBar from "./../../assets/svgs/signals/minusOneBar.svg";
import LocationSvg from "./../../assets/svgs/maps/location.svg";
import RebootedStatus from "./../../assets/svgs/statuses/rebooting.svg";

//status
import UpStatus from "./../../assets/svgs/statuses/up.svg";
import DownStatus from "./../../assets/svgs/statuses/down.svg";
import InActiveStatus from "./../../assets/svgs/statuses/inactive.svg";
import InProcessStatus from "./../../assets/svgs/statuses/inprocess.svg";
import UpSetStatus from "./../../assets/svgs/statuses/upset.svg";
import InVisibleStatus from "./../../assets/svgs/statuses/invisible.svg";
import StaticRouter from "./../../assets/svgs/statuses/router-static.png";
import MacAddressesCard from "../Cards/MacAddressesCard";
import EditEndUsersModal from "../Model/EditEndUsersModal";
import { showUserName } from "../../helpers/endUser";
//block svgs

import BlockInProcessOntIcon from "./../../assets/svgs/router/blockInProcess.svg";
import UnBlockInProcessOntIcon from "./../../assets/svgs/router/unblockInProcess.svg";
import BlockedOntIcon from "./../../assets/svgs/router/blocked.svg";
import { getSearchOntsRoute } from "../../helpers/apiRoutes";
const SEARCH_ONTS_ROUTE = getSearchOntsRoute();
const SearchONTComponent: React.FC<GP> = ({}) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
  const [collapsable, setCollapsable] = useState({
    found: false,
    bond: false,
    ont: false,
    actionQue: false,
  });
  const [activeEndUsers, setActiveEndUsers] = useState<any>([]);
  const [activeBindingId, setActiveBindingID] = useState<number>(-1);

  const [choice, setChoice] = useState<string>("PON_NUMBER");
  const [activeBinding, setActiveBinding] = useState<ActiveBindingForEndUser>({
    oltFrame: -1,
    oltSlot: -1,
    oltPonPort: -1,
    intPkOltID: -1,
    ponNumber: "",
  });
  const [show, setShow] = useState<boolean>(false);
  const [ontType, setOntType] = useState<number>(2);

  const handleClose = (isCall: boolean) => {
    if (isCall === true) {
      getSearchData({ searchString: searchString, choice });
    }
    setShow(false);
    setActiveEndUsers([]);
    setActiveBindingID(-1);
    setActiveBinding({
      oltFrame: -1,
      oltSlot: -1,
      oltPonPort: -1,
      intPkOltID: -1,
      ponNumber: "",
    });
  };
  const [
    getSearchData,
    searchResponseData,
    searchError,
    searchLoading,
    searchMessage,
    searchErrorCount,
  ] = useAxiosPost<SearchOntResponseProp>(`${SEARCH_ONTS_ROUTE}`);

  const [
    deleteRegistration,
    deleteRegistrationResponse,
    deleteError,
    deleteLoading,
    deleteMessage,
    deleteErrorCount,
  ] = useAxiosPost<DeleteRegistrationFromSearchProp>("binding/deleteBinding");

  useEffect(() => {
    if (deleteError !== null && deleteErrorCount !== 0) {
      notifyError(deleteError);
      setIsShowDelete(true);
      setIsSearched(true);
    }
  }, [deleteError, deleteErrorCount]);
  useEffect(() => {
    if (deleteRegistrationResponse !== null) {
      console.log({ deleteRegistrationResponse });
    }
  }, [deleteRegistrationResponse]);
  const [searchString, setSearchString] = useState("");

  const [results, setResults] = useState<any>({
    foundOnt: null,
    dbOntArray: null,
    bindArray: null,
    actionQueOnt: null,
  });
  useEffect(() => {
    if (searchError !== null && searchErrorCount !== 0) {
      notifyError(searchError);
    }
  }, [searchError, searchErrorCount]);
  useEffect(() => {
    if (searchResponseData !== null) {
      const responseData: any = searchResponseData.responseData;
      const {
        foundOnt = null,
        dbOntArray = null,
        bindArray = null,
        actionQueOnt = null,
      } = responseData;
      if (dbOntArray?.length > 0) {
        const { isOntEponOrGpon } = dbOntArray[0];
        setOntType(Number(isOntEponOrGpon));
      }
      setResults({
        foundOnt,
        dbOntArray,
        bindArray,
        actionQueOnt,
      });
      if (dbOntArray !== null || bindArray !== null) {
        setIsShowDelete(true);
      }
      //   setAllSlots(responseData || []);
    }
  }, [searchResponseData]);
  const handleSearch = () => {
    if (searchString.length < 3) {
      return;
    }
    setIsSearched(true);
    getSearchData({ searchString: searchString, choice });
  };
  const convertMetersToKiloMeters = (meters: any) => {
    if (meters < 1000) {
      return `${meters} Meters`;
    } else {
      return `${meters / 1000} KM`;
    }
  };

  const getTimeAgo = (_date: any) => {
    const startTime = moment(_date);
    const currentTime = moment();

    return (
      <Moment from={currentTime} ago>
        {startTime}
      </Moment>
    );
  };
  const handleDeleteOnt = (
    intPkOltID: number,
    intPkOntBindingID: number,
    ponNumber: string
  ) => {
    // deleteRegistration({
    //   intPkOltID,
    //   intPkOntBindingID,
    //   ponNumber,
    // });
    setIsShowDelete(false);
    setIsSearched(false);
  };

  const getSignalImage = (
    status: any,
    isOntInActive: any,
    isActivationInProcess: any,
    intPkOntRegisterationID: any,
    isOntRebooted: any
  ) => {
    if (isOntRebooted === true) {
      return <img src={RebootedStatus} />;
    }

    if (isActivationInProcess == true) {
      return <img src={InProcessStatus} />;
    }
    if (isOntInActive == true) {
      return <img src={InActiveStatus} />;
    }
    if (status === "UP") {
      return <img src={UpStatus} />;
    }
    if (status === "DOWN") {
      return <img src={DownStatus} />;
    }
    if (status === "UPSET") {
      return <img src={UpSetStatus} />;
    }
    if (intPkOntRegisterationID == null) {
      return <img src={InVisibleStatus} />;
    }
  };
  const getSignalsBar = (bars: number, isOntRebooted: any, ontStatus: any) => {
    if (isOntRebooted === true) {
      return <img src={MinusOneBar} className="image-float-right" />;
    }
    if (ontStatus === "DOWN") {
      return <img src={MinusOneBar} className="image-float-right" />;
    }
    if (bars === -1) {
      return <img src={MinusOneBar} className="image-float-right" />;
    } else if (bars === 1) {
      return <img src={OneBar} className="image-float-right" />;
    } else if (bars === 2) {
      return <img src={TwoBars} className="image-float-right" />;
    } else if (bars === 3) {
      return <img src={ThreeBars} className="image-float-right" />;
    } else if (bars === 4) {
      return <img src={FourBars} className="image-float-right" />;
    } else if (bars === 5) {
      return <img src={FiveBars} className="image-float-right" />;
    } else if (bars === 6) {
      return <img src={SixBars} className="image-float-right" />;
    }
  };
  const showPonOrMacAsciiPon = (
    ontType: number,
    mac: any,
    pon: any,
    asciiPon: any
  ) => {
    if (ontType === 1) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">MacAddress</span> :{" "}
          <span className="data-r-field">{mac}</span>{" "}
          <CopyToClipboard
            text={mac || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
    if (ontType === 2) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">Pon</span> :{" "}
          <span className="data-r-field">{pon}</span>{" "}
          <CopyToClipboard
            text={pon || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
    if (ontType === 3) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">Pon</span> :{" "}
          <span className="data-r-field">{asciiPon}</span>{" "}
          <CopyToClipboard
            text={asciiPon || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
  };
  const getBlockedImage = (blockState: number) => {
    if (blockState == 0) {
      return <img src={StaticRouter} className="image-float-right" />;
    }
    if (blockState == 1) {
      return <img src={BlockedOntIcon} className="image-float-right" />;
    }
    if (blockState == 2) {
      return <img src={BlockInProcessOntIcon} className="image-float-right" />;
    }
    if (blockState == 3) {
      return (
        <img src={UnBlockInProcessOntIcon} className="image-float-right" />
      );
    }
  };
  return (
    <>
      <ToastContainer />
      {show === true ? (
        <EditEndUsersModal
          show={show}
          intPkOntBindingID={activeBindingId}
          handleClose={handleClose}
          endUsers={activeEndUsers}
          activeBinding={activeBinding}
        />
      ) : null}
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="12" md="12" xl="12" className="p-5">
            <Card className="p-5">
              <Card.Title className="text-center olt-heading pt-3 main-ont-heading">
                {" "}
                Search ONTs
              </Card.Title>
              <Row className="mt-4">
                <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
                <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                  <div className="input-group">
                    <select
                      className="form-control-sm"
                      value={choice}
                      onChange={(e) => {
                        setChoice(e.target.value);
                      }}
                    >
                      <option value="PON_NUMBER">Pon Number</option>
                      <option value="END_USER">Phone/Name/PPPoEID</option>
                      <option value="PANEL_MAC_ADDRESS">
                        Panel Mac Address
                      </option>
                      <option value="CLIENT_MAC_ADDRESS">
                        Client Mac Address
                      </option>
                    </select>
                    <input
                      className="form-control"
                      placeholder={`Enter Mac Address Or Pon Number`}
                      type="text"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleSearch();
                        }}
                        className="button-primary-search"
                        type="button"
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
              {isSearched === true ? (
                <>
                  {/* {results.bindArray !== null ? (
                  <Card>
                    <Card.Body>
                      <Card.Title>Bindings</Card.Title>
                    </Card.Body>
                    <Collapse in={collapsable.bond}>
                      <Card.Body>
                        {results.bindArray.map((item: any) => {
                          return (
                            <>
                              <Row>
                                <h5 className="text-center">{item.oltName}</h5>
                                <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                                  <p>
                                    <span style={{ float: "left" }}>
                                      Pon#{item.ponNumber}
                                    </span>
                                    <span style={{ float: "right" }}>
                                      FSP: {`${item.fsp}`}
                                    </span>{" "}
                                  </p>
                                </Col>
                                <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                                  <p style={{ marginTop: "5px" }}>
                                    <span style={{ float: "left" }}>
                                      Bind Enforce Reg :{" "}
                                      {item.bindEnforceRegisteration === true
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                    <span style={{ float: "right" }}>
                                      <img
                                        src={item.ispLogoUrl}
                                        style={{ maxWidth: "50px" }}
                                      />
                                    </span>{" "}
                                  </p>
                                </Col>
                                <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                                  <p style={{ marginTop: "5px" }}>
                                    <span style={{ float: "left" }}>
                                      Created By :{" "}
                                      {`${item.userFullname} (${item.userType})`}
                                    </span>
                                  </p>
                                </Col>
                                <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                                  <p style={{ marginTop: "5px" }}>
                                    <span style={{ float: "left" }}>
                                      Vlan Name : {`${item.vlanName} `}
                                    </span>
                                  </p>
                                </Col>
                                <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                                  <p style={{ marginTop: "5px" }}>
                                    <span style={{ float: "left" }}>
                                      ISP Name : {`${item.ispName} `}
                                    </span>
                                  </p>
                                </Col>
                                <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                                  <p style={{ marginTop: "5px" }}>
                                    <span style={{ float: "left" }}>
                                      Date :{" "}
                                      {formatDate(item.bindCreatedDatetime)}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                      </Card.Body>
                    </Collapse>
                    <Button
                      onClick={() => {
                        setCollapsable({
                          ...collapsable,
                          bond: !collapsable.bond,
                        });
                      }}
                    >
                      {collapsable.bond ? (
                        <FontAwesomeIcon
                          icon={faMinus}
                          style={{
                            marginRight: "10px",
                            marginTop: "5px",
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{
                            marginRight: "10px",
                            marginTop: "5px",
                          }}
                        />
                      )}
                    </Button>
                  </Card>
                ) : (
                  <Card>
                    <Card.Body>
                      <Card.Title>Bindings</Card.Title>
                    </Card.Body>

                    <Card.Body>
                      <p>No Binding Found</p>
                    </Card.Body>
                  </Card>
                )} */}
                  {results.dbOntArray !== null ? (
                    <Card className="mt-3 single-ont-container">
                      <Card.Body>
                        <Card.Title>
                          <img src={OntSVG} /> ONT Registration
                        </Card.Title>
                      </Card.Body>
                      <Collapse in={collapsable.ont}>
                        <Card.Body>
                          {results.dbOntArray.map((item: any) => {
                            return (
                              <>
                                <Row className="single-ont-container ">
                                  <Col
                                    className="mx-h-100"
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <img
                                      style={{ width: "50px" }}
                                      src={item.ispLogoUrl}
                                    />
                                  </Col>

                                  <Col
                                    className="text-center mx-h-100"
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    {getSignalImage(
                                      item.ontStatus,
                                      item.isOntInActive,
                                      item.isActivationInProcess,
                                      item.intPkOntRegisterationID,
                                      item.isOntRebooted
                                    )}
                                  </Col>

                                  <Col
                                    className="mx-h-100 mt-3"
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    {getSignalsBar(
                                      item.barsCount,
                                      item.isOntRebooted,
                                      item.ontStatus
                                    )}
                                  </Col>
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <h4>
                                      <span
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setActiveEndUsers(item.endUsers);
                                          setShow(true);
                                          setActiveBindingID(
                                            item.intPkOntBindingID
                                          );
                                          setActiveBinding({
                                            oltFrame: item.oltFrame,
                                            oltSlot: item.oltSlot,
                                            oltPonPort: item.oltPonPort,
                                            ponNumber: item.ponNumber,
                                            intPkOltID: item.intPkOltID,
                                          });
                                        }}
                                        className=" end-user-name-heading cursor-pointer-class"
                                      >
                                        PPPoEID :
                                        {showUserName(
                                          item.endUsers,
                                          item.ontRegistrationDescInOLT
                                        )}
                                      </span>

                                      <CopyToClipboard
                                        text={
                                          showUserName(
                                            item.endUsers,
                                            item.ontRegistrationDescInOLT
                                          ) || ""
                                        }
                                        onCopy={() => {
                                          // notifySuccess(`Pon Number Copied`);
                                        }}
                                      >
                                        <img
                                          src={CopyButton}
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </CopyToClipboard>
                                    </h4>
                                  </Col>
                                  <Col
                                    md={5}
                                    lg={5}
                                    xl={5}
                                    xxl={5}
                                    sm={5}
                                    xs={5}
                                  >
                                    {getBlockedImage(Number(item.blockState))}
                                  </Col>
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">OLT</span> :{" "}
                                    <span className="data-r-field">
                                      {item.oltName}
                                    </span>
                                  </Col>
                                  <Col
                                    className="mt-2"
                                    md={5}
                                    lg={5}
                                    xl={5}
                                    xxl={5}
                                    sm={12}
                                    xs={12}
                                  >
                                    {ontType === 2 ? (
                                      <>
                                        <span className="image-float-left">
                                          <span className="data-r-label">
                                            FSP
                                          </span>{" "}
                                          :{" "}
                                          <span className="data-r-field">
                                            {item.fsp}
                                          </span>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className="image-float-left">
                                          <span className="data-r-label">
                                            Id
                                          </span>{" "}
                                          :{" "}
                                          <span className="data-r-field">
                                            {item.fspId}
                                          </span>
                                        </span>
                                      </>
                                    )}
                                    <span className="image-float-right">
                                      <span className="data-r-label">
                                        ONT ID
                                      </span>{" "}
                                      :{" "}
                                      <span className="data-r-field">
                                        {item.ontID}
                                      </span>
                                    </span>
                                  </Col>

                                  {showPonOrMacAsciiPon(
                                    ontType,
                                    item.formattedOntEponMacAddressID,
                                    item.ponNumber,
                                    item.asciiHexaPonNumber
                                  )}
                                  <Col
                                    className="mt-1"
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    sm={12}
                                    xs={12}
                                  >
                                    {item.ontStatus == "UP" ||
                                    item.ontStatus == "UPSET" ? (
                                      <>
                                        {" "}
                                        <span className="data-r-label">
                                          Signal/Distance:
                                        </span>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className="data-r-field">
                                          {item.ontRxSignalFromOLT} / ({" "}
                                          {convertMetersToKiloMeters(
                                            item.ontDistanceFromOltInMeters
                                          )}
                                          ) {"{"}{" "}
                                          {getTimeAgo(
                                            item.updateResultOntRxSignalFromOLTDate
                                          )}{" "}
                                          {"}"}
                                        </span>
                                      </>
                                    ) : null}
                                  </Col>
                                  <Col
                                    className="mt-1"
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      Registered By
                                    </span>{" "}
                                    <span className="data-r-field">
                                      {item.userType}
                                    </span>{" "}
                                  </Col>

                                  <MacAddressesCard
                                    servicePortsArray={item.servicePortArray}
                                  />
                                  {item.intPkOntRegisterationID !== null ? (
                                    <Col
                                      className="mt-1"
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      xxl={12}
                                      sm={12}
                                      xs={12}
                                    >
                                      <img
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigate(
                                            `/single-registration-by-id/${item.oltName}/${item.intPkOntBindingID}/${item.intPkOltID}`
                                          );
                                        }}
                                        className="image-float-right"
                                        src={LinkButtonImage}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </Col>
                                  ) : null}
                                </Row>

                                {/* <Row
                        onClick={(e) => {
                          navigate(
                            `/single-registration-by-id/${oltName}/${item.intPkOntBindingID}`
                          );
                        }}
                        key={item.intPkOntBindingID}
                        style={{
                          background: getBackGroundColorCodeByOntStatus(
                            item.ontStatus,
                            item.isOntInActive
                          ),
                          padding: "30px",
                          cursor: "pointer",
                          borderRadius: "12px",
                          marginBottom: "10px",
                        }}
                      >
                        <h5>
                          {item.isOntInActive === true
                            ? "Inactive"
                            : item.ontStatus}

                          {getSignalsBar(item.barsCount)}
                        </h5>
                        <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                          <p>
                            <span style={{ float: "left" }}>
                              Pon#{item.ponNumber}
                            </span>
                            <span style={{ float: "right" }}>
                              FSP: {`${item.fsp}`}
                              {"  "}ontID: {`${item.ontID}`}
                            </span>{" "}
                          </p>
                        </Col>
                        <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                          <p style={{ marginTop: "5px" }}>
                            <span style={{ float: "left" }}>
                              {item.ontStatus == "UP" ||
                              item.ontStatus == "UPSET" ? (
                                <>
                                  <p>
                                    Distance :{" "}
                                    {convertMetersToKiloMeters(
                                      item.ontDistanceFromOltInMeters
                                    )}{" "}
                                    {"{"}
                                    {getTimeAgo(
                                      item.updateResultOntDistanceFromOltInMetersDate
                                    )}
                                    {"}"}
                                  </p>
                                  <p>
                                    Rx Signal: {item.ontRxSignalFromOLT}
                                    {"{"}{" "}
                                    {getTimeAgo(
                                      item.updateResultOntRxSignalFromOLTDate
                                    )}{" "}
                                    {"}"}
                                  </p>
                                </>
                              ) : null}
                            </span>
                            <span style={{ float: "right" }}>
                              <img
                                src={item.ispLogoUrl}
                                style={{ maxWidth: "50px" }}
                              />
                            </span>{" "}
                          </p>
                        </Col>
                      </Row> */}
                              </>
                            );
                          })}
                        </Card.Body>
                      </Collapse>
                      <Button
                        onClick={() => {
                          setCollapsable({
                            ...collapsable,
                            ont: !collapsable.ont,
                          });
                        }}
                      >
                        {collapsable.ont ? (
                          <FontAwesomeIcon
                            icon={faMinus}
                            style={{
                              marginRight: "10px",
                              marginTop: "5px",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{
                              marginRight: "10px",
                              marginTop: "5px",
                            }}
                          />
                        )}
                      </Button>
                    </Card>
                  ) : (
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          {" "}
                          <img src={OntSVG} />
                          ONT Registration
                        </Card.Title>
                      </Card.Body>

                      <Card.Body>
                        <p>No ONT Found</p>
                      </Card.Body>
                    </Card>
                  )}

                  {results.foundOnt !== null ? (
                    <Card className="mt-3 single-ont-container">
                      <Card.Body>
                        <Card.Title>
                          {" "}
                          <img src={FoundOntSvg} />
                          Found ONTs
                        </Card.Title>
                      </Card.Body>
                      <Collapse in={collapsable.found}>
                        <Card.Body>
                          <Row>
                            <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                              <h5 className="text-center">
                                {results?.foundOnt?.oltName}
                              </h5>
                            </Col>

                            <Col
                              className="mt-3"
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              sm={12}
                              xs={12}
                            >
                              <span className="data-r-label-black">Pon</span> :{" "}
                              <span className="data-r-field">
                                {results?.foundOnt?.ponNumber}
                              </span>{" "}
                            </Col>
                            <Col
                              className="mt-3"
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              sm={6}
                              xs={12}
                            >
                              <span className="image-float-right">
                                <span className="data-r-label-black">FSP</span>{" "}
                                :{" "}
                                <span className="data-r-field">
                                  {results?.foundOnt?.fsp}
                                </span>{" "}
                              </span>
                            </Col>
                            <Col
                              className="mt-3"
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sm={12}
                              xs={12}
                            >
                              <span className="data-r-label-black">Date</span> :{" "}
                              <span className="data-r-field">
                                {results?.foundOnt?.datetime &&
                                  getTimeAgo(results?.foundOnt?.datetime)}{" "}
                                {results?.foundOnt?.datetime && "Ago"}
                              </span>{" "}
                            </Col>

                            <Col
                              className="mt-3"
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sm={12}
                              xs={12}
                            >
                              <span className="data-r-label-black">
                                Conflict
                              </span>{" "}
                              :{" "}
                              <span className="data-r-field-red">
                                {results?.foundOnt?.conflictMessage}
                              </span>{" "}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Collapse>
                      <Button
                        onClick={() => {
                          setCollapsable({
                            ...collapsable,
                            found: !collapsable.found,
                          });
                        }}
                      >
                        {collapsable.found ? (
                          <FontAwesomeIcon
                            icon={faMinus}
                            style={{
                              marginRight: "10px",
                              marginTop: "5px",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{
                              marginRight: "10px",
                              marginTop: "5px",
                            }}
                          />
                        )}
                      </Button>
                    </Card>
                  ) : (
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          {" "}
                          <img src={FoundOntSvg} /> Found ONTs
                        </Card.Title>
                      </Card.Body>

                      <Card.Body>
                        <p>No Results Found</p>
                      </Card.Body>
                    </Card>
                  )}

                  {results.actionQueOnt !== null ? (
                    <Card>
                      <Card.Body>
                        <Card.Title>ONT is under process</Card.Title>
                      </Card.Body>
                      <Collapse in={collapsable.actionQue}>
                        <Card.Body>
                          <Row>
                            <h5 className="text-center">
                              {results?.actionQueOnt?.oltName}
                            </h5>
                            <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                              <p>
                                <span style={{ float: "left" }}>
                                  Pon#{results?.actionQueOnt?.ponNumber}
                                </span>
                              </p>
                            </Col>

                            <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                              <p style={{ marginTop: "5px" }}>
                                <span style={{ float: "left" }}>
                                  FSP: {`${results?.actionQueOnt?.fsp}`}
                                </span>
                              </p>
                            </Col>

                            <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                              <p style={{ marginTop: "5px" }}>
                                <span style={{ float: "left" }}>
                                  VLAN: {`${results?.actionQueOnt?.vlan}`}
                                </span>
                              </p>
                            </Col>

                            <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                              <p style={{ marginTop: "5px" }}>
                                <span style={{ float: "left" }}>
                                  Action: {`${results?.actionQueOnt?.action}`}
                                </span>
                              </p>
                            </Col>

                            <Col md={12} lg={12} xl={12} xxl={12} sm={12}>
                              <p style={{ marginTop: "5px" }}>
                                <span style={{ float: "left" }}>
                                  Date:{" "}
                                  {results?.actionQueOnt?.requestDatetime &&
                                    getTimeAgo(
                                      results?.actionQueOnt?.requestDatetime
                                    )}
                                </span>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Collapse>
                      <Button
                        onClick={() => {
                          setCollapsable({
                            ...collapsable,
                            actionQue: !collapsable.actionQue,
                          });
                        }}
                      >
                        {collapsable.actionQue ? (
                          <FontAwesomeIcon
                            icon={faMinus}
                            style={{
                              marginRight: "10px",
                              marginTop: "5px",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{
                              marginRight: "10px",
                              marginTop: "5px",
                            }}
                          />
                        )}
                      </Button>
                    </Card>
                  ) : (
                    <Card>
                      <Card.Body>
                        <Card.Title>Action Que</Card.Title>
                      </Card.Body>

                      <Card.Body>
                        <p>No Results Found</p>
                      </Card.Body>
                    </Card>
                  )}
                </>
              ) : null}
            </Card>
            {/* Search Card */}

            {/* Search Card End */}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SearchONTComponent;
