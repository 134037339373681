import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import CapoltLogo from "./../../assets/svgs/logos/capolt-logo.svg";
import RouterImage from "./../../assets/svgs/logos/router-image.svg";
import "./../../assets/styles/login.css";
import { Card } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { notifySuccess } from "../toast/Success";
import OtpInput from "react-otp-input";
import { confirmAlert } from "react-confirm-alert";

type Props = {
  isLoggedIn?: boolean;
};

const UpdateEmailComponent: React.FC<Props> = ({}) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // * Resend Apis
  const [
    postResendCodeToCurrentEmail,
    postResendCodeToCurrentResponse,
    postResendCodeToCurrentError,
    postResendCodeToCurrentLoading,
    postResendCodeToCurrentMessage,
    postResendCodeToCurrentErrorCount,
  ] = useAxiosPost<ForgotPasswordProp>("users/auth/resendCodeOnCurrentEmail");
  useEffect(() => {
    if (
      postResendCodeToCurrentError !== null &&
      postResendCodeToCurrentErrorCount !== 0
    ) {
      setIsFirstResendDisabled(false);
      notifyError(postResendCodeToCurrentError);
    }
  }, [postResendCodeToCurrentError, postResendCodeToCurrentErrorCount]);
  useEffect(() => {
    if (postResendCodeToCurrentResponse !== null) {
      const message = postResendCodeToCurrentResponse.message;
      if (message) {
        notifySuccess(message);
      }
    }
  }, [postResendCodeToCurrentResponse]);

  const [
    postResendCodeToNewEmailApi,
    resendCodeToNewEmailApiResponse,
    resendCodeToNewEmailApiError,
    resendCodeToNewEmailApiLoading,
    resendCodeToNewEmailApiMessage,
    resendCodeToNewEmailApiErrorCount,
  ] = useAxiosPost<NewEmailRegisterProp>("users/auth/resendCodeToNewEmail");
  useEffect(() => {
    if (
      resendCodeToNewEmailApiError !== null &&
      resendCodeToNewEmailApiErrorCount !== 0
    ) {
      notifyError(resendCodeToNewEmailApiError);
      setIsSecondResendDisabled(false);
    }
  }, [resendCodeToNewEmailApiError, resendCodeToNewEmailApiErrorCount]);
  useEffect(() => {
    if (resendCodeToNewEmailApiResponse !== null) {
      const message = resendCodeToNewEmailApiResponse.message;
      if (message) {
        notifySuccess(message);
      }
    }
  }, [resendCodeToNewEmailApiResponse]);

  // * Resend Apis end
  const [
    sendCodeToCurrentEmailApi,
    currentEmailApiResponse,
    currentEmailApiError,
    currentEmailApiLoading,
    currentEmailApiMessage,
    currentEmailApiErrorCount,
  ] = useAxiosPost<NewEmailRegisterProp>("users/auth/getNewEmailForUpdate");

  useEffect(() => {
    if (currentEmailApiError !== null && currentEmailApiErrorCount !== 0) {
      notifyError(currentEmailApiError);
    }
  }, [currentEmailApiError, currentEmailApiErrorCount]);
  useEffect(() => {
    if (currentEmailApiResponse !== null) {
      const message = currentEmailApiResponse.message;
      if (message) {
        notifySuccess(message);
        setStep(4);
      }
    }
  }, [currentEmailApiResponse]);
  const [
    verifyCodeOnNewEmailApi,
    verifyCodeOnNewEmailResponse,
    verifyCodeOnNewEmailError,
    verifyCodeOnNewEmailLoading,
    verifyCodeOnNewEmailMessage,
    verifyCodeOnNewEmailErrorCount,
  ] = useAxiosPost<NewEmailVerificationCodeProp>(
    "users/auth/verifyNewEmailAndUpdate"
  );

  useEffect(() => {
    if (
      verifyCodeOnNewEmailError !== null &&
      verifyCodeOnNewEmailErrorCount !== 0
    ) {
      notifyError(verifyCodeOnNewEmailError);
    }
  }, [verifyCodeOnNewEmailError, verifyCodeOnNewEmailErrorCount]);
  useEffect(() => {
    if (verifyCodeOnNewEmailResponse !== null) {
      const message = verifyCodeOnNewEmailResponse.message;
      if (message) {
        notifySuccess(message);
        localStorage.setItem("email", newEmail);
        confirmAlert({
          title: "Email Updated",
          message:
            "Email Updated Successfully, For Security Reasons We are going to log you out and delete all your sessions in 20 seconds.",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                console.log("User clicked Yes so we just need to Delete");
              },
            },
          ],
          overlayClassName: "overlay-custom-class-name-for-alert",
        });
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }, 20000);
      }
    }
  }, [verifyCodeOnNewEmailResponse]);

  const [step, setStep] = useState<number>(1);
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<ForgotPasswordProp>("users/auth/sendCodeToUpdateEmail");

  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      notifyError(loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      const message = loginResponseData.message;
      if (message) {
        notifySuccess(message);
        setStep(2);
      }
    }
  }, [loginResponseData]);

  const [
    postVerifyCode,
    verifyCodeResponse,
    verifyError,
    verifyLoading,
    verifyMessage,
    verifyErrorCount,
  ] = useAxiosPost<VerifyForgotCodeProp>("users/auth/verifyCurrentEmail");

  const loginValidationSchema = yup.object().shape({
    email: yup.string().required(),
  });
  const verifyCodeValidationSchema = yup.object().shape({
    code: yup
      .string()
      .required()
      .min(4, "Must be 4 characters")
      .max(4, "Must be 4 Characters"),
  });

  const verifyNewCodeValidationSchema = yup.object().shape({
    code: yup
      .string()
      .required()
      .min(8, "Must be 8 characters")
      .max(8, "Must be 8 Characters"),
  });
  const currentEmailValidationSchema = yup.object().shape({
    newEmail: yup.string().email("Invalid Email").required("Invalid Email"),
  });

  useEffect(() => {
    if (verifyError !== null && verifyErrorCount !== 0) {
      notifyError(verifyError);
    }
  }, [verifyError, verifyErrorCount]);
  useEffect(() => {
    if (verifyCodeResponse !== null) {
      const message = verifyCodeResponse.message;
      if (message) {
        notifySuccess(message);
        setStep(3);
      }
    }
  }, [verifyCodeResponse]);
  useEffect(() => {
    setEmail(localStorage.getItem("email") || "");
  }, []);
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [newCode, setNewCode] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [isFirstResendDisabled, setIsFirstResendDisabled] =
    useState<boolean>(false);
  const [isSecondResendDisabled, setIsSecondResendDisabled] =
    useState<boolean>(false);
  const handleLogin = async (event: any) => {
    event.preventDefault();
    const dataToSend: ForgotPasswordProp = {
      email: email,
    };
    try {
      const isValid = await loginValidationSchema.validate(dataToSend);
      postLoginData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };
  const handleVerifyCode = async (event: any) => {
    event.preventDefault();
    const dataToSend: VerifyForgotCodeProp = {
      email: email,
      code,
    };
    try {
      const isValid = await verifyCodeValidationSchema.validate(dataToSend);
      postVerifyCode(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };
  const handleVerifyNewCode = async (event: any) => {
    event.preventDefault();
    const dataToSend: NewEmailVerificationCodeProp = {
      code: newCode,
      newEmail,
    };
    try {
      const isValid = await verifyNewCodeValidationSchema.validate(dataToSend);
      verifyCodeOnNewEmailApi(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    const dataToSend: any = {
      newEmail,
    };
    try {
      const isValid = await currentEmailValidationSchema.validate(dataToSend);
      if (!regex.test(newEmail)) {
        notifyError(`Invalid Email`);
        return;
      }
      console.log("here we are", newEmail);

      sendCodeToCurrentEmailApi(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  const handleResendCode = () => {
    setIsFirstResendDisabled(true);
    const dataToSend: ForgotPasswordProp = {
      email: email,
    };
    postResendCodeToCurrentEmail(dataToSend);
    setTimeout(() => {
      setIsFirstResendDisabled(false);
    }, 60000);
  };
  const handleResendCodeSecondStep = () => {
    setIsSecondResendDisabled(true);
    const dataToSend: NewEmailRegisterProp = {
      newEmail: newEmail,
    };
    postResendCodeToNewEmailApi(dataToSend);
    setTimeout(() => {
      setIsSecondResendDisabled(false);
    }, 60000);
  };
  const getActiveStep = () => {
    switch (step) {
      case 1:
        return step1();
      case 2:
        return step2();
      case 3:
        return step3();
      case 4:
        return step4();
    }
  };
  const step1 = () => {
    return (
      <>
        <div className="login-form-main-container">
          <Form.Group className="mb-3 min-w-100" controlId="formBasicEmail">
            <Form.Label>
              <b style={{ color: "#696969" }}>Current Email</b>
            </Form.Label>
            <Form.Control
              type="text"
              value={email}
              disabled={true}
              // onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Current email"
            />
          </Form.Group>

          <button className="button-primary" onClick={handleLogin}>
            Send Code
          </button>
        </div>
      </>
    );
  };
  const step2 = () => {
    return (
      <>
        <div className="login-form-main-container">
          <Form.Label>Enter OTP</Form.Label>
          <Form.Group className="mb-3">
            <OtpInput
              value={code}
              onChange={setCode}
              numInputs={4}
              inputStyle={`otp-input-single-container`}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </Form.Group>
          <button
            className="button-primary"
            disabled={code.length !== 4 ? true : false}
            onClick={handleVerifyCode}
          >
            Verify Code
          </button>
          <span
            onClick={(e) => {
              if (isFirstResendDisabled === true) {
                return;
              }
              handleResendCode();
            }}
            style={{
              fontWeight: "500",
              fontSize: "24px",
              color: "#09B0F0",
            }}
            className={`mt-2 ${
              isFirstResendDisabled === false ? "cursor-pointer-class" : ""
            }`}
          >
            Resend
          </span>
        </div>
      </>
    );
  };
  const step3 = () => {
    return (
      <>
        <div className="login-form-main-container">
          <Form.Label>New Email</Form.Label>
          <Form.Group className="mb-3 min-w-100">
            <Form.Control
              type="text"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="New Email"
            />
          </Form.Group>

          <button className="button-primary" onClick={handleResetPassword}>
            Send Code To New Email
          </button>
        </div>
      </>
    );
  };
  const step4 = () => {
    return (
      <>
        <div className="login-form-main-container">
          <Form.Label>
            Enter OTP That You Received On New Email : <b>{newEmail}</b>
          </Form.Label>
          <Form.Group className="mb-3">
            <OtpInput
              value={newCode}
              onChange={setNewCode}
              numInputs={8}
              inputStyle={`otp-input-single-container`}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </Form.Group>
          <button
            className="button-primary"
            disabled={newCode.length !== 8 ? true : false}
            onClick={handleVerifyNewCode}
          >
            Verify Code
          </button>
          <span
            onClick={(e) => {
              if (isSecondResendDisabled === true) {
                return;
              }
              handleResendCodeSecondStep();
            }}
            style={{
              fontWeight: "500",
              fontSize: "24px",
              color: "#09B0F0",
            }}
            className={`mt-2 ${
              isSecondResendDisabled === false ? "cursor-pointer-class" : ""
            }`}
          >
            Resend
          </span>
        </div>
      </>
    );
  };
  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Update Email
            </Card.Title>
            <Form>{getActiveStep()}</Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default UpdateEmailComponent;
