import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import "./../assets/styles/login.css";
import Axios from "axios";
import { baseURL } from "../hooks/useAxiosPost";
type Props = {
  isLoggedIn?: boolean;
};

const LoginFormComponentToDeleteAccount: React.FC<Props> = ({}) => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const handleLogin = async (event: any) => {
    event.preventDefault();
    const dataToSend: LoginType = {
      username: userName,
      password,
    };
    try {
      const res = await Axios.post(
        `${baseURL}/users/auth/loginToDeleteAccount`,
        dataToSend
      );
      console.log({ res });
      const authTokenString = res?.data?.responseData?.authTokenString || false;
      if (!authTokenString) {
        notifyError(`Invalid Credentials`);
        return;
      }
      localStorage.setItem("authTokenToDeleteAccount", authTokenString);
      window.location.href = "/delete-account";
    } catch (error: any) {
      console.log({ error: error.response.data.message });
      notifyError(error.response.data.message || `Some Error Occurs`);
    }
  };
  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };

  return (
    <>
      <ToastContainer />
      <div className="App" style={{ padding: "50px" }}>
        <h3>Login Form </h3>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-4">
              <form id="loginform" onSubmit={handleLogin}>
                <div className="form-group mt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter email or username"
                    value={userName}
                    onChange={(event) => setUserName(event.target.value)}
                  />
                </div>
                <div className="form-group mt-2">
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>

                <button type="submit" className="btn btn-primary mt-3">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginFormComponentToDeleteAccount;
