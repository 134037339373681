import CreateEndUserComponent from "../components/admin/CreateEndUser";

const CreateEndUsersPage = () => {
  return (
    <>
      <CreateEndUserComponent />
    </>
  );
};
export default CreateEndUsersPage;
