import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";

import { toast } from "react-toastify";
import PlusIconSvg from "./../../assets/svgs/icons/plusIcon.svg";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifySuccess } from "../toast/Success";
import { notifyError } from "../toast/Error";
import CreateEndUserModalComponent from "./CreateEndUserModal";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
const EditEndUsersModal: React.FC<EditUserModalProp> = ({
  handleClose,
  show,
  endUsers,
  intPkOntBindingID,
  activeBinding,
}) => {
  const [
    getFreeEndUsersData,
    freeEndUsersResponseData,
    freeEndUsersError,
    freeEndUsersLoading,
    freeEndUsersMessage,
    freeEndUsersErrorCount,
  ] = useAxiosPost<ResponseProp>("endUsers/getMyFreeEndUsers");

  const [
    removeEndUserPost,
    removeEndUserResponse,
    removeEndUserError,
    removeEndUsersLoading,
    removeEndUsersMessage,
    removeEndUsersErrorCount,
  ] = useAxiosPost<RemoveUserFromBinding>("binding/removeUsersFromBinding");
  const [
    linkBindingToEndUserPost,
    linkBindingToEndUserResponse,
    linkBindingToEndUserError,
    linkBindingToEndUsersLoading,
    linkBindingToEndUsersMessage,
    linkBindingToEndUsersErrorCount,
  ] = useAxiosPost<AssignUserToBinding>("binding/assignBindingToEndUser");

  const [showEndUserModel, setShowEndUserModel] = useState(false);
  const [selectedEndUser, setSelectedEndUser] = useState<any>("");
  const [isPlusIconClicked, setIsPlusIconIsClicked] = useState<boolean>(false);
  const [freeEndUsers, setFreeEndUsers] = useState<any>([]);
  useEffect(() => {
    if (freeEndUsersResponseData !== null) {
      const endUsersResponse: any = freeEndUsersResponseData.responseData;
      setFreeEndUsers(endUsersResponse || []);
    }
  }, [freeEndUsersResponseData]);
  useEffect(() => {
    getFreeEndUsersData({});
  }, []);

  useEffect(() => {
    if (removeEndUserResponse !== null) {
      const message: any = removeEndUserResponse.message;
      notifySuccess(message);
      handleClose(true);
    }
  }, [removeEndUserResponse]);
  useEffect(() => {
    if (removeEndUserError !== null && removeEndUsersErrorCount !== 0) {
      notifyError(removeEndUserError);
    }
  }, [removeEndUserError, removeEndUsersErrorCount]);

  useEffect(() => {
    if (
      linkBindingToEndUserError !== null &&
      linkBindingToEndUsersErrorCount !== 0
    ) {
      notifyError(linkBindingToEndUserError);
    }
  }, [linkBindingToEndUserError, linkBindingToEndUsersErrorCount]);

  useEffect(() => {
    if (linkBindingToEndUserResponse !== null) {
      const message: any = linkBindingToEndUserResponse.message;
      notifySuccess(message);
      handleClose(true);
    }
  }, [linkBindingToEndUserResponse]);
  const createEndUser = (
    endUserFullName: string,
    endUserAddress: string,
    endUserPhoneNumber: string | number,
    intPkAreaID: string | number,
    intPkEndUserID: number
  ) => {
    const endUser = {
      endUserFullName,
      endUserAddress,
      endUserPhoneNumber,
      intPkAreaID,
      intPkEndUserID,
    };
    let _freeEndUsers = freeEndUsers;
    _freeEndUsers.push(endUser);
    setFreeEndUsers(_freeEndUsers);
    setSelectedEndUser(intPkEndUserID);
  };
  const handleCloseEndUserModel = () => {
    setShowEndUserModel(false);
  };
  return (
    <>
      {showEndUserModel === true ? (
        <CreateEndUserModalComponent
          show={showEndUserModel}
          handleClose={handleCloseEndUserModel}
          createEndUser={createEndUser}
        />
      ) : null}
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Edit End Users <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="edit-user-items-parent">
            {endUsers.map((item: any) => {
              return (
                <div className="edit-user-item" key={item.intPkEndUserID}>
                  <span className="data-r-label-black end-user-item-name">
                    {item.endUserFullName}
                  </span>
                  <span
                    onClick={(e) => {
                      const role = getUserType();
                      if (
                        role === "" ||
                        role == "DEMO_DEALER" ||
                        role === "DEMO_ADMIN"
                      ) {
                        notifyDemoAccountError();
                        return;
                      }
                      e.preventDefault();
                      removeEndUserPost({
                        intPkOntBindingID: intPkOntBindingID,
                        endUsers: [Number(item.intPkEndUserID)],
                      });
                    }}
                    className="end-user-item-action data-r-label cursor-pointer-class"
                  >
                    Remove
                  </span>
                </div>
              );
            })}
            {isPlusIconClicked === true ? (
              <>
                <Form.Group style={{ width: "420px" }} className="mb-3 mt-3">
                  <Form.Select
                    style={{ borderRadius: "30px", height: "50px" }}
                    value={selectedEndUser}
                    onChange={(e) => {
                      setSelectedEndUser(e.target.value);
                    }}
                  >
                    <option value="">Select End user</option>
                    {freeEndUsers.map((item: any) => {
                      return (
                        <option
                          value={item.intPkEndUserID}
                          key={item.intPkEndUserID}
                        >
                          {item.endUserFullName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3 mt-5">
                  <button
                    className="button-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      const role = getUserType();
                      if (
                        role === "" ||
                        role == "DEMO_DEALER" ||
                        role === "DEMO_ADMIN"
                      ) {
                        notifyDemoAccountError();
                        return;
                      }
                      // handleClose();
                      if (selectedEndUser !== "") {
                        linkBindingToEndUserPost({
                          ...activeBinding,
                          intPkEndUserID: selectedEndUser,
                        });
                      }
                    }}
                  >
                    Proceed
                  </button>
                </Form.Group>
              </>
            ) : (
              <Form.Group className="mb-3 mt-5">
                <button
                  className="button-plus-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPlusIconIsClicked(true);
                    // handleClose();
                  }}
                >
                  <img src={PlusIconSvg} />
                </button>
              </Form.Group>
            )}
          </div>
          {isPlusIconClicked === true ? (
            <Form.Group className="mb-3">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowEndUserModel(true);
                }}
                className="button-primary"
                style={{ minWidth: "200px", float: "right" }}
              >
                New End User
              </button>
            </Form.Group>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default EditEndUsersModal;
/*




          */
