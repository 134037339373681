import AllAreasComponent from "../components/admin/AllAreas";
import DealerAreasComponent from "../components/dealer/DealerAreas";

const AllAreasPage = () => {
  const role = localStorage.getItem("userType") || "";
  const getPageRoleWise = () => {
    if (role === "ADMIN") {
      return <AllAreasComponent />;
    }
    if (role === "DEALER") {
      return <DealerAreasComponent />;
    }
    return <></>;
  };
  return <>{getPageRoleWise()}</>;
};
export default AllAreasPage;
