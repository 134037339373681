import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import PlusIconSvg from "./../../assets/svgs/icons/plusIcon.svg";
import useAxiosPost, {
  authTokenString,
  baseURL,
} from "../../hooks/useAxiosPost";
import { notifySuccess } from "../toast/Success";
import { notifyError } from "../toast/Error";
import CreateEndUserModalComponent from "./CreateEndUserModal";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
const UnlockPortModalComponent: React.FC<UnlockPonPortModalProp> = ({
  handleClose,
  show,
  intPkPonPortID,
  modalType,
}) => {
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [minutes, setMinutes] = useState<number | string>("");
  const onSubmit = async () => {
    if (selectedTime === "") {
      notifyError(`Invalid Time Selected`);
      return;
    }
    if (!Number.isInteger(Number(minutes))) {
      notifyError(`Invalid End Time, it should be an integer value`);
      return;
    }
    if (Number(minutes) < 1) {
      notifyError(`Invalid End Time, it should be an integer value`);
    }
    const getMeTotalMinutes = countTotalMinutes();
    if (getMeTotalMinutes > 2880) {
      notifyError(`Invalid End Time`);
      return;
    }
    try {
      const dataToSend = {
        authTokenString: authTokenString,
        intPkPonPortID,
        ponPort: {
          intPkPonPortID,
          openForMinutes: getMeTotalMinutes,
        },
      };
      const res = await Axios.post(`${baseURL}/${getRoute()}`, dataToSend);
      const data = res.data;
      const { success, errorMsg } = data;
      if (success === false) {
        notifyError(errorMsg);
      }
      if (success === true) {
        notifySuccess(errorMsg);
        handleClose(true);
      }
    } catch (error) {
      notifyError(`Server Error`);
    }
  };
  const countTotalMinutes = () => {
    if (selectedTime === "MINUTES") {
      return Number(minutes);
    }
    if (selectedTime === "HOURS") {
      return Number(minutes) * 60;
    }
    if (selectedTime === "DAYS") {
      return Number(minutes) * 60 * 24;
    }
    return Number(minutes);
  };
  const getRoute = () => {
    if (modalType === 1) {
      return "openPonPortEnforcement";
    }
    if (modalType === 2) {
      return "openPonPortDefaultVlan";
    }
    return "";
  };
  const getTitle = () => {
    if (modalType === 1) {
      return "Unlock Port";
    }
    if (modalType === 2) {
      return "Open Default Vlan";
    }
    return "";
  };
  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> {getTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Choose Duration</Form.Label>
          <Form.Select
            value={selectedTime}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedTime(value);
            }}
          >
            <option value={""}>Choose</option>
            <option value={"MINUTES"}>Minutes</option>
            <option value={"HOURS"}>Hours</option>
            <option value={"DAYS"}>Days</option>
          </Form.Select>

          <Form.Label>End Time</Form.Label>
          <Form.Control
            type="text"
            value={minutes}
            onChange={(e) => {
              const value = e.target.value;
              setMinutes(value);
            }}
          />
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              onClick={(e) => {
                e.preventDefault();
                const role = getUserType();
                if (
                  role === "" ||
                  role == "DEMO_DEALER" ||
                  role === "DEMO_ADMIN"
                ) {
                  notifyDemoAccountError();
                  return;
                }
                onSubmit();
              }}
              className="button-primary"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UnlockPortModalComponent;
