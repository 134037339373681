import React from "react";
import "./styles.css";

const PolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Privacy Policy for Capolt App:</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          At Capolt, we take your privacy seriously. This Privacy Policy
          explains how we collect, use, and share information when you use our
          app. By using Capolt, you agree to the terms of this Privacy Policy.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information We Collect</h2>
        <ul>
          <li>We do not collect any sensitive information from our users.</li>
          <li>
            We collect the following information when you sign up for or sign in
            to our service: (Name, Email address, Phone number, Address)
          </li>
          <li>
            We only collect information about your network routers and switches
            that you provide to us.
          </li>
          <li>
            We use Firebase Analytics and Crashlytics to collect information
            about how our app is used. This includes information about crashes
            and errors, as well as usage statistics such as the number of users
            and sessions.
          </li>
        </ul>
      </section>
      <section className="policy-section">
        <h2>How We Use Your Information:</h2>
        <ul>
          <li>
            We use Firebase Analytics and Crashlytics to improve our app and fix
            any issues that arise.
          </li>
        </ul>
        <p>
          {" "}
          We use your email address and phone number for the following purposes:
        </p>
        <ul>
          <li>To create and manage your account</li>
          <li>To communicate with you about your account and our service</li>
          <li>
            To send you important notifications, such as service status and
            account updates
          </li>
          <li>To improve our service</li>
        </ul>
      </section>
      <section className="policy-section">
        <h2>Information Sharing:</h2>
        <ul>
          <li>We do not share any information with third parties.</li>
          <li>
            We may share information with our employees or contractors who need
            to access it in order to provide support for our app.
          </li>
        </ul>
      </section>

      <section className="policy-section">
        <h2>Permission:</h2>
        <ul>
          <li>
            <strong>INTERNET:</strong> This permission is required for the
            application to access the internet.
          </li>
          <li>
            <strong>ACCESS_NETWORK_STATE:</strong> This permission is required
            for the application to get the current network state, such as
            whether the device is connected to Wi-Fi or cellular data.
          </li>
        </ul>
      </section>

      <section className="policy-section">
        <h2>Data Retention:</h2>
        <ul>
          <li>
            We retain the information you provide about your network routers and
            switches for as long as you use our app.
          </li>
          <li>
            We retain Firebase Analytics and Crashlytics data for up to 14
            months.
          </li>
        </ul>
      </section>
      <section className="policy-section">
        <h2>Security:</h2>
        <ul>
          <li>
            We take reasonable measures to protect your information from
            unauthorized access, disclosure, or destruction.
          </li>
          <li>
            We use industry-standard encryption to protect your data in transit.
          </li>
        </ul>
      </section>

      <section className="policy-section">
        <h2>Children's privacy:</h2>
        <ul>
          <li>
            The Application is not intended for use by children under the age of
            13. If you are under the age of 13, please do not use the
            Application.
          </li>
        </ul>
      </section>

      <section className="policy-section">
        <h2>Changes to this Privacy Policy:</h2>
        <ul>
          <li>
            We may update this Privacy Policy from time to time. If we make any
            material changes, we will notify you by email or by posting a notice
            in our app.
          </li>
        </ul>
      </section>
      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any requests or concerns about the Privacy Policy or use
          of information, you may contact us at{" "}
          <a href="mailto:capoltnms@gmail.com" data-popup-processed="true">
            capoltnms@gmail.com
          </a>{" "}
          we will respond to you at our earliest. We may refuse proportional
          requests or legal requirements.
        </p>
      </section>
    </div>
  );
};

export default PolicyPage;
