import React, { useState } from "react";
import BrowseBlockedOntsByPonPortsComponent from "../../components/admin/BlockedOnts";
import BrowseOntsByPonPortsComponent from "../../components/admin/BrowseOnts";

const BrowseOntsPage = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const switchTab = (tab: number) => {
    setActiveTab(tab);
  };
  return (
    <>
      {activeTab === 1 ? (
        <BrowseOntsByPonPortsComponent switchTab={switchTab} />
      ) : (
        <BrowseBlockedOntsByPonPortsComponent switchTab={switchTab} />
      )}
    </>
  );
};
export default BrowseOntsPage;
