import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { formatDate } from "../../helpers/date";
import CreateNewPricePolicyModalComponent from "../Model/CreateNewPricePolicyModal";
import UpdatePricePolicyModalComponent from "../Model/UpdatePricePolicyModal";
type Props = {
  isLoggedIn?: boolean;
};

const AllPricePolicyModelsComponentForSuperAdmin: React.FC<Props> = ({}) => {
  const [
    getPricePolicyData,
    getSuperCOOResponse,
    COOError,
    COOLoading,
    COOMessage,
    COOErrorCount,
  ] = useAxiosPost<ResponseProp>("superAdmin/getPricePolicy");
  const [pricePolicies, setPricePolicies] = useState<any>([]);
  useEffect(() => {
    getPricePolicyData({});
  }, []);
  const [show, setShow] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [activePolicyId, setActivePolicyId] = useState<number>(-1);
  const handleClose = (isApiCall: boolean) => {
    setShow(false);
    setShowUpdate(false);
    if (isApiCall === true) {
      getPricePolicyData({});
    }
  };
  useEffect(() => {
    if (COOError !== null && COOErrorCount !== 0) {
      notifyError(COOError);
    }
  }, [COOError, COOErrorCount]);
  useEffect(() => {
    if (getSuperCOOResponse !== null) {
      console.log({ getSuperCOOResponse });
      setPricePolicies(getSuperCOOResponse.responseData || []);
    }
  }, [getSuperCOOResponse]);

  return (
    <>
      <ToastContainer />
      {show === true ? (
        <CreateNewPricePolicyModalComponent
          show={show}
          handleClose={handleClose}
        />
      ) : null}
      <ToastContainer />
      {showUpdate === true ? (
        <UpdatePricePolicyModalComponent
          show={showUpdate}
          handleClose={handleClose}
          intPkOltPricePolicyID={activePolicyId}
        />
      ) : null}
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              All Price Policy Models
              <span style={{ float: "right" }}>
                {" "}
                <Button
                  onClick={(e) => {
                    setShow(true);
                  }}
                  variant="success"
                >
                  Create New Policy
                </Button>
              </span>
            </Card.Title>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Brand</th>
                      <th className="table-header-standard">Model</th>
                      <th className="table-header-standard">Price</th>
                      <th className="table-header-standard">
                        Trial Period Days
                      </th>
                      <th className="table-header-standard">Trail Allowed</th>
                      <th className="table-header-standard">
                        Disable Before Due Days
                      </th>
                      <th className="table-header-standard">Discount%</th>
                      <th className="table-header-standard">Status</th>
                      <th className="table-header-standard">Created</th>
                      <th className="table-header-standard">Last Updated</th>
                      <th className="table-header-standard">Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pricePolicies.map((item: any) => {
                      return (
                        <tr key={item.intPkOltPricePolicyID}>
                          <td>{item.brandName}</td>
                          <td>{item.oltModelName}</td>
                          <td>{item.price}</td>
                          <td>{item.trialPeriodDays}</td>
                          <td>
                            {item.isTrialAllowedOrNot == 1 ? "Yes" : "No"}
                          </td>
                          <td>{item.invoiceDueDaysBeforeDisable}</td>
                          <td>{item.discountPercentage}</td>
                          <td>{item.enable == 1 ? "Enabled" : "Disabled"}</td>

                          <td>{formatDate(item.dateCreated)}</td>
                          <td>{formatDate(item.dateUpdated)}</td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setShowUpdate(true);
                                setActivePolicyId(item.intPkOltPricePolicyID);
                              }}
                              variant="warning"
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllPricePolicyModelsComponentForSuperAdmin;
