import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import CapoltLogo from "./../../assets/svgs/icons/VerificationIcon.svg";
import RouterImage from "./../../assets/svgs/logos/router-image.svg";
import "./../../assets/styles/login.css";
import { InputGroup, FormControl } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { notifySuccess } from "../toast/Success";
import UpdateEmailModalAtSignupComponent from "../Model/UpdateEmailModalAtSignup";
type Props = {
  isLoggedIn?: boolean;
};

const VerificationComponent: React.FC<Props> = ({}) => {
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<VerifyCodeType>("users/auth/verifyCode");

  const [
    postResendApi,
    ResendApiResponse,
    ResendApiError,
    ResendApiLoading,
    ResendApiMessage,
    ResendApiErrorCount,
  ] = useAxiosPost<HandleResendCodeProp>("users/auth/resendCode");

  const loginValidationSchema = yup.object().shape({
    otp: yup.string().required(),
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!localStorage.getItem("goToVerification")) {
      window.location.href = "/login";
    }
    if (localStorage.getItem("auth_token")) {
      window.location.href = "/";
    }
  }, []);
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      notifyError(loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      console.log({ loginResponseData });
      setIsDisabled(true);
      notifySuccess(`User Verified Successfully`);
      localStorage.removeItem("signedUpUser");
      localStorage.removeItem("goToVerification");
      setTimeout(() => {
        window.location.href = "/login";
      }, 3000);
    }
  }, [loginResponseData]);

  const [otp, setOtp] = useState<any>("");
  const handleLogin = async (event: any) => {
    event.preventDefault();
    if (otp.length !== 4) {
      notifyError(`Invalid OTP `);
      return;
    }
    try {
      const isValid = await loginValidationSchema.validate({ otp: otp });
      postLoginData({
        intPkUserID: localStorage.getItem("signedUpUser") || null,
        code: otp,
      });
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };
  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  const handleResendCode = () => {
    //     ,
    setIsResendDisabled(true);
    postResendApi({
      intPkUserID: localStorage.getItem("signedUpUser") || null,
    });
    setTimeout(() => {
      setIsResendDisabled(false);
    }, 60000);
  };
  useEffect(() => {
    if (ResendApiError !== null && ResendApiErrorCount !== 0) {
      notifyError(ResendApiError);
    }
  }, [ResendApiError, ResendApiErrorCount]);
  useEffect(() => {
    if (ResendApiResponse !== null) {
      console.log({ ResendApiResponse });

      notifySuccess(ResendApiResponse.message || "Resent Successfully");
    }
  }, [ResendApiResponse]);
  const handleClose = (isCall: boolean) => {
    setShow(false);
  };
  return (
    <>
      <ToastContainer />
      {show === true ? (
        <UpdateEmailModalAtSignupComponent
          show={show}
          handleClose={handleClose}
          intPkUserID={Number(localStorage.getItem("signedUpUser"))}
        />
      ) : null}
      <Container fluid>
        <Row>
          <Col
            className="routerContainer"
            md={5}
            lg={5}
            xxl={5}
            xl={5}
            sm={5}
            xs={5}
          >
            <Row>
              <Col md={1} lg={1} xl={1} xxl={1} sm={1} xs={1}></Col>
              <Col
                className="mt-10-custom"
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                sm={10}
                xs={11}
              >
                <h1>Welcome To Capolt</h1>
                <h6 style={{ fontWeight: "normal" }}>Login To Your Account</h6>
              </Col>
              <img src={RouterImage} className="h-450" />
            </Row>
          </Col>
          <Col
            className="mt-15-custom"
            md={7}
            lg={7}
            xxl={7}
            xl={7}
            sm={7}
            xs={7}
          >
            <img src={CapoltLogo} />
            <h3>Verification</h3>
            <p style={{ color: "#696969" }}>
              Enter the verification code to complete sign up
            </p>
            <div>
              <Form>
                <div className="login-form-main-container ">
                  <Form.Group className="mb-3">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      inputStyle={`otp-input-single-container`}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </Form.Group>
                  <button
                    className="button-primary"
                    disabled={
                      otp.length !== 4 || loginLoading || isDisabled
                        ? true
                        : false
                    }
                    onClick={handleLogin}
                  >
                    Verify
                  </button>

                  <span
                    onClick={(e) => {
                      if (isResendDisabled === true) {
                        return;
                      }
                      handleResendCode();
                    }}
                    style={{
                      fontWeight: "500",
                      fontSize: "24px",
                      color: "#09B0F0",
                    }}
                    className={`mt-2 ${
                      isResendDisabled === false ? "cursor-pointer-class" : ""
                    }`}
                  >
                    Resend
                  </span>

                  <span
                    onClick={(e) => {
                      setShow(true);
                    }}
                    style={{
                      fontWeight: "500",
                      fontSize: "24px",
                      color: "#09B0F0",
                    }}
                    className={`mt-2 ${
                      isResendDisabled === false ? "cursor-pointer-class" : ""
                    }`}
                  >
                    Update Email
                  </span>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default VerificationComponent;
