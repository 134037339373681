import AllPricePolicyModelsComponentForSuperAdmin from "../../components/admin/AllPricePolicyModelsForSuperAdmin";

const AllPricePolicyModelsPageForSuperAdmin = () => {
  return (
    <>
      <AllPricePolicyModelsComponentForSuperAdmin />
    </>
  );
};
export default AllPricePolicyModelsPageForSuperAdmin;
