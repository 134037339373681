import AllSuperCOOsComponent from "../../components/admin/users/AllSuperCoo";

const AllSuperCooPage = () => {
  return (
    <>
      <AllSuperCOOsComponent />
    </>
  );
};
export default AllSuperCooPage;
