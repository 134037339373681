import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SingleOLTCard from "../Cards/SingleOLTCard";
import ModalForNewAutoOLTCreationForCompanyAdmin from "../Model/CompanyAdminCreateNewAutoOLTModal";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
import { noop } from "lodash";
import { getBrowseOltsRoute } from "../../helpers/apiRoutes";
const BROWSE_OLTS_ROUTE = getBrowseOltsRoute();
const role = getUserType();

const BrowseOLTsComponent: React.FC<GP> = ({}) => {
  const navigate = useNavigate();

  const [
    getAllOltsData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<ResponseProp>(`${BROWSE_OLTS_ROUTE}`);
  const [allOlts, setAllOlts] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      getAllOltsData({});
    }
  };
  const reloadTheApi = () => {
    getAllOltsData({});
  };
  useEffect(() => {
    getAllOltsData({});
  }, []);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { responseData } = oltResponseData;
      if (responseData && Array.isArray(responseData))
        responseData.sort((a: any, b: any) => a.intPkOltID - b.intPkOltID);
      setAllOlts(responseData || []);
    }
  }, [oltResponseData]);
  const prepareHealthStatsForOLT = (healthStats: any): pieData[] => {
    const {
      totalRegisteredOnts,
      totalOfflineOnts,
      totalUpsetOnts,
      totalOnlineOnts,
      totalInActiveOnts,
    } = healthStats;
    const data = [
      {
        // title: "Offline",
        value: Number(totalOfflineOnts) || 0,
        color: "red",
      },
      {
        // title: "Upset",
        value: Number(totalUpsetOnts) || 0,
        color: "yellow",
      },
      {
        // title: "Online",
        value: Number(totalOnlineOnts) || 0,
        color: "green",
      },
      {
        // title: "InActive",
        value: Number(totalInActiveOnts) || 0,
        color: "grey",
      },
    ];
    return data;
  };

  return (
    <>
      {show === true ? (
        <ModalForNewAutoOLTCreationForCompanyAdmin
          show={show}
          handleClose={handleClose}
        />
      ) : null}
      <ToastContainer />
      <Container fluid className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="12" md="12" xl={12} xxl={12}>
            <Container fluid>
              <Row>
                {role === "" || role === "DEMO_ADMIN" || role === "ADMIN" ? (
                  <Col>
                    <span>
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          if (role === "DEMO_ADMIN") {
                            notifyDemoAccountError();
                            return;
                          }
                          navigate("/inprocess-olts");
                        }}
                        to={`/inprocess-olts`}
                        style={{ float: "left", marginBottom: "10px" }}
                        className="btn btn-primary"
                      >
                        Processing Olts
                      </Link>
                    </span>
                    <span>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setShow(true);
                        }}
                        style={{ float: "right", marginBottom: "10px" }}
                        variant="primary"
                      >
                        Create New OLT
                      </Button>
                    </span>
                  </Col>
                ) : null}

                <Row className="">
                  {allOlts.map((olt: any, index: number) => {
                    return (
                      <Col sm={12} xs={12} lg="6" md="6" xl={4} xxl={4}>
                        <SingleOLTCard
                          key={Math.random()}
                          index={index + 1}
                          healthStats={prepareHealthStatsForOLT(
                            olt.oltHealthStats
                          )}
                          oltName={olt.oltName}
                          oltDefaultVlan={olt.oltDefaultVLAN}
                          oltHealthStats={olt.oltHealthStats}
                          intPkOltID={olt.intPkOltID}
                          isOltEponEnabled={Number(olt.isOltEponEnabled)}
                          isOltGponEnabled={Number(olt.isOltGponEnabled)}
                          brand={olt.brand}
                          model={olt.model}
                          OltCpuUsagePercentage={olt.OltCpuUsagePercentage}
                          OltFirmwareVersion={olt.OltFirmwareVersion}
                          OltHardwareVersion={olt.OltHardwareVersion}
                          OltMemoryUsagePercentage={
                            olt.OltMemoryUsagePercentage
                          }
                          OltNameInOLT={olt.OltNameInOLT}
                          OltOwnSystemMacAddress={olt.OltOwnSystemMacAddress}
                          OltUptime={olt.OltUptime}
                          host={olt.host}
                          oltTemperature={olt.oltTemperature}
                          serialNumber={olt.serialNumber}
                          isOltRebooted={olt.isOltRebooted}
                          reloadTheApi={reloadTheApi}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BrowseOLTsComponent;
