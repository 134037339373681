import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
type Props = {
  isLoggedIn?: boolean;
};

const CreateEndUserComponent: React.FC<Props> = ({}) => {
  const [
    postCrateEndUserData,
    endUserResponse,
    endUserError,
    endUserLoading,
    endUserMessage,
    endUserErrorCount,
  ] = useAxiosPost<EndUserProp>("endUsers/createEndUser");

  useEffect(() => {
    if (endUserError !== null && endUserErrorCount !== 0) {
      notifyError(endUserError);
    }
  }, [endUserError, endUserErrorCount]);
  useEffect(() => {
    if (endUserResponse !== null) {
      const { message } = endUserResponse;
      if (message) {
        notifySuccess(message);
      }
      setTimeout(() => {
        window.location.href = "/all-end-users";
      }, 5000);
    }
  }, [endUserResponse]);

  const [
    getMyAreasData,
    areasResponse,
    areasError,
    AreasLoading,
    areasMessage,
    areasErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAllAreas");
  const [allAreas, setAllAreas] = useState<any>([]);

  const createEndUserValidationSchema = yup.object().shape({
    endUserFullName: yup.string().required(),
    endUserAddress: yup.string().required(),
    endUserPhoneNumber: yup.string().required(),
    endUserPPPoEUserID: yup.string().required(),
    intPkAreaID: yup.string().required("Area Is Required"),
  });

  useEffect(() => {
    getMyAreasData({});
  }, []);
  useEffect(() => {
    if (areasResponse !== null) {
      const { responseData } = areasResponse;
      setAllAreas(responseData || []);
    }
  }, [areasResponse]);

  const [endUserData, setEndUserData] = useState<EndUserProp>({
    endUserFullName: "",
    endUserAddress: "",
    endUserPhoneNumber: "",
    intPkAreaID: "",
    endUserPPPoEUserID: "",
  });
  const handleCreateArea = async (event: any) => {
    event.preventDefault();
    const dataToSend: EndUserProp = endUserData;
    try {
      const isValid = await createEndUserValidationSchema.validate(dataToSend);
      postCrateEndUserData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEndUserData({
      ...endUserData,
      [name]: value,
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={endUserData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="6" md="6" className="bg-dark text-white p-5">
            <h3 className="mb-5 text-center">Create New End User</h3>
            <Form>
              {renderInput(
                `endUserPPPoEUserID`,
                "Enter User PPPeE Id",
                `Enter User PPPeE Id`
              )}
              {renderInput(
                `endUserFullName`,
                "Enter User Full Name",
                `Enter User Full Name`
              )}
              {renderInput(
                `endUserAddress`,
                "Enter User Address",
                `Enter User Address`
              )}
              {renderInput(
                `endUserPhoneNumber`,
                "Enter Phone Number",
                `Enter Phone Number`
              )}
              <Form.Group className="mb-3">
                <Form.Label> Select An Area</Form.Label>
                <Form.Select
                  value={endUserData.intPkAreaID}
                  onChange={(e) => {
                    setEndUserData({
                      ...endUserData,
                      intPkAreaID: e.target.value,
                    });
                  }}
                >
                  <option value={""}>Choose Area</option>
                  {allAreas.map((area: any) => {
                    return (
                      <option key={area.intPkAreaID} value={area.intPkAreaID}>
                        {area.areaName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Button
                onClick={handleCreateArea}
                variant="primary"
                type="submit"
                disabled={endUserLoading}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default CreateEndUserComponent;
