import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { FormControl, InputGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";

const CreateNewDealerComponentModal: React.FC<
  ShowModalForCreatingNewDealer
> = ({ show, handleClose, intPkCompanyID }) => {
  const [
    postCrateEndUserData,
    endUserResponse,
    endUserError,
    endUserLoading,
    endUserMessage,
    endUserErrorCount,
  ] = useAxiosPost<CreateDealerProp>("dealers/createNewDealer");

  useEffect(() => {
    if (endUserError !== null && endUserErrorCount !== 0) {
      notifyError(endUserError);
    }
  }, [endUserError, endUserErrorCount]);
  useEffect(() => {
    if (endUserResponse !== null) {
      const response: any = endUserResponse;
      const { message, responseData } = response;
      notifySuccess(message);
      handleClose(true);
    }
  }, [endUserResponse]);

  const createEndUserValidationSchema = yup.object().shape({
    userFullname: yup
      .string()
      .required()
      .min(5)
      .max(40, "Maximum 40 characters allowed in User Full Name"),
    password: yup
      .string()
      .required()
      .min(5)
      .max(20, "Maximum 20 characters allowed in password"),
    username: yup
      .string()
      .required()
      .min(5)
      .max(20, "Maximum 20 characters allowed in password"),
    contactNumber: yup
      .string()
      .required()
      .min(10)
      .max(20, "Maximum 20 characters allowed in phone"),
  });

  const [endUserData, setEndUserData] = useState<CreateDealerProp>({
    contactNumber: "",
    password: "",
    userFullname: "",
    username: "",
    userType: "DEALER",
  });
  const handleCreateArea = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateDealerProp = endUserData;
    try {
      const isValid = await createEndUserValidationSchema.validate(dataToSend);
      dataToSend.username = `${intPkCompanyID}${dataToSend.username}`;
      postCrateEndUserData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEndUserData({
      ...endUserData,
      [name]: value,
    });
  };
  const renderInput = (name: string, placeholder: string, label: string) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type="text"
          value={endUserData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            New Dealer <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="">
            <Row className="justify-content-center">
              <Col className=" p-5">
                {/* username: "", */}
                <h3 className="mb-5 text-center">Create New Dealer</h3>
                <Form>
                  {renderInput(
                    `userFullname`,
                    "Enter Dealer Full Name",
                    `Enter Dealer Full Name`
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label disabled> Enter Dealer UserName</Form.Label>
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        {intPkCompanyID}
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        aria-describedby="inputGroupPrepend"
                        value={endUserData.username}
                        onChange={(e) => {
                          setEndUserData({
                            ...endUserData,
                            username: e.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {renderInput(
                    `password`,
                    "Enter Dealer Password",
                    `Enter Dealer Password`
                  )}
                  {renderInput(
                    `contactNumber`,
                    "Enter Contact Phone",
                    `Enter Contact Phone`
                  )}

                  <Button
                    onClick={handleCreateArea}
                    variant="primary"
                    type="submit"
                    disabled={endUserLoading}
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateNewDealerComponentModal;
