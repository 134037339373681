import React from "react";
import LoginFormComponent from "../components/Auth/LoginForm";
const LoginPage = () => {
  return (
    <>
      <LoginFormComponent />
    </>
  );
};
export default LoginPage;
