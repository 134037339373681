import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { toast } from "react-toastify";
import CreateEndUserModalComponent from "./CreateEndUserModal";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { confirmAlert } from "react-confirm-alert"; // Import
import { notifySuccess } from "../toast/Success";
import { formatDate } from "../../helpers/date";
import { getUserType } from "../../helpers/userDetails";
import { notifyDemoAccountError } from "../toast/demoAccountError";
const RestoreFromLogsModal: React.FC<ShowRestoreModelForFoundOnts> = ({
  handleClose,
  show,
  endUsers,
  activeLogs,
  oltName,
  oltSlot,
  oltFrame,
  oltPonPort,
  //   createRegistration,
}) => {
  const [selectedEndUser, setSelectedEndUser] = useState<any>("");
  const [selectedVlan, setSelectedVlan] = useState<any>("");
  const [showEndUserModel, setShowEndUserModel] = useState(false);
  const [activeRestoreRecord, setActiveRestoreRecord] = useState<any>(null);
  const handleCloseEndUserModel = () => {
    setShowEndUserModel(false);
  };
  const [
    postCheckVlanOwnership,
    ownerShipResponse,
    ownerShipError,
    ownerShipLoading,
    ownerShipMessage,
    ownershipErrorCount,
  ] = useAxiosPost<UserOwnerShipCheckWithVlanProp>(
    "binding/checkVlanOWnershipForDealer"
  );

  const [
    postRestoreBinding,
    restoreBindingResponse,
    restoreBindingError,
    restoreBindingLoading,
    restoreBindingMessage,
    restoreBindingErrorCount,
  ] = useAxiosPost<RestoreBindingFromLogsProp>(
    "binding/restoreBindingFromBindingLogs"
  );
  useEffect(() => {
    if (restoreBindingError !== null && restoreBindingErrorCount !== 0) {
      notifyError(restoreBindingError);
      handleClose();
    }
  }, [restoreBindingError, restoreBindingErrorCount]);
  useEffect(() => {
    if (restoreBindingResponse !== null) {
      const restoreBidingRes: any = restoreBindingResponse.responseData;
      console.log({ restoreBidingRes });
      notifySuccess(`Binding Restored Successfully`);
      handleClose();
    }
  }, [restoreBindingResponse]);
  const createEndUser = (
    endUserFullName: string,
    endUserAddress: string,
    endUserPhoneNumber: string | number,
    intPkAreaID: string | number,
    intPkEndUserID: number
  ) => {
    const endUser = {
      endUserFullName,
      endUserAddress,
      endUserPhoneNumber,
      intPkAreaID,
      intPkEndUserID,
    };
    endUsers.push(endUser);
    setSelectedEndUser(intPkEndUserID);
  };
  useEffect(() => {
    if (ownerShipError !== null && ownershipErrorCount !== 0) {
      console.log({ ownerShipError });
      confirmAlert({
        title: "Vlan Ownership Error",
        message: "VLAN is Not Owned By User Would you like to continue ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              console.log(
                "User clicked Yes so we just need to send what ever vlan it has"
              );
              if (activeRestoreRecord == null) {
                notifyError(`Error While Restoring please try again`);
                return;
              }
              const { intPkOntBindingLogID } = activeRestoreRecord;
              if (!selectedEndUser) {
                notifyError(`No End User Selected`);
                return;
              }
              const dataToSend = {
                oltSlot,
                oltFrame,
                oltPonPort,
                intPkEndUserID: Number(selectedEndUser),
                intPkOntBindingLogID,
              };
              postRestoreBinding(dataToSend);
            },
          },
          {
            label: "No",
            onClick: () => {
              console.log(
                "User clicked no so we need to just close everything"
              );
              handleClose();
            },
          },
        ],
        overlayClassName: "overlay-custom-class-name-for-alert",
      });

      // notifyError(ownerShipError);
    }
  }, [ownerShipError, ownershipErrorCount]);
  useEffect(() => {
    if (ownerShipResponse !== null) {
      const ownerShipRes: any = ownerShipResponse.responseData;
      console.log({ ownerShipRes });
      if (activeRestoreRecord == null) {
        notifyError(`Error While Restoring please try again`);
        return;
      }
      if (!selectedEndUser) {
        notifyError(`No End User Selected`);
        return;
      }
      const { intPkOntBindingLogID } = activeRestoreRecord;
      const dataToSend = {
        oltSlot,
        oltFrame,
        oltPonPort,
        intPkEndUserID: Number(selectedEndUser),
        intPkOntBindingLogID,
      };
      postRestoreBinding(dataToSend);
    }
  }, [ownerShipResponse]);
  useEffect(() => {}, [endUsers]);

  return (
    <>
      {showEndUserModel === true ? (
        <CreateEndUserModalComponent
          show={showEndUserModel}
          handleClose={handleCloseEndUserModel}
          createEndUser={createEndUser}
        />
      ) : null}
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Restore Binding From Logs <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Select End User</Form.Label>
            <Form.Select
              value={selectedEndUser}
              onChange={(e) => {
                setSelectedEndUser(e.target.value);
              }}
            >
              <option value="">Select End user</option>
              {endUsers.map((item: any) => {
                return (
                  <option value={item.intPkEndUserID} key={item.intPkEndUserID}>
                    {item.endUserFullName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowEndUserModel(true);
              }}
              className="button-primary"
              style={{ minWidth: "200px", float: "right" }}
            >
              New End User
            </button>
          </Form.Group>
          <Form.Group className="mb-3">
            <Button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                if (selectedEndUser !== "" && selectedVlan !== "") {
                  //   createRegistration(
                  //     Number(selectedEndUser),
                  //     Number(selectedVlan)
                  //   );
                  handleClose();
                }
              }}
            >
              Create
            </Button>
          </Form.Group>

          {/* Here we have logs table */}

          <Table bordered>
            <thead>
              <tr>
                <th className="table-header-standard">OLT Name</th>
                <th className="table-header-standard">Pon Number</th>
                <th className="table-header-standard">Vlan</th>
                <th className="table-header-standard">FSP</th>
                <th className="table-header-standard">ISP</th>
                <th className="table-header-standard">Date</th>
                <th className="table-header-standard">Action</th>
              </tr>
            </thead>
            <tbody>
              {activeLogs.map((item: any) => {
                return (
                  <tr key={item.intPkOntBindingLogID}>
                    <td>{oltName}</td>
                    <td>{item.ponNumber}</td>
                    <td>{item.vlanName}</td>
                    <td>{`${item.oltFrame}/${item.oltSlot}/${item.oltPonPort}`}</td>
                    <td>
                      {" "}
                      <img style={{ width: "50px" }} src={item.ispLogoUrl} />
                    </td>
                    <td> {formatDate(item.bindLogCreatedDatetime)}</td>
                    <td>
                      {" "}
                      <button
                        disabled={ownerShipLoading}
                        className=" btn-success"
                        onClick={(e) => {
                          const role = getUserType();
                          if (
                            role === "" ||
                            role == "DEMO_DEALER" ||
                            role === "DEMO_ADMIN"
                          ) {
                            notifyDemoAccountError();
                            return;
                          }
                          setActiveRestoreRecord(item);
                          postCheckVlanOwnership({
                            intPkUserID: Number(item.intPkUserID),
                            intPkVlanID: Number(item.intPkVlanID),
                          });
                        }}
                      >
                        Restore
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default RestoreFromLogsModal;
