import AllBankAccountsByBankForSuperAdmin from "../../components/admin/AllBankAccountsByBankForSuperAdmin";

const AllBankAccountsByBankPageForSuperAdmin = () => {
  return (
    <>
      <AllBankAccountsByBankForSuperAdmin />
    </>
  );
};
export default AllBankAccountsByBankPageForSuperAdmin;
