import CreateNewONTModelComponent from "../../components/admin/models/CreateNewONTModel";

const CreateNewONTModelPage = () => {
  return (
    <>
      <CreateNewONTModelComponent />
    </>
  );
};
export default CreateNewONTModelPage;
