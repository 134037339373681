import AllStaffComponent from "../../components/companyAdmin/AllCompanyStaffComponent";

const CompanyStaffPageForCompanyAdmin = () => {
  return (
    <>
      <AllStaffComponent />
    </>
  );
};
export default CompanyStaffPageForCompanyAdmin;
