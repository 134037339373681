import Axios from "axios";
import { baseURL } from "./useAxiosPost";
export const logout = async (token: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        authTokenString: token,
      };
      await Axios.post(`${baseURL}/users/auth/logout`, body);
      window.location.href = "/login";
    } catch (error) {
      window.location.href = "/login";
    }
  });
};
