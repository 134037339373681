import CreateAreaComponent from "../components/admin/CreateArea";

const CreateAreaPage = () => {
  return (
    <>
      <CreateAreaComponent />
    </>
  );
};
export default CreateAreaPage;
