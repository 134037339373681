import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const MyComponent = () => {
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const handleCloseFirstModal = () => setShowFirstModal(false);
  const handleCloseSecondModal = () => setShowSecondModal(false);

  const handleShowFirstModal = () => setShowFirstModal(true);
  const handleShowSecondModal = () => setShowSecondModal(true);

  return (
    <>
      <Button variant="primary" onClick={handleShowFirstModal}>
        Open First Model
      </Button>

      {/* First Modal */}
      <Modal show={showFirstModal} onHide={handleCloseFirstModal}>
        <Modal.Header closeButton>
          <Modal.Title>First Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Modal body content */}
          <Button variant="primary" onClick={handleShowSecondModal}>
            Open Second Modal
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFirstModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Second Modal */}
      <Modal show={showSecondModal} onHide={handleCloseSecondModal}>
        <Modal.Header closeButton>
          <Modal.Title>Second Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>{/* Modal body content */}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSecondModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyComponent;
