import AllCompaniesComponentForAccount from "../../components/accountant/AllCompaniesAccount";

const AllCompaniesPageForAccountant = () => {
  return (
    <>
      <AllCompaniesComponentForAccount />
    </>
  );
};
export default AllCompaniesPageForAccountant;
