import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { notifyError } from "../../toast/Error";
import { notifySuccess } from "../../toast/Success";
import "./../../../assets/styles/olt.css";
import "./../../../assets/styles/ont-list.css";

const CreateNEWOLTModelComponent: React.FC<GP> = ({}) => {
  const [
    postCreateNewOLTModelData,
    createNewOLTModelResponseData,
    OLTError,
    OLTLoading,
    COOMessage,
    OLTErrorCount,
  ] = useAxiosPost<CreateNewOLTModelProp>("superAdmin/CreateNewOLTModel");

  const [
    getBrandsData,
    brandsResponse,
    brandsError,
    brandsLoading,
    brandsMessage,
    brandsErrorCount,
  ] = useAxiosPost<ResponseProp>("superAdmin/brands/getAllBrands");
  useEffect(() => {
    getBrandsData({});
  }, []);
  useEffect(() => {
    if (brandsError !== null && brandsErrorCount !== 0) {
      notifyError(brandsError);
    }
  }, [brandsError, brandsErrorCount]);
  useEffect(() => {
    if (brandsResponse !== null) {
      console.log({ brandsResponse: brandsResponse.responseData });
      setBrands(brandsResponse.responseData || []);
    }
  }, [brandsResponse]);

  const createNewCOOValidationSchema = yup.object().shape({
    oltModelName: yup.string().required(),
    url: yup.string().required(),
  });

  useEffect(() => {
    if (OLTError !== null && OLTErrorCount !== 0) {
      notifyError(OLTError);
    }
  }, [OLTError, OLTErrorCount]);
  useEffect(() => {
    if (createNewOLTModelResponseData !== null) {
      const { message } = createNewOLTModelResponseData;
      if (message) {
        notifySuccess(message);
      }
      setTimeout(() => {
        window.location.href = "/all-olt-models";
      }, 5000);
    }
  }, [createNewOLTModelResponseData]);

  const [newOltModelData, setNewOLTModelData] = useState<CreateNewOLTModelProp>(
    {
      oltModelName: "",
      intPkBrandID: "",
      totalSlots: 0,
      gponSlots: 0,
      isOltGponEnabled: 0,
      isOltEponEnabled: 0,
      technology: "",
      gponPorts: 0,
      eponPorts: 0,
      url: "",
    }
  );
  const [isOltGponEnabled, setIsOltGponEnabled] = useState<boolean>(false);
  const [isOltEponEnabled, setIsOltEponEnabled] = useState<boolean>(false);
  const [brands, setBrands] = useState<any>([]);
  const handleCreateCOO = async (event: any) => {
    event.preventDefault();
    const dataToSend: CreateNewOLTModelProp = {
      ...newOltModelData,
      isOltGponEnabled: isOltGponEnabled == true ? 1 : 0,
      isOltEponEnabled: isOltEponEnabled == true ? 1 : 0,
    };
    try {
      if (isOltEponEnabled === false && isOltGponEnabled === false) {
        notifyError(`You Need to enable one of the Epon Or Gpon`);
        return;
      }

      const isValid = await createNewCOOValidationSchema.validate(dataToSend);
      postCreateNewOLTModelData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewOLTModelData({
      ...newOltModelData,
      [name]: value,
    });
  };
  const renderInput = (name: string, placeholder: string, type: string) => {
    return (
      <>
        <Row className="mt-2">
          <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}></Col>
          <Col md={6} lg={6} xs={12} sm={12} xl={6} xxl={6}>
            <Form.Control
              className="min-height-50p input-shade-gray bd-35p"
              placeholder={`${placeholder}`}
              type={type}
              value={newOltModelData[name]}
              name={`${name}`}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
      </>
    );
  };
  const showFieldsDynamically = (technology: string) => {
    if (technology == "") {
      return <></>;
    }
    if (technology === "XPON") {
      return (
        <>
          {renderInput(`totalSlots`, "Total Slots", `text`)}
          {renderInput(`gponSlots`, "Total GPon Slots", `text`)}
        </>
      );
    }
    if (technology === "EPON") {
      return <>{renderInput(`eponPorts`, "Total Epon Ports", `text`)}</>;
    }
    if (technology === "GPON") {
      return <>{renderInput(`gponPorts`, "Total Gpon Ports", `text`)}</>;
    }
  };
  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Create New OLT Model
            </Card.Title>
            <Form className="mt-4">
              <Row className="mt-2">
                <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}></Col>
                <Col md={6} lg={6} xs={12} sm={12} xl={6} xxl={6}>
                  <Form.Select
                    className="min-height-50p bd-35p"
                    value={newOltModelData.intPkBrandID}
                    onChange={(e) => {
                      setNewOLTModelData({
                        ...newOltModelData,
                        intPkBrandID: e.target.value,
                      });
                    }}
                  >
                    <option value={""} key={""}>
                      Choose Brand
                    </option>{" "}
                    {brands.map((item: any) => {
                      return (
                        <option
                          key={item.intPkBrandID}
                          value={item.intPkBrandID}
                        >
                          {item.brandName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}></Col>
                <Col md={6} lg={6} xs={12} sm={12} xl={6} xxl={6}>
                  <Form.Select
                    className="min-height-50p bd-35p"
                    value={newOltModelData.technology}
                    onChange={(e) => {
                      setNewOLTModelData({
                        ...newOltModelData,
                        technology: e.target.value,
                      });
                    }}
                  >
                    <option value={""} key={""}>
                      Choose Technology
                    </option>{" "}
                    {["XPON", "EPON", "GPON"].map((item: any) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              {renderInput(`oltModelName`, "OLT Model Name", `text`)}
              {renderInput(`url`, "OLT Model Image URL", `text`)}
              {showFieldsDynamically(newOltModelData.technology)}
              <Row className="mt-2">
                <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}></Col>
                <Col md={6} lg={6} xs={12} sm={12} xl={6} xxl={6}>
                  <Form.Check
                    type="checkbox"
                    label="isOltEponEnabled"
                    checked={isOltEponEnabled}
                    onChange={(e) => {
                      setIsOltEponEnabled(e.target.checked);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}></Col>
                <Col md={6} lg={6} xs={12} sm={12} xl={6} xxl={6}>
                  <Form.Check
                    type="checkbox"
                    label="isOltGponEnabled"
                    checked={isOltGponEnabled}
                    onChange={(e) => {
                      setIsOltGponEnabled(e.target.checked);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={3} lg={3} xs={12} sm={12} xl={3} xxl={3}></Col>
                <Col
                  className="text-center mt-5"
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  xl={6}
                  xxl={6}
                >
                  <button
                    className="button-primary"
                    type="submit"
                    disabled={OLTLoading}
                    onClick={handleCreateCOO}
                  >
                    Create New
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default CreateNEWOLTModelComponent;
