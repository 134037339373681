import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link, useLocation } from "react-router-dom";
import SingleOLTCard from "../Cards/SingleOLTCard";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { formatDate } from "../../helpers/date";

const SingleOLTForDiagnoseForNOC: React.FC<GP> = ({}) => {
  const { intPkCompanyID, companyName, intPkOltID, oltType } = useParams();

  const [
    getAllOltsData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<DiagnoseOLTProp>("noc/openSingleOLTForDiagnose");
  const [
    getDiagnoseLogsRequest,
    diagnoseLogResponse,
    diagnoseResponseError,
    diagnoseResponseLoading,
    diagnoseResponseMessage,
    diagnoseResponseErrorCount,
  ] = useAxiosPost<DiagnoseOLTProp>("noc/getDiagnoseLog");

  const [
    requestPonPortData,
    ponPortRequestResponse,
    ponPortRequestError,
    ponPortRequestLoading,
    ponPortRequestMessage,
    ponPortRequestErrorCount,
  ] = useAxiosPost<DiagnoseOLTProp>("netflow/requests/diagnoseOLT", true);
  useEffect(() => {
    if (ponPortRequestError !== null && ponPortRequestErrorCount !== 0) {
      notifyError(ponPortRequestError);
    }
  }, [ponPortRequestError, ponPortRequestErrorCount]);
  const [allOlts, setAllOlts] = useState<any>([]);
  const [diagnoseLogs, setDiagnoseLogs] = useState<any>([]);
  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [isOltLoaded, setIsOltLoaded] = useState<boolean>(false);

  const reloadTheApi = () => {
    getAllOltsData({ intPkOltID: Number(intPkOltID) });
    getDiagnoseLogsRequest({ intPkOltID: Number(intPkOltID) });
  };
  useEffect(() => {
    getAllOltsData({ intPkOltID: Number(intPkOltID) });
    getDiagnoseLogsRequest({ intPkOltID: Number(intPkOltID) });
  }, []);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { responseData } = oltResponseData;
      if (responseData && Array.isArray(responseData))
        responseData.sort((a: any, b: any) => a.intPkOltID - b.intPkOltID);
      setAllOlts(responseData || []);
      setIsOltLoaded(true);
    }
  }, [oltResponseData]);
  useEffect(() => {
    if (diagnoseLogResponse !== null) {
      const { responseData } = diagnoseLogResponse;
      if (responseData && Array.isArray(responseData)) {
        setDiagnoseLogs(responseData || []);
      }
      setIsApiLoaded(true);
    }
  }, [diagnoseLogResponse]);
  const prepareHealthStatsForOLT = (healthStats: any): pieData[] => {
    const {
      totalRegisteredOnts,
      totalOfflineOnts,
      totalUpsetOnts,
      totalOnlineOnts,
      totalInActiveOnts,
    } = healthStats;
    const data = [
      {
        // title: "Offline",
        value: Number(totalOfflineOnts) || 0,
        color: "red",
      },
      {
        // title: "Upset",
        value: Number(totalUpsetOnts) || 0,
        color: "yellow",
      },
      {
        // title: "Online",
        value: Number(totalOnlineOnts) || 0,
        color: "green",
      },
      {
        // title: "InActive",
        value: Number(totalInActiveOnts) || 0,
        color: "grey",
      },
    ];
    return data;
  };
  const handleDiagnose = () => {
    requestPonPortData({ intPkOltID: Number(intPkOltID) });
  };
  useEffect(() => {
    if (ponPortRequestResponse != null) {
      const { message } = ponPortRequestResponse;
      if (message) {
        notifySuccess(message);
        getNewLog();
      }
    }
  }, [ponPortRequestResponse]);

  const getNewLog = () => {
    const Interval = setInterval(() => {
      getDiagnoseLogsRequest({ intPkOltID: Number(intPkOltID) });
    }, 2000);
    setTimeout(() => {
      clearInterval(Interval);
    }, 60000);
  };
  return (
    <>
      <ToastContainer />
      {isOltLoaded === true ? (
        <>
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col xs lg="10" md="10" xl={10} xxl={10}>
                <Container>
                  <Row>
                    {allOlts.map((olt: any, index: number) => {
                      return (
                        <SingleOLTCard
                          key={Math.random()}
                          index={index + 1}
                          healthStats={prepareHealthStatsForOLT(
                            olt.oltHealthStats
                          )}
                          oltName={olt.oltName}
                          oltDefaultVlan={olt.oltDefaultVLAN}
                          oltHealthStats={olt.oltHealthStats}
                          intPkOltID={olt.intPkOltID}
                          isOltEponEnabled={Number(olt.isOltEponEnabled)}
                          isOltGponEnabled={Number(olt.isOltGponEnabled)}
                          brand={olt.brand}
                          model={olt.model}
                          OltCpuUsagePercentage={olt.OltCpuUsagePercentage}
                          OltFirmwareVersion={olt.OltFirmwareVersion}
                          OltHardwareVersion={olt.OltHardwareVersion}
                          OltMemoryUsagePercentage={
                            olt.OltMemoryUsagePercentage
                          }
                          OltNameInOLT={olt.OltNameInOLT}
                          OltOwnSystemMacAddress={olt.OltOwnSystemMacAddress}
                          OltUptime={olt.OltUptime}
                          host={olt.host}
                          oltTemperature={olt.oltTemperature}
                          serialNumber={olt.serialNumber}
                          isOltRebooted={olt.isOltRebooted}
                          reloadTheApi={reloadTheApi}
                          showGoto={false}
                        />
                      );
                    })}
                  </Row>
                </Container>
              </Col>
            </Row>
            {Number(oltType) == 1 || Number(oltType) == 3 ? (
              <>
                <Row className="justify-content-center">
                  <Col xs lg="10" md="10" xl={10} xxl={10}>
                    <Container className=" mt-5">
                      <Card
                        style={{ marginBottom: "10px" }}
                        className="text-center"
                      >
                        <Card.Body>
                          <Card.Title className="olt-heading">
                            Diagnose Logs
                          </Card.Title>
                          {isApiLoaded === true ? (
                            <>
                              {diagnoseLogs.length > 0 ? (
                                <Row>
                                  <Table>
                                    <tr>
                                      <th className="table-header-standard">
                                        TelNet Status
                                      </th>
                                      <th className="table-header-standard">
                                        NetFlow Read String
                                      </th>
                                      <th className="table-header-standard">
                                        NetFlow Netflow Write String
                                      </th>
                                      <th className="table-header-standard">
                                        Request Created
                                      </th>
                                      <th className="table-header-standard">
                                        Request Updated
                                      </th>
                                    </tr>
                                    {diagnoseLogs.map((item: any) => {
                                      return (
                                        <tr key={item.intPkOltDiagnoseLogID}>
                                          <td>{item.telnetStatus}</td>
                                          <td>{item.netFlowReadStatus}</td>
                                          <td>{item.netFlowWriteStatus}</td>
                                          <td>{formatDate(item.createdAt)}</td>
                                          <td>
                                            {item.updatedAt !== null
                                              ? formatDate(item.updatedAt)
                                              : "-"}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </Table>
                                </Row>
                              ) : (
                                <p>No Logs Found</p>
                              )}
                            </>
                          ) : (
                            <ThreeDots
                              height="50"
                              width="50"
                              radius="9"
                              color="#5ac8fa"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              visible={true}
                            />
                          )}

                          <button
                            className="button-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDiagnose();
                            }}
                          >
                            Diagnose OLT
                          </button>
                        </Card.Body>
                      </Card>
                    </Container>
                  </Col>
                </Row>
              </>
            ) : null}
          </Container>
        </>
      ) : (
        <ThreeDots
          height="50"
          width="50"
          radius="9"
          color="#5ac8fa"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        />
      )}
    </>
  );
};
export default SingleOLTForDiagnoseForNOC;
