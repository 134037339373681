import BrowseOLTsComponent from "../../components/admin/BrowseOlts";

const BrowseOLTsPage = () => {
  return (
    <>
      <BrowseOLTsComponent />
    </>
  );
};
export default BrowseOLTsPage;
