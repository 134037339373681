import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import CapoltLogo from "./../../assets/svgs/logos/capolt-logo.svg";
import RouterImage from "./../../assets/svgs/logos/router-image.svg";
import "./../../assets/styles/login.css";
import { InputGroup, FormControl } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link } from "react-router-dom";

type Props = {
  isLoggedIn?: boolean;
};

const LoginFormComponent: React.FC<Props> = ({}) => {
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<LoginType>("auth/login");

  const loginValidationSchema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  useLayoutEffect(() => {
    if (localStorage.getItem("auth_token")) {
      window.location.href = "/";
    }
  }, []);
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      notifyError(loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      const { authTokenString, userType, email, isVerified, intPkUserID } =
        loginResponseData;
      if (Number(isVerified) !== 1) {
        //it means the user is not verified.
        if (!email) {
          //let's move to another page where we'll get this guy's email
          localStorage.setItem("signedUpUser", intPkUserID || "");
          localStorage.setItem("goForEmailAndPhoneVerification", "1");

          window.location.href = "/verify-email-phone";
        } else if (email) {
          // it means the guy have the email but doesn't verify it's account
          localStorage.setItem("signedUpUser", intPkUserID || "");
          localStorage.setItem("goToVerification", "1");
          window.location.href = "/verify";
        }
      } else if (Number(isVerified) === 1) {
        localStorage.setItem("auth_token", authTokenString || "");
        localStorage.setItem("userType", userType || "");
        localStorage.setItem("username", userName);
        window.location.href = "/";
      }
    }
  }, [loginResponseData]);

  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const handleLogin = async (event: any) => {
    event.preventDefault();
    const dataToSend: LoginType = {
      username: userName,
      password,
    };
    try {
      const isValid = await loginValidationSchema.validate(dataToSend);
      postLoginData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };
  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };

  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col
            className="routerContainer"
            md={5}
            lg={5}
            xxl={5}
            xl={5}
            sm={5}
            xs={5}
          >
            <Row>
              <Col md={1} lg={1} xl={1} xxl={1} sm={1} xs={1}></Col>
              <Col
                className="mt-10-custom"
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                sm={10}
                xs={11}
              >
                <h1>Welcome To Capolt</h1>
                <h6 style={{ fontWeight: "normal" }}>Login To Your Account</h6>
              </Col>
              <img src={RouterImage} className="h-450" />
            </Row>
          </Col>
          <Col
            className="mt-15-custom"
            md={7}
            lg={7}
            xxl={7}
            xl={7}
            sm={7}
            xs={7}
          >
            <img src={CapoltLogo} />
            <div>
              <Form>
                <div className="login-form-main-container">
                  <Form.Group
                    className="mb-3 min-w-100"
                    controlId="formBasicEmail"
                  >
                    <Form.Control
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Enter UserName"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 min-w-100"
                    controlId="formBasicPassword"
                  >
                    <Form.Control
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Enter Password"
                    />
                  </Form.Group>
                  <div className="mt-1 image-float-right">
                    <p>
                      Didn't Remember Password ?{" "}
                      <Link
                        className="signup-link-class"
                        to={`/forgot-password`}
                      >
                        Forgot Password
                      </Link>{" "}
                    </p>
                  </div>
                  <button className="button-primary" onClick={handleLogin}>
                    Login
                  </button>
                  <div className="mt-1 image-float-right">
                    <p>
                      Don't Have an Account ?{" "}
                      <Link className="signup-link-class" to={`/register`}>
                        Sign Up
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default LoginFormComponent;
