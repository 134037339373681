import ViewVlansByOltForCompanyAdmin from "../components/admin/ViewVlansByOltForCompanyAdmin";

const SyncVlansWIthOltPage = () => {
  return (
    <>
      <ViewVlansByOltForCompanyAdmin />
    </>
  );
};
export default SyncVlansWIthOltPage;
