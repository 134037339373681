import React, { useEffect, useState, ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";

import { toast } from "react-toastify";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import useAxiosPost, { baseURL } from "../../hooks/useAxiosPost";
import { removeSpacesFromString } from "../../helpers/strings";
import { getAuthToken } from "../../helpers/userDetails";
const UpdateCompanyIspModalComponent: React.FC<UpdateISpModalProp> = ({
  handleClose,
  show,
  _ispName,
  intPkIspID,
}) => {
  const UpdatePasswordForStaffValidationSchema = yup.object().shape({
    ispName: yup.string().required(),
  });

  const [ispName, setIspName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  useEffect(() => {
    setIspName(_ispName);
  }, []);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const handleCreateVlan = async (event: any) => {
    event.preventDefault();

    const formData = new FormData();
    if (selectedFile) {
      formData.append("ispLogo", selectedFile);
    }
    formData.append("intPkIspID", intPkIspID.toString());
    formData.append("ispName", ispName);
    formData.append("authTokenString", getAuthToken());
    setIsLoading(true);

    try {
      const response = await fetch(`${baseURL}/isps/updateMyIspDetails`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("File uploaded successfully.");
        notifySuccess(`ISP Details Updated Successfully`);
        handleClose(true);
        // Add any additional handling as needed
      } else {
        setIsLoading(false);
        console.error("File upload failed.", response);
        const data = await response.json();
        console.log({ data });
        notifyError(data.message || "Some Error Occur");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      notifyError(`Server Error`);
    }
  };

  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Update ISP Details <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label> Enter ISP Name</Form.Label>
            <Form.Control
              placeholder={`ISP Name`}
              type="text"
              value={ispName}
              onChange={(e) => setIspName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formFileSm" className="mb-3">
            <Form.Label>Choose ISP Logo</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} size="sm" />
          </Form.Group>

          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateVlan}
              disabled={isLoading}
              className="button-primary"
              type="submit"
            >
              Update
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UpdateCompanyIspModalComponent;
