import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import { Link } from "react-router-dom";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import ModalForOLTUpdate from "../Model/UpdateOLTModal";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SearchOltsComponentForNOC: React.FC<GP> = ({}) => {
  const [
    getAllOltsData,
    oltResponseData,
    oltError,
    oltLoading,
    oltMessage,
    oltErrorCount,
  ] = useAxiosPost<SearchOLTsForNOCProp>("noc/oltSearchForNOC");

  const [
    postUpdateOLTStatus,
    oltStatusResponse,
    statusError,
    statusLoading,
    statusMessage,
    statusErrorCount,
  ] = useAxiosPost<UpdateOLTStatus>("superCOO/enableOrDisableOLT");

  const [
    postOltSync,
    syncOltResponse,
    syncOltError,
    syncOltLoading,
    syncOltMessage,
    syncOltErrorCount,
  ] = useAxiosPost<SyncOltProp>("olt/handleOLTSync");
  useEffect(() => {
    if (syncOltError !== null && syncOltErrorCount !== 0) {
      notifyError(syncOltError);
    }
  }, [syncOltError, syncOltErrorCount]);
  useEffect(() => {
    if (syncOltResponse !== null) {
      notifySuccess(`Olt Sync Start`);
    }
  }, [syncOltResponse]);
  const [allOlts, setAllOlts] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [activeOlt, setActiveOlt] = useState<any>({});
  const [searchString, setSearchString] = useState<string>("");
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [activeCompanyName, setActiveCompanyName] = useState<string>("");
  const [activeCompanyId, setActiveCompanyId] = useState<number>(-1);
  //   useEffect(() => {
  //     getAllOltsData({ intPkCompanyID: Number(intPkCompanyID) });
  //   }, []);
  useEffect(() => {
    if (oltError !== null && oltErrorCount !== 0) {
      notifyError(oltError);
    }
  }, [oltError, oltErrorCount]);
  useEffect(() => {
    if (oltResponseData !== null) {
      const { responseData } = oltResponseData;
      setAllOlts(responseData || []);
    }
  }, [oltResponseData]);

  useEffect(() => {
    if (statusError !== null && statusErrorCount !== 0) {
      notifyError(statusError);
    }
  }, [statusError, statusErrorCount]);
  useEffect(() => {
    if (oltStatusResponse !== null) {
      const { responseData, message } = oltStatusResponse;
      notifySuccess(message || "OLT Status Updated");
      getAllOltsData({ searchString: searchString });
    }
  }, [oltStatusResponse]);

  const handleClose = (isCall: boolean) => {
    setShow(false);
    setShowEdit(false);

    setActiveOlt({});
    if (isCall === true) {
      getAllOltsData({ searchString: searchString });
    }
  };
  const handleUpdateOLTStatus = (intPkOltID: number, value: number) => {
    postUpdateOLTStatus({ intPkOltID, value });
  };
  const enableOLT = (oltName: string, intPkOltID: number) => {
    confirmAlert({
      title: `Enable OLT `,
      message: `Do you want to Enable ${oltName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just Enable it.");
            handleUpdateOLTStatus(intPkOltID, 1);
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const disableOLT = (oltName: string, intPkOltID: number) => {
    confirmAlert({
      title: `Disable OLT `,
      message: `Do you want to Disable ${oltName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("User clicked Yes so we just Disable it.");
            handleUpdateOLTStatus(intPkOltID, 0);
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("User clicked no so we need to just close everything");
          },
        },
      ],
      overlayClassName: "overlay-custom-class-name-for-alert",
    });
  };
  const handleSearch = () => {
    if (searchString.length < 3) {
      return;
    }
    setIsSearched(true);
    getAllOltsData({ searchString: searchString });
  };
  const getOltType = (olt: any) => {
    if (olt.brand == "VSOL" && olt.isOltEponEnabled == 1) {
      return 1;
    }
    if (olt.brand == "VSOL" && olt.isOltGponEnabled == 1) {
      return 3;
    }
    if (olt.brand == "Huawei" && olt.isOltGponEnabled == 1) {
      return 2;
    }
  };
  return (
    <>
      <ToastContainer />
      {showEdit === true ? (
        <ModalForOLTUpdate
          companyName={activeCompanyName || ""}
          intPkCompanyID={Number(activeCompanyId)}
          show={showEdit}
          handleClose={handleClose}
          oltData={activeOlt}
        />
      ) : null}

      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Search OLT
            </Card.Title>
            <Row className="mt-4">
              <Col md={2} lg={2} xs={12} sm={12} xl={2} xxl={2}></Col>
              <Col md={8} lg={8} xs={12} sm={12} xl={8} xxl={8}>
                <div className="input-group">
                  <input
                    className="form-control"
                    placeholder={`Search OLT`}
                    type="text"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSearch();
                      }}
                      className="button-primary-search"
                      type="button"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="table-header-standard">Login</th>
                      <th className="table-header-standard">Company Name</th>
                      <th className="table-header-standard">OLT Name</th>
                      <th className="table-header-standard">Brand</th>
                      <th className="table-header-standard">Model</th>
                      <th className="table-header-standard">Host</th>
                      <th className="table-header-standard">Port</th>
                      <th className="table-header-standard">BotNode</th>
                      <th className="table-header-standard">NetFlowBotNode</th>
                      <th className="table-header-standard">Sync</th>
                      <th className="table-header-standard">View Slots</th>
                      <th className="table-header-standard">View Vlans</th>
                      <th className="table-header-standard">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allOlts.map((olt: any) => {
                      return (
                        <tr key={olt.intPkOltID}>
                          <td>
                            <Link
                              target="_blank"
                              className="button-primary link-class"
                              to={`/company-login-through-noc/${olt.intPkCompanyID}`}
                            >
                              Login
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/all-olts-by-company/${olt.intPkCompanyID}/${olt.companyName}`}
                            >
                              {olt.companyName}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/open-single-olt-for-diagnose/${
                                olt.intPkCompanyID
                              }/${olt.companyName}/${
                                olt.intPkOltID
                              }/${getOltType(olt)}`}
                            >
                              {olt.oltName}
                            </Link>
                          </td>
                          <td>{olt.brand}</td>
                          <td>{olt.model}</td>
                          <td>{olt.host}</td>
                          <td>{olt.port}</td>
                          <td>
                            {olt.nodes.botNode !== false
                              ? olt.nodes.botNode.botNodeName
                              : "-"}
                          </td>
                          <td>
                            {olt.nodes.netFlowBotNode !== false
                              ? olt.nodes.netFlowBotNode.botNodeName
                              : "-"}
                          </td>
                          <td>
                            {olt.brand === "VSOL" ? (
                              <Button
                                variant="primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  postOltSync({
                                    intPkOltID: Number(olt.intPkOltID),
                                  });
                                }}
                              >
                                Sync
                              </Button>
                            ) : (
                              "-"
                            )}
                          </td>

                          <td>
                            {olt.brand === "Huawei" ? (
                              <Link
                                className="button-blue link-class"
                                to={`/all-slots-by-olt/${olt.intPkOltID}/${olt.oltName}`}
                              >
                                View Slots
                              </Link>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            <Link
                              className="button-blue link-class"
                              to={`/all-vlans-by-olt/${olt.intPkOltID}/${olt.oltName}/${olt.brand}`}
                            >
                              View Vlans
                            </Link>
                          </td>
                          <td>
                            {olt.enable == 0 ? (
                              <>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    enableOLT(olt.oltName, olt.intPkOltID);
                                  }}
                                  variant="primary"
                                >
                                  Enable
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    disableOLT(olt.oltName, olt.intPkOltID);
                                  }}
                                  variant="danger"
                                >
                                  Disable
                                </Button>
                              </>
                            )}
                          </td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveOlt(olt);
                                setShowEdit(true);
                                setActiveCompanyName(olt.companyName);
                                setActiveCompanyId(olt.intPkCompanyID);
                              }}
                              variant="warning"
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default SearchOltsComponentForNOC;
