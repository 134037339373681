import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
import { notifySuccess } from "../toast/Success";
import { notifyError } from "../toast/Error";
type Props = {
  isLoggedIn?: boolean;
};

const UpdatePasswordComponent: React.FC<Props> = ({}) => {
  const [
    postUpdatePassword,
    updatePasswordResponse,
    updatePasswordError,
    updatePasswordLoading,
    updatePasswordMessage,
    updatePasswordErrorCount,
  ] = useAxiosPost<UpdateUerPasswordApiData>("users/updatePassword");

  const UpdatePasswordValidationSchema = yup.object().shape({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required(),
  });

  useEffect(() => {
    if (updatePasswordError !== null && updatePasswordErrorCount !== 0) {
      notifyError(updatePasswordError);
    }
  }, [updatePasswordError, updatePasswordErrorCount]);
  useEffect(() => {
    if (updatePasswordResponse !== null) {
      const { message } = updatePasswordResponse;
      if (message) {
        notifySuccess(message);
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    }
  }, [updatePasswordResponse]);

  const [passwordData, setPasswordData] = useState<UpdateUerPasswordProp>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleCreateCOO = async (event: any) => {
    event.preventDefault();
    const dataToSend: UpdateUerPasswordApiData = passwordData;
    try {
      const isValid = await UpdatePasswordValidationSchema.validate(dataToSend);
      if (passwordData.confirmPassword !== passwordData.newPassword) {
        notifyError(`New Password Mismatch`);
        return;
      }
      postUpdatePassword(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs lg="12" md="12" xl="12" className="p-5">
          <Card className="p-5">
            <Card.Title className="text-center olt-heading fw-700 pt-3 ">
              {" "}
              Update Password
            </Card.Title>
            <Form className="mt-4">
              <Row className="mt-2">
                <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                  <Row>
                    <Col
                      style={{ lineHeight: "50px" }}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      xs={12}
                      sm={12}
                    >
                      Enter Current Password
                    </Col>
                    <Col md={7} lg={7} xl={7} xxl={7} xs={12} sm={12}>
                      <Form.Control
                        className="min-height-50p input-shade-gray bd-35p"
                        placeholder={`Enter Current Password`}
                        type="text"
                        value={passwordData.currentPassword}
                        onChange={(e) => {
                          setPasswordData({
                            ...passwordData,
                            currentPassword: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                  <Row>
                    <Col
                      style={{ lineHeight: "50px" }}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      xs={12}
                      sm={12}
                    >
                      Enter New Password
                    </Col>
                    <Col md={7} lg={7} xl={7} xxl={7} xs={12} sm={12}>
                      <Form.Control
                        className="min-height-50p input-shade-gray bd-35p"
                        placeholder={`Enter New Password`}
                        type="text"
                        value={passwordData.newPassword}
                        onChange={(e) => {
                          setPasswordData({
                            ...passwordData,
                            newPassword: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12} lg={12} xs={12} sm={12} xl={12} xxl={12}>
                  <Row>
                    <Col
                      style={{ lineHeight: "50px" }}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      xs={12}
                      sm={12}
                    >
                      Confirm New Password
                    </Col>
                    <Col md={7} lg={7} xl={7} xxl={7} xs={12} sm={12}>
                      <Form.Control
                        className="min-height-50p input-shade-gray bd-35p"
                        placeholder={`Confirm New Password`}
                        type="text"
                        value={passwordData.confirmPassword}
                        onChange={(e) => {
                          setPasswordData({
                            ...passwordData,
                            confirmPassword: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col
                  className="text-center mt-5"
                  md={12}
                  lg={12}
                  xs={12}
                  sm={12}
                  xl={12}
                  xxl={12}
                >
                  <button
                    className="button-primary"
                    type="submit"
                    disabled={updatePasswordLoading}
                    onClick={handleCreateCOO}
                  >
                    Update Password
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default UpdatePasswordComponent;
