import React, { useEffect, useState, ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";

import { toast } from "react-toastify";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import useAxiosPost from "../../hooks/useAxiosPost";
const UpdateVlanDetailsFromOltModalComponent: React.FC<
  UpdateVlanFromOLTResponseModelProp
> = ({
  handleClose,
  show,
  intPkOltID,
  vlanName,
  vlan,
  intPkIspID,
  intPkVlanID,
}) => {
  const [
    getApiData,
    apiResponseData,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("isps/getAllIsps");

  const [
    postBotData,
    linkedBotsResponse,
    linkingError,
    linkingLoading,
    vlanMessage,
    linkingErrorCount,
  ] = useAxiosPost<UpdateEponVlanFromOltResponse>("vlans/updateVlanDetails");
  const CreateVlanValidationSchema = yup.object().shape({
    vlanName: yup
      .string()
      .required()
      .min(3, "Vlan Name must be 3 characters long"),
    intPkIspID: yup.string().required("Invalid ISP"),
  });
  const [allIsps, setAllIsps] = useState<any>([]);
  const [actualIsps, setActualIsps] = useState<any>([]);

  const [vlanNameValue, setVlanName] = useState<string>("");
  const [selectedISP, setSelectedISP] = useState<string>("");
  const [vlanType, setVlanType] = useState<string>("INTERNET");
  const [dbaProfile, setDBAProfile] = useState<number>(10);
  const [ispsType, setIspsType] = useState<string>("native");
  useEffect(() => {
    getApiData({});
    setSelectedISP(intPkIspID.toString());
  }, []);

  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponseData !== null) {
      const responseData: any = apiResponseData.responseData;
      // setAllIsps(responseData || []);
      setActualIsps(responseData || []);
      const native = responseData.filter(
        (item: any) => item.intPkCompanyID == null
      );
      setAllIsps(native || []);
    }
  }, [apiResponseData]);
  useEffect(() => {
    setVlanName(vlanName);
  }, []);

  //   useEffect(() => {
  //     console.log({ intPkNetFlowBotNodeID, intPkBotNodeID, intPkOltID });
  //     setBotsData({
  //       intPkOltID: intPkOltID,
  //       intPkBotNodeID: intPkBotNodeID,
  //       intPkNetFlowBotNodeID: intPkNetFlowBotNodeID,
  //     });
  //   }, []);
  const handleCreateVlan = async (event: any) => {
    event.preventDefault();
    const dataToSend = {
      vlanName: vlanNameValue,
      intPkOltID: intPkOltID,
      intPkIspID: selectedISP,
      intPkVlanID,
    };
    try {
      const isValid = await CreateVlanValidationSchema.validate(dataToSend);
      console.log({ isValid, dataToSend });
      postBotData(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (linkingError !== null && linkingErrorCount !== 0) {
      notifyError(linkingError);
    }
  }, [linkingError, linkingErrorCount]);
  useEffect(() => {
    if (linkedBotsResponse !== null) {
      const { message } = linkedBotsResponse;
      if (message) {
        notifySuccess(message);
      }
      handleClose(true);
    }
  }, [linkedBotsResponse]);

  const handleChange = (e: any) => {
    e.persist();
    console.log(e.target.value);
    setIspsType(e.target.value);
    if (e.target.value === "native") {
      //it means we wil get natives.
      const native = actualIsps.filter(
        (item: any) => item.intPkCompanyID == null
      );
      setAllIsps(native);
    } else if (e.target.value === "MyIsp") {
      const myIsps = actualIsps.filter(
        (item: any) => item.intPkCompanyID !== null
      );
      setAllIsps(myIsps);
    }
  };

  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Update Vlan Details <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label> Vlan</Form.Label>

            <Form.Control type="text" value={vlan} disabled={true} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label> VlanName</Form.Label>

            <Form.Control
              type="text"
              value={vlanNameValue}
              onChange={(e) => {
                setVlanName(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" style={{ display: "flex" }}>
            <Form.Check
              value="native"
              type="radio"
              aria-label="Native"
              label="Native"
              onChange={handleChange}
              checked={ispsType === "native"}
            />
            <Form.Check
              value="MyIsp"
              type="radio"
              aria-label="radio 2"
              label="My Isps"
              onChange={handleChange}
              checked={ispsType === "MyIsp"}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label> Select ISP</Form.Label>
            <Form.Select
              value={selectedISP}
              onChange={(e) => {
                setSelectedISP(e.target.value);
              }}
            >
              <option value={""}>Choose ISP</option>
              {allIsps.map((item: any) => {
                return (
                  <option key={item.intPkIspID} value={item.intPkIspID}>
                    {item.ispName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateVlan}
              disabled={linkingLoading}
              className="button-primary"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UpdateVlanDetailsFromOltModalComponent;
