//NewOLTModelPage
import AllSlotsModelsComponent from "../../components/admin/models/AllSlotsModels";

const AllSlotsModelsPage = () => {
  return (
    <>
      <AllSlotsModelsComponent />
    </>
  );
};
export default AllSlotsModelsPage;
