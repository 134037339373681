import React, { useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";

const CreateNewPricePolicyModalComponent: React.FC<
  ShowModalForCreatingNewPricePolicy
> = ({ show, handleClose }) => {
  const [
    postBrandsData,
    brandsResponse,
    brandsError,
    brandsLoading,
    brandsMessage,
    brandsErrorCount,
  ] = useAxiosPost<ResponseProp>("superCOO/getAllBrands");

  const [
    postOltModelsData,
    modelsResponse,
    modelsError,
    modelsLoading,
    modelsMessage,
    modelsErrorCount,
  ] = useAxiosPost<ResponseProp>("superCOO/getAllOltModels");
  const [brands, setBrands] = useState<any>([]);
  const [oltModels, setOltModels] = useState<any>([]);
  const [actualOntModels, setActualModels] = useState<any>([]);
  useEffect(() => {
    if (brandsResponse !== null) {
      console.log({ brandsResponse });
      setBrands(brandsResponse.responseData || []);
    }
  }, [brandsResponse]);
  useEffect(() => {
    if (modelsResponse !== null) {
      console.log({ modelsResponse });
      setActualModels(modelsResponse.responseData || []);
    }
  }, [modelsResponse]);

  useEffect(() => {
    postOltModelsData({});
    postBrandsData({});
  }, []);
  const [
    postCrateNewPricePolicy,
    pricePolicyResponse,
    pricePolicyError,
    pricePolicyLoading,
    endUserMessage,
    pricePolicyErrorCount,
  ] = useAxiosPost<CreateNewPricePolicyModelProp>(
    "superAdmin/createNewPricePolicy"
  );

  useEffect(() => {
    if (pricePolicyError !== null && pricePolicyErrorCount !== 0) {
      notifyError(pricePolicyError);
    }
  }, [pricePolicyError, pricePolicyErrorCount]);
  useEffect(() => {
    if (pricePolicyResponse !== null) {
      const response: any = pricePolicyResponse;
      const { message, responseData } = response;

      if (message) {
        notifySuccess(message);

        handleClose(true);
      }
    }
  }, [pricePolicyResponse]);

  const createEndUserValidationSchema = yup.object().shape({
    intPkBrandID: yup.number().integer().required().min(1),
    intPkOLTModelID: yup.number().integer().required().min(1),
    trialPeriodDays: yup.number().integer().required().min(0).max(10),
    isTrialAllowedOrNot: yup.number().integer().required().min(0),
    price: yup.number().integer().required().min(0),
    invoiceDueDaysBeforeDisable: yup
      .number()
      .integer()
      .required()
      .min(0)
      .max(10),
    discountPercentage: yup.number().integer().required().min(0).max(10),
  });

  const [pricePolicyModelData, setPricePolicyModelData] =
    useState<CreateNewPricePolicyModelProp>({
      intPkBrandID: "",
      intPkOLTModelID: "",
      trialPeriodDays: "",
      isTrialAllowedOrNot: "",
      price: "0",
      invoiceDueDaysBeforeDisable: "0",
      discountPercentage: "0",
    });
  const handleCreateArea = async (event: any) => {
    event.preventDefault();
    if (pricePolicyModelData.isTrialAllowedOrNot == "") {
      notifyError(`Either Choose yes or no for Give Trail  `);
      return;
    }
    const dataToSend: CreateNewPricePolicyModelProp = pricePolicyModelData;
    try {
      const isValid = await createEndUserValidationSchema.validate(dataToSend);
      postCrateNewPricePolicy(dataToSend);
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPricePolicyModelData({
      ...pricePolicyModelData,
      [name]: value,
    });
  };
  const renderInput = (
    name: string,
    placeholder: string,
    label: string,
    type: string = "text"
  ) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label> {label}</Form.Label>
        <Form.Control
          placeholder={`${placeholder}`}
          type={type}
          value={pricePolicyModelData[name]}
          name={`${name}`}
          onChange={handleInputChange}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <Modal
        keyboard={false}
        backdrop="static"
        show={show}
        onHide={() => {
          handleClose(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Price Policy Model <b>{""}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className=" p-5">
                <h3 className="mb-5 text-center">Create Price Policy Model</h3>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label> Choose OLT Brand</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        const models = actualOntModels.filter(
                          (item: any) => item.intPkBrandID == e.target.value
                        );
                        setOltModels(models);
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          intPkBrandID: e.target.value,
                        });
                      }}
                      value={pricePolicyModelData.intPkBrandID}
                    >
                      <option value="">Choose OLT Brand</option>
                      {brands.map((item: any) => {
                        return (
                          <option
                            value={item.intPkBrandID}
                            key={item.intPkBrandID}
                          >
                            {item.brandName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label> Choose OLT Model</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          intPkOLTModelID: e.target.value,
                        });
                      }}
                      value={pricePolicyModelData.intPkOLTModelID}
                    >
                      <option value="">Choose OLT Model</option>
                      {oltModels.map((item: any) => {
                        return (
                          <option
                            value={item.intPkOLTModelID}
                            key={item.intPkOLTModelID}
                          >
                            {item.oltModelName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Trail Period Days</Form.Label>
                    <Form.Control
                      placeholder={`Trail Period Days`}
                      type={"number"}
                      disabled={
                        pricePolicyModelData.isTrialAllowedOrNot == 0
                          ? true
                          : false
                      }
                      value={pricePolicyModelData.trialPeriodDays}
                      onChange={(e) => {
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          trialPeriodDays: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  {renderInput(`price`, "Enter Price", "Enter Price", "number")}
                  <Form.Group className="mb-3">
                    <Form.Label> Choose</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        if (e.target.value == "0") {
                          setPricePolicyModelData({
                            ...pricePolicyModelData,
                            isTrialAllowedOrNot: e.target.value,
                            trialPeriodDays: 0,
                          });
                        } else {
                          setPricePolicyModelData({
                            ...pricePolicyModelData,
                            isTrialAllowedOrNot: e.target.value,
                          });
                        }
                      }}
                      value={pricePolicyModelData.isTrialAllowedOrNot}
                    >
                      <option value="">Choose</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {" "}
                      Choose Invoice Days Before Olt Disable
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          invoiceDueDaysBeforeDisable: e.target.value,
                        });
                      }}
                      value={pricePolicyModelData.invoiceDueDaysBeforeDisable}
                    >
                      <option value="0">No Day</option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: number) => {
                        return (
                          <option value={item} key={item}>
                            {`${item} Days`}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label> Select Discount Percentage</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setPricePolicyModelData({
                          ...pricePolicyModelData,
                          discountPercentage: e.target.value,
                        });
                      }}
                      value={pricePolicyModelData.discountPercentage}
                    >
                      <option value="0">0%</option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: number) => {
                        return (
                          <option value={item} key={item}>
                            {`${item} %`}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Button
                    onClick={handleCreateArea}
                    variant="primary"
                    type="submit"
                    disabled={pricePolicyLoading}
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateNewPricePolicyModalComponent;
